import React, { CSSProperties, useState } from "react";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { ChevronDown, ChevronUp, MoveDown, MoveUp } from "lucide-react";
import useIsMobile from "../../components/hooks/useIsMobile";
import { EngagementReport } from "../../protogen/engagement_service_pb";
import LinkRouter from "../../components/navigation/LinkRouter";
import { daysBetween, pluralize, relativeTime } from "../../common/utils";
import { WrappedRing } from "../../components/time-utilization/TimeUtilization";
import { FamilyStatus } from "../../protogen/advisors_service_pb";

const formatProtoTime = (
  timeSec: undefined | BigInt | bigint | number,
  defaultVal?: null,
) => {
  const lastActivity = new Date(Number(timeSec || 0) * 1000);
  return timeSec && lastActivity.getFullYear() > 2020
    ? relativeTime(lastActivity)
    : defaultVal;
};

enum EngagementState {
  DANGER,
  WARNING,
  GOOD,
}

const lastContactToState = (report: EngagementReport): EngagementState => {
  const lastContactDays = daysBetween(
    new Date(Number(report.stats?.activityLatestCommunicationSec) * 1000),
  );
  if (lastContactDays > 7) {
    return EngagementState.DANGER;
  } else if (lastContactDays > 4) {
    return EngagementState.WARNING;
  } else {
    return EngagementState.GOOD;
  }
};

const utilizationToState = (report: EngagementReport): EngagementState => {
  const percentUtilization =
    report.planUtilization?.periodHoursUtilized /
    report.planUtilization?.planHoursAllocated;
  const daysLeftInPeriod = daysBetween(
    new Date(report.planUtilization?.periodEndDate || ""),
  );
  if (percentUtilization > 1) {
    return EngagementState.DANGER;
  } else if (percentUtilization < 0.4 && daysLeftInPeriod < 14) {
    return EngagementState.WARNING;
  } else {
    return EngagementState.GOOD;
  }
};

const getReportState = (report: EngagementReport): EngagementState => {
  const contactState = lastContactToState(report);
  const utilizationState = utilizationToState(report);
  if (
    contactState === EngagementState.DANGER ||
    utilizationState === EngagementState.DANGER
  ) {
    return EngagementState.DANGER;
  } else if (
    contactState === EngagementState.WARNING ||
    utilizationState === EngagementState.WARNING
  ) {
    return EngagementState.WARNING;
  } else {
    return EngagementState.GOOD;
  }
};

const stateToTextTreatment = (state: EngagementState): CSSProperties => {
  switch (state) {
    case EngagementState.DANGER:
      return { color: "#C14743", fontWeight: 500 };
    case EngagementState.WARNING:
      return { color: "#F59F51", fontWeight: 500 };
    case EngagementState.GOOD:
    default:
      return {};
  }
};

const reportToRowColor = (report: EngagementReport): string => {
  const state = getReportState(report);
  if (report.family?.status !== FamilyStatus.ACTIVE) {
    return "#D4D4D4";
  }
  switch (state) {
    case EngagementState.DANGER:
      return "#EF7B77";
    case EngagementState.WARNING:
      return "#F59F51";
    case EngagementState.GOOD:
      return "#468E3E";
  }
};

type FamilyReportRowProps = {
  report: EngagementReport;
};

const FamilyReportRow = ({ report }: FamilyReportRowProps) => {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  const percentUtilization = (
    (report.planUtilization?.periodHoursUtilized /
      report.planUtilization?.planHoursAllocated) *
    100
  ).toFixed(0);
  const daysUntilRenewal = daysBetween(report.planUtilization?.periodEndDate);
  return (
    <Box
      sx={{
        borderRadius: "8px",
        border: "1px solid #D4D4D4",
        background: "#FFF",
        overflow: "hidden",
      }}
    >
      <Box position="relative">
        <div
          style={{
            width: "6px",
            height: "100%",
            position: "absolute",
            background: reportToRowColor(report),
          }}
        ></div>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          sx={{
            padding: "27px 24px",
            alignItems: "center",
            cursor: !open ? "pointer" : undefined,
          }}
          onClick={() => !open && setOpen(!open)}
        >
          <Box flex={1}>
            <LinkRouter
              to={`/families/${encodeURIComponent(report.family.ref)}`}
            >
              <Typography variant="bodyHeavy">{report.family.name}</Typography>
            </LinkRouter>
          </Box>
          <Box flex={1} display={isMobile ? "none" : undefined}>
            <LinkRouter
              to={`/advisor/${encodeURIComponent(report.advisor.ref)}`}
            >
              <Typography variant="body">
                {report.advisor.displayName}
              </Typography>
            </LinkRouter>
          </Box>
          <Box
            flex={1}
            sx={{
              ...(report.family?.status === FamilyStatus.ACTIVE
                ? stateToTextTreatment(lastContactToState(report))
                : {}),
            }}
          >
            {formatProtoTime(report.stats?.activityLatestCommunicationSec)}
          </Box>
          <Box
            flex={1}
            display={isMobile ? "none" : undefined}
            sx={{
              ...(report.family?.status === FamilyStatus.ACTIVE
                ? stateToTextTreatment(utilizationToState(report))
                : {}),
            }}
          >{`${percentUtilization}%`}</Box>
          <Box flex={1} display={isMobile ? "none" : undefined}>
            {daysUntilRenewal} {pluralize("day", daysUntilRenewal)}
          </Box>
          <Box flex={1} display={isMobile ? "none" : undefined}>
            {report.family.startDate
              ? new Date(report.family.startDate).toLocaleDateString("en-US", {
                  month: "short",
                  year: "numeric",
                  day: "numeric",
                })
              : ""}
          </Box>
          <Box flex={0}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <ChevronUp /> : <ChevronDown />}
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <div
          style={{
            height: "1px",
            background: "#E2E3E4",
          }}
        />
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
            <Box sx={{ padding: "24px 32px" }} flex={2}>
              <Typography variant="h3" marginBottom="10px">
                Tasks
              </Typography>
              <Box display="flex" flexDirection="row" gap="15px">
                <Box display="flex" flexDirection="column" gap="10px">
                  <Typography variant="bodySmallHeavy">Open tasks</Typography>
                  <Typography variant="bodySmallHeavy">
                    Completed tasks
                  </Typography>
                  <Typography variant="bodySmallHeavy">
                    Latest Activity
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap="10px">
                  <Typography variant="bodySmall">
                    {report.stats?.tasksOpen}
                  </Typography>
                  <Typography variant="bodySmall">
                    {report.stats?.tasksCompleted}
                  </Typography>
                  <Typography variant="bodySmall">
                    {formatProtoTime(report.stats?.tasksLatestActivitySec)}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <div
              style={{
                width: "1px",
                background: "#E2E3E4",
              }}
            />
            <Box sx={{ padding: "24px 32px" }} flex={2}>
              <Typography variant="h3" marginBottom="10px">
                Communications
              </Typography>
              <Box display="flex" flexDirection="row" gap="15px">
                <Box display="flex" flexDirection="column" gap="10px">
                  <Typography variant="bodySmallHeavy">
                    Last activity
                  </Typography>
                  <Typography variant="bodySmallHeavy">
                    Unread Emails
                  </Typography>
                  <Typography variant="bodySmallHeavy">
                    Unread Messages
                  </Typography>
                  <Typography variant="bodySmallHeavy">Unread Tasks</Typography>
                  <Typography variant="bodySmallHeavy">
                    7 Day activity
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap="10px">
                  <Typography variant="bodySmall">
                    {new Date(
                      Number(report.stats?.activityLatestCommunicationSec) *
                        1000,
                    ).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    })}
                  </Typography>
                  <Typography variant="bodySmall">
                    {report.stats?.activityUnreadEmailCount}
                  </Typography>
                  <Typography variant="bodySmall">
                    {report.stats?.activityUnreadMessageCount}
                  </Typography>
                  <Typography variant="bodySmall">
                    {report.stats?.activityTaskMessageCount}
                  </Typography>
                  <Typography variant="bodySmall">
                    {report.stats?.activitySevenDayCount}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <div
              style={{
                width: "1px",
                background: "#E2E3E4",
              }}
            />
            <Box sx={{ padding: "24px 32px" }} flex={3}>
              <Typography variant="h3" marginBottom="10px">
                Plan
              </Typography>
              <Box display="flex" flexDirection="row" gap="15px">
                <Box display="flex" flexDirection="column" gap="10px">
                  <Typography variant="bodySmallHeavy">Monthly rate</Typography>
                  <Typography variant="bodySmallHeavy">Renews</Typography>
                  <Typography variant="bodySmallHeavy">Avg. Hours</Typography>
                  <Typography variant="bodySmallHeavy">Member since</Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap="10px">
                  <Typography variant="bodySmall">
                    {report.family?.billingInfo?.product?.amountCents ? (
                      <>
                        $
                        {(
                          report.family?.billingInfo?.product?.amountCents / 100
                        ).toFixed(0)}
                      </>
                    ) : (
                      "-"
                    )}
                  </Typography>
                  <Typography variant="bodySmall">
                    {/* format date as "Aug 13" */}
                    {new Date(
                      report.planUtilization?.periodEndDate,
                    ).toLocaleDateString("en-US", {
                      month: "short",
                      day: "numeric",
                    })}
                  </Typography>
                  <Typography variant="bodySmall">
                    {report.stats?.averageMonthlyHours.toFixed(1)}
                  </Typography>
                  <Typography variant="bodySmall">
                    {new Date(report.family.startDate).toLocaleDateString(
                      "en-US",
                      { month: "short", year: "numeric" },
                    )}
                  </Typography>
                </Box>
                <WrappedRing planUtilization={report.planUtilization} />
              </Box>
            </Box>
          </Box>
          <div
            style={{
              height: "1px",
              background: "#E2E3E4",
            }}
          />
          {/*<Box*/}
          {/*  display="flex"*/}
          {/*  flexDirection="column"*/}
          {/*  sx={{ padding: "24px 32px" }}*/}
          {/*>*/}
          {/*  <Typography variant="h3">Vibe check</Typography>*/}
          {/*  <Typography variant="body">*/}
          {/*    Client seems pleased that Mel has been proactive in suggesting*/}
          {/*    tasks. However, there are large gaps of time in which there are no*/}
          {/*    communications and blah blah blah.*/}
          {/*  </Typography>*/}
          {/*</Box>*/}
        </Box>
      </Collapse>
    </Box>
  );
};

type SortFields = { field: string; asc: boolean }[];

const sortFamiliesByFields = (
  reports: EngagementReport[],
  sortFields: SortFields,
) => {
  const dateStrToNum = (dateStr: string) => {
    if (!dateStr) return 0;
    const date = new Date(dateStr);
    return date.getTime();
  };
  return reports.sort((a, b) => {
    for (const sort of sortFields) {
      let valueA: string | number | bigint | undefined;
      let valueB: string | number | bigint | undefined;

      // Determine which field to sort by based on "name"
      if (sort.field === "state") {
        valueA = getReportState(a);
        valueB = getReportState(b);
      } else if (sort.field === "familyName") {
        valueA = a.family?.name.toLowerCase();
        valueB = b.family?.name.toLowerCase();
      } else if (sort.field === "advisorName") {
        valueA = a.advisor?.displayName.toLowerCase();
        valueB = b.advisor?.displayName.toLowerCase();
      } else if (sort.field === "lastActivity") {
        // Reversed since we want the most recent activity first.
        valueA = b.stats?.activityLatestCommunicationSec;
        valueB = a.stats?.activityLatestCommunicationSec;
      } else if (sort.field === "utilization") {
        valueA =
          a.planUtilization?.periodHoursUtilized /
          a.planUtilization?.planHoursAllocated;
        valueB =
          b.planUtilization?.periodHoursUtilized /
          b.planUtilization?.planHoursAllocated;
      } else if (sort.field === "renewal") {
        valueA = dateStrToNum(a.planUtilization?.periodEndDate);
        valueB = dateStrToNum(b.planUtilization?.periodEndDate);
      } else if (sort.field === "startDate") {
        valueA = dateStrToNum(a.family?.startDate);
        valueB = dateStrToNum(b.family?.startDate);
      }

      // Perform comparison
      if (valueA !== valueB) {
        if (valueA === undefined) return sort.asc ? -1 : 1;
        if (valueB === undefined) return sort.asc ? 1 : -1;

        if (valueA < valueB) return sort.asc ? -1 : 1;
        if (valueA > valueB) return sort.asc ? 1 : -1;
      }
    }
    return 0; // If all fields are equal
  });
};

const ColumnHeader = ({
  title,
  sortKey,
  sorts,
  setSorts,
  hideMobile = false,
}: {
  title: string;
  sortKey: string;
  sorts: SortFields;
  setSorts: (s: SortFields) => void;
  hideMobile?: boolean;
}) => {
  const isMobile = useIsMobile();
  if (hideMobile && isMobile) return null;
  const arrowSx = {
    height: "16px",
    width: "16px",
    paddingTop: "3px",
    marginRight: "3px",
  };
  // Default to down unless it is present and set to false.
  const currentSortVal = sorts.find((s) => s.field === sortKey);
  const upArrow = currentSortVal && !currentSortVal.asc;

  const onClick = () => {
    const newSorts = sorts.filter((s) => s.field !== sortKey);
    // First sort should always be ascending.
    if (upArrow || !currentSortVal) {
      newSorts.unshift({ field: sortKey, asc: true });
    } else {
      newSorts.unshift({ field: sortKey, asc: false });
    }
    setSorts(newSorts);
  };
  return (
    <Typography
      flex={1}
      variant="bodyHeavy"
      onClick={onClick}
      sx={{
        cursor: "pointer",
        userSelect: "none",
      }}
    >
      {upArrow ? <MoveUp style={arrowSx} /> : <MoveDown style={arrowSx} />}
      {title}
    </Typography>
  );
};

export { sortFamiliesByFields, ColumnHeader, FamilyReportRow };
