import React, { useEffect, useState } from "react";
import { ForumPost } from "../../protogen/forum_service_pb";
import { useEditPostLike } from "../../services/forum";
import { Box, Typography } from "@mui/material";
import { Heart, MessageSquare } from "lucide-react";
import LikerTooltip from "./LikerTooltip";
import { pluralize } from "../../common/utils";

export default ({ post }: { post: ForumPost }) => {
  const [postLikes, setPostLikes] = useState<number>(0);
  const [isLikedByUser, setIsLikeByUser] = useState<boolean>(false);

  const { request } = useEditPostLike((r) => {
    if (r.isLiked === undefined) return;
    setPostLikes((prev) => (r.isLiked ? prev + 1 : prev - 1));
    setIsLikeByUser(r.isLiked);
  });
  const handleEditCommentLike = () => {
    request({
      postRef: post.ref,
      isLiked: !isLikedByUser,
    });
  };

  useEffect(() => {
    setPostLikes(post.totalLikes);
    setIsLikeByUser(post.isLikedByUser);
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="12px"
      width={{ xs: "100%", md: "auto" }}
    >
      <LikerTooltip likers={post.likerHints} total={postLikes}>
        <Box
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleEditCommentLike();
          }}
          sx={{
            display: "flex",
            alignItems: "center",
            color: "#6B6E7B",
            cursor: "pointer",
          }}
        >
          {isLikedByUser ? (
            <Heart size="24px" fill="#EF7B77" stroke="#EF7B77" />
          ) : (
            <Heart size="24px" stroke="#8E9598" />
          )}
          <Typography
            variant="body"
            sx={{
              marginLeft: "4px",
              color: "#616161",
            }}
          >
            {postLikes} {pluralize("like", postLikes)}
          </Typography>
        </Box>
      </LikerTooltip>
      <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
        <MessageSquare size="24px" stroke="#8E9598" />
        <Typography
          variant="body"
          sx={{
            marginLeft: "4px",
            color: "#616161",
          }}
        >
          {post.commentCount} {post.commentCount == 1 ? "reply" : "replies"}
        </Typography>
      </Box>
    </Box>
  );
};
