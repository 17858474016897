import useIsVisible from "../hooks/useIsVisible";
import React, {
  forwardRef,
  MouseEvent,
  useContext,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  useArchiveEmails,
  useAssignEmailToInbox,
  useFetchEmailInbox,
  useMarkEmailsUnreadStatus,
} from "../../services/email";
import { List, ListItem, Box, Button, Typography } from "@mui/material";
import { ReactComponent as EllipseIcon } from "../../icons/Menu/Ellipse.svg";
import WithDividers from "../helpers/WithDividers";
import Loading from "../common/Loading";
import { protoInt64 } from "@bufbuild/protobuf";
import { FetchEmailInboxResponse_EmailMessageThread as EmailMessageThread } from "../../protogen/email_service_pb";
import EmailCompose from "../email/EmailComposeDialog";
import { Family } from "../../protogen/advisors_service_pb";
import { useListAdvisorFamilies } from "../../services/advisor";
import { AutocompleteEntry } from "../email/AddressCompletion";
import { InboxHandle } from "./utils";
import DateDisplay from "../common/DateDisplay";
import RoundedDropDown from "../common/RoundedDropDown";
import useIsMobile from "../hooks/useIsMobile";
import { Mail, MailOpen, Trash2 } from "lucide-react";
import Checkbox from "../common/Checkbox";
import ConfirmationDialog, {
  useConfirmationDialog,
} from "../common/ConfirmationDialog";
import { CurrentUserContext } from "../context/RequireAuth";

type InboxSelectorProps = {
  thread: EmailMessageThread;
  families: Family[];
  onUpdate: () => void;
};
const InboxSelector = ({ thread, families, onUpdate }: InboxSelectorProps) => {
  const currentUser = useContext(CurrentUserContext);
  const { request, loading } = useAssignEmailToInbox(() => {
    onUpdate();
  });
  const onAssign = async (entityRef: string | null) => {
    if (!entityRef) return;
    if (entityRef === "my-inbox") {
      await request({
        emailRef: thread.latestEmailRef,
        inboxType: "advisor",
        inboxEntityRef: currentUser.ref,
      });
    } else {
      await request({
        emailRef: thread.latestEmailRef,
        inboxType: "family",
        inboxEntityRef: entityRef,
      });
    }
  };
  // All other emails at this time can be re-assigned.
  return (
    <RoundedDropDown
      initial={thread.family?.ref || null}
      // For now, family emails can't be re-assigned.
      disabled={!!thread.family || loading}
      onChange={async (s) => await onAssign(s?.value || null)}
      options={[
        ...families
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((f) => ({
            label: f.name,
            value: f.ref,
          })),
        {
          label: "My inbox",
          value: "my-inbox",
        },
      ]}
      displayValue={!thread.family ? "My inbox" : undefined}
      small={true}
    />
  );
};

interface EmailListItemProps {
  thread: EmailMessageThread;
  families: Family[];
  onUpdate: () => void;
  selected?: boolean;
  onClick?: (latestEmailRef: string) => void;
  checked?: boolean;
  onCheck?: (checked: boolean) => void;
}

const EmailListItem = ({
  thread,
  families,
  onUpdate,
  onClick,
  selected = false,
  checked,
  onCheck,
}: EmailListItemProps) => {
  const isMobile = useIsMobile();
  const [markedRead, setMarkedRead] = useState(false);
  useEffect(() => {
    if (thread.unread && markedRead) {
      setMarkedRead(false);
    }
  }, [thread]);
  const unread = thread.unread && !markedRead;
  return (
    <ListItem
      onClick={() => {
        setMarkedRead(true);
        onClick && onClick(thread.latestEmailRef);
        checked !== undefined && onCheck?.(!checked);
      }}
      key={thread.latestEmailRef}
      sx={{
        cursor: "pointer",
        backgroundColor: selected ? "#FAF9FA" : "white",
        width: "100%",
        padding: !isMobile ? "16px 28px" : "16px 18px",
        gap: "8px",
        alignItems: "start",
      }}
    >
      {checked !== undefined && (
        <Box display="flex" flexDirection="column" justifyContent="start">
          <Checkbox
            checked={checked}
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onCheck?.(e.target.checked);
            }}
            size={!isMobile ? "small" : undefined}
            sx={{ padding: 0 }}
          />
        </Box>
      )}
      <Box display="flex" flexDirection="column" gap="2px" width="100%">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex" }}>
            <Typography
              component="div"
              variant="bodySmall"
              color="text.tertiary"
            >
              {unread && (
                <EllipseIcon
                  height={8}
                  width={8}
                  style={{ marginRight: "5px" }}
                />
              )}
            </Typography>
            <Typography
              variant={unread ? "bodyHeavy" : "body"}
              color="text.primary"
            >
              {/* TODO(Kip): This isn't to design, but it's going to take some work to wire up the senders on the backend. */}
              {thread.sender}
            </Typography>
          </Box>
          <DateDisplay
            date={new Date(Number(thread.lastMessageSec) * 1000)}
            color="text.secondary"
            sx={{ textAlign: "end" }}
          />
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Typography
            component="div"
            variant={unread ? "bodyHeavy" : "body"}
            sx={{
              flexGrow: 1,
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {thread.latestIsDraft && (
              <Typography
                component="span"
                color="secondary.main"
                style={{
                  marginRight: "8px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              >
                Draft:
              </Typography>
            )}
            {thread.subject}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant={unread ? "bodySmallHeavy" : "bodySmall"}
            sx={{
              color: "#616161",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {thread.teaserText}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "8px",
          }}
        >
          <InboxSelector
            thread={thread}
            families={families}
            onUpdate={onUpdate}
          />
        </Box>
      </Box>
    </ListItem>
  );
};

const dedupeEmails = (
  a: EmailMessageThread[],
  b: EmailMessageThread[],
): EmailMessageThread[] => {
  const seen = new Set<string>();
  return b.concat(a).filter((thread) => {
    if (seen.has(thread.latestEmailRef)) {
      return false;
    }
    seen.add(thread.latestEmailRef);
    return true;
  });
};

const sortEntryLastUpdated = (a: EmailMessageThread, b: EmailMessageThread) => {
  const aTs = protoInt64.parse(a.lastMessageSec!);
  const bTs = protoInt64.parse(b.lastMessageSec!);
  return aTs < bTs ? 1 : aTs > bTs ? -1 : 0;
};

const InboxToolbar = ({
  markAsRead,
  checkedMessages,
  refresh,
  reset,
}: {
  markAsRead: boolean;
  checkedMessages: null | Set<string>;
  refresh: () => void;
  reset: (empty: boolean) => void;
}) => {
  const [multiSelect, setMultiSelect] = useState(false);
  const confirmState = useConfirmationDialog();
  const { request: unreadRequest, loading: unreadLoading } =
    useMarkEmailsUnreadStatus();
  const { request: archiveRequest, loading: archiveLoading } =
    useArchiveEmails();
  const loading = unreadLoading || archiveLoading;
  const changeReadStatus = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!loading && checkedMessages?.size) {
      await unreadRequest({
        emailRefs: Array.from(checkedMessages.values()),
        markAsRead: markAsRead,
      });
      refresh();
    }
  };
  const archiveEmail = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!loading && checkedMessages?.size) {
      confirmState.openDialog(async () => {
        await archiveRequest({
          emailRefs: Array.from(checkedMessages.values()),
        });
        reset(false);
        refresh();
      });
    }
  };
  const btnSx = {
    height: "unset",
    minWidth: "unset",
    ".MuiButton-startIcon": { marginLeft: 0, marginRight: "4px" },
  };
  useEffect(() => {
    // Reset state!
    if (!multiSelect) {
      reset(true);
    } else {
      reset(false);
    }
  }, [multiSelect]);
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="row"
      justifyContent="space-between"
      sx={{
        padding: "0 28px",
        marginBottom: "8px",
      }}
    >
      {!multiSelect && (
        <Button
          sx={btnSx}
          variant="text"
          size="small"
          onClick={() => setMultiSelect(true)}
        >
          Select
        </Button>
      )}
      {multiSelect && (
        <Box display="flex" flexDirection="row" gap={"20px"}>
          <Button
            sx={btnSx}
            variant="text"
            startIcon={
              markAsRead ? <MailOpen size="16px" /> : <Mail size="16px" />
            }
            size="small"
            onClick={changeReadStatus}
            disabled={checkedMessages?.size === 0 || loading}
          >
            {markAsRead ? "Mark read" : "Mark unread"}
          </Button>
          <Button
            disabled={checkedMessages?.size === 0 || loading}
            sx={btnSx}
            variant="text"
            startIcon={<Trash2 size="16px" />}
            size="small"
            onClick={archiveEmail}
          >
            Delete
          </Button>
        </Box>
      )}
      {/*<Button*/}
      {/*  sx={btnSx}*/}
      {/*  variant="text"*/}
      {/*  endIcon={<ChevronDown size="16px" />}*/}
      {/*  size="small"*/}
      {/*>*/}
      {/*  Show all*/}
      {/*</Button>*/}
      <ConfirmationDialog
        title="Delete emails"
        noIcon={true}
        content="Are you sure you want to delete these emails?"
        {...confirmState.dialogProps}
      />
    </Box>
  );
};

interface EmailInboxProps {
  selectedRef?: string;
  onEntrySelect(latestEmailRef: string): void;
}

export default forwardRef<InboxHandle, EmailInboxProps>(
  ({ selectedRef, onEntrySelect }: EmailInboxProps, ref) => {
    const { isVisible } = useIsVisible({});
    const [checkedMessages, setCheckedMessages] = useState<null | Set<string>>(
      null,
    );
    const [emailComposeOpen, setEmailComposeOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [feedCursor, setFeedCursor] = useState<string | null>(null);
    const [threads, setThreads] = useState<EmailMessageThread[]>([]);
    const [autoCompleteEntries, setAutoCompleteEntries] = React.useState<
      AutocompleteEntry[]
    >([]);
    useImperativeHandle(ref, () => ({
      triggerCompose: () => setEmailComposeOpen(true),
      triggerRefresh: () => request(),
    }));
    const { request, loading } = useFetchEmailInbox((r) => {
      setLoaded(true);

      setThreads((t) =>
        // When refreshing the first page do a hard refresh
        dedupeEmails(page === 0 ? [] : t, r.threads).sort(sortEntryLastUpdated),
      );
      setAutoCompleteEntries(
        r.autocompleteEntries.map((a) => ({
          name: a.name,
          email: a.email,
        })),
      );
      if (r.threads.length === 0) {
        setHasMore(false);
      }
      setFeedCursor(r.nextCursor);
      if (checkedMessages && checkedMessages.size > 0) {
        const emailRefs = new Set(r.threads.map((t) => t.latestEmailRef));
        setCheckedMessages((s) => {
          if (!s) return null;
          return new Set(Array.from(s).filter((ref) => emailRefs.has(ref)));
        });
      }
    });
    const { request: familyRequest, data: familyData } =
      useListAdvisorFamilies();

    useEffect(() => {
      request();
      familyRequest();
    }, []);

    useEffect(() => {
      const intervalId = setInterval(async () => {
        // Stop refreshing once we've loaded further pages. Just to hard to manage that state.
        if (isVisible && page === 0) {
          request();
        }
      }, 30000);
      return () => clearInterval(intervalId);
    }, [page]);

    const nextPage = async () => {
      if (feedCursor) {
        setPage((p) => p + 1);
        await request({
          cursor: feedCursor,
        });
      }
    };

    if (!loaded) return <Loading />;
    return (
      <>
        <InboxToolbar
          markAsRead={threads.some(
            (t) => checkedMessages?.has(t.latestEmailRef) && t.unread,
          )}
          reset={(empty) =>
            empty
              ? setCheckedMessages(null)
              : setCheckedMessages(new Set(selectedRef ? [selectedRef] : []))
          }
          checkedMessages={checkedMessages}
          refresh={() => request()}
        />
        <Box
          sx={{
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <List
            sx={{
              paddingTop: 0,
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <WithDividers>
              {threads.map((thread) => (
                <EmailListItem
                  checked={checkedMessages?.has(thread.latestEmailRef)}
                  onCheck={(checked) => {
                    if (checked) {
                      setCheckedMessages((s) => {
                        s?.add(thread.latestEmailRef);
                        return new Set(s);
                      });
                    } else {
                      setCheckedMessages((s) => {
                        s?.delete(thread.latestEmailRef);
                        return new Set(s);
                      });
                    }
                  }}
                  key={thread.latestEmailRef}
                  thread={thread}
                  families={familyData?.families || []}
                  onUpdate={() => request()}
                  onClick={onEntrySelect}
                  selected={
                    thread.latestEmailRef === selectedRef ||
                    checkedMessages?.has(thread.latestEmailRef)
                  }
                />
              ))}
            </WithDividers>
            {loaded && threads.length >= 0 && (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                  sx={{ marginTop: "20px" }}
                  variant="outlined"
                  disabled={loading || !hasMore}
                  onClick={nextPage}
                >
                  Load more
                </Button>
              </Box>
            )}
          </List>
          <EmailCompose
            closed={!emailComposeOpen}
            onClose={() => {
              setEmailComposeOpen(false);
              // Refresh the inbox after creating a draft.
              request();
            }}
            onSent={(e) => {
              onEntrySelect(e.emailMessage?.ref || "");
            }}
            onRemove={() => {
              request();
            }}
            autocompleteEntries={autoCompleteEntries}
            // TODO: Handle replies...
            // inReplyTo={feedFocusState.replyToEmail}
            // TODO: Handle drafts...
            // initialDraft={feedFocusState.editEmailDraft}
          />
        </Box>
      </>
    );
  },
);
