import { EmailMessage } from "../../protogen/conversation_pb";
import { FeedFocusState } from "../../types/feed";
import FeedEntryBox from "../common/FeedEntryBox";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as MailIcon } from "../../icons/MailIcon.svg";
import LinkRouter from "../navigation/LinkRouter";
import { Badge } from "@mui/material";

type EmailPreviewProps = {
  email: EmailMessage;
  isAdvisor: boolean;
  feedFocusState: FeedFocusState;
  setFeedFocusState: (s: FeedFocusState) => void;
};
export default ({ email }: EmailPreviewProps) => {
  return (
    <LinkRouter to={`/inbox/email/${encodeURIComponent(email.ref)}`}>
      <FeedEntryBox
        header={
          email.isDraft ? (
            <>
              <span
                style={{
                  color: "#C14743",
                  marginRight: "4px",
                }}
              >
                [draft]
              </span>
              {email.subject}
            </>
          ) : (
            email.subject
          )
        }
        subheader={email.teaserText}
        draftTreatment={email.isDraft}
        unreadTreatment={email.unread}
        icon={
          <Badge
            color="secondary"
            variant="dot"
            overlap="circular"
            invisible={!email.unread}
            sx={{
              alignSelf: "center",
              ".MuiBadge-dot": {
                top: "15%",
                right: "5%",
              },
            }}
          >
            <SvgIcon
              sx={(style) => ({
                fontSize: 24,
                color: style.palette.text.secondary,
              })}
            >
              <MailIcon />
            </SvgIcon>
          </Badge>
        }
      />
    </LinkRouter>
  );
};
