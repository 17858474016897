import React, { CSSProperties, ReactNode, useEffect, useState } from "react";
import {
  AppBar,
  Breakpoint,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Toolbar,
  Typography,
} from "@mui/material";
import { DialogProps } from "@mui/material/Dialog/Dialog";
import useIsMobile from "../hooks/useIsMobile";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type HeaderProps = {
  primaryActionEnabled: boolean;
  handleClose: () => void;
  title?: string;
  primaryAction?: () => void;
  primaryActionName?: string | ReactNode;
  unpad?: boolean;
};

const FullScreenHeader = ({
  title,
  handleClose,
  primaryAction,
  primaryActionName,
  primaryActionEnabled,
}: HeaderProps) => {
  let action: ReactNode = null;
  if (primaryAction && typeof primaryActionName === "string") {
    action = (
      <Button
        disabled={!primaryActionEnabled}
        onClick={primaryAction}
        variant="text"
        sx={{ color: "#FFF" }}
      >
        {primaryActionName}
      </Button>
    );
  } else if (primaryAction && primaryActionName) {
    action = (
      <IconButton disabled={!primaryActionEnabled} onClick={primaryAction}>
        {primaryActionName}
      </IconButton>
    );
  }
  return (
    <AppBar sx={{ position: "relative", backgroundColor: "#575F70" }}>
      <Toolbar>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
        <Typography
          sx={{
            ml: 2,
            flex: 1,
            textOverflow: "ellipsis",
            overflowX: "hidden",
            textWrap: "nowrap",
          }}
          variant="h6"
          component="div"
        >
          {title}
        </Typography>
        {action}
      </Toolbar>
    </AppBar>
  );
};

const NormalHeader = ({ title, handleClose, unpad }: HeaderProps) => {
  return (
    <>
      {title && (
        <DialogTitle
          sx={{
            textOverflow: "ellipsis",
            overflowX: "hidden",
            textWrap: "nowrap",
            paddingRight: "40px",
            ...(unpad ? { paddingBottom: "0" } : {}),
          }}
        >
          <Typography variant="title">{title}</Typography>
        </DialogTitle>
      )}
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <CloseIcon />
      </IconButton>
    </>
  );
};

interface Props extends DialogProps {
  onClose: () => void;
  title?: string;
  primaryAction?: () => void;
  primaryActionName?: string | ReactNode;
  noActionsFullScreen?: boolean;
  primaryActionEnabled?: boolean;
  isNewDesign?: boolean;
  fullWidthSize?: Breakpoint;
  unpad?: boolean;
  style?: CSSProperties;
}

export default ({
  onClose,
  title,
  primaryAction,
  primaryActionName,
  children,
  fullScreen,
  noActionsFullScreen,
  fullWidthSize,
  primaryActionEnabled = true,
  unpad,
  style,
  ...otherProps
}: Props) => {
  const isMobile = useIsMobile();
  // Defer to an explicit fullScreen prop if it's provided
  const isFullScreen = fullScreen === undefined ? isMobile : fullScreen;
  const headerProps = {
    title,
    handleClose: onClose,
    primaryAction,
    primaryActionName,
    primaryActionEnabled,
    unpad,
  };
  const [themeBase] = useState(
    document.querySelector("meta[name=theme-color]")?.getAttribute("content") ||
      undefined,
  );

  const revertThemeColor = () => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (metaThemeColor) {
      metaThemeColor.setAttribute("content", themeBase || "");
    }
  };
  useEffect(() => {
    const metaThemeColor = document.querySelector("meta[name=theme-color]");
    if (otherProps.open) {
      if (metaThemeColor) {
        metaThemeColor.setAttribute("content", "#575F70");
      }
    } else {
      revertThemeColor();
    }
    return () => {
      revertThemeColor();
    };
  }, [otherProps.open]);

  return (
    <Dialog
      fullScreen={isFullScreen}
      PaperComponent={Paper}
      TransitionComponent={isFullScreen ? Transition : undefined}
      slotProps={{
        backdrop: {
          style: {
            background: "rgba(209, 209, 209, 0.30)",
            backdropFilter: "blur(3px)",
          },
        },
      }}
      PaperProps={{
        style: {
          ...(isFullScreen
            ? {
                boxShadow: "none",
              }
            : {
                borderRadius: "12px",
                backgroundColor: "#FFF",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                border: "1px solid #EAECF0",
              }),
          ...style,
        },
      }}
      onClose={onClose}
      maxWidth={fullWidthSize || undefined}
      fullWidth={fullWidthSize ? true : undefined}
      {...otherProps}
    >
      {isFullScreen ? (
        <FullScreenHeader {...headerProps} />
      ) : (
        <NormalHeader {...headerProps} />
      )}
      <DialogContent
        sx={{ ...(unpad ? { paddingTop: "0", paddingBottom: "0" } : {}) }}
      >
        {children}
      </DialogContent>
      {!isFullScreen &&
        !noActionsFullScreen &&
        primaryAction &&
        primaryActionName && (
          <DialogActions sx={{ padding: "24px" }}>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button disabled={!primaryActionEnabled} onClick={primaryAction}>
              {primaryActionName}
            </Button>
          </DialogActions>
        )}
    </Dialog>
  );
};
