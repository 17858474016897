import React, { useState } from "react";
import { Fade } from "@mui/material";

import "./Dialpad.css";

type Props = {
  onPress: (value: string) => void;
  open?: boolean;
};
export default ({ open = true, onPress }: Props) => {
  const [pressedButton, setPressedButton] = useState<string | null>(null);

  const handleButtonPress = (value: string) => {
    setPressedButton(value);
    onPress(value);
  };

  const handleButtonRelease = () => {
    setPressedButton(null);
  };

  const buttons = [
    { value: "1", subText: "" },
    { value: "2", subText: "ABC" },
    { value: "3", subText: "DEF" },
    { value: "4", subText: "GHI" },
    { value: "5", subText: "JKL" },
    { value: "6", subText: "MNO" },
    { value: "7", subText: "PQRS" },
    { value: "8", subText: "TUV" },
    { value: "9", subText: "WXYZ" },
    { value: "*", subText: "" },
    { value: "0", subText: "+" },
    { value: "#", subText: "" },
  ];
  const containerStyle: React.CSSProperties = {
    position: "absolute",
    bottom: "120px",
    maxWidth: "20rem",
    margin: "0 auto",
    padding: "1rem",
    backgroundColor: "#f3f4f6",
    borderRadius: "0.5rem",
    boxShadow:
      "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
  };

  const gridStyle: React.CSSProperties = {
    display: "grid",
    gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    gap: "1rem",
  };

  const buttonStyle: React.CSSProperties = {
    aspectRatio: "1 / 1",
    borderRadius: "9999px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "1.875rem",
    fontWeight: 300,
    backgroundColor: "white",
    border: "2px solid #e5e7eb",
    transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1)",
    padding: "3px",
  };

  const pressedButtonStyle: React.CSSProperties = {
    ...buttonStyle,
    backgroundColor: "#d1d5db",
    boxShadow: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
    transform: "scale(0.95)",
  };

  const subTextStyle: React.CSSProperties = {
    fontSize: "10px",
    marginTop: "0px",
    fontWeight: 400,
  };

  return (
    <Fade in={open}>
      <div style={containerStyle}>
        <div style={gridStyle}>
          {buttons.map((btn) => (
            <button
              key={btn.value}
              onMouseDown={() => handleButtonPress(btn.value)}
              onMouseUp={handleButtonRelease}
              onMouseLeave={handleButtonRelease}
              onTouchStart={() => handleButtonPress(btn.value)}
              onTouchEnd={handleButtonRelease}
              style={
                pressedButton === btn.value ? pressedButtonStyle : buttonStyle
              }
            >
              <span>{btn.value}</span>
              {btn.subText && <span style={subTextStyle}>{btn.subText}</span>}
            </button>
          ))}
        </div>
      </div>
    </Fade>
  );
};
