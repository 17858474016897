import { useState } from "react";
import { Box, Typography } from "@mui/material";
import { GripVertical, Pencil } from "lucide-react";
import { FactSectionStub } from "../../protogen/facts_pb";
import useIsMobile from "../hooks/useIsMobile";

export default ({
  factSection,
  onClick,
}: {
  factSection: FactSectionStub;
  onClick: (section: FactSectionStub) => void;
}) => {
  const isMobile = useIsMobile();
  const [isHovered, setIsHovered] = useState(false);
  return (
    <Box
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{
        marginBottom: "12px",
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            height: "24px",
          }}
        >
          {(isHovered || isMobile) && (
            <GripVertical
              size="24px"
              stroke="#B3AFB6"
              style={{ cursor: "grab" }}
            />
          )}
          <Typography
            variant="h3Serif"
            sx={
              {
                //fontSize: "16px",
              }
            }
          >
            {factSection.name}
          </Typography>
          {(isHovered || isMobile) && (
            <Pencil
              style={{ cursor: "pointer", marginTop: "2px", marginLeft: "8px" }}
              size="20px"
              stroke="#B3AFB6"
              onClick={() => {
                onClick(factSection);
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};
