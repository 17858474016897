import { useState } from "react";
import { Box, TextField, Button, Typography } from "@mui/material";
import { useCreateShortUrl } from "src/services/urlShortener";
import GridPage from "src/components/layout/GridPage";
import useIsMobile from "src/components/hooks/useIsMobile";

export default () => {
  const isMobile = useIsMobile();
  const [url, setUrl] = useState("");
  const [shortUrl, setShortUrl] = useState("");
  const { request } = useCreateShortUrl((r) => {
    setShortUrl(r.shortUrl);
  });
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUrl(event.target.value);
  };

  const onClick = () => {
    request({ url });
  };
  return (
    <GridPage
      sx={{
        padding: isMobile ? "32px 24px" : "",
        margin: isMobile ? "" : "64px min(7%, 100px)",
        maxWidth: "1000px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <Typography variant="title" id="tableTitle">
          Url Shortener
        </Typography>
        <Box>
          <Typography>
            The URL shortener shortens long urls that contain a metadata
            information, like shopping affiliate links, and produce a concise
            Faye url. This can also replace other url shorteners when sending
            text messsages, which are often marked as spam.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
          }}
        >
          <TextField
            value={url}
            autoComplete="off"
            label="Url"
            size="small"
            fullWidth
            onChange={handleEmailChange}
          ></TextField>
          <Box>
            <Button onClick={onClick}>Generate</Button>
          </Box>
        </Box>
        {shortUrl && (
          <Box
            sx={{
              display: "flex",
              gap: "20px",
              alignItems: "center",
            }}
          >
            <Typography>{shortUrl}</Typography>
            <Box>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  navigator.clipboard.writeText(shortUrl);
                }}
              >
                Copy
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </GridPage>
  );
};
