import { useContext, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import TwoPanelGrid, { Panel } from "../components/details-page/TwoPanelGrid";
import { useGetPhoneCall } from "../services/phone";
import Loading from "../components/common/Loading";
import PhoneCallActivityPanel from "../components/details-page/PhoneCallActivityPanel";
import { AccountStub } from "../protogen/common_pb";
import PhoneCallDetailsPanel from "../components/details-page/PhoneCallDetailsPanel";
import useIsVisible from "../components/hooks/useIsVisible";
import { SearchContext } from "../components/context/SearchContextProvider";
import GridPage from "../components/layout/GridPage";
import { Alert, AlertTitle, Box, Typography } from "@mui/material";
import { ReactComponent as ClipboardBullets } from "../icons/ClipboardBullets.svg";
import { PhoneIcon } from "@heroicons/react/24/outline";
import {
  commaSeparatedEnglishList,
  getFormattedDuration,
  relativeTime,
} from "../common/utils";
import { PhoneCall_State } from "../protogen/conversation_pb";
import Duration from "../components/common/Duration";

export default () => {
  const location = useLocation();
  let params = useParams();
  const queryParams = new URLSearchParams(location.search);
  let segmentRefs: string[] = [];
  if (queryParams.get("segmentRefs")) {
    segmentRefs = queryParams.get("segmentRefs")!.split(",");
  }
  const { isVisible } = useIsVisible({});
  const { setPhoneCall } = useContext(SearchContext);
  const { data, request, errorCode } = useGetPhoneCall((r) => {
    setPhoneCall(r.phoneCall);
  });
  const callRef = params.callRef!;

  useEffect(() => {
    request({ callRef: callRef });
    const intervalId = setInterval(async () => {
      if (isVisible) {
        request({ callRef: callRef });
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [callRef]);

  if (errorCode === 404) {
    // Only happens when you delete a draft without other threads.
    return (
      <GridPage>
        <Alert severity="warning">
          <AlertTitle>Phone Call Not Found</AlertTitle>
          No phone call with the given ID was found.
        </Alert>
      </GridPage>
    );
  }
  if (!data) {
    return <Loading />;
  }
  const { phoneCall, caller, recipients, callNotes, familyRef } = data;
  const trackToUser: Record<string, AccountStub> = {
    inbound: caller,
    outbound: recipients[0],
  };

  const title = caller.isAdvisor
    ? `Call with ${commaSeparatedEnglishList(
        recipients.map((r) => r.firstName).sort(),
      )}`
    : `Call from ${caller.firstName}`; // Can only receive a call from one person
  const callDate = relativeTime(new Date(Number(phoneCall.creationSec) * 1000));
  return (
    <TwoPanelGrid
      defaultPanel={Panel.Right}
      leftPanel={{
        title: (
          <Box sx={{ marginBottom: "16px" }}>
            <Typography
              variant="title"
              sx={{
                marginBottom: "16px",
              }}
            >
              {title}
            </Typography>
            <span
              style={{
                marginLeft: "8px",
                fontSize: "14px",
                color: "#AAA",
                fontStyle: "italic",
              }}
            >
              {callDate}
            </span>
          </Box>
        ),
        switchContent: (
          <ClipboardBullets
            height="18px"
            width="14px"
            strokeWidth="2px"
            stroke="#6B6E7B"
          />
        ),
        content: (
          <PhoneCallDetailsPanel
            familyRef={familyRef}
            suggestions={data.suggestions}
            callNotes={callNotes}
            caller={caller}
            recipient={recipients[0]}
            phoneCall={phoneCall}
          />
        ),
      }}
      rightPanel={{
        title: (
          <Typography
            variant="title"
            sx={{
              marginBottom: "16px",
            }}
          >
            Transcript
          </Typography>
        ),
        subtitle: (
          <Box
            sx={{
              paddingBottom: "16px",
              borderBottom: "1px solid #EAECF0",
            }}
          >
            <span style={{ marginRight: "10px" }}>{title}</span>
            &#183;
            <span style={{ marginLeft: "10px" }}>
              {phoneCall.state === PhoneCall_State.ACTIVE ? (
                <Duration
                  startTime={new Date(Number(phoneCall.creationSec) * 1000)}
                />
              ) : (
                getFormattedDuration(
                  Number(phoneCall.lastUpdatedSec),
                  Number(phoneCall.creationSec),
                )
              )}
            </span>
          </Box>
        ),
        switchContent: (
          <PhoneIcon
            height="18px"
            width="14px"
            strokeWidth="2px"
            stroke="#6B6E7B"
          />
        ),
        content: (
          <PhoneCallActivityPanel
            phoneCall={phoneCall}
            trackToUser={trackToUser}
            segmentRefs={segmentRefs ? segmentRefs : undefined}
            refresh={() => request({ callRef: callRef })}
            familyRef={familyRef}
          />
        ),
      }}
    />
  );
};
