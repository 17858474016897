import { TextField, TextFieldProps } from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import StripeInput from "./StripeInput";

// Add other TextFields as needed
export type StripeElement =
  | typeof CardCvcElement
  | typeof CardExpiryElement
  | typeof CardNumberElement;

type StripeTextFieldProps<T extends StripeElement> = Omit<
  TextFieldProps,
  "onChange" | "inputComponent" | "inputProps"
> & {
  inputProps?: React.ComponentProps<T>;
  labelErrorMessage?: string;
  onChange?: React.ComponentProps<T>["onChange"];
  stripeElement?: T;
};

const StripeTextDefaultProps = {
  inputProps: undefined,
  labelErrorMessage: "",
  onChange: () => {},
  stripeElement: undefined,
};

const StripeTextField = <T extends StripeElement>(
  props: StripeTextFieldProps<T> = StripeTextDefaultProps,
): JSX.Element => {
  const {
    helperText,
    InputLabelProps,
    InputProps = {},
    inputProps,
    error,
    labelErrorMessage,
    stripeElement,
    ...other
  } = props;

  return (
    <TextField
      fullWidth
      variant="outlined"
      InputLabelProps={{
        ...InputLabelProps,
        shrink: true,
      }}
      error={error}
      InputProps={{
        ...InputProps,
        // @ts-ignore
        inputProps: {
          ...inputProps,
          ...InputProps.inputProps,
          component: stripeElement,
        },
        inputComponent: StripeInput,
      }}
      helperText={error ? labelErrorMessage : helperText}
      {...other}
    />
  );
};

export const StripeTextFieldNumber = (
  props: StripeTextFieldProps<
    typeof CardNumberElement
  > = StripeTextDefaultProps,
): JSX.Element => {
  return (
    <StripeTextField
      {...props}
      label="Credit card number"
      stripeElement={CardNumberElement}
    />
  );
};

export const StripeTextFieldExpiry = (
  props: StripeTextFieldProps<
    typeof CardExpiryElement
  > = StripeTextDefaultProps,
): JSX.Element => {
  return (
    <StripeTextField
      {...props}
      label="Expiration"
      stripeElement={CardExpiryElement}
    />
  );
};

export const StripeTextFieldCVC = (
  props: StripeTextFieldProps<typeof CardCvcElement> = StripeTextDefaultProps,
): JSX.Element => {
  return (
    <StripeTextField {...props} label="CVC" stripeElement={CardCvcElement} />
  );
};
