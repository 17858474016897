import { Box, Button } from "@mui/material";
import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Task } from "../../protogen/tasks_pb";
import { PlanUtilization } from "../../protogen/common_pb";
import { Family } from "../../protogen/advisors_service_pb";
import LinkRouter from "../navigation/LinkRouter";
import TasksTable from "../../components/tasks/TasksTable";
import useIsMobile from "../hooks/useIsMobile";
import TimeUtilization from "../../components/time-utilization/TimeUtilization";
import AddTaskBar from "../tasks/AddTaskBar";

interface Props {
  title: string;
  family: Family;
  tasks: Task[];
  planUtilization: PlanUtilization;
}
export default ({ family, tasks, planUtilization }: Props) => {
  const isMobile = useIsMobile();
  return (
    <Box display="flex" flexDirection="column" gap="24px">
      {isMobile && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            marginBottom: "24px",
          }}
        >
          <TimeUtilization planUtilization={planUtilization}></TimeUtilization>
        </Box>
      )}
      <AddTaskBar familyRef={family.ref} />
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: "16px",
          alignSelf: "stretch",
          flexDirection: "column",
        }}
      >
        <TasksTable tasks={tasks} isSortable={true} hideStatus={true} />
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "end",
        }}
      >
        <LinkRouter to={`/tasks?familyRefs=${encodeURIComponent(family.ref)}`}>
          <Button
            variant="text"
            endIcon={
              <ArrowRightIcon
                height="20px"
                width="20px"
                strokeWidth="2.5px"
                color="#198282"
              />
            }
          >
            See all tasks
          </Button>
        </LinkRouter>
      </Box>
    </Box>
  );
};
