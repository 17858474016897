import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormHelperText,
  InputAdornment,
  OutlinedInput,
  Skeleton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  useConfirmContactInfo,
  useGenerateAdvisorContactNux,
} from "../../..//services/nux";
import auth from "../../../components/nux/auth";
import { getStepPath } from "../../../components/nux/utils";
import NuxContentLayout from "../../../components/nux/NuxContentLayout";
import useIsMobile from "../../../components/hooks/useIsMobile";
import { refreshToken } from "../../../services/api";
import { useFetchAdvisorProfile } from "../../../services/advisor";
import { Advisor } from "../../../protogen/common_pb";
import AuthService from "../../../services/auth";
import CurrentUserAvatar, {
  AvatarBadgeType,
} from "../../../components/common/CurrentUserAvatar";
import { USER_NUX_TYPE } from "../../../components/nux/constants";

const validAreaCode = (areaCode: string) => {
  return (
    areaCode.length === 3 &&
    parseInt(areaCode) >= 100 &&
    parseInt(areaCode) <= 999
  );
};

export default () => {
  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [advisor, setAdvisor] = useState<Advisor>();
  const [areaCode, setAreaCode] = useState("");
  const [generatedPhone, setGeneratedPhone] = useState<string>("");

  const { request, loading } = useConfirmContactInfo(async (r) => {
    if (r?.nuxDetails?.stepName) {
      // Successfully created advisor phone.  Account set from pending to active.
      await refreshToken();
      const path = getStepPath(r.nuxDetails.stepName, USER_NUX_TYPE.ADVISOR);
      navigate(path);
    }
  });

  const { request: fetchRequest } = useFetchAdvisorProfile((r) => {
    setAdvisor(r.advisor);
    setAreaCode(r.advisor.personalPhone.slice(2, 5));
  });

  const { request: generateContactRequest, loading: generatingContact } =
    useGenerateAdvisorContactNux((r) => {
      setGeneratedPhone(r?.advisorPhone);
    });

  const onContinue = () => {
    request({
      token: auth.getAuthData(),
    });
  };

  useEffect(() => {
    if (!currentUser) {
      return;
    }
    fetchRequest({ advisorRef: currentUser.ref });
  }, []);

  useEffect(() => {
    generateContactRequest({
      token: auth.getAuthData(),
      areaCode: areaCode,
    });
  }, []);

  return (
    <NuxContentLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "36px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ alignSelf: isMobile ? "start" : "center" }}
            variant="title"
          >
            Your Faye Advisor contact info
          </Typography>
          <Typography
            sx={{ alignSelf: isMobile ? "start" : "center" }}
            variant="body"
          >
            Below is your personal Faye Advisor contact information. Hooray!
            Clients and leads will contact you using this phone number and
            email, which will relay through to the Faye platform. Click
            “confirm” if everything looks good.
          </Typography>
          <Box
            sx={{
              alignSelf: "flex-start",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
          >
            <Typography variant="h3Serif">Contact preferences</Typography>
            <Box display="flex" flexDirection="column">
              <Typography variant="h3Serif" sx={{ fontSize: "14px" }}>
                Area Code
              </Typography>
              <OutlinedInput
                type={"text"}
                size={"small"}
                sx={{
                  width: "80px",
                  alignItems: "center",
                  input: { padding: 0, textAlign: "center" },
                  ".MuiInputAdornment-positionStart": {
                    marginRight: 0,
                    paddingRight: 0,
                  },
                  ".MuiInputAdornment-positionEnd": {
                    marginLeft: 0,
                    paddingLeft: 0,
                  },
                }}
                startAdornment={
                  <InputAdornment sx={{ paddingRight: 0 }} position="start">
                    {"("}
                  </InputAdornment>
                }
                endAdornment={
                  <InputAdornment
                    sx={{ paddingLeft: 0, marginLeft: 0 }}
                    position="end"
                  >
                    {")"}
                  </InputAdornment>
                }
                label="Area Code"
                value={areaCode}
                inputProps={{ maxLength: 3, pattern: "[0-9]+" }}
                onChange={(e) => {
                  setAreaCode(e.target.value);
                  if (
                    validAreaCode(e.target.value) &&
                    e.target.value !== advisor?.personalPhone.slice(2, 5)
                  ) {
                    generateContactRequest({
                      token: auth.getAuthData(),
                      areaCode: e.target.value,
                    });
                  }
                }}
              />
              {!generatingContact &&
                validAreaCode(areaCode) &&
                areaCode !== generatedPhone.slice(2, 5) && (
                  <FormHelperText>
                    Not all area codes currently have phone numbers available.
                  </FormHelperText>
                )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                gap: isMobile ? "16px" : "0",
                width: "100%",
                borderRadius: "24px",
                border: "1px solid  #EAEBEC",
                padding: "32px",
              }}
            >
              <Box
                sx={{
                  aspectRatio: "1/1",
                  flexGrow: 1,
                  maxWidth: isMobile ? "90px" : "90px",
                  width: "100%",
                  marginRight: "16px",
                }}
              >
                <CurrentUserAvatar
                  user={advisor}
                  size={90}
                  autoscale={true}
                  badgeType={AvatarBadgeType.NONE}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  flexGrow: 1,
                }}
              >
                <Typography variant="h3Serif">
                  {advisor?.firstName} {advisor?.lastName}
                </Typography>
                <Typography
                  sx={{ marginTop: "6px" }}
                  variant="bodySmallHeavy"
                  color="text.secondary"
                >
                  {" "}
                  Faye Advisor
                </Typography>
                <Typography sx={{ marginTop: "13px" }} variant="body">
                  {advisor?.username}
                </Typography>
                <Typography variant="body">
                  {generatingContact ? (
                    <Skeleton variant="text" />
                  ) : (
                    generatedPhone
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: isMobile ? "block" : "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button disabled={loading} onClick={onContinue}>
                  Confirm
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </NuxContentLayout>
  );
};
