import { useState } from "react";
import { Box } from "@mui/material";
import { PlusIcon } from "@heroicons/react/24/outline";
import ReactiveDialog from "../common/ReactiveDialog";
import { useNavigate } from "react-router-dom";
import useIsMobile from "../hooks/useIsMobile";
import { Advisor } from "../../protogen/common_pb";
import { useGetOrCreateMessageConversation } from "../../services/messaging";
import AdvisorAutocomplete from "../messaging/AdvisorAutocomplete";
import { ShellCompositionSection } from "../activity/TextMessageCompose";

interface Props {
  open: boolean;
  onClose: () => void;
}

export default ({ open, onClose }: Props) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { request, loading } = useGetOrCreateMessageConversation();
  const [advisors, setAdvisors] = useState<Advisor[]>([]);
  const onStart = async () => {
    const resp = await request({
      userRefs: advisors.map((a) => a.ref),
    });
    if (resp?.conversationRef) {
      onClose();
      navigate(`/inbox/messages/${encodeURIComponent(resp.conversationRef)}`);
      return true;
    }
    return false;
  };
  return (
    <ReactiveDialog
      open={open}
      onClose={onClose}
      title="New Thread"
      unpad={true}
      primaryActionEnabled={!loading && advisors.length > 0}
      primaryAction={onStart}
      primaryActionName={
        !isMobile ? (
          "Start thread"
        ) : (
          <PlusIcon height={24} width={24} stroke="white" />
        )
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        paddingTop="20px"
        gap="20px"
        height="100%"
        justifyContent={"space-between"}
      >
        <AdvisorAutocomplete setSelected={setAdvisors} />
        <ShellCompositionSection
          onFocus={onStart}
          placeholder={"Send message"}
          disabled={advisors.length === 0}
        />
      </Box>
    </ReactiveDialog>
  );
};
