import { useContext, useEffect, useRef } from "react";
import { Chip, Autocomplete, ListItem, TextField } from "@mui/material";
import ListItemText from "@mui/material/ListItemText";
import { CurrentUserContext } from "../../components/context/RequireAuth";
import { useListAllAdvisors } from "../../services/advisor";
import { Advisor } from "../../protogen/common_pb";

export default ({
  setSelected,
  autoFocus = false,
}: {
  setSelected: (a: Advisor[]) => void;
  autoFocus?: boolean;
}) => {
  const currentUser = useContext(CurrentUserContext);
  const autocompleteRef = useRef<HTMLDivElement>(null);
  const { request, data, loading } = useListAllAdvisors();
  useEffect(() => {
    request({
      // Advisors often interact with faye staff members.
      includeStaff: true,
    });
  }, []);
  const advisors = (data?.advisors || []).filter(
    (a) => a.ref !== currentUser.ref,
  );

  const stringOrRef = (a: Advisor | string) => {
    return typeof a === "string" ? a : a.ref;
  };

  useEffect(() => {
    if (!loading && autoFocus && autocompleteRef.current) {
      autocompleteRef.current.querySelector("input")?.focus();
    }
  }, [loading, autoFocus]);
  return (
    <Autocomplete
      ref={autocompleteRef}
      autoFocus={autoFocus}
      disabled={loading}
      multiple
      filterSelectedOptions
      limitTags={2}
      isOptionEqualToValue={(option, value) =>
        stringOrRef(option) === stringOrRef(value)
      }
      getOptionLabel={(option: Advisor | string) =>
        typeof option === "string" ? option : option.displayName
      }
      renderOption={(props, option: Advisor | string) => (
        <ListItem {...props} key={stringOrRef(option)}>
          <ListItemText
            primary={typeof option !== "string" && option.displayName}
          />
        </ListItem>
      )}
      onChange={(_, val) => {
        if (!val) return;
        if (typeof val === "string") return;
        const values = val as Advisor[];
        setSelected(values);
      }}
      options={advisors}
      renderTags={(value: (string | Advisor)[], getTagProps) =>
        value.map((option: string | Advisor, index: number) => (
          <Chip
            variant="outlined"
            label={typeof option === "string" ? option : option.displayName}
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          // variant="filled"
          label="Recipients"
          // placeholder="Favorites"
        />
      )}
    />
  );
};
