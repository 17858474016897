import useProtoMethod from "./useProtoMethod";
import {
  GetMemberDetailsRequest,
  GetMemberDetailsResponse,
  UpdateMembersFamilyRequest,
  UpdateMembersFamilyResponse,
  GetBillingPortalUrlRequest,
  GetBillingPortalUrlResponse,
} from "../protogen/member_service_pb";

const useGetMemberDetails = (
  callback?: (r: GetMemberDetailsResponse) => void,
) => {
  return useProtoMethod<GetMemberDetailsRequest, GetMemberDetailsResponse>(
    "GetMemberDetails",
    GetMemberDetailsResponse,
    { callback },
  );
};

const useUpdateMembersFamily = (
  callback?: (r: UpdateMembersFamilyResponse) => void,
) => {
  return useProtoMethod<
    UpdateMembersFamilyRequest,
    UpdateMembersFamilyResponse
  >("UpdateMembersFamily", UpdateMembersFamilyResponse, { callback });
};

const useGetBillingPortalUrl = (
  callback?: (r: GetBillingPortalUrlResponse) => void,
) => {
  return useProtoMethod<
    GetBillingPortalUrlRequest,
    GetBillingPortalUrlResponse
  >("GetBillingPortalUrl", GetBillingPortalUrlResponse, { callback });
};

export { useGetMemberDetails, useUpdateMembersFamily, useGetBillingPortalUrl };
