import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { useState } from "react";

export type Option = {
  label: string;
  value: string;
};

type Props = {
  options: Option[];
  initial?: string | null;
  onChange: (s: Option | null) => void;
  displayValue?: string;
  disabled?: boolean;
  noBorder?: boolean;
  small?: boolean;
};

export default ({
  options,
  initial,
  onChange,
  displayValue,
  disabled = false,
  noBorder = false,
  small = false,
}: Props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<string>(initial || "");
  const optionsMap = new Map(options.map((o) => [o.value, o]));
  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const {
      target: { value: val },
    } = event;
    setValue(val || "");
    onChange(val !== "" ? (optionsMap.get(val) as Option) : null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <FormControl
      onClick={() => {
        setOpen(!open);
      }}
      sx={{ cursor: "pointer" }}
    >
      <Select
        disabled={disabled}
        value={value}
        displayEmpty
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        renderValue={(selected) => {
          const display = optionsMap.get(selected as string)?.label;
          return (
            <Typography
              component="span"
              variant={small ? "bodySmall" : "body"}
              color={"#8E9598"}
            >
              {display || displayValue}
            </Typography>
          );
        }}
        label={displayValue}
        onChange={handleChange}
        sx={{
          cursor: "pointer",
          minHeight: small ? "30px" : "48px",
          fieldset: {
            ...(noBorder
              ? {
                  border: "none !important",
                  "&:hover": {
                    border: "none !important",
                  },
                }
              : {
                  borderRadius: "100px",
                  border: "2px solid #EAEBEC !important",
                }),
          },
          ".MuiInputBase-input": {
            marginTop: small ? "3px" : "0px",
            paddingLeft: small ? "14px" : "auto",
          },
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              marginTop: "8px",
              borderRadius: "12px",
              border: "2px solid #EAEBEC",
              background: "#FFF",
              display: "flex",
              padding: "19px 48px 19px 24px",
              flexDirection: "column",
              alignItems: "flex-start",
              boxShadow: "none",
              ul: {
                padding: 0,
                margin: 0,
                li: {
                  padding: 0,
                  marginBottom: "20px",
                  "&.Mui-selected, &:hover": {
                    backgroundColor: "transparent",
                  },
                  "&.Mui-selected, &:focus": {
                    backgroundColor: "transparent",
                  },
                  "&:last-child": {
                    marginBottom: "0px",
                  },
                },
              },
            },
          },
        }}
        IconComponent={() => {
          return (
            <div
              style={{
                right: small ? "14px" : "20px",
                top: small ? "6px" : "16px",
                pointerEvents: "none",
                position: "absolute",
              }}
            >
              <ChevronDownIcon
                height="16px"
                width="16px"
                strokeWidth="3px"
                color="#8E9598"
              />
            </div>
          );
        }}
      >
        {options.map((o) => (
          <MenuItem
            key={o.value}
            value={o.value}
            disableRipple={true}
            disableTouchRipple={true}
            sx={{
              "&:hover": { backgroundColor: "transparent" },
              "&.Mui-selected:hover": { backgroundColor: "transparent" },
            }}
          >
            <ListItemText primary={o.label} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
