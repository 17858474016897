import { useState } from "react";
import {
  Typography,
  Menu,
  MenuItem,
  Box,
  Divider,
  MenuItemProps,
} from "@mui/material";
import {
  ReactNodeViewRenderer,
  NodeViewContent,
  NodeViewWrapper,
  findParentNode,
} from "@tiptap/react";
import TiptapTable, { TableOptions } from "@tiptap/extension-table";
import { Plus, Trash2 } from "lucide-react";
import { TextSelection, Transaction } from "@tiptap/pm/state";

const CustomMenuItem = ({
  children,
  ...props
}: {
  children: React.ReactNode;
} & MenuItemProps) => {
  return (
    <MenuItem sx={{ padding: "0px" }} {...props}>
      {children}
    </MenuItem>
  );
};

export const TableWrapper = TiptapTable.extend<TableOptions>({
  addNodeView() {
    return ReactNodeViewRenderer(({ editor }: any) => {
      const [isHover, setIsHover] = useState(false);
      const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
      const [tooltipPosition, setTooltipPosition] = useState<{
        x: number;
        y: number;
      } | null>(null);

      const addColumnToEnd = () => {
        const { selection } = editor.state;
        const table = findParentNode((node) => node.type.name === "table")(
          selection,
        );
        if (table) {
          const { pos: tablePos, node: tableNode } = table;

          editor
            .chain()
            .focus()
            .selectNodeBackward()
            .command(({ tr }: { tr: Transaction }) => {
              // Select the last cell of the first row
              const firstRow = tableNode.child(0);
              const lastCellInRowPos = tablePos + 1 + firstRow.nodeSize - 3;
              const $pos = tr.doc.resolve(lastCellInRowPos);
              tr.setSelection(TextSelection.near($pos));
              return true;
            })
            .addColumnAfter()
            .run();
        }
      };
      const addRowToBottom = () => {
        const { selection } = editor.state;
        const table = findParentNode((node) => node.type.name === "table")(
          selection,
        );
        if (table) {
          const { pos: tablePos, node: tableNode } = table;
          editor
            .chain()
            .focus()
            .selectNodeBackward()
            .command(({ tr }: { tr: Transaction }) => {
              const lastCellPos = tablePos + tableNode.nodeSize - 3; // Position of the last cell
              const $pos = tr.doc.resolve(lastCellPos);
              tr.setSelection(TextSelection.near($pos));
              return true;
            })
            .addRowAfter()
            .run();
        }
      };
      const handleRightClick = (e: React.MouseEvent<HTMLTableCellElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const cell = e.target as HTMLElement;
        const rect = cell.getBoundingClientRect();
        setTooltipPosition({ x: rect.left, y: rect.top - 40 });
        setAnchorEl(cell);
        editor.chain().focus().run();
      };

      const runEditorAction = (action: () => void) => {
        action();
        setAnchorEl(null);
      };

      return (
        <NodeViewWrapper
          className="custom-table-wrapper"
          onContextMenu={handleRightClick}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          <Box sx={{ display: "inline-flex", flexDirection: "column" }}>
            <Box sx={{ display: "flex", width: "100%" }}>
              <NodeViewContent
                as="table"
                className="custom-table-content"
                style={{
                  width: "auto",
                }}
              ></NodeViewContent>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  isHover && addColumnToEnd();
                }}
              >
                {
                  <Plus
                    style={{
                      cursor: isHover ? "pointer" : undefined,
                      visibility: !isHover ? "hidden" : undefined,
                    }}
                    size={16}
                  />
                }
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
                marginRight: "16px", // to offset the right icon
              }}
              onClick={() => {
                isHover && addRowToBottom();
              }}
            >
              <Plus
                style={{
                  cursor: isHover ? "pointer" : undefined,
                  visibility: !isHover ? "hidden" : undefined,
                }}
                size={16}
              />
            </Box>
          </Box>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorPosition={
              tooltipPosition
                ? { top: tooltipPosition.y, left: tooltipPosition.x }
                : undefined
            }
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: "12px 20px",
                gap: "20px",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
              >
                <Typography variant="bodySmallHeavy" color="#6B6E7B">
                  Column
                </Typography>
                <CustomMenuItem
                  onClick={() =>
                    runEditorAction(() =>
                      editor.chain().focus().addColumnBefore().run(),
                    )
                  }
                >
                  <Plus
                    size={20}
                    style={{ paddingLeft: "0px", marginRight: "8px" }}
                  ></Plus>
                  <Typography variant="body" color="#3D3D3D">
                    Add column left
                  </Typography>
                </CustomMenuItem>
                <CustomMenuItem
                  onClick={() =>
                    runEditorAction(() =>
                      editor.chain().focus().addColumnAfter().run(),
                    )
                  }
                >
                  <Plus
                    size={20}
                    style={{ paddingLeft: "0px", marginRight: "8px" }}
                  ></Plus>
                  <Typography variant="body" color="#3D3D3D">
                    Add column right
                  </Typography>
                </CustomMenuItem>
                <CustomMenuItem
                  onClick={() =>
                    runEditorAction(() =>
                      editor.chain().focus().deleteColumn().run(),
                    )
                  }
                >
                  <Trash2
                    size={20}
                    style={{ paddingLeft: "0px", marginRight: "8px" }}
                  ></Trash2>
                  <Typography variant="body" color="#3D3D3D">
                    Delete column
                  </Typography>
                </CustomMenuItem>
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "12px" }}
              >
                <Typography variant="bodySmallHeavy" color="#6B6E7B">
                  Row
                </Typography>
                <CustomMenuItem
                  onClick={() =>
                    runEditorAction(() =>
                      editor.chain().focus().addRowBefore().run(),
                    )
                  }
                >
                  <Plus
                    size={20}
                    style={{ paddingLeft: "0px", marginRight: "8px" }}
                  ></Plus>
                  <Typography variant="body" color="#3D3D3D">
                    Add row above
                  </Typography>
                </CustomMenuItem>
                <CustomMenuItem
                  onClick={() =>
                    runEditorAction(() =>
                      editor.chain().focus().addRowAfter().run(),
                    )
                  }
                >
                  <Plus
                    size={20}
                    style={{ paddingLeft: "0px", marginRight: "8px" }}
                  ></Plus>
                  <Typography variant="body" color="#3D3D3D">
                    Add row below
                  </Typography>
                </CustomMenuItem>
                <CustomMenuItem
                  onClick={() =>
                    runEditorAction(() =>
                      editor.chain().focus().deleteRow().run(),
                    )
                  }
                >
                  <Trash2
                    size={20}
                    style={{ paddingLeft: "0px", marginRight: "8px" }}
                  ></Trash2>
                  <Typography variant="body" color="#3D3D3D">
                    Delete row
                  </Typography>
                </CustomMenuItem>
              </Box>
              <Divider sx={{ marginLeft: "-20px", marginRight: "-20px" }} />
              <CustomMenuItem
                onClick={() =>
                  runEditorAction(() =>
                    editor.chain().focus().deleteTable().run(),
                  )
                }
              >
                <Trash2
                  size={20}
                  style={{ paddingLeft: "0px", marginRight: "8px" }}
                  stroke="#C14743"
                ></Trash2>
                <Typography variant="body" color="secondary.dark">
                  Delete table
                </Typography>
              </CustomMenuItem>
            </Box>
          </Menu>
        </NodeViewWrapper>
      );
    });
  },
});
