import { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import GridPage from "../../components/layout/GridPage";
import TaskInspirationDialog from "../../components/tasks/TaskInspirationDialog";
import { useListAllTaskInspirations } from "../../services/tasks";
import { TaskInspiration } from "../../protogen/tasks_pb";
import useIsMobile from "../../components/hooks/useIsMobile";
import { InspiredTaskList } from "../../components/tasks/TaskInspiration";

const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const groupByType = (inspirations: TaskInspiration[]) => {
  const inspirationsByType = inspirations.reduce(
    (acc: { [key: string]: TaskInspiration[] }, inspiration) => {
      const type = inspiration.type;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(inspiration);
      return acc;
    },
    {},
  );

  return inspirationsByType;
};

export default () => {
  const isMobile = useIsMobile();
  const [selectedTaskInspiration, setSelectedTaskInspiration] =
    useState<TaskInspiration | null>(null);
  const [open, setOpen] = useState(false);
  const { data, request: listAllTaskInspirationRequest } =
    useListAllTaskInspirations(() => {});
  useEffect(() => {
    listAllTaskInspirationRequest();
  }, [open]);
  if (!data) return <></>;
  const inspirationsByType = groupByType(data.taskInspirations);

  return (
    <GridPage
      sx={{
        padding: isMobile ? "32px 24px" : "",
        margin: isMobile ? "" : "64px min(7%, 100px)",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="title">Task inspiration</Typography>
        <Button
          onClick={() => {
            setOpen(true);
          }}
        >
          Add new
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          gap: "16px",
        }}
      >
        {Object.keys(inspirationsByType).map((type, i) => {
          const inspirations = inspirationsByType[type];
          return (
            <InspiredTaskList
              numVisibileTasks={100}
              showStatus={true}
              iconType="More"
              key={i}
              title={capitalizeFirstLetter(type)}
              titleSx={{
                fontFamily: "AlbertSans",
                fontSize: "18px",
                fontWeight: "700",
              }}
              tasks={inspirations}
              onTaskClick={(task) => {
                setSelectedTaskInspiration(task as TaskInspiration);
                setOpen(true);
              }}
            />
          );
        })}
      </Box>
      <TaskInspirationDialog
        open={open}
        taskInspiration={selectedTaskInspiration}
        onClose={() => {
          setOpen(false);
          setSelectedTaskInspiration(null);
        }}
      />
    </GridPage>
  );
};
