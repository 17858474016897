import { Letter } from "react-letter";
import { EmailMessage } from "../../protogen/conversation_pb";
import { Box, Button, IconButton } from "@mui/material";
import { List } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Attachment } from "../../protogen/common_pb";
import { useState } from "react";
import AttachmentDialog, {
  isSupportedAttachment,
} from "../common/AttachmentDialog";
import { FileDown } from "lucide-react";
import { downloadAttachment } from "../editor/AttachmentList";
import { humanFileSize } from "../../common/utils";

const containsBlockquote = (html: string): boolean => {
  const openTag = /<blockquote\b[^>]*>/; // checks for <blockquote> with any attributes
  const closeTag = /<\/blockquote>/; // checks for </blockquote>
  return openTag.test(html) && closeTag.test(html);
};

const Attachments = ({ attachments }: { attachments: Attachment[] }) => {
  const [openAttachment, setOpenAttachment] = useState<Attachment | null>(null);
  const onAttachmentClick = async (attachment: Attachment) => {
    if (isSupportedAttachment(attachment)) {
      // Handle document...
      setOpenAttachment(attachment);
    } else {
      await downloadAttachment(attachment);
    }
  };

  return (
    <List>
      {attachments.map((attachment, i) => (
        <ListItem key={i} sx={{ backgroundColor: "#F3F6F9", marginTop: "8px" }}>
          <IconButton
            onClick={async (e) => {
              e.preventDefault();
              e.stopPropagation();
              await downloadAttachment(attachment);
            }}
          >
            <FileDown height="24px" width="24px" />
          </IconButton>
          <ListItemText
            primary={
              <div
                style={{ fontSize: "14px", cursor: "pointer" }}
                onClick={async () => await onAttachmentClick(attachment)}
              >
                <span style={{ fontWeight: 600 }}>{attachment.filename}</span>
                <span style={{ marginLeft: "5px", fontStyle: "italic" }}>
                  ({humanFileSize(attachment.fileSize)})
                </span>
              </div>
            }
          />
          {/*<ListItemSecondaryAction>*/}
          {/*  <IconButton edge="end" aria-label="delete" onClick={() => onDelete(attachment.filename)}>*/}
          {/*    <CloseIcon />*/}
          {/*  </IconButton>*/}
          {/*</ListItemSecondaryAction>*/}
        </ListItem>
      ))}
      <AttachmentDialog
        attachment={openAttachment}
        onClose={() => setOpenAttachment(null)}
      />
    </List>
  );
};

const containsOutlookSignature = (html: string): boolean => {
  // Alternatively, MS outlook seems to just use <hr> to separate past emails
  return /ms-outlook-mobile-signature/.test(html);
};

export default ({ email }: { email: EmailMessage }) => {
  const [showOriginal, setShowOriginal] = useState(
    !(containsBlockquote(email.html) || containsOutlookSignature(email.html)),
  );
  // If there's no email.html, let's still show inline attachments.
  // NOTE(Kip): Some email clients add inline metadata where there is no inline reference. Until we figure
  // that out lets risk duplicating some attachments ehre.
  // const visibleAttachments = email.html
  //   ? email.attachments.filter((a) => !a.inlineReference)
  //   : email.attachments;
  const visibleAttachments = email.attachments;
  return (
    <Box
      sx={{
        overflowX: "scroll",
        "& img": {
          maxWidth: "100%",
          maxHeight: "100%",
        },
        "*": {
          textWrap: "wrap",
        },
        blockquote: {
          display: !showOriginal ? "none" : undefined,
        },
        // Note that this might be a Mick specific fix for outlook. lets see!
        "div[id$=ms-outlook-mobile-signature] ~ *": {
          display: !showOriginal ? "none" : undefined,
        },
      }}
    >
      <Letter html={email.html || ""} text={email.text} />
      {!showOriginal && (
        <Button
          variant="text"
          sx={(theme) => ({ color: theme.palette.text.secondary })}
          onClick={() => setShowOriginal(true)}
        >
          Show original
        </Button>
      )}
      {visibleAttachments.length > 0 && (
        <Attachments attachments={visibleAttachments} />
      )}
    </Box>
  );
};
