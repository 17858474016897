import { Document } from "@tiptap/extension-document";
import { Text } from "@tiptap/extension-text";
import { Paragraph } from "@tiptap/extension-paragraph";
import { HardBreak } from "@tiptap/extension-hard-break";
import { Color } from "@tiptap/extension-color";
import TextStyle from "@tiptap/extension-text-style";
import ListItem from "@tiptap/extension-list-item";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import { StarterKit } from "@tiptap/starter-kit";
import CustomImage from "./extensions/CustomImage";
import { Placeholder } from "@tiptap/extension-placeholder";
import ResizableImageExtension from "./ResizableImageExtension";
import { Attachment, UploadAttachment } from "../../protogen/common_pb";
import { PlainMessage } from "@bufbuild/protobuf";
import { JSONContent } from "@tiptap/react";
import Recommendations from "./extensions/Recommendations";
import ProductLink from "./extensions/ProductLink";
import Loom from "./extensions/Loom";
import { Youtube } from "@tiptap/extension-youtube";
import UrlShortener from "./extensions/UrlShortener";
import CustomLink from "./extensions/CustomLink";
import TableRow from "@tiptap/extension-table-row";
import { TableHeader } from "@tiptap/extension-table-header";
import { CustomTableCell } from "./extensions/CustomTableCell";
import { TableWrapper } from "./extensions/CustomTable";
import TiptapTable from "@tiptap/extension-table";
import { Underline } from "@tiptap/extension-underline";
import Highlight from "@tiptap/extension-highlight";

// Url shortener matching.  Ignores sensitive words part of the query string
// Requires atleast 18 characters (the length of the shortened url)
export const SHORT_URL_REGEX =
  /^https?:\/\/(?!.*?[?&](login|token|password|auth|oauth|signin|signup|reset|confirm|activate|admin|api|secret|key|session|cookie)=)[^\s]{18,}$/;
export const PLACEHOLDER_PATH = "/assets/images/image-placeholder.png";

type JSONValue = { [key: string]: any } | JSONValue[];
const renderInitialContent = (
  content: string | JSONContent | undefined,
  attachments: Attachment[],
) => {
  // Handle first load of attachments by swapping in the URLs.
  const attachmentMap = attachments.reduce(
    (mapping, a) => {
      mapping[a.inlineReference] = a;
      return mapping;
    },
    {} as { [inlineReference: string]: Attachment },
  );

  const _step = (node: JSONValue): JSONValue => {
    if (Array.isArray(node)) {
      return node.map(_step);
    } else if (typeof node === "object" && node !== null) {
      if (node.type === "image") {
        node.attrs = node.attrs || {};
        if (!node.attrs.src) {
          if (attachmentMap[node.attrs.title]) {
            node.attrs.src = attachmentMap[node.attrs.title].url;
          } else {
            node.attrs.src = PLACEHOLDER_PATH;
          }
        }
      }
      if (node.content) {
        node.content = node.content.map(_step);
      }
    }
    return node;
  };

  if (typeof content === "object") {
    return _step(content);
  }
  return content;
};

const extractImageReferences = (content: JSONContent) => {
  const references = new Set<string>([]);

  const traverseNodes = (node: JSONValue) => {
    if (Array.isArray(node)) {
      node.map(traverseNodes);
    } else if (typeof node === "object" && node !== null) {
      if (node.type === "image") {
        references.add(node.attrs.title);
      }
      if (node.content) {
        node.content.map(traverseNodes);
      }
    }
    return node;
  };
  traverseNodes(content);
  return references;
};

const generateRandomName = (prefix?: string) => {
  return Math.random()
    .toString(36)
    .replace("0.", prefix || "");
};

const getExtensions = ({
  placeholder,
  editable = true,
  urlShortenerEnabled = false,
  isRichTextEnabled = true,
}: {
  placeholder?: string;
  editable?: boolean;
  urlShortenerEnabled?: boolean;
  isRichTextEnabled?: boolean;
}) => {
  return [
    // https://tiptap.dev/api/nodes/task-list
    ...(urlShortenerEnabled ? [UrlShortener] : []),
    Color.configure({ types: [TextStyle.name, ListItem.name] }),
    // TextStyle.configure({ types: [ListItem.name] }),
    ...(isRichTextEnabled
      ? [
          StarterKit.configure({
            bulletList: {
              keepMarks: true,
              keepAttributes: false, // TODO : Making this as `false` because marks are not preserved when I try to preserve attrs, awaiting a bit of help
            },
            orderedList: {
              keepMarks: true,
              keepAttributes: false, // TODO : Making this as `false` because marks are not preserved when I try to preserve attrs, awaiting a bit of help
            },
            heading: {
              levels: [3],
            },
          }),
          Underline,
          TextStyle,
          Highlight.configure({ multicolor: true }),
          CustomLink.configure({
            openOnClick: true,
          }),
          TaskList,
          TaskItem.configure({
            nested: true,
          }),
          // This is an improvement on the vanilla "Image" extension that allows us to resize images.,
          ...(editable ? [ResizableImageExtension] : [CustomImage]),
          // Add to group that is "configured"
          Recommendations,
          ProductLink,

          // Support tables. Custom table manager when in edit mode.
          ...(editable ? [TableWrapper] : [TiptapTable]),
          TableRow,
          TableHeader,
          CustomTableCell,
        ]
      : [Document, Text, Paragraph, HardBreak]),

    ...(placeholder === undefined
      ? []
      : [
          Placeholder.configure({
            placeholder: placeholder,
          }),
        ]),
    Youtube.configure({
      addPasteHandler: true,
      nocookie: true,
    }),
    Loom.configure({
      addPasteHandler: true,
      allowFullscreen: true,
      hideEmbedTopBar: false,
      hideOwner: true,
    }),
  ];
};
const blobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export type RichContent = {
  text: string;
  html: string;
  json: string;
  attachments: PlainMessage<UploadAttachment>[];
};

type JSONNode = Record<string, any> | JSONNode[];

const stripImageSrc = (input: string) => {
  const parsed = JSON.parse(input);
  // Recursive function to traverse and modify the JSON structure
  const _step = (node: JSONNode): JSONNode => {
    if (Array.isArray(node)) {
      return node.map((item) => _step(item));
    }

    if (typeof node === "object" && node !== null) {
      if (node["type"] === "image") {
        node.attrs = { ...(node.attrs ?? {}), src: "" };
      }

      if (node["content"]) {
        node["content"] = node["content"].map(_step);
      }
    }

    return node;
  };

  // Modify the data and return it as a string
  return JSON.stringify(_step(parsed));
};

export {
  getExtensions,
  generateRandomName,
  renderInitialContent,
  extractImageReferences,
  blobToBase64,
  stripImageSrc,
};
