import { useState, useEffect, CSSProperties } from "react";
import { Box, Button, Typography } from "@mui/material";
import { FeedEntry, Medium } from "../../protogen/advisors_service_pb";
import { EphemeralTask } from "../../types/tasks";
import { Suggestions } from "../../protogen/suggestions_pb";
import { ReactComponent as SparklesIcon } from "../../icons/Advisor/Sparkles.svg";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import useIsMobile from "../hooks/useIsMobile";
import { EphemeralFact } from "../facts/types";
import { EphemeralEvent } from "../../types/calendars";
import TaskSuggestionItems from "./TaskSuggestionItems";
import EventSuggestionItems from "./EventSuggestionItems";
import FactSuggestionItem from "./FactSuggestionItem";
import useLocalStorageState from "../hooks/useLocalStorageState";

const SHOW_OPEN_SUGGESTION_MESSAGE_HISTORY_LIMIT = 5;

export const insertTaskSuggestion = (
  entries: FeedEntry[],
  actions: SuggestionActionsType,
  entryCreator: (entry: FeedEntry, index: number) => JSX.Element,
  sx?: CSSProperties,
  familyRef?: string,
): JSX.Element[] => {
  let lastTaskSuggestionTime = 0;

  return entries.flatMap((entry, idx) => {
    const result: JSX.Element[] = [entryCreator(entry, idx)];
    const ts = Number(entry.session);

    if (
      entry.medium === Medium.SMS &&
      lastTaskSuggestionTime !== ts &&
      entry.textMessage?.sessionSuggestions?.hasSuggestions
    ) {
      // set defaultOpen if ts is within the last 6 hours
      const isDefaultOpen =
        ts > Date.now() / 1000 - 6 * 60 * 60 &&
        idx < SHOW_OPEN_SUGGESTION_MESSAGE_HISTORY_LIMIT;
      result.unshift(
        <Suggestion
          actions={actions}
          suggestions={entry.textMessage.sessionSuggestions}
          entityRef={entry.ref}
          entityType={entry.medium === Medium.SMS ? "message" : "email"}
          key={`suggestion-${entry.medium}-${entry.ref}`}
          defaultOpen={isDefaultOpen}
          sx={sx}
          familyRef={familyRef}
          session={entry.session.toString()}
        />,
      );
      lastTaskSuggestionTime = ts;
    }

    return result;
  });
};

export type SuggestionActionsType = {
  suggestFact: (t: EphemeralFact) => void;
  suggestEvent: (t: EphemeralEvent) => void;
  suggestTask: (t: EphemeralTask) => void;
};

export const SuggestionsList = ({
  suggestions,
  entityRef,
  entityType,
  actions,
  familyRef,
  isOpen = true,
}: {
  suggestions: Suggestions;
  entityRef: string;
  entityType: string;
  actions: SuggestionActionsType;
  familyRef?: string;
  isOpen?: boolean;
}) => {
  return (
    <Box display="flex" flexDirection="column" gap="12px" width="100%">
      {suggestions.tasks.length > 0 && (
        <TaskSuggestionItems
          entityRef={entityRef}
          entityType={entityType}
          suggestions={suggestions.tasks}
          suggestTask={actions.suggestTask}
          isOpen={isOpen}
        />
      )}
      {suggestions.events.length > 0 && (
        <EventSuggestionItems
          entityRef={entityRef}
          entityType={entityType}
          suggestions={suggestions.events}
          suggestEvent={actions.suggestEvent}
          isOpen={isOpen}
          familyRef={familyRef}
        />
      )}
      {suggestions.facts.map((suggestion, index) => (
        <FactSuggestionItem
          key={index}
          entityRef={entityRef}
          entityType={entityType}
          suggestion={suggestion}
          suggestFact={actions.suggestFact}
          index={index}
          isOpen={isOpen}
        />
      ))}
    </Box>
  );
};

export const Suggestion = ({
  entityRef,
  entityType,
  suggestions,
  sx,
  actions,
  familyRef,
  defaultOpen = false,
  session = undefined,
}: {
  entityRef: string;
  entityType: string;
  suggestions: Suggestions;
  actions: SuggestionActionsType;
  familyRef?: string;
  sx?: CSSProperties;
  defaultOpen?: boolean;
  session?: string;
}) => {
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState(false);
  const [expanded, setExpanded] = useLocalStorageState<{
    [key: string]: boolean;
  }>("faye.settings.smart-suggestions-expanded", {});

  useEffect(() => {
    if (defaultOpen) {
      setIsOpen(true);
    }
    if (session) {
      const isSessionOpen =
        expanded[session] === undefined ? defaultOpen : expanded[session];
      setIsOpen(isSessionOpen);
    }
  }, []);

  return (
    <Box
      sx={{
        marginBottom: "20px",
        maxWidth: isMobile ? "100%" : "450px",
        ...sx,
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            backgroundColor: isOpen ? "inherit" : "#FFF",
            padding: isOpen ? "0px" : "0px 16px",
            border: isOpen ? "none" : "1px solid #F2F2F2",
            borderRadius: "8px",
            cursor: "pointer",
          }}
          onClick={() => {
            if (session) {
              setExpanded((prevState) => {
                return {
                  ...prevState,
                  [session]: !isOpen,
                };
              });
            }
            setIsOpen(!isOpen);
          }}
        >
          <Button
            sx={{
              ".MuiButton-startIcon": {
                margin: 0,
                marginRight: "8px",
              },
            }}
            variant="text"
            startIcon={<SparklesIcon />}
          >
            <Typography color="text.secondary" variant="bodySmall">
              Smart suggestions
            </Typography>
          </Button>
          {isOpen ? (
            <ChevronUpIcon height="20px" width="20px" strokeWidth="2" />
          ) : (
            <ChevronDownIcon height="20px" width="20px" strokeWidth="2" />
          )}
        </Box>
      </Box>
      <SuggestionsList
        suggestions={suggestions}
        actions={actions}
        isOpen={isOpen}
        entityRef={entityRef}
        entityType={entityType}
        familyRef={familyRef}
      />
    </Box>
  );
};
