import { ForumPost, ForumPostType } from "../../protogen/forum_service_pb";
import useIsVisible from "../hooks/useIsVisible";
import React, { useEffect, useState } from "react";
import { useFetchForumPage } from "../../services/forum";
import Loading from "../common/Loading";
import { Button } from "@mui/material";
import PostListItem from "./PostListItem";
import { Box } from "@mui/system";
import useIsMobile from "../hooks/useIsMobile";

const dedupeEntries = (entries: ForumPost[]) => {
  const result: ForumPost[] = [];
  const indexMap = new Map<string, number>();
  for (const item of entries) {
    // Create a unique string representation of the keys
    const key = `${item.ref}`;
    if (!indexMap.has(key)) {
      indexMap.set(key, result.length);
      result.push(item);
    }
  }
  return result;
};

const sortPostsBasic = (a: ForumPost, b: ForumPost) => {
  return Number(b.sortSec) - Number(a.sortSec);
};

const sortPostsPinned = (a: ForumPost, b: ForumPost) => {
  // Convert isPinned to a number for comparison: true to 1, false to 0
  // We subtract 'b' from 'a' to ensure pinned posts come first
  const pinComparison = Number(b.pinnedSec) - Number(a.pinnedSec);

  // If pinComparison is 0 (both posts have the same pin status),
  // we then sort by creation time (newer posts first)
  if (pinComparison === 0) {
    return sortPostsBasic(a, b);
  }

  // Otherwise, return the result of the pin comparison
  return pinComparison;
};

type Props = {
  postType: ForumPostType | null;
  topics?: string[];
  respectPinned: boolean;
};
export default ({ postType, topics, respectPinned }: Props) => {
  const { isVisible } = useIsVisible({});
  const [posts, setPosts] = React.useState<ForumPost[]>([]);
  const [cursor, setCursor] = useState<string | null>(null);
  const isMobile = useIsMobile();

  const sortPosts = respectPinned ? sortPostsPinned : sortPostsBasic;
  const { request, error, data } = useFetchForumPage((r) => {
    // setForumOnly(true);
    setCursor(r.nextCursor);
    setPosts((preValue) =>
      dedupeEntries([...preValue, ...r.posts]).sort(sortPosts),
    );
  });

  const commonParams = () => ({
    anyTopics: topics || [],
    anyPostTypes: postType ? [postType] : [],
    pageSize: 0, // Use default
    cursor: "",
    respectPinned: respectPinned,
  });

  useEffect(() => {
    request(commonParams());
    const intervalId = setInterval(async () => {
      if (isVisible) {
        request(commonParams());
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const nextPage = async () => {
    if (cursor) {
      await request({
        ...commonParams(),
        cursor: cursor,
      });
    }
  };
  if (error || !data) {
    return <Loading />;
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={"24px"}
      sx={{
        width: "100%",
        paddingTop: "24px",
        gap: isMobile ? "12px" : "24px",
      }}
    >
      {posts.map((post) => (
        <PostListItem
          key={post.ref}
          post={post}
          respectPinned={respectPinned}
        />
      ))}
      {posts && cursor && (
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            onClick={nextPage}
            variant={"text"}
            sx={{ marginTop: "16px" }}
          >
            Load more
          </Button>
        </Box>
      )}
    </Box>
  );
};
