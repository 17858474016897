import { Box } from "@mui/material";
import ReactiveDialog from "../common/ReactiveDialog";
import { useEffect, useState } from "react";
import {
  useCreateFamilySecret,
  useEditSecret,
  useDeleteSecret,
} from "../../services/secrets";
import { EphemeralSecret, FormErrors } from "./types";
import { SecretStub } from "../../protogen/secrets_pb";
import SecretView from "./SecretView";
import SecretTypeSelect from "./SecretTypeSelect";

type Props = {
  open: boolean;
  onClose: () => void;
  onCreate: (s?: SecretStub) => void;
  onEdit: (s?: SecretStub) => void;
  onDelete: (secretRef: string) => void;

  familyRef: string;
  isEdit: boolean;
  secret: EphemeralSecret | null;
};

const defaultFormData = (): EphemeralSecret => ({
  name: "",
  value: "",
  valueType: "",
  attachments: [],
  uploads: [],
});

const validateSecret = (s: EphemeralSecret): FormErrors => {
  const errors: FormErrors = {};
  if (!s.name) {
    errors.name = "Name is required";
  }
  if (!s.valueType) {
    errors.valueType = "Value Type is required";
  }
  return errors;
};

export default ({
  open,
  onClose,
  onCreate,
  onEdit,
  onDelete,
  familyRef,
  secret,
  isEdit,
}: Props) => {
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const [formData, setFormData] = useState<EphemeralSecret>(defaultFormData());
  const { request: createRequest, loading: createLoading } =
    useCreateFamilySecret((r) => {
      onCreate(r?.secret);
    });
  const { request: editRequest, loading: editLoading } = useEditSecret((r) => {
    onEdit(r?.secret);
  });
  const { request: deleteRequest, loading: deleteLoading } = useDeleteSecret(
    () => {
      onDelete(formData?.ref!);
    },
  );
  const loading = createLoading || editLoading || deleteLoading;

  useEffect(() => {
    if (!open && !isEdit) {
      // Clear the form on close.
      setFormData(defaultFormData());
    } else if (isEdit && secret !== null) {
      setFormData({
        ref: secret.ref,
        name: secret.name,
        value: secret.value,
        valueType: secret.valueType,
        attachments: secret.attachments,
        uploads: [],
      });
    }
  }, [open, secret]);

  const updateFormData = (s: EphemeralSecret) => {
    setFormData((prevState) => ({
      ...(prevState || {}),
      ...s,
    }));
  };
  const _validate = () => {
    const errors = validateSecret(formData);
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onSave = async () => {
    if (loading) return;
    if (isEdit) {
      await editRequest({
        secretRef: formData.ref!,
        name: formData.name,
        value: formData.value,
        attachments: formData.uploads,
      });
    } else {
      if (_validate()) {
        createRequest({
          familyRef: familyRef,
          valueType: formData.valueType!,
          name: formData.name!,
          value: formData.value!,
          attachments: formData.uploads!,
        });
      }
    }
  };
  return (
    <ReactiveDialog fullWidthSize="sm" open={open} onClose={() => onClose()}>
      <SecretView
        secret={formData}
        updateFormData={updateFormData}
        onSave={onSave}
        isEdit={isEdit}
        disabled={loading}
        onDelete={() => {
          deleteRequest({ secretRef: formData.ref! });
        }}
        children={
          !isEdit && (
            <Box sx={{ marginBottom: "12px" }}>
              <SecretTypeSelect
                selectedCategory={formData?.valueType || null}
                onChange={(category) => {
                  updateFormData({ ...formData, valueType: category });
                }}
                error={formErrors?.valueType}
              />
            </Box>
          )
        }
      ></SecretView>
    </ReactiveDialog>
  );
};
