import { Task } from "../../protogen/tasks_pb";
import { Typography } from "@mui/material";
import LinkRouter from "../navigation/LinkRouter";
import React from "react";

const TaskBookmarks = ({ task }: { task: Task }) => {
  return (
    <Typography
      variant="body"
      key={task.ref}
      fontSize={"14px"}
      sx={{
        textWrap: "nowrap",
        textOverflow: "ellipsis",
        overflowX: "clip",
      }}
    >
      Saved to{" "}
      <LinkRouter
        to={`/tasks/${encodeURIComponent(task.ref)}`}
        sx={{
          display: "inline",
          color: "#198282",
          fontSize: "14px",
          fontWeight: 700,
        }}
      >
        "{task.title}"
      </LinkRouter>
    </Typography>
  );
};

export default ({ tasks }: { tasks: Task[] }) => {
  return (
    <div
      style={{
        marginTop: "0px 5px 3px",
        display: "flex",
        flexDirection: "column",
        gap: "2px",
      }}
    >
      {tasks.map((task) => (
        <TaskBookmarks key={task.ref} task={task} />
      ))}
    </div>
  );
};
