import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Container } from "@mui/material";
import { ContainerProps } from "@mui/material/Container/Container";
import InfiniteScroll from "react-infinite-scroll-component";

interface ScrollContainerProps extends ContainerProps {
  children: ReactNode;
  hasMore: boolean;
  fetchMoreData: () => void;
  initiallySticky?: boolean;
  scrollToLatestOnChange?: boolean;
  setScrollPercentage?: (scrollPercentage: number) => void;
}

export interface ScrollContainerHandle {
  setSticky: () => void;
  scrollToFirstMatch: (p: (n: Element) => boolean) => void;
}

// https://codesandbox.io/s/youthful-kapitsa-nlv1j?file=/src/index.js:126-214
export default forwardRef<ScrollContainerHandle, ScrollContainerProps>(
  (
    {
      children,
      hasMore,
      fetchMoreData,
      initiallySticky = false,
      scrollToLatestOnChange = false,
      setScrollPercentage,
      ...otherProps
    }: ScrollContainerProps,
    ref,
  ) => {
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [isSticky, setIsSticky] = useState(initiallySticky);

    useImperativeHandle(ref, () => ({
      setSticky: () => {
        setIsSticky(true);
      },
      scrollToFirstMatch: (p: (n: Element) => boolean) => {
        if (scrollContainerRef.current) {
          const scrollContainer = scrollContainerRef.current;
          const elements =
            // It's the great grandchild of the scroll container which contains the elements!
            scrollContainer?.firstElementChild?.firstElementChild?.children ||
            [];
          for (let i = elements.length - 1; i >= 0; i--) {
            if (p(elements[i])) {
              elements[i].scrollIntoView({ behavior: "smooth" });
              break;
            }
          }
        }
      },
    }));

    const handleScroll = () => {
      if (isSticky) {
        setIsSticky(false);
      }
      if (setScrollPercentage && scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          scrollContainerRef.current;
        const newScrollPercentage = Math.floor(
          (1 - Math.abs(scrollTop) / (scrollHeight - clientHeight)) * 100,
        );
        setScrollPercentage(newScrollPercentage);
      }
    };

    useEffect(() => {
      if (isSticky && scrollContainerRef.current) {
        const scrollContainer = scrollContainerRef.current;
        const scrollElement =
          // It's the great grandchild of the scroll container which contains the elements!
          scrollContainer?.firstElementChild?.firstElementChild
            ?.firstElementChild;
        if (scrollElement) {
          scrollElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, [isSticky]);

    useEffect(() => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.addEventListener("scroll", handleScroll);
      }
      return () => {
        if (scrollContainerRef.current) {
          scrollContainerRef.current.removeEventListener(
            "scroll",
            handleScroll,
          );
        }
      };
    }, [isSticky, scrollContainerRef.current]);

    useEffect(() => {
      if (scrollContainerRef.current && scrollToLatestOnChange) {
        scrollContainerRef.current.scrollTop =
          scrollContainerRef.current.scrollHeight;
      }
    }, [children]);
    return (
      <Container
        id="scrollableDiv"
        ref={scrollContainerRef}
        {...otherProps}
        sx={{
          ...otherProps.sx,
          flex: "1 1 100px",
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
        }}
      >
        {/*Put the scroll bar always on the bottom*/}
        <InfiniteScroll
          dataLength={React.Children.toArray(children).length}
          next={fetchMoreData}
          style={{ display: "flex", flexDirection: "column-reverse" }} //To put endMessage and loader to the top.
          inverse={true}
          hasMore={hasMore}
          loader={<h4>Loading...</h4>}
          scrollableTarget="scrollableDiv"
        >
          {children}
        </InfiniteScroll>
      </Container>
    );
  },
);
