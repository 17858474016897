import { Box, Typography } from "@mui/material";
import React, { useState } from "react";
import AddPostDialog from "../forum/AddPostDialog";
import { ForumPostType } from "../../protogen/forum_service_pb";
import { useNavigate } from "react-router";

type Props = {};

export default ({}: Props) => {
  const [modalOpen, setModalOpen] = useState(false);
  // Note: Needs to persist the recommendation ref in the task.
  const [recommendationRef, setRecommendationRef] = useState("");
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          alignItems: "flex-start",
          display: "flex",
          gap: "16px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: "1",
            flexDirection: "column",
            flexGrow: "1",
            gap: "2px",
          }}
        >
          <Typography variant="body">
            {!recommendationRef ? (
              <>
                Did you find out anything new? Contribute the community by{" "}
                <span
                  style={{
                    color: "#198282",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setModalOpen(true);
                  }}
                >
                  adding a recommendation
                </span>
                .
              </>
            ) : (
              <>
                Your recommendation has been shared with the community,{" "}
                <span
                  style={{
                    color: "#198282",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(
                      `/community/${encodeURIComponent(recommendationRef)}`,
                    );
                  }}
                >
                  view it here
                </span>
                .
              </>
            )}
          </Typography>
        </Box>
      </Box>
      <AddPostDialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onCreated={(p) => {
          setRecommendationRef(p.ref);
          setModalOpen(false);
        }}
        // topics={topics}
        postType={ForumPostType.POST_RECOMMENDATION}
      />
    </>
  );
};
