import useProtoMethod from "./useProtoMethod";
import {
  CreateAdvisorNuxRequest,
  CreateAdvisorNuxResponse,
  FetchNuxDetailsRequest,
  FetchNuxDetailsResponse,
  ConfirmAdvisorLandingRequest,
  ConfirmAdvisorLandingResponse,
  AddPersonalDetailsAdvisorNuxRequest,
  AddPersonalDetailsAdvisorNuxResponse,
  AcceptAdvisorTermsRequest,
  AcceptAdvisorTermsResponse,
  ConfirmContactInfoRequest,
  ConfirmContactInfoResponse,
  AddPublicProfileRequest,
  AddPublicProfileResponse,
  FinishAdvisorNuxRequest,
  FinishAdvisorNuxResponse,
  GenerateAdvisorContactNuxRequest,
  GenerateAdvisorContactNuxResponse,
  CreateMemberNuxRequest,
  CreateMemberNuxResponse,
  ConfirmMemberLandingRequest,
  ConfirmMemberLandingResponse,
  AddMemberDetailsRequest,
  AddMemberDetailsResponse,
  AcceptMemberPlanRequest,
  AcceptMemberPlanResponse,
  AddMemberContactsRequest,
  AddMemberContactsResponse,
  AddMemberTasksRequest,
  AddMemberTasksResponse,
  FinishMemberNuxRequest,
  FinishMemberNuxResponse,
  CreateNuxChangeEventRequest,
  CreateNuxChangeEventResponse,
} from "../protogen/nux_service_pb";

const useCreateAdvisorNux = (
  callback?: (r: CreateAdvisorNuxResponse) => void,
) => {
  return useProtoMethod<CreateAdvisorNuxRequest, CreateAdvisorNuxResponse>(
    "CreateAdvisorNux",
    CreateAdvisorNuxResponse,
    { callback },
  );
};

const useConfirmAdvisorLanding = (
  callback?: (r: ConfirmAdvisorLandingResponse) => void,
) => {
  return useProtoMethod<
    ConfirmAdvisorLandingRequest,
    ConfirmAdvisorLandingResponse
  >("ConfirmAdvisorLanding", ConfirmAdvisorLandingResponse, { callback });
};

const useAddPersonalDetailsAdvisorNux = (
  callback?: (r: AddPersonalDetailsAdvisorNuxResponse) => void,
) => {
  return useProtoMethod<
    AddPersonalDetailsAdvisorNuxRequest,
    AddPersonalDetailsAdvisorNuxResponse
  >("AddPersonalDetailsAdvisorNux", AddPersonalDetailsAdvisorNuxResponse, {
    callback,
  });
};

const useFetchNuxDetails = (
  callback?: (r: FetchNuxDetailsResponse) => void,
) => {
  return useProtoMethod<FetchNuxDetailsRequest, FetchNuxDetailsResponse>(
    "FetchNuxDetails",
    FetchNuxDetailsResponse,
    {
      callback,
    },
  );
};

const useAcceptAdvisorTerms = (
  callback?: (r: AcceptAdvisorTermsResponse) => void,
) => {
  return useProtoMethod<AcceptAdvisorTermsRequest, AcceptAdvisorTermsResponse>(
    "AcceptAdvisorTerms",
    AcceptAdvisorTermsResponse,
    { callback },
  );
};

const useConfirmContactInfo = (
  callback?: (r: ConfirmContactInfoResponse) => void,
) => {
  return useProtoMethod<ConfirmContactInfoRequest, ConfirmContactInfoResponse>(
    "ConfirmContactInfo",
    ConfirmContactInfoResponse,
    { callback },
  );
};

const useAddPublicProfile = (
  callback?: (r: AddPublicProfileResponse) => void,
) => {
  return useProtoMethod<AddPublicProfileRequest, AddPublicProfileResponse>(
    "AddPublicProfile",
    AddPublicProfileResponse,
    { callback },
  );
};

const useFinishAdvisorNux = (
  callback?: (r: FinishAdvisorNuxResponse) => void,
) => {
  return useProtoMethod<FinishAdvisorNuxRequest, FinishAdvisorNuxResponse>(
    "FinishAdvisorNux",
    FinishAdvisorNuxResponse,
    { callback },
  );
};

const useGenerateAdvisorContactNux = (
  callback?: (r: GenerateAdvisorContactNuxResponse) => void,
) => {
  return useProtoMethod<
    GenerateAdvisorContactNuxRequest,
    GenerateAdvisorContactNuxResponse
  >("GenerateAdvisorContactNux", GenerateAdvisorContactNuxResponse, {
    callback,
  });
};

const useCreateMemberNux = (
  callback?: (r: CreateMemberNuxResponse) => void,
) => {
  return useProtoMethod<CreateMemberNuxRequest, CreateMemberNuxResponse>(
    "CreateMemberNux",
    CreateMemberNuxResponse,
    { callback },
  );
};

const useConfirmMemberLanding = (
  callback?: (r: ConfirmMemberLandingResponse) => void,
) => {
  return useProtoMethod<
    ConfirmMemberLandingRequest,
    ConfirmMemberLandingResponse
  >("ConfirmMemberLanding", ConfirmMemberLandingResponse, { callback });
};

const useAddMemberDetails = (
  callback?: (r: AddMemberDetailsResponse) => void,
) => {
  return useProtoMethod<AddMemberDetailsRequest, AddMemberDetailsResponse>(
    "AddMemberDetails",
    AddMemberDetailsResponse,
    { callback },
  );
};

const useAcceptMemberPlan = (
  callback?: (r: AcceptMemberPlanResponse) => void,
) => {
  return useProtoMethod<AcceptMemberPlanRequest, AcceptMemberPlanResponse>(
    "AcceptMemberPlan",
    AcceptMemberPlanResponse,
    { callback },
  );
};

const useAddMemberContacts = (
  callback?: (r: AddMemberContactsResponse) => void,
) => {
  return useProtoMethod<AddMemberContactsRequest, AddMemberContactsResponse>(
    "AddMemberContacts",
    AddMemberContactsResponse,
    { callback },
  );
};

const useAddMemberTasks = (callback?: (r: AddMemberTasksResponse) => void) => {
  return useProtoMethod<AddMemberTasksRequest, AddMemberTasksResponse>(
    "AddMemberTasks",
    AddMemberTasksResponse,
    { callback },
  );
};

const useFinishMemberNux = (
  callback?: (r: FinishMemberNuxResponse) => void,
) => {
  return useProtoMethod<FinishMemberNuxRequest, FinishMemberNuxResponse>(
    "FinishMemberNux",
    FinishMemberNuxResponse,
    { callback },
  );
};

const useCreateMemberNuxChangeEvent = (
  callback?: (r: CreateNuxChangeEventResponse) => void,
) => {
  return useProtoMethod<
    CreateNuxChangeEventRequest,
    CreateNuxChangeEventResponse
  >("CreateMemberNuxChangeEvent", CreateNuxChangeEventResponse, { callback });
};

const useCreateNuxChangeEvent = (
  callback?: (r: CreateNuxChangeEventResponse) => void,
) => {
  return useProtoMethod<
    CreateNuxChangeEventRequest,
    CreateNuxChangeEventResponse
  >("CreateNuxChangeEvent", CreateNuxChangeEventResponse, { callback });
};

export {
  useCreateAdvisorNux,
  useFetchNuxDetails,
  useConfirmAdvisorLanding,
  useAddPersonalDetailsAdvisorNux,
  useAcceptAdvisorTerms,
  useConfirmContactInfo,
  useAddPublicProfile,
  useFinishAdvisorNux,
  useGenerateAdvisorContactNux,
  useCreateMemberNux,
  useConfirmMemberLanding,
  useAddMemberDetails,
  useAcceptMemberPlan,
  useAddMemberContacts,
  useAddMemberTasks,
  useFinishMemberNux,
  useCreateMemberNuxChangeEvent,
  useCreateNuxChangeEvent,
};
