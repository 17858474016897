import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Button,
  Drawer,
  IconButton,
  FormControlLabel,
} from "@mui/material";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/outline";
import Loading from "../components/common/Loading";
import GridPage from "../components/layout/GridPage";
import { useListTasksForAdvisor } from "../services/tasks";
import { TaskState } from "../protogen/tasks_pb";
import { SearchContext } from "../components/context/SearchContextProvider";
import TasksTable from "../components/tasks/TasksTable";
import { TaskFilter } from "../components/tasks/FilterContext";
import { PlainMessage } from "@bufbuild/protobuf";
import { ListTasksForAdvisorRequest } from "../protogen/tasks_service_pb";
import useIsMobile from "../components/hooks/useIsMobile";
import { ReactComponent as CloseIcon } from "../icons/Close.svg";
import { PRIORITY_STATES } from "../components/creation/PrioritySelect";
import {
  AddFilterContext,
  FilterContext,
} from "../components/tasks/FilterContext";
import useSessionStorageState from "../components/hooks/useSessionStorageState";
import Checkbox from "../components/common/Checkbox";
import { TASK_STATES } from "../components/tasks/constants";
import AddTaskBar from "../components/tasks/AddTaskBar";

const buildRequest = (
  filters: TaskFilter[],
): PlainMessage<ListTasksForAdvisorRequest> => {
  let resp: PlainMessage<ListTasksForAdvisorRequest> = {
    taskStates: [],
    familyRefs: [],
    categories: [],
    priorities: [],
  };
  filters.forEach((f) => {
    if (f.field === "status") {
      resp.taskStates = f.value as string[];
    } else if (f.field === "family") {
      resp.familyRefs = f.value as string[];
    } else if (f.field === "category") {
      resp.categories = f.value as string[];
    } else if (f.field === "priority") {
      resp.priorities = f.value as string[];
    }
  });
  return resp;
};

type CheckboxFilterProps = {
  options: {
    label: string;
    value: string;
    icon?: React.ReactNode;
  }[];
  title: string;
  fieldName: string;
  onChange: (field: string, value: string, isChecked: boolean) => void;
  initial?: string[];
};

const CheckboxFilter = ({
  options,
  title,
  fieldName,
  onChange,
  initial = [],
}: CheckboxFilterProps) => {
  const [checkedValues, setCheckedValues] = useState<string[]>(initial);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>{<Typography variant="h4">{title}</Typography>}</Box>
      {options.map(({ value, label }, idx) => (
        <FormControlLabel
          key={idx}
          label={label}
          control={
            <Checkbox
              checked={checkedValues.includes(value)}
              onChange={(e: { target: { checked: any } }) => {
                const isChecked = e.target.checked;
                setCheckedValues((prevCheckedValues) => {
                  return isChecked
                    ? [...prevCheckedValues, value]
                    : prevCheckedValues.filter((v) => v !== value);
                });
                onChange(fieldName, value, isChecked);
              }}
            />
          }
        />
      ))}
    </Box>
  );
};

const _Wrapped = ({ initialStates }: { initialStates: string[] }) => {
  const { setTasksOnly } = useContext(SearchContext);
  const isMobile = useIsMobile();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const familyParam = queryParams.get("familyRefs");
  const familyRefs: string[] | undefined = familyParam
    ? familyParam.split(",")
    : undefined;
  const [singleFamilyRef] = useState<string | null>(
    familyRefs && familyRefs.length === 1 ? familyRefs[0] : null,
  );

  const [showFilters, setShowFilters] = useState(false);
  const { loading, data, request } = useListTasksForAdvisor(() => {
    setTasksOnly(true);
  });

  const fetchTasks = (filters: TaskFilter[]) => {
    request(buildRequest(filters));
  };

  const { upsertFilter } = useContext(FilterContext);
  const [storedPriorities, setStoredPriorities] = useSessionStorageState<
    string[]
  >("faye.tasks.filters-priorities", []);
  const [storedStates, setStoredStates] = useSessionStorageState<string[]>(
    "faye.tasks.filters-states",
    initialStates,
  );
  const [storedFamilies, setStoredFamilies] = useSessionStorageState<string[]>(
    "faye.tasks.filters-families",
    [],
  );

  const stateSetters: {
    [key: string]: (values: string[]) => void;
  } = {
    priority: setStoredPriorities,
    status: setStoredStates,
    family: setStoredFamilies,
  };

  const stateGetters: {
    [key: string]: string[];
  } = {
    priority: storedPriorities,
    status: storedStates,
    family: storedFamilies,
  };

  const handleChange = (field: string, value: string, isChecked: boolean) => {
    const state = stateGetters[field];
    const newValues = isChecked
      ? state.includes(value)
        ? state
        : [...state, value]
      : state.filter((v: string) => v !== value);

    if (stateSetters[field] && (field !== "family" || !singleFamilyRef)) {
      stateSetters[field](newValues);
    }

    upsertFilter({
      field: field,
      operator: "oneOf",
      value: newValues,
    });
  };

  useEffect(() => {
    // update filter context when the component gets rendered based on local storage
    const fields = [
      { field: "priority", value: storedPriorities },
      { field: "status", value: storedStates },
      { field: "family", value: familyRefs ? familyRefs : storedFamilies },
    ];

    fields.forEach(({ field, value }) => {
      upsertFilter({
        field: field,
        operator: "oneOf",
        value: value,
      });
    });

    fetchTasks([
      { field: "status", operator: "oneOf", value: storedStates },
      { field: "priority", operator: "oneOf", value: storedPriorities },
      ...(familyRefs
        ? [{ field: "family", operator: "oneOf", value: familyRefs }]
        : [{ field: "family", operator: "oneOf", value: storedFamilies }]),
    ]);
  }, []);
  return (
    <GridPage
      sx={{
        maxWidth: "1000px",
        margin: isMobile ? "" : "64px min(7%, 100px)",
        padding: isMobile ? "32px 24px" : "",
        gap: "20px",
      }}
    >
      <Box
        sx={{
          flex: "1 1 100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="display"
          id="tableTitle"
          component="div"
          sx={{ marginBottom: "" }}
        >
          Tasks
        </Typography>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <Button
            startIcon={
              <AdjustmentsHorizontalIcon
                height="20px"
                width="20px"
                stroke="#198282"
              />
            }
            variant="text"
            onClick={() => setShowFilters(true)}
            style={{ marginRight: "16px" }}
          >
            Filters
          </Button>
        </Box>
      </Box>
      <AddTaskBar
        familyRef={singleFamilyRef || undefined}
        selectFamilies={data?.families || []}
      />
      <Box
        sx={{
          width: "100%",
          background: "var(--base-white, #FFF)",
          display: "flex",
          flexDirection: "column",
          padding: "0px",
        }}
      >
        {!data ? (
          <Loading />
        ) : (
          <TasksTable
            loading={loading}
            fetchTasks={fetchTasks}
            tasks={data.tasks}
            isSortable={true}
          />
        )}
      </Box>
      <Box>
        <Drawer
          anchor={"right"}
          open={showFilters}
          onClose={() => setShowFilters(false)}
        >
          <Box
            sx={{
              width: "348px",
              display: "flex",
              flexDirection: "column",
              padding: "48px 32px",
              gap: "52px",
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h2Serif">Filters</Typography>
              <IconButton
                onClick={() => {
                  setShowFilters(false);
                }}
                sx={{
                  height: "34px",
                  width: "34px",
                  padding: 0,
                  border: "2px solid #EDEDED",
                  borderRadius: "100px",
                }}
              >
                <CloseIcon height="12px" width="12px" stroke="#8E9598" />
              </IconButton>
            </Box>
            <CheckboxFilter
              title="Priority"
              fieldName="priority"
              initial={storedPriorities}
              options={PRIORITY_STATES.map((s) => ({
                label: s.label,
                value: s.value.toString(),
                icon: s.icon,
              }))}
              onChange={handleChange}
            />
            <CheckboxFilter
              title="Status"
              fieldName="status"
              initial={storedStates}
              options={TASK_STATES.map((s) => ({
                label: s.label,
                value: s.value.toString(),
                icon: s.icon,
              }))}
              onChange={handleChange}
            />
            <CheckboxFilter
              options={
                data?.families.map((f) => ({ label: f.name, value: f.ref })) ||
                []
              }
              title="Family"
              fieldName="family"
              initial={singleFamilyRef ? familyRefs : storedFamilies}
              onChange={handleChange}
            />
          </Box>
        </Drawer>
      </Box>
    </GridPage>
  );
};

export default () => {
  const initialStates = [
    TaskState.OPEN.toString(),
    TaskState.INPROGRESS.toString(),
  ];
  return (
    <AddFilterContext initialStates={initialStates}>
      <_Wrapped initialStates={initialStates} />
    </AddFilterContext>
  );
};
