import { styled } from "@mui/system";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { PhoneState } from "../../types/phone";
import CallIcon from "@mui/icons-material/Call";
import { useContext } from "react";
import { CallContext } from "../context/AddCallContext";
import { useTransferCallDevice } from "../../services/phone";
import { Device } from "../../protogen/phone_service_pb";

const DarkBox = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.grey,
  color: theme.palette.grey,
  padding: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

type Props = {
  state: PhoneState;
};

type ActiveCallProps = {
  callId: string;
  endCall: () => void;
  muteCall: () => void;
  isMuted: boolean;
};
const ActiveCall = ({
  callId,
  endCall,
  muteCall,
  isMuted,
}: ActiveCallProps) => {
  const { request } = useTransferCallDevice();
  const callForward = () => {
    request({
      callId,
      toDevice: Device.PHONE,
    });
  };
  return (
    <>
      <span>Transfer to Phone</span>
      <IconButton
        onClick={() => {
          callForward();
        }}
        color="inherit"
        size="small"
      >
        <PhoneForwardedIcon />
      </IconButton>
      <span>Mute</span>
      <IconButton
        onClick={() => {
          muteCall();
        }}
        color="inherit"
        size="small"
      >
        {isMuted ? <VolumeUpIcon /> : <VolumeOffIcon />}
      </IconButton>
      <span>End Call</span>
      <IconButton
        onClick={() => {
          endCall();
        }}
        color="inherit"
        size="small"
      >
        <CloseIcon />
      </IconButton>
    </>
  );
};

export default ({ state }: Props) => {
  const {
    device: { isMuted, acceptCall, denyCall, endCall, muteCall },
    activeCallId,
  } = useContext(CallContext);
  return (
    <DarkBox>
      {state === PhoneState.RINGING && (
        <>
          <span>Calling Friends</span>
          <IconButton onClick={acceptCall!} color="inherit" size="small">
            <CallIcon />
          </IconButton>
          <IconButton onClick={denyCall!} color="inherit" size="small">
            <CloseIcon />
          </IconButton>
        </>
      )}
      {state === PhoneState.ACTIVE && (
        <ActiveCall
          callId={activeCallId!}
          endCall={endCall!}
          isMuted={isMuted}
          muteCall={muteCall!}
        />
      )}
    </DarkBox>
  );
};
