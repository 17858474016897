import { Box, Typography, Link } from "@mui/material";
import NuxContentLayout from "../../../components/nux/NuxContentLayout";
import { ReactComponent as FayeIcon } from "../../../icons/FayeBrand.svg";

export default () => {
  return (
    <NuxContentLayout>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: "48px",
        }}
      >
        <FayeIcon
          style={{
            height: "48px",
            width: "82px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="title" sx={{ marginBottom: "24px" }}>
            Welcome!
          </Typography>
          <Typography variant="body" sx={{ marginBottom: "8px" }}>
            Oops, your invitation link has expired. Please reach out to your
            advisor or <Link href="mailto:hi@findfaye.com">Faye</Link> for help.
          </Typography>
        </Box>
      </Box>
    </NuxContentLayout>
  );
};
