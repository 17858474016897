import { Box, Typography } from "@mui/material";
import CurrentUserAvatar from "../../../components/common/CurrentUserAvatar";
import { Advisor, PublicAdvisor } from "src/protogen/common_pb";
export default ({
  advisor,
  message,
  backgroundColor,
}: {
  advisor?: Advisor | PublicAdvisor;
  message: string;
  backgroundColor?: string;
}) => {
  return (
    <Box
      sx={(theme) => ({
        padding: "24px",
        display: "flex",
        flexDirection: "column",
        gap: "24px",
        border: `1px solid ${theme.palette.border}`,
        borderRadius: "12px",
        backgroundColor: backgroundColor ? backgroundColor : "inherit",
      })}
    >
      <Box sx={{ display: "flex", gap: "24px", alignItems: "center" }}>
        <Box>
          <CurrentUserAvatar user={advisor} size={62} clickable={false} />
        </Box>
        <Box>
          <Typography variant="h3Serif">{advisor?.displayName}</Typography>
          <Typography
            variant="bodyHeavy"
            color="#6B6E7B"
            sx={{ fontWeight: 600 }}
          >
            Family Advisor
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Typography variant="bodyHeavy" color="#3D3D3D">
          A note from {advisor?.firstName || "your advisor"}
        </Typography>
        <Typography variant="body" color="#3D3D3D">
          {message}
        </Typography>
      </Box>
    </Box>
  );
};
