import { Typography, ListItem, Box } from "@mui/material";
import { Task } from "../../protogen/tasks_pb";
import LinkRouter from "../../components/navigation/LinkRouter";
import useIsMobile from "../hooks/useIsMobile";

export default ({ tasks, sx }: { tasks: Task[]; sx?: React.CSSProperties }) => {
  const isMobile = useIsMobile();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "12px" }}>
      {tasks.length === 0 && (
        <ListItem
          disablePadding
          sx={{
            padding: "20px",
            maxWidth: "900px",
          }}
        >
          <Typography
            variant="body"
            color="#6B6E7B"
            sx={{
              marginBottom: isMobile ? "8px" : "4px",
            }}
          >
            No tasks
          </Typography>
        </ListItem>
      )}
      {tasks.map((task, index) => {
        return (
          <LinkRouter
            sx={{ padding: "0px" }}
            key={index}
            to={`/tasks/${encodeURIComponent(task.ref)}`}
          >
            <Box
              sx={(theme) => ({
                width: "100%",
                padding: "20px",
                paddingRight: "16px",
                border: `1px solid ${theme.palette.border}`,
                borderRadius: "8px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                cursor: "pointer",
                ...sx,
              })}
            >
              <Typography variant="body" color="#3D3D3D">
                {task.title}
              </Typography>
            </Box>
          </LinkRouter>
        );
      })}
    </Box>
  );
};
