import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, TextField, Divider } from "@mui/material";
import {
  Hammer,
  TreePalm,
  Baby,
  PartyPopper,
  Sparkles,
  Utensils,
} from "lucide-react";
import auth from "../../../components/nux/auth";
import { useAddMemberTasks } from "../../..//services/nux";
import { getStepPath } from "../../../components/nux/utils";
import NuxContentLayout from "../../../components/nux/NuxContentLayout";
import useIsMobile from "../../..//components/hooks/useIsMobile";
import { USER_NUX_TYPE } from "../../../components/nux/constants";
import { ChevronUp, ChevronDown } from "lucide-react";

type ExampleTask = {
  title: string;
  icon: React.ReactElement;
  examples: string[];
};

const EXAMPLE_TASKS: ExampleTask[] = [
  {
    title: "Home services",
    icon: <Hammer size={32} stroke="#198282" strokeWidth={1.5} />,
    examples: ["Find a new handyman", "Book a pest control specialist"],
  },
  {
    title: "Travel and vacation",
    icon: <TreePalm size={32} stroke="#198282" strokeWidth={1.5} />,
    examples: ["Book a beach house for 7 people", "Research car rentals"],
  },
  {
    title: "Children's activities",
    icon: <Baby size={32} stroke="#198282" strokeWidth={1.5} />,
    examples: ["Find beginner ballet lessons", "Register for summer camps"],
  },
  {
    title: "Parties and events",
    icon: <PartyPopper size={32} stroke="#198282" strokeWidth={1.5} />,
    examples: ["Book a bounce house", "Find and wrap a present"],
  },
  {
    title: "Calendar management",
    icon: <Sparkles size={32} stroke="#198282" strokeWidth={1.5} />,
    examples: ["Reschedule PT appointment", "Book recurring date night"],
  },

  {
    title: "Meal planning",
    icon: <Utensils size={32} stroke="#198282" strokeWidth={1.5} />,
    examples: [
      "Find new kid-friendly recipes",
      "Order grocery delivery each week",
    ],
  },
];

const Task = ({
  title,
  icon,
  examples,
}: {
  title: string;
  icon: React.ReactElement;
  examples: string[];
}) => {
  const isMobile = useIsMobile();
  const [isOpen, setIsOpen] = useState<boolean>(!isMobile);
  return (
    <Box
      sx={{
        width: isMobile ? "100%" : "225px",
        display: "flex",
        flexDirection: "column",
        gap: "8px",
      }}
    >
      <Box
        sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: isMobile ? "12px" : "8px",
          }}
        >
          {icon}
          <Typography variant="bodyHeavy">{title}</Typography>
        </Box>
        {isMobile && (
          <Box
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? (
              <ChevronUp color="#8E9598" size={24}></ChevronUp>
            ) : (
              <ChevronDown color="#8E9598" size={24}></ChevronDown>
            )}
          </Box>
        )}
      </Box>
      {isOpen && (
        <Box sx={{ display: "flex", flexDirection: "column", gap: "6px" }}>
          {examples.map((example) => (
            <Box key={example}>
              <Box sx={{ paddingLeft: isMobile ? "44px" : "0px" }}>
                <Typography variant="bodySmall">{example}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [taskInquiry, setTaskInquiry] = useState<string>("");
  const { request, loading } = useAddMemberTasks((r) => {
    if (r?.nuxDetails?.stepName) {
      const path = getStepPath(r.nuxDetails.stepName, USER_NUX_TYPE.MEMBER);
      navigate(path);
    }
  });

  const onContinue = async () => {
    await request({
      token: auth.getAuthData(),
      taskInquiry: taskInquiry,
    });
  };

  return (
    <NuxContentLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            marginBottom: "8px",
          }}
        >
          <Typography variant="title">Tasks</Typography>
          <Typography variant="body" color="#3D3D3D">
            Now it’s time to start thinking about that to-do list! Here are some
            categories and examples to give you ideas.
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "24px",
            padding: "12px 0px",
          }}
        >
          {EXAMPLE_TASKS.map((task, i) => {
            return (
              <Box key={i} sx={{ width: isMobile ? "100%" : "auto" }}>
                <Task key={task.title} {...task} />
                {isMobile ? (
                  <Divider sx={{ marginTop: "16px" }}></Divider>
                ) : (
                  <></>
                )}
              </Box>
            );
          })}
        </Box>
        <Box sx={{ margin: "40px 0px" }}>
          <Box sx={{ marginBottom: "16px" }}>
            <Typography variant="h4">
              Which tasks are top of mind for you?
            </Typography>
          </Box>
          <TextField
            multiline
            fullWidth
            rows={7}
            onChange={(e) => {
              setTaskInquiry(e.target.value);
            }}
          ></TextField>
        </Box>
        <Box sx={{ alignSelf: "flex-end", width: isMobile ? "100%" : "auto" }}>
          <Button
            sx={{ width: "100%" }}
            onClick={onContinue}
            disabled={loading}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </NuxContentLayout>
  );
};
