import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { Task } from "../../protogen/tasks_pb";
import SharePrompt from "./SharePrompt";
import BlockContainer from "./blocks/BlockContainer";
import BlockGutterMap from "./blocks/BlockGutterMap";
import { minutesToDisplay } from "./utils";

const formatTime = (timeInSeconds: bigint | undefined): string => {
  // Temp time format util we have a centralized one
  if (!timeInSeconds) return "";
  const date = new Date(Number(timeInSeconds) * 1000);

  return date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    month: "long",
    day: "numeric",
    year: "numeric",
    hour12: true,
  });
};

export default ({
  task,
  newlyCompleted = false,
}: {
  task: Task;
  newlyCompleted?: boolean;
}) => {
  useEffect(() => {
    if (newlyCompleted) {
      // scroll into view at the vert bottom.
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  }, []);
  return (
    <BlockContainer
      gutterIcon={BlockGutterMap.timeCompletion.icon}
      isLast={true}
      advisorViewOnly={true}
    >
      <Box
        sx={(theme) => ({
          display: "flex",
          alignItems: "flex-start",
          alignSelf: "stretch",
          flexDirection: "column",
          border: `1px solid ${theme.palette.border}`,
          borderRadius: "12px",
          padding: "20px 32px",
          gap: "16px",
        })}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Typography variant="h4">Hooray, you finished a task! 🎉</Typography>
          <Typography variant="bodySmall" sx={{ color: "#8E9598" }}>
            {formatTime(task.completionDateSec)}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <SharePrompt />
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <Typography variant="body">Total time tracked</Typography>
            <Typography variant="bodyHeavy">
              {minutesToDisplay(
                (task?.timing?.minutesReported || 0) +
                  (task?.timing?.hoursReported || 0) * 60,
              )}
            </Typography>
            <Typography
              onClick={() => {
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              sx={{ cursor: "pointer" }}
              variant="bodyHeavy"
              color="#198282"
            >
              Review
            </Typography>
          </Box>
        </Box>
      </Box>
    </BlockContainer>
  );
};
