import { ReactNode, useContext, useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { Family, FeedEntry, Medium } from "../../protogen/advisors_service_pb";
import { CallContext } from "../context/AddCallContext";
import { useInitiateCall } from "../../services/phone";
import { Device } from "../../protogen/phone_service_pb";
import { CurrentUserContext } from "../context/RequireAuth";
import { PhoneState } from "../../types/phone";
import PhoneStatus from "../phone/PhoneStatus";
import { useNavigate } from "react-router-dom";
import ReactiveDialog from "../common/ReactiveDialog";
import PhoneIcon from "@mui/icons-material/Phone";
import useIsMobile from "../hooks/useIsMobile";

const checkMicPermissions = async (): Promise<[boolean, string | null]> => {
  const constraints = { audio: true };
  try {
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    // Permission granted, stop the stream immediately
    stream.getTracks().forEach((track) => track.stop());
  } catch (error) {
    console.error(error);
    if (error instanceof Error) {
      if (
        error.name === "NotAllowedError" ||
        error.name === "PermissionDeniedError"
      ) {
        // Microphone access is denied
        return [false, "Microphone access denied."];
      } else if (
        error.name === "NotFoundError" ||
        error.name === "DevicesNotFoundError"
      ) {
        // No microphone found
        return [false, "No microphone device found."];
      }
    }
    // Other errors
    return [false, "Error accessing the microphone."];
  }
  return [true, null];
};

type CallFormProps = {
  onStarted: (e: FeedEntry) => void;
  recipientRef?: string | null;
  family?: Family;
};

const CallForm = ({
  onStarted,
  family,
  recipientRef = null,
}: CallFormProps) => {
  const currentUser = useContext(CurrentUserContext);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [recRef, setRecRef] = useState<string | null>(recipientRef);
  const { setAcceptNextCall, setActiveCallId } = useContext(CallContext);
  const [device, setDevice] = useState<number>(
    // On mobile, default to call forwarding.
    isMobile ? Device.PHONE : Device.CLIENT,
  );
  const { loading, request } = useInitiateCall((resp) => {
    setAcceptNextCall();
    setActiveCallId(resp.phoneCall.ref);
    const call = resp.phoneCall;
    onStarted(
      new FeedEntry({
        ref: call.ref,
        senderRef: call.callerRef,
        timestampSec: call.lastUpdatedSec,
        medium: Medium.PHONE,
        phoneCall: call,
        recipientRefs: call.recipientRefs,
      }),
    );
    navigate(`/calls/${encodeURIComponent(call.ref)}`);
  });

  const handleChange = (event: SelectChangeEvent<number>) => {
    setDevice(event.target.value as number);
  };
  const groupMembers =
    family && family.familyMembers.filter((m) => m.primaryPhone);
  const initiateCall = () => {
    if (!recRef) return;
    const memberRefs =
      groupMembers && recRef === "group-sms"
        ? groupMembers.map((r) => r.ref)
        : [recRef];
    request({
      toMemberRefs: memberRefs,
      advisorDevice: device,
    });
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      sx={{ padding: "20px", height: "100%" }}
    >
      {family && (
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-disabled-label">
            Calling
          </InputLabel>
          <Select
            labelId="demo-simple-select-disabled-label"
            id="demo-simple-select-disabled"
            defaultValue={recRef}
            label="Calling"
            onChange={(e) => setRecRef(e.target.value as string)}
          >
            {family.familyMembers.map((member) => (
              <MenuItem
                value={member.ref}
                key={member.ref}
                disabled={!member.primaryPhone}
              >
                <Box display={"flex"} flexDirection={"column"}>
                  <div>{member.displayName}</div>
                  {!member.primaryPhone && (
                    <FormHelperText sx={{ mt: 0 }}>
                      Missing phone number
                    </FormHelperText>
                  )}
                </Box>
              </MenuItem>
            ))}
            {groupMembers && groupMembers.length > 1 && (
              <MenuItem value={"group-sms"}>
                <div>{groupMembers.map((g) => g.firstName).join(" & ")}</div>
              </MenuItem>
            )}
          </Select>
        </FormControl>
      )}
      <FormControl sx={{ m: 1, minWidth: 120 }} disabled={loading}>
        <InputLabel id="demo-simple-select-disabled-label">
          Call from
        </InputLabel>
        <Select
          labelId="demo-simple-select-disabled-label"
          id="demo-simple-select-disabled"
          value={device}
          label="Call from"
          disabled={loading || isMobile}
          onChange={handleChange}
        >
          <MenuItem value={Device.CLIENT}>Here</MenuItem>
          <MenuItem value={Device.PHONE}>
            Cell {currentUser.phoneTeaser}
          </MenuItem>
        </Select>
        {device === Device.CLIENT ? (
          <FormHelperText>
            The call will be made from the browser / app.
          </FormHelperText>
        ) : (
          <FormHelperText>
            The call will be connected with your personal cell phone number.
          </FormHelperText>
        )}
      </FormControl>
      <Box display="flex">
        <Button
          fullWidth
          color="success"
          onClick={initiateCall}
          disabled={loading || !recRef}
          style={{ marginTop: "25px" }}
          startIcon={<PhoneIcon />}
        >
          Call
        </Button>
      </Box>
    </Box>
  );
};

const PermissionsError = ({ retry }: { retry: () => void }) => {
  const userAgent = window.navigator.userAgent;
  let browserInstructions: ReactNode;
  if (userAgent.includes("Chrome")) {
    // Check if Chrome
    const chromeLink =
      "chrome://settings/content/siteDetails?site=https%3A%2F%2Fadvisor.findfaye.com";
    browserInstructions = (
      <p>
        Go to the chrome settings and enable microphone access for this website:{" "}
        <b>{chromeLink}</b>
      </p>
    );
  } else if (userAgent.includes("Safari")) {
    // Check if Safari
    browserInstructions = (
      <p>
        Enable microphone access on this website by going to{" "}
        <b>
          <i>Safari / Settings for advisor.findfaye.com</i>
        </b>
        .
      </p>
    );
  } else {
    // Not Chrome or Safari
    browserInstructions = (
      <p>Please grant microphone access to the browser to continue.</p>
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      sx={{ padding: "20px", height: "100%" }}
    >
      <Box display="flex">
        <Alert
          action={
            <Button variant="outlined" size="small" onClick={retry}>
              RETRY
            </Button>
          }
          severity="warning"
        >
          <AlertTitle>Microphone Unavailable</AlertTitle>
          Calls cannot be made without microphone access.
          {browserInstructions}
        </Alert>
      </Box>
    </Box>
  );
};

interface PhoneCallInstantiationProps {
  onStarted: (e: FeedEntry) => void;
  recipientRef?: string;
  family?: Family;
  closed: boolean;
  onClose: () => void;
}

export default ({
  onStarted,
  recipientRef,
  family,
  closed,
  onClose,
}: PhoneCallInstantiationProps) => {
  const {
    device: { phoneState },
  } = useContext(CallContext);
  const [micAccess, setMicAccess] = useState<boolean | null>(null);

  useEffect(() => {
    if (!closed) {
      checkMicPermissions().then(([granted]) => {
        setMicAccess(granted);
      });
    }
  }, [closed]);

  const activeCall =
    phoneState === PhoneState.ACTIVE || phoneState === PhoneState.RINGING;
  if (activeCall) {
    return (
      <Box>
        <PhoneStatus state={phoneState} />
      </Box>
    );
  }

  if (micAccess === null) {
    return null;
  } else if (micAccess) {
    return (
      <ReactiveDialog
        open={!closed}
        onClose={onClose}
        title={`Start Phone Call`}
      >
        <CallForm
          onStarted={onStarted}
          recipientRef={recipientRef}
          family={family}
        />
      </ReactiveDialog>
    );
  } else {
    return (
      <ReactiveDialog open={!closed} onClose={onClose} title={""}>
        <PermissionsError retry={checkMicPermissions} />
      </ReactiveDialog>
    );
  }
};
