import React, { useState } from "react";
import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import { Box } from "@mui/material";
import Loading from "../../components/common/Loading";
import useIsMobile from "../../components/hooks/useIsMobile";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import FamilySelect from "../../components/creation/FamilySelect";
import { useGenerateICSUrl } from "../../services/calendar";
import { KeyIcon } from "@heroicons/react/24/outline";
import { getApiDomain } from "../../constants";

export default () => {
  const isMobile = useIsMobile();
  const { request, data, loading } = useGenerateICSUrl();
  const [familyRef, setFamilyRef] = useState<string | null>(null);
  const apiDomain = getApiDomain().slice(0, -4); // Remove "/api" from the end
  const calendarUrl = data?.icsUrlPath && `${apiDomain}${data?.icsUrlPath}`;

  const onCopy = () => {
    if (calendarUrl) {
      navigator.clipboard.writeText(calendarUrl);
    }
  };
  const onGenerate = async () => {
    if (familyRef) {
      await request({ familyRef });
    }
  };
  return (
    <Box
      sx={{
        margin: isMobile ? "" : "64px min(7%, 100px)",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "0",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginBottom="16px"
        >
          <Breadcrumbs
            breadcrumbs={[
              {
                name: "Home",
                link: "/",
              },
              { name: "Tools", link: "/tools" },
            ]}
          />
          <Box
            display="flex"
            width="100%"
            gap={isMobile ? "12px" : "24px"}
            {...(isMobile && { flexDirection: "column" })}
            {...(!isMobile && {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Typography variant="display">Calendar URLs</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "16px",
          padding: !isMobile ? "24px 0" : undefined,
        }}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          alignItems="center"
          gap={"12px"}
        >
          <FamilySelect
            sx={{
              width: "300px",
            }}
            onChange={(familyRef) => setFamilyRef(familyRef)}
            selectedFamily={familyRef}
            adminMode={true}
          />
          <Button disabled={loading || !familyRef} onClick={onGenerate}>
            Generate
          </Button>
        </Box>
        {loading && !data && <Loading />}
        {calendarUrl && (
          <>
            {data?.integrations?.length === 0 && (
              <Alert severity="warning" sx={{ padding: "12px 24px" }}>
                No calendar integrations found for this family.
              </Alert>
            )}
            <Alert
              icon={<KeyIcon height="22px" width="22px" />}
              severity="info"
              sx={{ padding: "12px 24px" }}
            >
              <AlertTitle>Secure Calendar URL</AlertTitle>
              <div
                style={{
                  cursor: "pointer",
                  borderColor: "rgba(0, 0, 0, 0.23)",
                  border: "1px solid #D4D4D4",
                  borderRadius: "8px",
                  padding: "15px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  marginTop: "10px",
                }}
                onClick={onCopy}
              >
                {calendarUrl}
              </div>
              <Typography variant="caption" onClick={onCopy}>
                Click to copy to clipboard.
              </Typography>
            </Alert>
            <Button
              variant={"outlined"}
              onClick={() => {
                // https://stackoverflow.com/questions/74709014/pre-populate-google-calendar-subscribe-by-url
                window.open(
                  `https://calendar.google.com/calendar/u/0/r?cid=${encodeURIComponent(
                    calendarUrl.replace("https://", "webcal://"),
                  )}`,
                  "_blank",
                );
              }}
            >
              Add to Google Calendar
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};
