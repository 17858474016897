import useProtoMethod from "./useProtoMethod";

import {
  LiveTranscriptionsRequest,
  LiveTranscriptionsResponse,
} from "../protogen/phone_service_pb";
import { getInferenceDomain } from "../constants";

const useLiveTranscriptions = (
  callback?: (r: LiveTranscriptionsResponse) => void,
) => {
  return useProtoMethod<LiveTranscriptionsRequest, LiveTranscriptionsResponse>(
    "LiveTranscriptions",
    LiveTranscriptionsResponse,
    {
      baseUrl: getInferenceDomain(),
      callback,
    },
  );
};

export { useLiveTranscriptions };
