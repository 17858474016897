import ReactiveDialog from "../common/ReactiveDialog";
import { useEffect, useState } from "react";
import {
  useCreateFactSection,
  useEditFactSection,
  useDeleteFactSection,
} from "../../services/facts";
import { FormErrors, EphemeralFactSection } from "./types";
import { FactSectionStub } from "../../protogen/facts_pb";
import FactSectionView from "./FactSectionView";

type Props = {
  open: boolean;
  onClose: () => void;
  onCreate: (s?: FactSectionStub) => void;
  onEdit: (s?: FactSectionStub) => void;
  onDelete: (factRef: string) => void;

  familyRef: string;
  isEdit: boolean;
  factSection: EphemeralFactSection | null;
};

const defaultFormData = (): EphemeralFactSection => ({
  name: "",
});

const validateFactSection = (s: EphemeralFactSection): FormErrors => {
  const errors: FormErrors = {};
  if (!s.name) {
    errors.name = "Name is required";
  }
  return errors;
};

export default ({
  open,
  onClose,
  onCreate,
  onEdit,
  onDelete,
  familyRef,
  factSection,
  isEdit,
}: Props) => {
  const [formData, setFormData] =
    useState<EphemeralFactSection>(defaultFormData());
  const { request: createRequest } = useCreateFactSection((r) => {
    onCreate(r?.factSection);
  });
  const { request: editRequest } = useEditFactSection((r) => {
    onEdit(r?.factSection);
  });
  const { request: deleteRequest } = useDeleteFactSection(() => {
    onDelete(formData?.ref!);
  });

  useEffect(() => {
    if (!open && !isEdit) {
      // Clear the form on close.
      setFormData(defaultFormData());
    } else if (factSection !== null) {
      setFormData({
        ref: factSection.ref,
        name: factSection.name,
        sortOrder: factSection.sortOrder,
      });
    }
  }, [open, factSection]);

  const updateFormData = (s: EphemeralFactSection) => {
    setFormData((prevState) => ({
      ...(prevState || {}),
      ...s,
    }));
  };
  const _validate = () => {
    const errors = validateFactSection(formData);
    return Object.keys(errors).length === 0;
  };

  const onSave = async () => {
    if (isEdit) {
      await editRequest({
        factSectionRef: formData.ref!,
        name: formData.name,
        sortOrder: formData.sortOrder!,
      });
    } else {
      if (_validate()) {
        createRequest({
          familyRef: familyRef,
          name: formData.name!,
        });
      }
    }
  };
  return (
    <ReactiveDialog fullWidthSize="sm" open={open} onClose={() => onClose()}>
      <FactSectionView
        factSection={formData}
        updateFormData={updateFormData}
        onSave={onSave}
        onCancel={() => onClose()}
        isEdit={isEdit}
        onDelete={() => {
          deleteRequest({ factSectionRef: formData.ref! });
        }}
      />
    </ReactiveDialog>
  );
};
