import { Box, IconButton } from "@mui/material";
import React from "react";
import { Bookmark } from "lucide-react";
import { Task } from "../../protogen/tasks_pb";
import TaskBookmarks from "./TaskBookmarks";

interface Props {
  alignLeft: boolean;
  avatarGutter: boolean;
  avatarIcon?: React.ReactNode;
  children: React.ReactNode;
  headerLeftText?: string | React.ReactNode | null;
  headerRightText?: string | React.ReactNode | null;
  action?: React.ReactNode;
  highlighted?: string;
  onBookmark?: () => void;
  taskBookmarks?: Task[];
}
export default ({
  alignLeft,
  avatarGutter,
  avatarIcon,
  children,
  headerLeftText,
  headerRightText,
  action,
  highlighted,
  onBookmark,
  taskBookmarks,
}: Props) => {
  const hasHeader = headerLeftText || headerRightText;
  return (
    <div>
      <Box
        data-is-highlighted={!!highlighted}
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "row",
          justifyContent: alignLeft ? "flex-start" : "flex-end",
          gap: "12px",
          paddingBottom: "8px",
          paddingTop: "8px",
          ...(highlighted
            ? {
                backgroundColor: highlighted,
                borderRadius: "10px",
              }
            : {}),
          "&:not(:hover)": {
            ".bookmark-button": {
              display: "none",
            },
          },
        }}
      >
        {(avatarIcon || action) && (
          <div>
            {avatarIcon}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: avatarIcon ? "calc(100% - 40px)" : "100%",
                justifyContent: "end",
                paddingBottom: "4px",
              }}
            >
              {action}
            </div>
          </div>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: alignLeft ? "flex-start" : "flex-end",
            // NOTE(Kip): This was here, seems purposeful, but not sure why. Will delete later.
            // minWidth: "200px",
            // 36px to leave room for gap + bookmark icon.
            maxWidth: "calc(100% - 36px)",
            ...(avatarIcon || action || !avatarGutter
              ? {}
              : { marginLeft: "52px" }),
          }}
        >
          {hasHeader && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginBottom: "4px",
                alignItems: "baseline",
                gap: "8px",
              }}
            >
              <div
                style={{
                  color: "#344054",
                  fontSize: "14px",
                  fontWeight: 500,
                  lineHeight: "20px",
                  userSelect: "none",
                }}
              >
                {headerLeftText}
              </div>
              <Box sx={{ flexGrow: 1 }}></Box>
              <div
                style={{
                  fontSize: "12px",
                  color: "#475467",
                  lineHeight: "18px",
                  userSelect: "none",
                }}
              >
                {headerRightText}
              </div>
            </Box>
          )}
          {children}
        </Box>
        {alignLeft && onBookmark && (
          <IconButton
            sx={{
              paddingRight: 0,
              paddingLeft: 0,
              marginTop: hasHeader ? "24px" : undefined,
            }}
            className={"bookmark-button"}
            onClick={() => onBookmark()}
          >
            <Bookmark />
          </IconButton>
        )}
      </Box>
      {taskBookmarks && <TaskBookmarks tasks={taskBookmarks} />}
    </div>
  );
};
