import { PhoneCall } from "../../protogen/conversation_pb";
import TabbedDetailsPanel from "./TabbedDetailsPanel";
import Section from "./Section";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import NotesEditor from "./NotesEditor";
import React, { useRef, useState } from "react";
import { ReactComponent as SparklesIcon } from "../../icons/Advisor/Sparkles.svg";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useGenerateSuggestions } from "../../services/extraction";
import Loading from "../common/Loading";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DateDisplay from "../common/DateDisplay";
import JSONPretty from "react-json-pretty";
import { Note } from "../../protogen/notes_pb";
import { DeleteForever } from "@mui/icons-material";
import { useDeletePhoneCall } from "../../services/phone";
import { useNavigate } from "react-router-dom";
import ConfirmationDialog, {
  useConfirmationDialog,
} from "../common/ConfirmationDialog";
import { AccountStub } from "../../protogen/common_pb";
import {
  SuggestionEntityType,
  Suggestions,
} from "../../protogen/suggestions_pb";
import { SuggestionsList } from "../activity/Suggestions";
import SuggestionActions, {
  SuggestionActionsHandle,
} from "../activity/SuggestionActions";

type SuggestionsSectionProps = {
  phoneCall: PhoneCall;
  suggestions: Suggestions;
  familyRef: string;
  title: string;
};

const SuggestionsSection = ({
  phoneCall,
  suggestions,
  familyRef,
}: SuggestionsSectionProps) => {
  const actionsRef = useRef<SuggestionActionsHandle | null>(null);
  const { request, loading } = useGenerateSuggestions();
  const handleClick = async () => {
    await request({
      entityType: SuggestionEntityType.PHONECALL,
      entityRef: phoneCall.ref,
      suggestionType: "",
    });
  };
  return (
    <Section
      title={
        <Box display="flex" flexDirection="row" gap="10px" sx={{}}>
          <SparklesIcon />
          <Typography color="#198282" variant="bodySmall">
            Smart suggestions
          </Typography>
        </Box>
      }
      actionIcon={
        <IconButton onClick={handleClick}>
          <RefreshIcon />
        </IconButton>
      }
    >
      <Box
        display="flex"
        flexDirection="column"
        gap="24px"
        mb="16px"
        width="100%"
      >
        {loading && (
          <Box sx={{ width: "100%" }}>
            <Loading minHeight={null} />
          </Box>
        )}
        {!loading && !suggestions?.hasSuggestions && (
          <Box>
            <Typography variant="bodySmall">
              Analyze this call to find suggested actions, tasks, and events.
            </Typography>
          </Box>
        )}
        {!loading && suggestions?.hasSuggestions && (
          <SuggestionsList
            suggestions={suggestions}
            actions={{
              suggestTask: (t) => actionsRef.current?.suggestTask(t),
              suggestFact: (f) => actionsRef.current?.suggestFact(f),
              suggestEvent: (e) => actionsRef.current?.suggestEvent(e),
            }}
            isOpen={true}
            entityRef={phoneCall.ref}
            entityType={"phonecall"}
            familyRef={familyRef}
          />
        )}
      </Box>
      <SuggestionActions ref={actionsRef} familyRef={familyRef} />
    </Section>
  );
};

interface SmartNotesSectionProps {
  phoneCall: PhoneCall;
  note: Note;
  title: string;
}
const SmartNotesSection = ({ title, note }: SmartNotesSectionProps) => {
  // Temporarily removing this feature until we can rethink it.
  // const [text, setText] = useState("");
  // const { request, loading } = usePromptExtraction((r) => {
  //   setText(`<p>${r.result}`);
  // });
  // if (loading) {
  //   return <Loading />;
  // }
  return (
    <NotesEditor
      note={note}
      title={title}
      initialContent={""}
      // summarizer={() => {
      //   request({
      //     promptName: "summarize",
      //     callRef: phoneCall.ref,
      //   });
      // }}
    />
  );
};

const DebugEventsSection = ({ call }: { call: PhoneCall; title: string }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState<number | null>(null);
  const confirmState = useConfirmationDialog();
  const { request, loading } = useDeletePhoneCall(() => {
    navigate(`/calls/${encodeURIComponent(call.ref)}`);
  });
  const handleChange =
    (panel: number) => (_: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : null);
    };
  return (
    <Section
      title={"Delete Call"}
      actionIcon={
        <IconButton
          disabled={loading}
          onClick={() => {
            confirmState.openDialog(() => {
              // Make request.
              request({ callRef: call.ref });
            });
          }}
        >
          <DeleteForever />
        </IconButton>
      }
    >
      <i>System Events</i>
      <Box
        sx={{
          maxWidth: "100%",
        }}
      >
        {call.events
          .slice()
          .reverse()
          .map((event, i) => {
            const eventData = JSON.parse(event);
            return (
              <Accordion
                key={i}
                expanded={expanded === i}
                onChange={handleChange(i)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: "50%", flexShrink: 0 }}>
                    {eventData["Timestamp"]
                      ? DateDisplay({ date: new Date(eventData["Timestamp"]) })
                      : "N/A"}
                  </Typography>
                  <Typography sx={{ color: "text.secondary" }}>
                    {eventData["CallStatus"] || "N/A"}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <JSONPretty id="json-pretty" data={eventData}></JSONPretty>
                </AccordionDetails>
              </Accordion>
            );
          })}
        <ConfirmationDialog {...confirmState.dialogProps} />
      </Box>
    </Section>
  );
};

type Props = {
  phoneCall: PhoneCall;
  caller: AccountStub;
  recipient: AccountStub;
  callNotes: Note;
  familyRef: string;
  suggestions: Suggestions;
};

export default ({ phoneCall, callNotes, familyRef, suggestions }: Props) => {
  return (
    <>
      <TabbedDetailsPanel
        panels={[
          <SmartNotesSection
            key={0}
            note={callNotes}
            phoneCall={phoneCall}
            title={"Notes"}
          />,
          <SuggestionsSection
            key={1}
            phoneCall={phoneCall}
            suggestions={suggestions}
            familyRef={familyRef}
            title={"Smart Suggestions"}
          />,
          <DebugEventsSection title={"Advanced"} key={4} call={phoneCall} />,
        ]}
      />
    </>
  );
};
