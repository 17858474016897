import {
  useRef,
  ReactNode,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Box, Typography, Button, Divider, Collapse } from "@mui/material";
import { formatUSPhoneNumber, getDomainFromURL } from "../../../common/utils";
import LinkRouter from "../../navigation/LinkRouter";
import { BlockHandle, BlockProps, usePollForBlockUpdates } from "./utils";
import BlockContainer from "./BlockContainer";
import BlockGutterMap from "./BlockGutterMap";
import { AccountStub } from "../../../protogen/common_pb";
import { useUpdateBlockMetadata } from "../../../services/tasks";
import { ChevronDown, ChevronUp } from "lucide-react";

import {
  TaskBlock,
  TaskRecommendation,
  TaskRecommendationResult,
  ForumPostResult,
  ForumPostRecommendation,
} from "../../../protogen/tasks_pb";
import useIsMobile from "../../hooks/useIsMobile";

const RecommenderByline = ({ recommender }: { recommender: AccountStub }) => {
  return (
    <Typography
      color="#6B6E7B"
      variant="bodySmall"
      sx={{
        textAlign: "right",
      }}
    >
      Shared by{" "}
      <LinkRouter to={`/advisor/${recommender.ref}`} inline>
        <span
          style={{
            cursor: "pointer",
          }}
        >
          {recommender.displayName}
        </span>
      </LinkRouter>
    </Typography>
  );
};

const SuggestionsContainer = ({
  title,
  children,
  actionTitle,
  actionLink,
  targetNew,
  isCollapsed,
  toggleCollapsed,
}: {
  title: ReactNode;
  children: ReactNode[];
  actionTitle?: string;
  actionLink?: string;
  targetNew?: boolean;
  isCollapsed?: boolean;
  toggleCollapsed?: () => void;
}) => {
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: isCollapsed ? "0px" : "12px",
        padding: isMobile ? "16px 20px" : "16px 24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {title}
        <Box
          sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          onClick={toggleCollapsed}
        >
          {isCollapsed ? (
            <ChevronDown size={24} stroke="#8E9598" />
          ) : (
            <ChevronUp size={24} stroke="#8E9598" />
          )}
        </Box>
      </Box>

      <Collapse in={!isCollapsed}>
        <Box>
          {children}
          {actionLink && actionTitle && (
            <LinkRouter to={actionLink} targetNew={targetNew}>
              <Button
                variant="text"
                sx={{
                  width: "fit-content",
                  height: "inherit",
                }}
              >
                {actionTitle}
              </Button>
            </LinkRouter>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};
const SuggestionEntry = ({
  destinationUrl,
  title,
  description,
  thumbnailUrl,
  subHeader,
  phone,
  address,
}: {
  destinationUrl: string;
  title: string;
  description: string;
  thumbnailUrl?: string | null;
  subHeader?: string | ReactNode | null;
  phone?: string;
  address?: string;
}) => {
  const isMobile = useIsMobile();
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={"8px"}
      sx={{
        padding: "16px 0px",
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        gap="4px"
        alignItems="center"
        sx={{ overflow: "hidden" }}
      >
        <Typography
          color="#3D3D3D"
          variant="body"
          sx={{
            fontWeight: isMobile ? 500 : 700,
            overflow: "hidden",
            cursor: "pointer",
            flexGrow: 1,
          }}
          onClick={() => window.open(destinationUrl, "_blank")}
        >
          {title}
        </Typography>
        {!isMobile && subHeader}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        gap="4px"
        alignItems="center"
        sx={{ overflow: "hidden" }}
      >
        {isMobile && subHeader}
      </Box>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{
          gap: "8px",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            gap: "12px",
          }}
        >
          <Typography
            color="#616161"
            variant="bodySmall"
            sx={{
              display: "-webkit-box",
              WebkitLineClamp: "3",
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              wordBreak: "break-word",
            }}
          >
            {description}
          </Typography>
          {(phone || address) && (
            <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
              {phone && (
                <Typography color="#6B6E7B" variant="bodySmall">
                  {formatUSPhoneNumber(phone)}
                </Typography>
              )}
              {address && (
                <Typography color="#6B6E7B" variant="bodySmall">
                  {address}
                </Typography>
              )}
            </Box>
          )}
        </Box>
        {thumbnailUrl && (
          <LinkRouter to={destinationUrl} targetNew>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "4px",
              }}
            >
              {!isMobile && (
                <img
                  src={thumbnailUrl}
                  alt={title}
                  style={{
                    height: "100%",
                    aspectRatio: "1 / 1",
                    objectFit: "cover",
                    maxHeight: "110px",
                    cursor: "pointer",
                    borderRadius: "8px",
                  }}
                />
              )}
              {getDomainFromURL(destinationUrl) && (
                <Typography
                  color="#6B6E7B"
                  variant="bodySmall"
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  {getDomainFromURL(destinationUrl)}
                </Typography>
              )}
            </Box>
          </LinkRouter>
        )}
      </Box>
    </Box>
  );
};

export default forwardRef<BlockHandle, BlockProps>(
  ({ block: initialBlock }: BlockProps, ref) => {
    const [parsedContent, setParsedContent] = useState<
      TaskRecommendation | ForumPostRecommendation
    >();
    const [parsedMetadata, setParsedMetadata] = useState<any>();
    const hasContent = useRef<boolean>(!!initialBlock.content);
    const { request } = useUpdateBlockMetadata((r) => {
      if (!r.block?.metadata) return;
      setParsedMetadata(JSON.parse(r.block?.metadata));
    });
    const { block, polling } = usePollForBlockUpdates({
      initialBlock,
      isComplete: (b: TaskBlock) =>
        JSON.parse(b.metadata).processing_state !== "processing",
      onCompleted: (b: TaskBlock) => {
        hasContent.current = !!b.content;
      },
    });
    useImperativeHandle(ref, () => ({
      isPolling: () => polling,
    }));
    useEffect(() => {
      const { content: jsonString } = block;
      if (!jsonString) return;
      let parsedContent = JSON.parse(jsonString);
      const details = parsedContent.content?.[0]?.attrs?.details;

      parsedContent[
        block.type === "community-resources" ? "posts" : "results"
      ] = details;
      setParsedContent(parsedContent);

      if (!block.metadata) return;
      setParsedMetadata(JSON.parse(block.metadata));
    }, [block]);

    const handleToggleCollapsed = () => {
      request({
        taskBlockRef: block.ref,
        key: "isCollapsed",
        value: (!parsedMetadata.isCollapsed).toString(),
      });
    };

    if (polling || !hasContent?.current) return <></>;

    return (
      <BlockContainer
        gutterIcon={BlockGutterMap.recommendation.icon}
        advisorViewOnly={true}
      >
        <Box display="flex" flexDirection="column">
          {hasContent?.current && (
            <Box>
              {block.type === "community-resources" ? (
                <SuggestionsContainer
                  title={
                    <Typography color="#3D3D3D" variant="bodyHeavy">
                      Advisor recommendations: {parsedContent?.queryString}
                    </Typography>
                  }
                  actionTitle="See all recommendations"
                  actionLink={`/search?domain=community&query=${encodeURIComponent(
                    (parsedContent && parsedContent?.queryString) || "",
                  )}`}
                  targetNew
                  isCollapsed={parsedMetadata?.isCollapsed}
                  toggleCollapsed={handleToggleCollapsed}
                >
                  {parsedContent && "posts" in parsedContent
                    ? parsedContent.posts.map(
                        (post: ForumPostResult, i: number) => {
                          return (
                            <Box key={i}>
                              <SuggestionEntry
                                destinationUrl={`/community/${encodeURIComponent(
                                  post.postRef,
                                )}`}
                                title={post.title}
                                description={post.textContent}
                                subHeader={
                                  <RecommenderByline
                                    recommender={post.createdBy!}
                                  />
                                }
                              />
                              {i < parsedContent.posts.length - 1 && (
                                <Divider></Divider>
                              )}
                            </Box>
                          );
                        },
                      )
                    : []}
                </SuggestionsContainer>
              ) : (
                <SuggestionsContainer
                  title={
                    <Typography color="#3D3D3D" variant="bodyHeavy">
                      Web results for "{parsedContent?.queryString}"
                    </Typography>
                  }
                  actionTitle="See all recommendations"
                  actionLink={
                    (parsedContent &&
                      "url" in parsedContent &&
                      parsedContent?.url) ||
                    ""
                  }
                  targetNew
                  isCollapsed={parsedMetadata?.isCollapsed}
                  toggleCollapsed={handleToggleCollapsed}
                >
                  {parsedContent && "results" in parsedContent
                    ? parsedContent.results.map(
                        (result: TaskRecommendationResult, i: number) => {
                          return (
                            <Box key={i}>
                              <SuggestionEntry
                                destinationUrl={result.url}
                                title={result.title}
                                description={result.explanation}
                                thumbnailUrl={result.thumbnailUrl}
                                phone={result.phone}
                                address={result.address}
                              />
                              {i < parsedContent.results.length - 1 && (
                                <Divider></Divider>
                              )}
                            </Box>
                          );
                        },
                      )
                    : []}
                </SuggestionsContainer>
              )}
            </Box>
          )}
        </Box>
      </BlockContainer>
    );
  },
);
