import useProtoMethod from "./useProtoMethod";
import {
  SendTextMessageRequest,
  SendTextMessageResponse,
} from "../protogen/text_message_service_pb";

const useSendTextMessage = (
  callback?: (r: SendTextMessageResponse) => void,
) => {
  return useProtoMethod<SendTextMessageRequest, SendTextMessageResponse>(
    "SendTextMessage",
    SendTextMessageResponse,
    { callback },
  );
};

export { useSendTextMessage };
