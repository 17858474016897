import TextField from "@mui/material/TextField";
import { Box } from "@mui/system";
import { EphemeralPost, FormErrors } from "./types";
import DocumentEditor from "../editor/DocumentEditor";
import { Button } from "@mui/material";
import CheckedDropDown from "../common/CheckedDropDown";
import TopicAutocomplete from "./TopicAutocomplete";
import {
  POST_LABELS_BY_TYPE,
  POST_TYPES,
  POST_TYPES_BY_LABEL,
} from "./constants";
import { isUserAdmin } from "../../common/userUtils";
import { CurrentUserContext } from "../context/RequireAuth";
import { useContext } from "react";
import { ForumPostType } from "../../protogen/forum_service_pb";

type Props = {
  formData: EphemeralPost;
  updateFormData: (f: EphemeralPost) => void;
  hideAction: boolean;
  errors: FormErrors | null;
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
};

export default ({
  formData,
  updateFormData,
  hideAction,
  errors,
  loading,
  onSubmit,
}: Props) => {
  const currentUser = useContext(CurrentUserContext);
  let initialDetailsText: string | undefined = undefined;
  if (formData?.body?.payload) {
    initialDetailsText = JSON.parse(formData?.body?.payload);
  } else if (formData?.body?.textContent) {
    initialDetailsText = formData?.body?.textContent;
  }
  // Filter out the resource post type if the user is not an admin
  const postTypes = isUserAdmin(currentUser)
    ? POST_TYPES
    : POST_TYPES.filter((pt) => pt[1] !== ForumPostType.POST_RESOURCE);
  return (
    <Box height="100%" display={"flex"} flexDirection={"column"} gap={"18px"}>
      <Box display={"flex"} flexDirection={"row"}>
        <CheckedDropDown
          disabled={loading}
          radio={true}
          options={postTypes.map((pt) => ({
            label: pt[0],
            value: pt[0],
            mutuallyExclusive: true,
          }))}
          sx={{
            width: "240px",
            "span.MuiTypography-body": {
              textOverflow: "ellipsis",
              overflow: "hidden",
            },
          }}
          displayValue={
            formData.postType
              ? POST_LABELS_BY_TYPE[formData.postType]
              : "Post type"
          }
          onChange={(selected) =>
            updateFormData({
              postType:
                selected.length > 0
                  ? POST_TYPES_BY_LABEL[selected[0].value]
                  : undefined,
            })
          }
        />
      </Box>
      <Box display={"flex"} flexDirection={"row"}>
        <TextField
          sx={{ flexGrow: 1 }}
          autoFocus
          label="Title"
          type="text"
          fullWidth
          autoComplete="off"
          variant="outlined"
          value={formData.title}
          error={!!errors?.name}
          helperText={errors?.name}
          onChange={(e) => updateFormData({ title: e.target.value })}
        />
      </Box>
      <Box display={"flex"} flexDirection={"row"}>
        <TopicAutocomplete
          setSelected={(s) => updateFormData({ topics: s })}
          initialSelected={formData.topics || []}
          sx={{
            width: "100%",
            maxHeight: "48px",
            ".MuiInputBase-root": {
              minHeight: "48px",
            },
            ".MuiAutocomplete-tag": {
              marginTop: "0px",
            },
            input: {
              marginTop: "0px",
            },
          }}
        />
      </Box>
      <DocumentEditor
        initialContent={initialDetailsText}
        disabled={loading}
        placeholder="Add details..."
        attachmentsEnabled={true}
        editorMinHeight={"30vh"}
        editorMaxHeight={"30vh"}
        setContent={(c) =>
          updateFormData({
            body: {
              payload: c.json,
              contentType: "json",
              textContent: c.text,
              attachments: c.attachments,
            },
          })
        }
        primaryAction={
          !hideAction && (
            <Button onClick={onSubmit} disabled={loading}>
              Post
            </Button>
          )
        }
        sx={{
          height: "100%",
        }}
        scrollable={true}
        formError={!!errors?.content}
        formHelperText={errors?.content ? "A post body is required" : undefined}
      />
    </Box>
  );
};
