import React, { useState } from "react";
import { useUpdateFamilyBilling } from "../../services/billing";
import ReactiveDialog from "../common/ReactiveDialog";
import { TextField } from "@mui/material";

type Props = {
  open: boolean;
  onClose: () => void;
  familyRef: string;
  initialCustomerId?: string;
};

export default ({ open, onClose, familyRef, initialCustomerId }: Props) => {
  const { request, loading } = useUpdateFamilyBilling();
  const [customerId, setCustomerId] = useState<string | null>(
    initialCustomerId || null,
  );
  const onSubmit = async () => {
    if (!customerId) {
      return;
    }
    const data = await request({
      familyRef: familyRef,
      customerId: customerId,
      unlinkCustomer: false,
    });
    if (data) {
      onClose();
    }
  };
  return (
    <ReactiveDialog
      open={open}
      fullWidthSize="sm"
      onClose={onClose}
      title={"Link Stripe customer account to family"}
      primaryAction={onSubmit}
      primaryActionName={"Save"}
      primaryActionEnabled={!loading}
    >
      <p>This will link an existing Stripe customer account to a family.</p>
      <TextField
        label="Customer ID"
        variant="outlined"
        placeholder={"Stripe customer ID (i.e. cus_1234567890)"}
        defaultValue={customerId}
        onChange={(e) => setCustomerId(e.target.value)}
        autoComplete="off"
        disabled={loading}
        sx={{ minWidth: "400px" }}
        fullWidth
        onClick={(event) => event.stopPropagation()}
        onKeyDown={(event) => {
          // Allow shift for newlines.
          if (event.key === "Enter") {
            onSubmit();
          }
        }}
      />
    </ReactiveDialog>
  );
};
