import { Fragment } from "react";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import { SecretStub } from "../../protogen/secrets_pb";
import { getTypeIcon } from "./SecretTypeSelect";

export default ({
  secrets,
  handleSecretClick,
}: {
  secrets: SecretStub[];
  handleSecretClick: (secretRef: string) => void;
}) => {
  return (
    <List
      sx={{
        border: "1px solid #D4D4D4",
        borderRadius: "8px",
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      {secrets.map((secret, index, array) => {
        return (
          <Fragment key={secret.ref}>
            <ListItem
              sx={{
                padding: "18px 24px",
                "& .MuiListItemSecondaryAction-root": {
                  top: "33px",
                  right: "24px",
                },
              }}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="comments"
                  sx={{
                    "&:hover": {
                      backgroundColor: "inherit",
                    },
                  }}
                >
                  {getTypeIcon(secret.valueType)}
                </IconButton>
              }
              disablePadding
              onClick={() => {
                handleSecretClick(secret.ref);
              }}
            >
              <ListItemButton
                sx={{
                  padding: 0,
                  "&:hover": {
                    backgroundColor: "inherit",
                  },
                }}
                dense
                disableRipple
              >
                <ListItemText
                  primaryTypographyProps={{ variant: "body" }}
                  sx={{
                    color: "#3D3D3D",
                  }}
                  primary={secret.name}
                />
              </ListItemButton>
            </ListItem>
            {index < array.length - 1 && <Divider />}
          </Fragment>
        );
      })}
    </List>
  );
};
