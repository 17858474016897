import {
  FormControl,
  MenuItem,
  ListItemIcon,
  SelectChangeEvent,
} from "@mui/material";
import ListItem from "@mui/material/ListItem";
import { TaskState } from "../../protogen/tasks_pb";
import { TASK_STATES } from "./constants";
import { useState } from "react";
import { Trash2 } from "lucide-react";
import Select from "../common/Select";

interface Props {
  selected: TaskState;
  onChange: (s: TaskState) => void;
  error?: string | null;
  disabled?: boolean;
  deleteTask?: () => void;
}

export default ({ selected, onChange, error, disabled, deleteTask }: Props) => {
  const [open, setOpen] = useState(false);
  const [initialSelected] = useState(selected);
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const selectedState: TaskState = event.target.value as TaskState;
    if (selectedState !== selected) {
      setOpen(!open);
      onChange(selectedState);
    }
  };

  return (
    <FormControl sx={{ width: "100%" }}>
      <Select
        disabled={disabled}
        error={!!error}
        defaultValue={initialSelected.toString()}
        onClick={() =>
          // Content might shift before open.  Let the event loop run first.
          setTimeout(() => {
            setOpen(!open);
          }, 1)
        }
        onChange={handleChange}
        sx={{
          fieldset: { border: "2px solid #EAEBEC", borderRadius: "100px" },
        }}
        open={open}
      >
        {TASK_STATES.map((state) => (
          <MenuItem key={state.value} value={state.value}>
            <ListItem
              component="div"
              disablePadding
              sx={(theme) => ({
                color: theme.palette.text.primary,
                div: { minWidth: "none" },
              })}
            >
              <ListItemIcon
                sx={{
                  minWidth: "initial",
                  paddingRight: "4px",
                  width: "24px",
                  marginRight: "4px",
                  svg: { width: "20px" },
                }}
              >
                {state.icon}
              </ListItemIcon>
              {state.label}
            </ListItem>
          </MenuItem>
        ))}
        {deleteTask && (
          <ListItem
            component="li"
            sx={(theme) => ({
              color: theme.palette.text.primary,
              padding: "12px",
              cursor: "pointer",
              fontWeight: 500,
              userSelect: "none",
            })}
            onMouseDown={() => {
              deleteTask();
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: "initial",
                paddingRight: "4px",
                width: "24px",
                marginRight: "4px",
                svg: { width: "20px" },
              }}
            >
              <Trash2 color={"#C14743"} />
            </ListItemIcon>
            Delete
          </ListItem>
        )}
      </Select>
    </FormControl>
  );
};
