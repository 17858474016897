// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/calendar_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { ContentUpdates } from "./notes_pb.ts";
import { CalendarEvent, EventNotice, EventReminder, RecurringUnit } from "./calendar_pb.ts";

/**
 * @generated from message protogen.CreateCalendarEventRequest
 */
export class CreateCalendarEventRequest extends Message<CreateCalendarEventRequest> {
  /**
   * Optional
   *
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: protogen.ContentUpdates description = 3;
   */
  description?: ContentUpdates;

  /**
   * @generated from field: int64 startSecUTC = 4;
   */
  startSecUTC = protoInt64.zero;

  /**
   * @generated from field: int64 endSecUTC = 5;
   */
  endSecUTC = protoInt64.zero;

  /**
   * @generated from field: bool allDay = 6;
   */
  allDay = false;

  /**
   * For all day events we need to specifically store the start and end dates so there's no
   * ambiguity on timezone converted dates.
   *
   * @generated from field: string startDisplayDate = 7;
   */
  startDisplayDate = "";

  /**
   * @generated from field: string endDisplayDate = 8;
   */
  endDisplayDate = "";

  /**
   * @generated from field: protogen.RecurringUnit recurringUnit = 10;
   */
  recurringUnit = RecurringUnit.RecurringUnit_UNSPECIFIED;

  /**
   * @generated from field: int32 recurringInterval = 11;
   */
  recurringInterval = 0;

  /**
   * @generated from field: int64 recurringUntilSecUTC = 12;
   */
  recurringUntilSecUTC = protoInt64.zero;

  /**
   * Email addresses.
   *
   * @generated from field: repeated string invitees = 13;
   */
  invitees: string[] = [];

  /**
   * @generated from field: string sourceEntityType = 14;
   */
  sourceEntityType = "";

  /**
   * @generated from field: string sourceEntityRef = 15;
   */
  sourceEntityRef = "";

  /**
   * @generated from field: int32 sourceEntityIndex = 16;
   */
  sourceEntityIndex = 0;

  /**
   * @generated from field: repeated protogen.EventReminder reminders = 17;
   */
  reminders: EventReminder[] = [];

  /**
   * @generated from field: string displayTimezone = 18;
   */
  displayTimezone = "";

  constructor(data?: PartialMessage<CreateCalendarEventRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateCalendarEventRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "message", T: ContentUpdates },
    { no: 4, name: "startSecUTC", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "endSecUTC", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "allDay", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "startDisplayDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "endDisplayDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "recurringUnit", kind: "enum", T: proto3.getEnumType(RecurringUnit) },
    { no: 11, name: "recurringInterval", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "recurringUntilSecUTC", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "invitees", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "sourceEntityType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "sourceEntityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "sourceEntityIndex", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 17, name: "reminders", kind: "message", T: EventReminder, repeated: true },
    { no: 18, name: "displayTimezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCalendarEventRequest {
    return new CreateCalendarEventRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCalendarEventRequest {
    return new CreateCalendarEventRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCalendarEventRequest {
    return new CreateCalendarEventRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCalendarEventRequest | PlainMessage<CreateCalendarEventRequest> | undefined, b: CreateCalendarEventRequest | PlainMessage<CreateCalendarEventRequest> | undefined): boolean {
    return proto3.util.equals(CreateCalendarEventRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateCalendarEventResponse
 */
export class CreateCalendarEventResponse extends Message<CreateCalendarEventResponse> {
  /**
   * @generated from field: protogen.CalendarEvent event = 1;
   */
  event?: CalendarEvent;

  /**
   * @generated from field: protogen.EventNotice notice = 2;
   */
  notice?: EventNotice;

  constructor(data?: PartialMessage<CreateCalendarEventResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateCalendarEventResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event", kind: "message", T: CalendarEvent },
    { no: 2, name: "notice", kind: "message", T: EventNotice },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCalendarEventResponse {
    return new CreateCalendarEventResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCalendarEventResponse {
    return new CreateCalendarEventResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCalendarEventResponse {
    return new CreateCalendarEventResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCalendarEventResponse | PlainMessage<CreateCalendarEventResponse> | undefined, b: CreateCalendarEventResponse | PlainMessage<CreateCalendarEventResponse> | undefined): boolean {
    return proto3.util.equals(CreateCalendarEventResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListCalendarEventsRequest
 */
export class ListCalendarEventsRequest extends Message<ListCalendarEventsRequest> {
  /**
   * @generated from field: repeated string familyRefs = 1;
   */
  familyRefs: string[] = [];

  /**
   * @generated from field: int64 startSec = 2;
   */
  startSec = protoInt64.zero;

  /**
   * @generated from field: int64 endSec = 3;
   */
  endSec = protoInt64.zero;

  /**
   * @generated from field: bool ignoreTasks = 4;
   */
  ignoreTasks = false;

  /**
   * @generated from field: bool ignoreEvents = 5;
   */
  ignoreEvents = false;

  /**
   * @generated from field: bool includeOverdueTasks = 6;
   */
  includeOverdueTasks = false;

  /**
   * @generated from field: string startCursor = 7;
   */
  startCursor = "";

  constructor(data?: PartialMessage<ListCalendarEventsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListCalendarEventsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 2, name: "startSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "endSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "ignoreTasks", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "ignoreEvents", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "includeOverdueTasks", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "startCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCalendarEventsRequest {
    return new ListCalendarEventsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCalendarEventsRequest {
    return new ListCalendarEventsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCalendarEventsRequest {
    return new ListCalendarEventsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListCalendarEventsRequest | PlainMessage<ListCalendarEventsRequest> | undefined, b: ListCalendarEventsRequest | PlainMessage<ListCalendarEventsRequest> | undefined): boolean {
    return proto3.util.equals(ListCalendarEventsRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListCalendarEventsResponse
 */
export class ListCalendarEventsResponse extends Message<ListCalendarEventsResponse> {
  /**
   * @generated from field: repeated protogen.EventNotice eventNotices = 1;
   */
  eventNotices: EventNotice[] = [];

  /**
   * @generated from field: string nextCursor = 3;
   */
  nextCursor = "";

  constructor(data?: PartialMessage<ListCalendarEventsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListCalendarEventsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "eventNotices", kind: "message", T: EventNotice, repeated: true },
    { no: 3, name: "nextCursor", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListCalendarEventsResponse {
    return new ListCalendarEventsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListCalendarEventsResponse {
    return new ListCalendarEventsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListCalendarEventsResponse {
    return new ListCalendarEventsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListCalendarEventsResponse | PlainMessage<ListCalendarEventsResponse> | undefined, b: ListCalendarEventsResponse | PlainMessage<ListCalendarEventsResponse> | undefined): boolean {
    return proto3.util.equals(ListCalendarEventsResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateCalendarEventRequest
 */
export class UpdateCalendarEventRequest extends Message<UpdateCalendarEventRequest> {
  /**
   * @generated from field: string eventRef = 1;
   */
  eventRef = "";

  /**
   * @generated from field: string updatedTitle = 2;
   */
  updatedTitle = "";

  /**
   * @generated from field: protogen.ContentUpdates updatedDescription = 3;
   */
  updatedDescription?: ContentUpdates;

  /**
   * @generated from field: int64 updatedStartSecUTC = 4;
   */
  updatedStartSecUTC = protoInt64.zero;

  /**
   * @generated from field: int64 updatedEndSecUTC = 5;
   */
  updatedEndSecUTC = protoInt64.zero;

  /**
   * @generated from field: bool updatedAllDay = 6;
   */
  updatedAllDay = false;

  /**
   * For all day events we need to specifically store the start and end dates so there's no
   * ambiguity on timezone converted dates.
   *
   * @generated from field: string updatedStartDisplayDate = 7;
   */
  updatedStartDisplayDate = "";

  /**
   * @generated from field: string updatedEndDisplayDate = 8;
   */
  updatedEndDisplayDate = "";

  /**
   * @generated from field: protogen.RecurringUnit updatedRecurringUnit = 10;
   */
  updatedRecurringUnit = RecurringUnit.RecurringUnit_UNSPECIFIED;

  /**
   * @generated from field: int32 updatedRecurringInterval = 11;
   */
  updatedRecurringInterval = 0;

  /**
   * @generated from field: int64 updatedRecurringUntilSecUTC = 12;
   */
  updatedRecurringUntilSecUTC = protoInt64.zero;

  /**
   * Email addresses.
   *
   * @generated from field: repeated string updatedInvitees = 13;
   */
  updatedInvitees: string[] = [];

  /**
   * @generated from field: repeated protogen.EventReminder updatedReminders = 14;
   */
  updatedReminders: EventReminder[] = [];

  /**
   * @generated from field: string updatedDisplayTimezone = 15;
   */
  updatedDisplayTimezone = "";

  constructor(data?: PartialMessage<UpdateCalendarEventRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateCalendarEventRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "eventRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updatedTitle", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "updatedDescription", kind: "message", T: ContentUpdates },
    { no: 4, name: "updatedStartSecUTC", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "updatedEndSecUTC", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "updatedAllDay", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 7, name: "updatedStartDisplayDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "updatedEndDisplayDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "updatedRecurringUnit", kind: "enum", T: proto3.getEnumType(RecurringUnit) },
    { no: 11, name: "updatedRecurringInterval", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 12, name: "updatedRecurringUntilSecUTC", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "updatedInvitees", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "updatedReminders", kind: "message", T: EventReminder, repeated: true },
    { no: 15, name: "updatedDisplayTimezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCalendarEventRequest {
    return new UpdateCalendarEventRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCalendarEventRequest {
    return new UpdateCalendarEventRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCalendarEventRequest {
    return new UpdateCalendarEventRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCalendarEventRequest | PlainMessage<UpdateCalendarEventRequest> | undefined, b: UpdateCalendarEventRequest | PlainMessage<UpdateCalendarEventRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCalendarEventRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateCalendarEventResponse
 */
export class UpdateCalendarEventResponse extends Message<UpdateCalendarEventResponse> {
  /**
   * @generated from field: protogen.CalendarEvent event = 1;
   */
  event?: CalendarEvent;

  /**
   * @generated from field: protogen.EventNotice notice = 2;
   */
  notice?: EventNotice;

  constructor(data?: PartialMessage<UpdateCalendarEventResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateCalendarEventResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event", kind: "message", T: CalendarEvent },
    { no: 2, name: "notice", kind: "message", T: EventNotice },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCalendarEventResponse {
    return new UpdateCalendarEventResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCalendarEventResponse {
    return new UpdateCalendarEventResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCalendarEventResponse {
    return new UpdateCalendarEventResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCalendarEventResponse | PlainMessage<UpdateCalendarEventResponse> | undefined, b: UpdateCalendarEventResponse | PlainMessage<UpdateCalendarEventResponse> | undefined): boolean {
    return proto3.util.equals(UpdateCalendarEventResponse, a, b);
  }
}

/**
 * @generated from message protogen.DeleteCalendarEventRequest
 */
export class DeleteCalendarEventRequest extends Message<DeleteCalendarEventRequest> {
  /**
   * @generated from field: string eventRef = 1;
   */
  eventRef = "";

  constructor(data?: PartialMessage<DeleteCalendarEventRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DeleteCalendarEventRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "eventRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteCalendarEventRequest {
    return new DeleteCalendarEventRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteCalendarEventRequest {
    return new DeleteCalendarEventRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteCalendarEventRequest {
    return new DeleteCalendarEventRequest().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteCalendarEventRequest | PlainMessage<DeleteCalendarEventRequest> | undefined, b: DeleteCalendarEventRequest | PlainMessage<DeleteCalendarEventRequest> | undefined): boolean {
    return proto3.util.equals(DeleteCalendarEventRequest, a, b);
  }
}

/**
 * @generated from message protogen.DeleteCalendarEventResponse
 */
export class DeleteCalendarEventResponse extends Message<DeleteCalendarEventResponse> {
  constructor(data?: PartialMessage<DeleteCalendarEventResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DeleteCalendarEventResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DeleteCalendarEventResponse {
    return new DeleteCalendarEventResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DeleteCalendarEventResponse {
    return new DeleteCalendarEventResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DeleteCalendarEventResponse {
    return new DeleteCalendarEventResponse().fromJsonString(jsonString, options);
  }

  static equals(a: DeleteCalendarEventResponse | PlainMessage<DeleteCalendarEventResponse> | undefined, b: DeleteCalendarEventResponse | PlainMessage<DeleteCalendarEventResponse> | undefined): boolean {
    return proto3.util.equals(DeleteCalendarEventResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateAttendeeStatusRequest
 */
export class UpdateAttendeeStatusRequest extends Message<UpdateAttendeeStatusRequest> {
  /**
   * @generated from field: string status = 1;
   */
  status = "";

  /**
   * @generated from field: string token = 2;
   */
  token = "";

  constructor(data?: PartialMessage<UpdateAttendeeStatusRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateAttendeeStatusRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAttendeeStatusRequest {
    return new UpdateAttendeeStatusRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAttendeeStatusRequest {
    return new UpdateAttendeeStatusRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAttendeeStatusRequest {
    return new UpdateAttendeeStatusRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAttendeeStatusRequest | PlainMessage<UpdateAttendeeStatusRequest> | undefined, b: UpdateAttendeeStatusRequest | PlainMessage<UpdateAttendeeStatusRequest> | undefined): boolean {
    return proto3.util.equals(UpdateAttendeeStatusRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateAttendeeStatusResponse
 */
export class UpdateAttendeeStatusResponse extends Message<UpdateAttendeeStatusResponse> {
  constructor(data?: PartialMessage<UpdateAttendeeStatusResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateAttendeeStatusResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateAttendeeStatusResponse {
    return new UpdateAttendeeStatusResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateAttendeeStatusResponse {
    return new UpdateAttendeeStatusResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateAttendeeStatusResponse {
    return new UpdateAttendeeStatusResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateAttendeeStatusResponse | PlainMessage<UpdateAttendeeStatusResponse> | undefined, b: UpdateAttendeeStatusResponse | PlainMessage<UpdateAttendeeStatusResponse> | undefined): boolean {
    return proto3.util.equals(UpdateAttendeeStatusResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetCalendarEventRequest
 */
export class GetCalendarEventRequest extends Message<GetCalendarEventRequest> {
  /**
   * @generated from field: string eventRef = 1;
   */
  eventRef = "";

  constructor(data?: PartialMessage<GetCalendarEventRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetCalendarEventRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "eventRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCalendarEventRequest {
    return new GetCalendarEventRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCalendarEventRequest {
    return new GetCalendarEventRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCalendarEventRequest {
    return new GetCalendarEventRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCalendarEventRequest | PlainMessage<GetCalendarEventRequest> | undefined, b: GetCalendarEventRequest | PlainMessage<GetCalendarEventRequest> | undefined): boolean {
    return proto3.util.equals(GetCalendarEventRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetCalendarEventResponse
 */
export class GetCalendarEventResponse extends Message<GetCalendarEventResponse> {
  /**
   * @generated from field: protogen.CalendarEvent event = 1;
   */
  event?: CalendarEvent;

  /**
   * @generated from field: protogen.EventNotice notice = 2;
   */
  notice?: EventNotice;

  constructor(data?: PartialMessage<GetCalendarEventResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetCalendarEventResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "event", kind: "message", T: CalendarEvent },
    { no: 2, name: "notice", kind: "message", T: EventNotice },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCalendarEventResponse {
    return new GetCalendarEventResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCalendarEventResponse {
    return new GetCalendarEventResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCalendarEventResponse {
    return new GetCalendarEventResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCalendarEventResponse | PlainMessage<GetCalendarEventResponse> | undefined, b: GetCalendarEventResponse | PlainMessage<GetCalendarEventResponse> | undefined): boolean {
    return proto3.util.equals(GetCalendarEventResponse, a, b);
  }
}

/**
 * @generated from message protogen.CalendarIntegration
 */
export class CalendarIntegration extends Message<CalendarIntegration> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: string status = 3;
   */
  status = "";

  /**
   * @generated from field: int64 createdSec = 4;
   */
  createdSec = protoInt64.zero;

  /**
   * @generated from field: int64 lastAccessedSec = 5;
   */
  lastAccessedSec = protoInt64.zero;

  /**
   * @generated from field: repeated protogen.CalendarIntegration.Calendar calendars = 6;
   */
  calendars: CalendarIntegration_Calendar[] = [];

  constructor(data?: PartialMessage<CalendarIntegration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CalendarIntegration";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "createdSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "lastAccessedSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "calendars", kind: "message", T: CalendarIntegration_Calendar, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CalendarIntegration {
    return new CalendarIntegration().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CalendarIntegration {
    return new CalendarIntegration().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CalendarIntegration {
    return new CalendarIntegration().fromJsonString(jsonString, options);
  }

  static equals(a: CalendarIntegration | PlainMessage<CalendarIntegration> | undefined, b: CalendarIntegration | PlainMessage<CalendarIntegration> | undefined): boolean {
    return proto3.util.equals(CalendarIntegration, a, b);
  }
}

/**
 * @generated from message protogen.CalendarIntegration.Calendar
 */
export class CalendarIntegration_Calendar extends Message<CalendarIntegration_Calendar> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string summary = 2;
   */
  summary = "";

  /**
   * @generated from field: bool enabled = 3;
   */
  enabled = false;

  /**
   * @generated from field: string timezone = 4;
   */
  timezone = "";

  /**
   * @generated from field: string role = 5;
   */
  role = "";

  constructor(data?: PartialMessage<CalendarIntegration_Calendar>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CalendarIntegration.Calendar";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "summary", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "enabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "timezone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "role", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CalendarIntegration_Calendar {
    return new CalendarIntegration_Calendar().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CalendarIntegration_Calendar {
    return new CalendarIntegration_Calendar().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CalendarIntegration_Calendar {
    return new CalendarIntegration_Calendar().fromJsonString(jsonString, options);
  }

  static equals(a: CalendarIntegration_Calendar | PlainMessage<CalendarIntegration_Calendar> | undefined, b: CalendarIntegration_Calendar | PlainMessage<CalendarIntegration_Calendar> | undefined): boolean {
    return proto3.util.equals(CalendarIntegration_Calendar, a, b);
  }
}

/**
 * @generated from message protogen.GetCalendarIntegrationsRequest
 */
export class GetCalendarIntegrationsRequest extends Message<GetCalendarIntegrationsRequest> {
  constructor(data?: PartialMessage<GetCalendarIntegrationsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetCalendarIntegrationsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCalendarIntegrationsRequest {
    return new GetCalendarIntegrationsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCalendarIntegrationsRequest {
    return new GetCalendarIntegrationsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCalendarIntegrationsRequest {
    return new GetCalendarIntegrationsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetCalendarIntegrationsRequest | PlainMessage<GetCalendarIntegrationsRequest> | undefined, b: GetCalendarIntegrationsRequest | PlainMessage<GetCalendarIntegrationsRequest> | undefined): boolean {
    return proto3.util.equals(GetCalendarIntegrationsRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetCalendarIntegrationsResponse
 */
export class GetCalendarIntegrationsResponse extends Message<GetCalendarIntegrationsResponse> {
  /**
   * @generated from field: repeated protogen.CalendarIntegration integrations = 1;
   */
  integrations: CalendarIntegration[] = [];

  constructor(data?: PartialMessage<GetCalendarIntegrationsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetCalendarIntegrationsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integrations", kind: "message", T: CalendarIntegration, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetCalendarIntegrationsResponse {
    return new GetCalendarIntegrationsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetCalendarIntegrationsResponse {
    return new GetCalendarIntegrationsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetCalendarIntegrationsResponse {
    return new GetCalendarIntegrationsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetCalendarIntegrationsResponse | PlainMessage<GetCalendarIntegrationsResponse> | undefined, b: GetCalendarIntegrationsResponse | PlainMessage<GetCalendarIntegrationsResponse> | undefined): boolean {
    return proto3.util.equals(GetCalendarIntegrationsResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateCalendarIntegrationRequest
 */
export class CreateCalendarIntegrationRequest extends Message<CreateCalendarIntegrationRequest> {
  /**
   * @generated from field: string accessCode = 1;
   */
  accessCode = "";

  /**
   * @generated from field: string accessScope = 2;
   */
  accessScope = "";

  /**
   * @generated from field: string accessRedirectUri = 3;
   */
  accessRedirectUri = "";

  /**
   * @generated from field: string calendarUrl = 4;
   */
  calendarUrl = "";

  constructor(data?: PartialMessage<CreateCalendarIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateCalendarIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accessCode", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "accessScope", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "accessRedirectUri", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "calendarUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCalendarIntegrationRequest {
    return new CreateCalendarIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCalendarIntegrationRequest {
    return new CreateCalendarIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCalendarIntegrationRequest {
    return new CreateCalendarIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCalendarIntegrationRequest | PlainMessage<CreateCalendarIntegrationRequest> | undefined, b: CreateCalendarIntegrationRequest | PlainMessage<CreateCalendarIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(CreateCalendarIntegrationRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateCalendarIntegrationResponse
 */
export class CreateCalendarIntegrationResponse extends Message<CreateCalendarIntegrationResponse> {
  /**
   * @generated from field: protogen.CalendarIntegration integration = 1;
   */
  integration?: CalendarIntegration;

  constructor(data?: PartialMessage<CreateCalendarIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateCalendarIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration", kind: "message", T: CalendarIntegration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateCalendarIntegrationResponse {
    return new CreateCalendarIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateCalendarIntegrationResponse {
    return new CreateCalendarIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateCalendarIntegrationResponse {
    return new CreateCalendarIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateCalendarIntegrationResponse | PlainMessage<CreateCalendarIntegrationResponse> | undefined, b: CreateCalendarIntegrationResponse | PlainMessage<CreateCalendarIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(CreateCalendarIntegrationResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateCalendarIntegrationRequest
 */
export class UpdateCalendarIntegrationRequest extends Message<UpdateCalendarIntegrationRequest> {
  /**
   * @generated from field: string integrationRef = 1;
   */
  integrationRef = "";

  /**
   * @generated from field: bool deactivate = 2;
   */
  deactivate = false;

  /**
   * @generated from field: bool activate = 3;
   */
  activate = false;

  /**
   * @generated from field: string enableCalendarId = 4;
   */
  enableCalendarId = "";

  /**
   * @generated from field: string disableCalendarId = 5;
   */
  disableCalendarId = "";

  constructor(data?: PartialMessage<UpdateCalendarIntegrationRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateCalendarIntegrationRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integrationRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "deactivate", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "activate", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "enableCalendarId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "disableCalendarId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCalendarIntegrationRequest {
    return new UpdateCalendarIntegrationRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCalendarIntegrationRequest {
    return new UpdateCalendarIntegrationRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCalendarIntegrationRequest {
    return new UpdateCalendarIntegrationRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCalendarIntegrationRequest | PlainMessage<UpdateCalendarIntegrationRequest> | undefined, b: UpdateCalendarIntegrationRequest | PlainMessage<UpdateCalendarIntegrationRequest> | undefined): boolean {
    return proto3.util.equals(UpdateCalendarIntegrationRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateCalendarIntegrationResponse
 */
export class UpdateCalendarIntegrationResponse extends Message<UpdateCalendarIntegrationResponse> {
  /**
   * @generated from field: protogen.CalendarIntegration integration = 1;
   */
  integration?: CalendarIntegration;

  constructor(data?: PartialMessage<UpdateCalendarIntegrationResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateCalendarIntegrationResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "integration", kind: "message", T: CalendarIntegration },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateCalendarIntegrationResponse {
    return new UpdateCalendarIntegrationResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateCalendarIntegrationResponse {
    return new UpdateCalendarIntegrationResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateCalendarIntegrationResponse {
    return new UpdateCalendarIntegrationResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateCalendarIntegrationResponse | PlainMessage<UpdateCalendarIntegrationResponse> | undefined, b: UpdateCalendarIntegrationResponse | PlainMessage<UpdateCalendarIntegrationResponse> | undefined): boolean {
    return proto3.util.equals(UpdateCalendarIntegrationResponse, a, b);
  }
}

/**
 * @generated from message protogen.GenerateICSUrlRequest
 */
export class GenerateICSUrlRequest extends Message<GenerateICSUrlRequest> {
  /**
   * Maybe more finegrained control?
   *
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  constructor(data?: PartialMessage<GenerateICSUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GenerateICSUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateICSUrlRequest {
    return new GenerateICSUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateICSUrlRequest {
    return new GenerateICSUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateICSUrlRequest {
    return new GenerateICSUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateICSUrlRequest | PlainMessage<GenerateICSUrlRequest> | undefined, b: GenerateICSUrlRequest | PlainMessage<GenerateICSUrlRequest> | undefined): boolean {
    return proto3.util.equals(GenerateICSUrlRequest, a, b);
  }
}

/**
 * @generated from message protogen.GenerateICSUrlResponse
 */
export class GenerateICSUrlResponse extends Message<GenerateICSUrlResponse> {
  /**
   * does not include the domain!
   *
   * @generated from field: string icsUrlPath = 1;
   */
  icsUrlPath = "";

  /**
   * @generated from field: repeated protogen.CalendarIntegration integrations = 2;
   */
  integrations: CalendarIntegration[] = [];

  constructor(data?: PartialMessage<GenerateICSUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GenerateICSUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "icsUrlPath", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "integrations", kind: "message", T: CalendarIntegration, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GenerateICSUrlResponse {
    return new GenerateICSUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GenerateICSUrlResponse {
    return new GenerateICSUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GenerateICSUrlResponse {
    return new GenerateICSUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GenerateICSUrlResponse | PlainMessage<GenerateICSUrlResponse> | undefined, b: GenerateICSUrlResponse | PlainMessage<GenerateICSUrlResponse> | undefined): boolean {
    return proto3.util.equals(GenerateICSUrlResponse, a, b);
  }
}

