import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Stepper from "../../components/nux/Stepper";
import useIsMobile from "../../components/hooks/useIsMobile";
import { USER_NUX_TYPE } from "../../components/nux/constants";

export default ({ userType }: { userType: USER_NUX_TYPE }) => {
  const isMobile = useIsMobile();
  return (
    <Box sx={{ display: "flex", flexDirection: isMobile ? "column" : "row" }}>
      <Box>
        <Stepper userType={userType} />
      </Box>
      <Box sx={{ width: "100%" }}>
        <Outlet />
      </Box>
    </Box>
  );
};
