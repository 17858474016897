import { useNavigate, useParams } from "react-router-dom";
import { Box, Skeleton, Button, Typography, Grid } from "@mui/material";
import { styled } from "@mui/system";
import { useEffect, useState } from "react";
import DocumentViewer from "../components/editor/DocumentViewer";
import GridPage from "../components/layout/GridPage";
import {
  UserAvatar,
  DefaultAvatar,
} from "../components/common/CurrentUserAvatar";
import AdvisorDetails from "../components/advisor/AdvisorDetails";
import useIsMobile from "../components/hooks/useIsMobile";
import { useFetchAdvisorProfile } from "../services/advisor";
import AdvisorSpecialties from "../components/advisor/AdvisorSpecialties";

const CenteredBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export default () => {
  const navigate = useNavigate();
  let params = useParams();
  const isMobile = useIsMobile();
  useState<boolean>(false);
  const { request, data, loading, error } = useFetchAdvisorProfile();

  useEffect(() => {
    request({ advisorRef: params.advisorRef! });
  }, [params.advisorRef]);

  const advisor = data?.advisor;
  return (
    <GridPage sx={{ padding: "0px" }}>
      {error && <div>Not Found</div>}
      {!error && (
        <Grid container>
          {/* It should span the full width on mobile (12).  The other grid panel is removed. */}
          <Grid item xs={isMobile ? 12 : 8}>
            <CenteredBox
              sx={{
                padding: isMobile ? "24px" : "108px 70px",
              }}
            >
              <Box
                sx={{
                  maxWidth: "750px",
                  gap: "10px",
                  display: "flex",
                  flexDirection: "column",
                  ...(isMobile
                    ? {
                        marginBottom: "10px",
                      }
                    : {
                        marginBottom: "44px",
                      }),
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    ...(isMobile
                      ? {
                          marginBottom: "10px",
                          gap: "20px",
                          flexDirection: "column",
                          alignItems: "center",
                        }
                      : {
                          marginBottom: "35px",
                          gap: "50px",
                          flexDirection: "row",
                        }),
                  }}
                >
                  <Box
                    sx={{
                      flexGrow: 1,
                      maxWidth: isMobile ? "100px" : "158px",
                      width: "100%",
                    }}
                  >
                    {advisor ? (
                      <UserAvatar user={advisor} size={isMobile ? 100 : 158} />
                    ) : (
                      <DefaultAvatar size={isMobile ? 100 : 158} />
                    )}
                    <Button
                      onClick={() => {
                        navigate(
                          `/inbox/messages?recipientRefs=${advisor?.ref}`,
                        );
                      }}
                      sx={{ marginTop: "20px" }}
                      variant="outlined"
                      fullWidth
                    >
                      Message
                    </Button>
                  </Box>
                  <AdvisorDetails advisor={advisor} loading={loading} />
                </Box>
                <AdvisorSpecialties advisor={advisor} />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "16px",
                  }}
                >
                  <Typography variant="h2Serif">About me</Typography>
                  {loading ? (
                    <Skeleton width="80%" />
                  ) : (
                    <DocumentViewer
                      sx={{ marginBottom: "44px" }}
                      content={
                        advisor && advisor?.blurbContent
                          ? JSON.parse(advisor?.blurbContent)
                          : ""
                      }
                    />
                  )}
                </Box>
              </Box>
            </CenteredBox>
          </Grid>
        </Grid>
      )}
    </GridPage>
  );
};
