import { Box, Button, Typography } from "@mui/material";
import { Advisor } from "../../protogen/common_pb";

type Props = {
  advisor: Advisor;
};

export default ({ advisor }: Props) => {
  return (
    <>
      {advisor?.specialties.length > 0 && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            marginBottom: "32px",
          }}
        >
          <Typography variant="h2Serif">Specialties</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: "12px",
              flexWrap: "wrap",
            }}
          >
            {advisor?.specialties.map((specialty: string, i: number) => {
              return (
                <Button
                  key={i}
                  sx={{
                    backgroundColor: "border",
                    cursor: "default",
                    color: "text.secondary",
                    "&:hover": {
                      backgroundColor: "border",
                    },
                  }}
                >
                  {specialty}
                </Button>
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
};
