import { BlockHandle, BlockProps, toMaybeNumber } from "./utils";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { InsertableSuggestion } from "../../editor/extensions/utils";
import DocumentEditor, {
  Handle as DocumentHandle,
} from "../../editor/DocumentEditor";
import useAutoSave from "../../editor/useAutoSave";
import { Box } from "@mui/material";
import { useUpdateBlockContent } from "../../../services/tasks";

export interface Handle extends BlockHandle {
  insertSuggestion: (s: InsertableSuggestion) => void;
}

export default forwardRef<Handle, BlockProps>(
  ({ block, onUpdate }: BlockProps, ref) => {
    const _initialContent =
      block.content && block.content !== "{}" ? JSON.parse(block.content) : "";
    const [hover, setHover] = useState(false);
    const documentEditorRef = useRef<DocumentHandle | null>(null);
    const { request, error } = useUpdateBlockContent();
    // Note(Kip): I can't figure out how to make this work without passing the method ref here.
    useImperativeHandle(ref, () => ({
      insertSuggestion: (s: InsertableSuggestion) =>
        documentEditorRef.current?.insertSuggestion(s),
    }));
    const { setEditorContent } = useAutoSave(
      block.content || null,
      toMaybeNumber(block?.lastUpdatedSec),
      async (c) => {
        const resp = await request({
          taskBlockRef: block.ref,
          content: c.json,
          contentType: "json",
          textContent: c.text,
          attachments: c.attachments,
        });
        if (resp) {
          onUpdate && onUpdate(resp?.block?.lastUpdatedSec || BigInt(0));
        }
      },
      !!error,
    );

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          gap: "16px",
          alignSelf: "stretch",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            gap: "20px",
            borderRadius: "12px",
            padding: "0px 20px 20px 0px",
            background: "#FFF",
            width: "100%",
            ...(hover ? { backgroundColor: "#e8f4f8" } : {}),
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
                gap: "4px",
                flex: "1 0 0",
                alignSelf: "stretch",
              }}
            ></Box>
          </Box>
          <DocumentEditor
            ref={documentEditorRef}
            setContent={setEditorContent}
            passiveEditor={true}
            placeholder={"Write details about this task"}
            initialContent={_initialContent}
            // Not necessary since we fire a toast on save.g
            // primaryAction={savingIndicator}
            attachmentsEnabled={true}
            initialAttachments={block.attachments}
            setDragState={(d) => setHover(d)}
            editorMinHeight={"74px"}
          />
        </Box>
      </Box>
    );
  },
);
