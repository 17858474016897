import useProtoMethod from "./useProtoMethod";
import {
  CreateShortUrlRequest,
  CreateShortUrlResponse,
} from "../protogen/url_shortener_service_pb";

const useCreateShortUrl = (callback?: (r: CreateShortUrlResponse) => void) => {
  return useProtoMethod<CreateShortUrlRequest, CreateShortUrlResponse>(
    "CreateShortUrl",
    CreateShortUrlResponse,
    { callback },
  );
};

export { useCreateShortUrl };
