import { CSSProperties } from "react";
import { Box } from "@mui/material";
import Linkify from "react-linkify";
import WarningIcon from "@mui/icons-material/Warning";
import { Attachment as AttachmentProto } from "../../protogen/common_pb";
import MessageBubble from "./MessageBubble";
import AttachmentComp from "../common/AttachmentComp";

const isLocalLink = (url: string) => {
  try {
    const inputUrl = new URL(url);
    const currentUrl = new URL(window.location.href);
    // Check if the domains are exactly the same, including subdomains
    return inputUrl.hostname === currentUrl.hostname;
  } catch (error) {
    // If the URL is invalid, log the error and return false
    console.error("Invalid URL:", error);
    return false;
  }
};

interface TextMessageProps {
  loading?: boolean;
  error?: string | undefined;
  content: string | null;
  attachments: AttachmentProto[];
  alignLeft: boolean;
  openAttachment: (a: AttachmentProto) => void;
  sx?: CSSProperties;
}

export default ({
  loading,
  error,
  content,
  attachments,
  alignLeft,
  openAttachment,
  sx,
}: TextMessageProps) => {
  return (
    <Box>
      {content && (
        <MessageBubble
          loading={loading}
          alignLeft={alignLeft}
          sx={{
            ...sx,
            ...(error
              ? { border: "2px solid red", boxShadow: "0 0 3px red" }
              : {}),
          }}
        >
          <Linkify
            componentDecorator={(
              decoratedHref: string,
              decoratedText: string,
              key: number,
            ) => (
              <a
                {...(isLocalLink(decoratedHref) ? {} : { target: "_new" })}
                href={decoratedHref}
                key={key}
              >
                {decoratedText}
              </a>
            )}
          >
            {content}
          </Linkify>
        </MessageBubble>
      )}
      {attachments.length > 0 && (
        <div
          style={{
            gap: "8px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: "8px",
          }}
        >
          {attachments.map((attachment, i) => (
            <AttachmentComp
              key={i}
              attachment={attachment}
              openAttachment={openAttachment}
              loading={loading}
              error={error}
            />
          ))}
        </div>
      )}
      {error && (
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            left: "10px",
            justifyContent: alignLeft ? "flex-end" : "flex-start",
          }}
        >
          <WarningIcon sx={{ color: "red", fontSize: "11px" }} />
          <span style={{ color: "red", fontSize: "11px", marginLeft: "3px" }}>
            {error}
          </span>
        </div>
      )}
    </Box>
  );
};
