import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import Loading from "../../components/common/Loading";
import useIsMobile from "../../components/hooks/useIsMobile";
import Breadcrumbs from "../../components/common/Breadcrumbs";
import { useParams } from "react-router-dom";
import FamilySelect from "../../components/creation/FamilySelect";
import { useNavigate } from "react-router";
import { useFetchFamilyBilling } from "../../services/billing";
import LinkStripeCustomer from "../../components/billing/LinkStripeCustomer";
import CreateStripeCustomer from "../../components/billing/CreateStripeCustomer";
import UnlinkStripeButton from "../../components/billing/UnlinkStripeButton";
import { CustomerCharge } from "../../protogen/billing_service_pb";
import DateDisplay from "src/components/common/DateDisplay";

const BillingHistory = ({ charges }: { charges: CustomerCharge[] }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Charge ID</TableCell>
            <TableCell align="right">Date</TableCell>
            <TableCell align="right">Amount</TableCell>
            <TableCell align="right">Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {charges.map((charge) => (
            <TableRow
              key={charge.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {charge.id}
              </TableCell>
              <TableCell align="right">
                <DateDisplay
                  date={new Date(Number(charge.createdSec) * 1000)}
                />
              </TableCell>
              <TableCell align="right">{`$${charge.amount / 100}`}</TableCell>
              <TableCell align="right">{charge.description}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default () => {
  let params = useParams();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [editCustomer, setEditCustomer] = useState<boolean>(false);
  const [addPayment, setAddPayment] = useState<boolean>(false);
  const { request, data, loading: loadingRequest } = useFetchFamilyBilling();

  useEffect(() => {
    if (params.familyRef) {
      request({ familyRef: params.familyRef });
    }
  }, [params.familyRef]);

  return (
    <Box
      sx={{
        margin: isMobile ? "" : "64px min(7%, 100px)",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "0",
      }}
    >
      <Box display="flex" flexDirection="row">
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          marginBottom="16px"
        >
          <Breadcrumbs
            breadcrumbs={[
              {
                name: "Home",
                link: "/",
              },
              { name: "Tools", link: "/tools" },
            ]}
          />
          <Box
            display="flex"
            width="100%"
            gap={isMobile ? "12px" : "24px"}
            {...(isMobile && { flexDirection: "column" })}
            {...(!isMobile && {
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            })}
          >
            <Typography variant="display">Customer Billing</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          gap: "16px",
          padding: !isMobile ? "24px 0" : undefined,
        }}
      >
        {!params.familyRef && (
          <Box>
            <Typography variant="h6">Select a family to bill</Typography>
            <FamilySelect
              onChange={(familyRef) =>
                navigate(`/tools/customer-billing/${familyRef}`)
              }
              selectedFamily={null}
              adminMode={true}
            />
          </Box>
        )}
        {params.familyRef && loadingRequest && !data && <Loading />}
        {params.familyRef && data && (
          <>
            <Typography variant="h3">{data.family.name}</Typography>
            {data.accountLinked && (
              <>
                <Typography variant="bodyHeavy">Account linked</Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  gap={"12px"}
                  justifyContent={"space-between"}
                >
                  <Button
                    variant="outlined"
                    onClick={() => setEditCustomer(true)}
                  >
                    Update Customer ID
                  </Button>
                  <UnlinkStripeButton
                    familyRef={params.familyRef}
                    onComplete={() => {
                      request({ familyRef: params.familyRef! });
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="h6">Payment History</Typography>
                  <BillingHistory charges={data.charges} />
                </Box>
              </>
            )}
            {!data.accountLinked && (
              <>
                <Typography variant="bodyHeavy">
                  Family not linked to stripe
                </Typography>
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  gap={"12px"}
                  justifyContent={"space-between"}
                >
                  <Button
                    variant="outlined"
                    onClick={() => setEditCustomer(true)}
                  >
                    Link CustomerID
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => setAddPayment(true)}
                  >
                    Add payment method
                  </Button>
                </Box>
              </>
            )}
            <LinkStripeCustomer
              open={editCustomer}
              onClose={() => {
                setEditCustomer(false);
                request({ familyRef: params.familyRef! });
              }}
              familyRef={params.familyRef}
              initialCustomerId={
                data?.accountLinked ? data?.customerAccount?.id : undefined
              }
            />
            <CreateStripeCustomer
              open={addPayment}
              onClose={() => {
                setAddPayment(false);
                request({ familyRef: params.familyRef! });
              }}
              familyRef={params.familyRef}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
