import { useEffect, useState } from "react";
import ReactiveDialog from "../common/ReactiveDialog";
import { EphemeralAdvisor, FormErrors } from "./types";
import { useCreateAdvisor } from "../../services/admin";
import AdvisorForm from "./AdvisorForm";
import { defaultAdvisor, validateForm } from "./utils";

interface Props {
  open: boolean;
  onClose: (c?: boolean) => void;
}

export default ({ open, onClose }: Props) => {
  const [errors] = useState<FormErrors | null>(null);
  const [advisorData, setAdvisorData] =
    useState<EphemeralAdvisor>(defaultAdvisor());
  const { request, loading } = useCreateAdvisor(() => {
    onClose(true);
  });

  useEffect(() => {
    setAdvisorData(defaultAdvisor());
  }, [open]);

  const handleCreate = () => {
    if (validateForm(advisorData)) {
      request({
        firstName: advisorData.firstName!,
        lastName: advisorData.lastName!,
        username: advisorData.username!,
        advisorPhone: advisorData.advisorPhone!,
        personalEmail: advisorData.personalEmail!,
        personalPhone: advisorData.personalPhone!,
        sendIntroEmail: advisorData.sendIntroEmail!,
        timezone: advisorData.timezone!,
        status: advisorData.status!,
        address: advisorData.address!,
      });
    }
  };

  return (
    <ReactiveDialog
      fullWidthSize="sm"
      open={open}
      onClose={() => onClose(false)}
      title={`New Advisor`}
    >
      <AdvisorForm
        formData={advisorData}
        updateFormData={(updates) =>
          setAdvisorData((pv) => ({ ...pv, ...updates }))
        }
        errors={errors}
        onSubmit={handleCreate}
        loading={loading}
      />
    </ReactiveDialog>
  );
};
