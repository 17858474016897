import { EventNotice } from "../../protogen/calendar_pb";
import { Box, Typography } from "@mui/material";
import EventListItem from "./EventListItem";
import { Family } from "../../protogen/advisors_service_pb";
import useIsMobile from "../hooks/useIsMobile";
import { dateToDayAndDate } from "../../common/utils";

const _dateFromString = (dateString: string) => {
  let parts = dateString.split("-");

  const yearPart = parseInt(parts[0]);
  const monthPart = parseInt(parts[1]) - 1; // months are 0-based in JavaScript
  const dayPart = parseInt(parts[2]);

  // Passing in the parts makes the day explicit.  If not JS date will try to convert to users local tz
  return new Date(yearPart, monthPart, dayPart);
};

const datesToDayAndDateRange = (
  start: string,
  end: string,
  shortDates: boolean,
) => {
  const startDate = _dateFromString(start);
  const endDate = _dateFromString(end);
  const currentYear = new Date().getFullYear();
  const day = startDate.toLocaleDateString("en-US", {
    weekday: shortDates ? "short" : "long",
  });
  const sameMonth =
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getFullYear() === endDate.getFullYear();
  const startDateStr = startDate.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
  });
  const endDateStr = endDate.toLocaleDateString("en-US", {
    day: "numeric",
    ...(sameMonth ? {} : { month: "short" }),
    ...(endDate.getFullYear() !== currentYear ? { year: "numeric" } : {}),
  });
  return [
    day,
    shortDates
      ? `${startDateStr}-${endDateStr}`
      : `${startDateStr} - ${endDateStr}`,
  ];
};

type EventWrapper = {
  startSec: number;
  event: EventNotice;
  rangeIndex?: [number, number];
};
const unnestMultidayEvents = (
  events: EventNotice[],
  skipPast: boolean,
): EventWrapper[] => {
  const wrapped: EventWrapper[] = [];
  const now = new Date();
  for (let i = 0; i < events.length; i++) {
    const event = events[i];
    const start = new Date(Number(event.startSec) * 1000);
    const end = new Date(Number(event.endSec) * 1000);
    const duration = (end.getTime() - start.getTime()) / 1000 / 60 / 60 / 24;
    if (event.allDay && duration > 1) {
      const days = Math.ceil(duration);
      for (let j = 1; j <= days; j++) {
        const date = new Date(start.getTime());
        date.setDate(date.getDate() + j);
        const endDate = new Date(start.getTime());
        endDate.setDate(endDate.getDate() + j + 1);
        if (skipPast && endDate < now) {
          continue;
        }
        wrapped.push({
          startSec: date.getTime() / 1000,
          event: event,
          rangeIndex: [j, days - 1],
        });
      }
    } else {
      wrapped.push({
        startSec: Number(event.startSec),
        event: event,
      });
    }
  }
  return wrapped;
};

const groupEventsByDate = (events: EventNotice[]) => {
  const wrapped = unnestMultidayEvents(events, true);
  const sorted = wrapped.sort((a, b) => Number(a.startSec - b.startSec));
  return sorted.reduce(
    (groups, event) => {
      let date;
      // All day events have the "start date" stored since they are not relative to timezone
      if (event.event.allDay) {
        date = event.event.startDate;
      } else {
        const dateObj = new Date(Number(event.startSec) * 1000);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, "0");
        const day = String(dateObj.getDate()).padStart(2, "0");
        date = `${year}-${month}-${day}`;
      }
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(event);
      return groups;
    },
    {} as Record<string, EventWrapper[]>,
  );
};

type ListEntry = {
  type: string;
  date: string;
  events?: EventWrapper[];
  range?: number;
  dateRange?: [string, string];
};

const aggregateMultiDayEvents = (entries: ListEntry[]): ListEntry[] => {
  const result: ListEntry[] = [];
  let temp: ListEntry[] = [];
  const isPartOfStreak = (entry1: ListEntry, entry2: ListEntry): boolean => {
    return (
      entry1.type === "day" &&
      entry2.type === "day" &&
      entry1.events?.length === 1 &&
      entry2.events?.length === 1 &&
      entry1.events[0].event.eventRef === entry2.events[0].event.eventRef
    );
  };
  for (let i = 0; i < entries.length; i++) {
    temp.push(entries[i]);
    if (i + 1 < entries.length && isPartOfStreak(entries[i], entries[i + 1])) {
      continue;
    } else {
      if (temp.length > 1) {
        result.push({
          type: "days",
          date: temp[0].date,
          events: temp[0].events,
          range: temp.length,
          dateRange: [temp[0].date, temp[temp.length - 1].date],
        });
      } else {
        result.push(temp[0]); // Single date, not adjacent to next
      }
      temp = []; // Reset for the next group
    }
  }
  return result;
};

export default ({
  events,
  selectEvent,
  families,
  familyPageView = false,
  dateCursor = false,
}: {
  events: EventNotice[];
  selectEvent?: (eventRef: string) => void;
  families?: Family[];
  familyPageView?: boolean;
  dateCursor?: boolean;
}) => {
  const isMobile = useIsMobile();
  const groupedEvents = groupEventsByDate(events);
  const familyMap = new Map(
    (families || []).map((obj) => [obj.ref, obj] as const),
  );
  const keys = Object.keys(groupedEvents);
  keys.sort((a, b) => {
    const dateA = new Date(a);
    const dateB = new Date(b);
    return dateA.getTime() - dateB.getTime();
  });
  let entries: ListEntry[] = keys.map((date) => ({
    type: "day",
    date: date,
    events: groupedEvents[date],
  }));
  if (dateCursor) {
    // Get today's date at 00:00:00 for a fair comparison
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    let insertIndex;
    for (insertIndex = 0; insertIndex < keys.length; insertIndex++) {
      if (today <= new Date(keys[insertIndex])) {
        break;
      }
    }
    entries.splice(insertIndex, 0, {
      type: "today",
      date: "today",
    });
  }
  entries = aggregateMultiDayEvents(entries);
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignSelf="stretch"
      gap={isMobile ? "20px" : "28px"}
      marginTop="24px"
    >
      {entries.map((entry) => {
        const { date, type } = entry;
        const cursorEntry = type === "today";
        let [dte, day]: [string | null, string | null] = [null, null];
        switch (type) {
          case "day":
            [dte, day] = dateToDayAndDate(date, isMobile);
            break;
          case "days": {
            const [start, end] = entry.dateRange!;
            [dte, day] = datesToDayAndDateRange(start, end, isMobile);
            break;
          }
          case "today":
          default:
            break;
        }
        return (
          <Box
            key={date}
            display="flex"
            flexDirection="row"
            gap={isMobile ? "12px" : "24px"}
            alignSelf="stretch"
            alignItems={cursorEntry ? "center" : undefined}
          >
            <Box
              sx={{
                paddingTop: cursorEntry ? undefined : "px",
                minWidth: isMobile ? "47px" : "100px",
              }}
            >
              {cursorEntry ? (
                <Typography
                  variant="body"
                  sx={{
                    color: "secondary.dark",
                    display: "flex",
                    justifyContent: "end",
                  }}
                >
                  Today
                </Typography>
              ) : (
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems={isMobile ? "center" : "end"}
                  sx={{ marginTop: "8px" }}
                >
                  <Typography variant="h3Serif" sx={{ lineHeight: "140%" }}>
                    {dte}
                  </Typography>
                  <Typography
                    variant="bodySmall"
                    sx={{
                      color: "text.tertiary",
                    }}
                  >
                    {day}
                  </Typography>
                </Box>
              )}
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap={isMobile ? "6px" : "8px"}
              flex="1"
            >
              {cursorEntry && (
                <svg
                  width="100%"
                  height="3"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <line
                    x1="0"
                    y1="1.50003"
                    x2="100%"
                    y2="1.49997"
                    stroke="#EF7B77"
                    strokeWidth="2"
                  />
                </svg>
              )}
              {!cursorEntry &&
                date &&
                (entry?.events || []).map((wrappedEvent) => (
                  <EventListItem
                    key={
                      wrappedEvent.event.eventRef || wrappedEvent.event.taskRef
                    }
                    event={wrappedEvent.event}
                    onClick={selectEvent}
                    family={familyMap.get(wrappedEvent.event.familyRef)}
                    familyPageView={familyPageView}
                    rangeIndex={wrappedEvent.rangeIndex}
                    rangeIndexSize={entry.range}
                  />
                ))}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
