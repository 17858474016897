import ReactiveDialog from "../common/ReactiveDialog";
import { useEffect, useState } from "react";
import {
  useCreateTaskInspiration,
  useUpdateTaskInspiration,
} from "../../services/tasks";

import { EphemeralTaskInspiration, TaskFormErrors } from "../../types/tasks";
import { TaskInspiration } from "../../protogen/tasks_pb";
import TaskInspirationForm from "./TaskInspirationForm";

type Props = {
  open: boolean;
  taskInspiration: EphemeralTaskInspiration | null;
  onClose: () => void;
  onCreate?: (s?: TaskInspiration) => void;
  onEdit?: (s?: TaskInspiration) => void;
};

const defaultFormData = (): EphemeralTaskInspiration => ({
  type: "",
  title: "",
  status: "active",
  defaultDetails: [],
});

const validateTaskInspiration = (
  t: EphemeralTaskInspiration,
): TaskFormErrors => {
  const errors: TaskFormErrors = {};
  if (!t.title) {
    errors.title = "Title is required";
  }
  if (t.defaultDetails?.some((d) => !(d.name && d.type))) {
    errors.details = "Details require a name and type";
  }
  return errors;
};

export default ({
  open,
  taskInspiration,
  onClose,
  onCreate,
  onEdit,
}: Props) => {
  const [formErrors, setFormErrors] = useState<TaskFormErrors | null>(null);
  const [formData, setFormData] =
    useState<EphemeralTaskInspiration>(defaultFormData());

  const { request: createRequest } = useCreateTaskInspiration((r) => {
    onCreate && onCreate(r?.taskInspiration);
  });
  const { request: editRequest } = useUpdateTaskInspiration((r) => {
    onEdit && onEdit(r?.taskInspiration);
  });

  useEffect(() => {
    if (!open) {
      setFormData(defaultFormData());
    } else if (taskInspiration !== null) {
      setFormData({
        ref: taskInspiration.ref,
        type: taskInspiration.type,
        title: taskInspiration.title,
        status: taskInspiration.status,
        additionalContext: taskInspiration.additionalContext,
        defaultDetails: taskInspiration.defaultDetails,
      });
    }
  }, [open]);

  const updateFormData = (s: EphemeralTaskInspiration) => {
    setFormData((prevState) => ({
      ...(prevState || {}),
      ...s,
    }));
  };
  const _validate = () => {
    const errors = validateTaskInspiration(formData);
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const onSave = async () => {
    if (_validate()) {
      if (taskInspiration !== null) {
        await editRequest({
          taskInspirationRef: formData.ref!,
          title: formData.title || "",
          status: formData.status || "active",
          additionalContext: formData.additionalContext || "",
          defaultDetails: formData.defaultDetails || [],
        });
      } else {
        await createRequest({
          title: formData.title || "",
          type: formData.type || "common",
          additionalContext: formData.additionalContext || "",
          defaultDetails: formData.defaultDetails || [],
        });
      }
      onClose();
    }
  };
  return (
    <ReactiveDialog
      title={
        taskInspiration !== null
          ? "Edit task inspiration"
          : "Add task inspiration"
      }
      fullWidthSize="sm"
      open={open}
      onClose={() => {
        onClose();
      }}
    >
      <TaskInspirationForm
        formData={formData}
        updateFormData={updateFormData}
        onSave={onSave}
        isEdit={taskInspiration !== null}
        errors={formErrors}
      ></TaskInspirationForm>
    </ReactiveDialog>
  );
};
