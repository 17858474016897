import { ForumPostType } from "../../protogen/forum_service_pb";

export enum TopicFeed {
  Home = "home",
  AskFAs = "askfas",
  Recommendations = "recommendations",
  Resources = "resources",
  Chatter = "chatter",
}

type PageDef = {
  value: TopicFeed | null;
  type: ForumPostType;
  label: string;
  title: string | null;
  subtitle: string | null;
};

export const PAGE_DEFS: { [Name: string]: PageDef } = {
  home: {
    value: TopicFeed.Home,
    type: ForumPostType.POST_DISCUSSION,
    label: "Chatter",
    title: null,
    subtitle: null,
  },
  chatter: {
    value: TopicFeed.Chatter,
    type: ForumPostType.POST_DISCUSSION,
    label: "Chatter",
    title: "Chatter",
    subtitle: "Chat with your fayevorite people.",
  },
  askfas: {
    value: TopicFeed.AskFAs,
    type: ForumPostType.POST_ASKFAS,
    label: "Ask FAs",
    title: "Ask FAs",
    subtitle: "Ask the community for assistance in how to tackle problems.",
  },
  recommendations: {
    value: TopicFeed.Recommendations,
    type: ForumPostType.POST_RECOMMENDATION,
    label: "Recommendations",
    title: "Recommendations",
    subtitle: null,
  },
  resources: {
    value: TopicFeed.Resources,
    type: ForumPostType.POST_RESOURCE,
    label: "Resources",
    title: "Resources",
    subtitle: "Guides and playbooks for getting more done.",
  },
};

export const POST_TYPES: [string, ForumPostType][] = [
  ["Chatter", ForumPostType.POST_DISCUSSION],
  ["Recommendations", ForumPostType.POST_RECOMMENDATION],
  ["Resources", ForumPostType.POST_RESOURCE],
  ["Ask FAs", ForumPostType.POST_ASKFAS],
];
export const POST_TYPES_BY_LABEL = Object.fromEntries(POST_TYPES);
export const POST_LABELS_BY_TYPE = Object.fromEntries(
  POST_TYPES.map(([label, type]) => [type, label]),
);
