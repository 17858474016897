import { Box } from "@mui/material";
import {
  Check,
  FileText,
  Globe,
  Lightbulb,
  LucideIcon,
  PenLine,
  Timer,
} from "lucide-react";
import { UserAvatar } from "../../common/CurrentUserAvatar";
import { AccountStub } from "../../../protogen/common_pb";

const GutterIcon = ({ Icon, color }: { Icon: LucideIcon; color: string }) => {
  return (
    <Box
      sx={(theme) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "6px",
        border: `1px solid ${theme.palette.border}`,
        borderRadius: "100px",
      })}
    >
      <Icon size={20} color={color} />
    </Box>
  );
};

const GutterAvatar = ({ user }: { user: AccountStub }) => {
  return <UserAvatar user={user} size={34} autoscale={false} />;
};
export default {
  timeCompletion: {
    icon: GutterIcon({ Icon: Check, color: "#198282" }),
  },
  message: {
    icon: GutterAvatar,
  },
  recommendation: {
    icon: GutterIcon({ Icon: Globe, color: "#198282" }),
  },
  requirements: {
    icon: GutterIcon({ Icon: Lightbulb, color: "#198282" }),
  },
  document: {
    icon: GutterIcon({ Icon: FileText, color: "#198282" }),
  },
  privateNote: {
    icon: GutterIcon({ Icon: PenLine, color: "#198282" }),
  },
  timeTracking: {
    icon: GutterIcon({ Icon: Timer, color: "#198282" }),
  },
};
