import React, { useMemo } from "react";
import { Attachment } from "../../protogen/common_pb";
import { Dialog, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { withImageProps } from "./SignedImageCache";
import DocViewer from "@cyntler/react-doc-viewer";

interface DocumentProp {
  uri: string;
  fileName: string;
  fileType: string;
}

interface MemoizedDocViewerProps {
  document: DocumentProp;
}

// DocViewer does not handle re-renders well when props change
// renders blank page.
const MemoizedDocViewer = React.memo(({ document }: MemoizedDocViewerProps) => {
  return <DocViewer prefetchMethod="GET" documents={[document]} />;
});

const isImage = (attachment: Attachment) => {
  return attachment.contentType.startsWith("image/");
};

const isDocument = (attachment: Attachment) => {
  const documentTypes = new Set([
    // Note: Pain to get their image renderer to work correctly.
    // "image/bmp",
    // "image/gif",
    // "image/jpg",
    // "image/jpeg",
    // "image/png",
    // "image/tiff",
    "application/vnd.oasis.opendocument.text",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/pdf",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/csv",
    "text/htm",
    "text/html",
    "text/plain",
    "video/mp4",
  ]);
  return documentTypes.has(attachment.contentType.toLowerCase());
};

export const isSupportedAttachment = (attachment: Attachment) => {
  return isImage(attachment) || isDocument(attachment);
};

export default ({
  attachment,
  onClose,
}: {
  attachment: Attachment | null;
  onClose: () => void;
}) => {
  if (!attachment || !isSupportedAttachment(attachment)) {
    return null;
  }
  // const { src: cachedUrl } = withAssetProps({ url: attachment.url });
  const memoizedDocument = useMemo(() => {
    if (!attachment) return null;
    return {
      uri: attachment.url,
      fileName: attachment.filename,
      fileType: attachment.contentType,
    };
  }, [attachment]);
  return (
    <Dialog
      open={!!attachment}
      onClose={onClose}
      {...(!isImage(attachment)
        ? {
            fullWidth: true,
            maxWidth: "xl",
          }
        : {})}
      PaperProps={{
        sx: {
          width: undefined,
          ...(!isImage(attachment)
            ? {
                height: "100%",
              }
            : {}),
        },
      }}
    >
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
          zIndex: 10,
          color: isImage(attachment) ? "#FFF" : undefined,
        }}
      >
        <CloseIcon />
      </IconButton>
      {isDocument(attachment) && memoizedDocument && (
        <MemoizedDocViewer document={memoizedDocument} />
      )}
      {isImage(attachment) && (
        <img
          {...withImageProps({ url: attachment.url })}
          alt={attachment.filename}
          style={{
            maxWidth: "80vh",
            maxHeight: "80vh",
            objectFit: "cover",
            // Doesn't work on safari
            // width: '-webkit-fill-available',
            // height: '-webkit-fill-available',
          }}
        />
      )}
    </Dialog>
  );
};
