import { mergeAttributes, Node, nodePasteRule } from "@tiptap/core";

// This regex has two goals which could be separated:
//  1) see if the URL is a Loom link to check on paste
//  2) extract the video ID from the URL
// Note: Not handled (yet) is extracting more parameters from the URL.
const LOOM_REGEX =
  /^(?:https?:\/\/)?(?:www\.)?(?:loom\.com)\/(?:share|embed)\/([a-zA-Z0-9_-]+)\/?(?:\?.*)?$/g;

const isValidLoomUrl = (url: string) => {
  return url.match(LOOM_REGEX);
};

const styleObjectToCssString = (styles: Record<string, any>): string => {
  return Object.entries(styles)
    .map(([key, value]) => {
      const kebabKey = key.replace(/([a-z0-9])([A-Z])/g, "$1-$2").toLowerCase();
      return `${kebabKey}: ${value};`;
    })
    .join(" ");
};

export interface LoomOptions {
  addPasteHandler: boolean;
  inline: boolean;
  width: number;
  height: number;
  HTMLAttributes: Record<string, any>;
  noBorder: boolean;
  //https://support.loom.com/hc/en-us/articles/360002208317-How-to-embed-your-video-into-a-webpage
  allowFullscreen: boolean;
  autoplay: boolean;
  mute: boolean;
  timestamp?: string;
  hideShare: boolean;
  hideEmbedTopBar: boolean;
  hideTitle: boolean;
  hideOwner: boolean;
}

type SetLoomVideoOptions = {
  sourceLink: string;
  width?: number;
  height?: number;
};

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    loom: {
      /**
       * Insert a youtube video
       */
      setLoomVideo: (options: SetLoomVideoOptions) => ReturnType;
    };
  }
}

export default Node.create<LoomOptions>({
  name: "loom",

  addOptions() {
    return {
      addPasteHandler: true,
      inline: true,
      HTMLAttributes: {},
      height: 480,
      width: 640,
      // more general parameters.
      noBorder: true,
      allowFullscreen: true,
      autoplay: false,
      mute: false,
      timestamp: undefined,
      hideShare: false,
      hideEmbedTopBar: false,
      hideTitle: false,
      hideOwner: false,
    };
  },

  inline() {
    return this.options.inline;
  },

  group() {
    return this.options.inline ? "inline" : "block";
  },

  draggable: true,
  selectable: true,

  addAttributes() {
    return {
      sourceLink: {
        default: null,
      },
      width: {
        default: this.options.width,
      },
      height: {
        default: this.options.height,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "div[data-loom-video] iframe",
      },
    ];
  },

  addCommands() {
    return {
      setLoomVideo:
        (options: SetLoomVideoOptions) =>
        ({ commands }) => {
          if (!isValidLoomUrl(options.sourceLink)) {
            return false;
          }

          return commands.insertContent({
            type: this.name,
            attrs: options,
          });
        },
    };
  },

  addPasteRules() {
    if (!this.options.addPasteHandler) {
      return [];
    }

    return [
      nodePasteRule({
        find: LOOM_REGEX,
        type: this.type,
        getAttributes: (match) => {
          return { sourceLink: match.input };
        },
      }),
    ];
  },

  renderHTML({ HTMLAttributes }) {
    const attrs = HTMLAttributes as SetLoomVideoOptions;
    // Reset the lastIndex to ensure the regex works correctly when called multiple times
    LOOM_REGEX.lastIndex = 0;
    // Use the exec method to search for a match
    const match = LOOM_REGEX.exec(attrs.sourceLink);
    // match[1] will be the captured group if a match is found
    const videoId = match ? match[1] : "";
    const queryParams = {
      hideEmbedTopBar: this.options.hideEmbedTopBar,
      t: this.options.timestamp,
      allowFullscreen: this.options.allowFullscreen,
      autoplay: this.options.autoplay,
      muted: this.options.mute,
      hide_share: this.options.hideShare,
      hide_title: this.options.hideTitle,
      hide_owner: this.options.hideOwner,
    };
    // Convert the params object into a query string
    const queryString = Object.entries(queryParams)
      .map(([key, value]) =>
        value !== undefined
          ? `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
          : null,
      )
      .filter((param) => param !== null)
      .join("&");

    // Combine the base URL with the query string to form the full URL
    const embedUrl = `https://www.loom.com/embed/${videoId}?${queryString}`;

    return [
      "div",
      { "data-loom-video": "" },
      [
        "iframe",
        mergeAttributes(HTMLAttributes, {
          src: embedUrl,
          width: attrs.width,
          height: attrs.height,
          style: styleObjectToCssString({
            borderRadius: "12px",
            ...(this.options.noBorder ? { border: "none" } : {}),
          }),
        }),
      ],
    ];
  },
});
