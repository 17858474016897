import { Fragment, useContext } from "react";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import NavItem from "./NavItem";
import DrawerHeader from "./DrawerHeader";
import { ReactComponent as FayeIcon } from "../../icons/FayeBrand.svg";
import { ReactComponent as ClipboardIcon } from "../../icons/Menu/Clipboard.svg";
import { ReactComponent as HomeIcon } from "../../icons/Menu/Home.svg";
import { ReactComponent as LoveIcon } from "../../icons/Menu/Love.svg";
import CurrentUserAvatar from "../common/CurrentUserAvatar";
import LinkRouter from "../navigation/LinkRouter";
import { CurrentUserContext } from "../context/RequireAuth";
// import { CalendarHeart } from "lucide-react";

type MemberDrawerContentProps = {
  isPersistent: boolean;
  selectedTab: string;
  open: boolean;
  setClosed(): void;
  isMobile: boolean;
};

export default ({
  isPersistent,
  selectedTab,
  open,
  setClosed,
}: MemberDrawerContentProps) => {
  const currentUser = useContext(CurrentUserContext);
  return (
    <Fragment>
      <List sx={{ padding: 0 }}>
        <DrawerHeader>
          {isPersistent && (
            <LinkRouter to="/">
              <FayeIcon
                style={{
                  height: "26px",
                  margin: "12px 0px",
                }}
              />
            </LinkRouter>
          )}
          {!isPersistent && (
            <IconButton
              onClick={() => setClosed()}
              sx={{ ml: "auto", marginRight: "10px" }}
            >
              <CloseIcon sx={{ color: "#545F71" }} />
            </IconButton>
          )}
        </DrawerHeader>
        <div style={{ paddingTop: "17px" }}>
          <NavItem
            selected={selectedTab === "/home"}
            open={open}
            onClick={setClosed}
            icon={<HomeIcon />}
            text="Home"
            url="/home"
          />
          <NavItem
            selected={selectedTab === "/family-info"}
            open={open}
            onClick={setClosed}
            icon={<LoveIcon />}
            text="Family info"
            url="/family-info"
          />
          <NavItem
            selected={selectedTab === "/tasks"}
            open={open}
            onClick={setClosed}
            icon={<ClipboardIcon />}
            text="Tasks"
            url="/tasks"
          />
          {/* Commented out while in progress. */}
          {/*<NavItem*/}
          {/*  selected={selectedTab === "/settings/integrations"}*/}
          {/*  open={open}*/}
          {/*  onClick={setClosed}*/}
          {/*  icon={<CalendarHeart />}*/}
          {/*  text="Calendars"*/}
          {/*  url="/settings/integrations"*/}
          {/*/>*/}
        </div>
      </List>
      <List>
        <NavItem
          selected={selectedTab === "/settings"}
          onClick={setClosed}
          open={open}
          icon={<CurrentUserAvatar size={48} />}
          text={currentUser.firstName}
          url="/settings"
        />
      </List>
    </Fragment>
  );
};
