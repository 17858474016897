import { useNavigate } from "react-router";
import { useEffect, useRef, useState } from "react";
import { Box, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";
import { generateSearchQueryUrl } from "../search/utils";

type Props = {
  topics?: string[];
  fullWidth?: boolean;
  initialQuery?: string;
  onQuery?: (query: string) => void;
  captureHotkeys?: boolean;
  autofocus?: boolean;
  expand?: boolean;
  onKeyPress?: boolean;
};
export default ({
  topics,
  initialQuery,
  onQuery,
  fullWidth = false,
  captureHotkeys = false,
  autofocus = false,
  expand = true,
  onKeyPress = false,
}: Props) => {
  const navigate = useNavigate();
  const textFieldRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const [focused, setFocused] = useState(false);
  const handleQuery = () => {
    const query = textFieldRef.current?.value.trim();
    if (onQuery) {
      // Handle onQuery with empty content so we can "clear" things
      onQuery(query || "");
      return;
    } else if (query) {
      navigate(generateSearchQueryUrl(query, topics, "community"));
    }
  };

  // Capture search events and focus the search bar.
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        captureHotkeys &&
        (event.ctrlKey || event.metaKey) &&
        event.key === "k"
      ) {
        textFieldRef.current?.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [captureHotkeys]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        flex: "1 1 auto",
        ...(fullWidth
          ? {}
          : {
              marginLeft: "40px",
              transition: "max-width 0.5s",
              maxWidth:
                expand && (focused || textFieldRef.current?.value.trim())
                  ? "500px"
                  : "250px",
            }),
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          width: "100%",
          borderRadius: "12px",
          border: "2px solid #EDEDED",
        }}
      >
        <IconButton
          type="button"
          sx={{ p: "10px", paddingRight: "0px" }}
          aria-label="search"
          onClick={handleQuery}
        >
          <SearchIcon />
        </IconButton>
        <InputBase
          autoFocus={autofocus}
          inputRef={textFieldRef}
          fullWidth
          defaultValue={initialQuery}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search"
          inputProps={{ "aria-label": "Search" }}
          onKeyUp={(event) => {
            if (onKeyPress) {
              event.preventDefault();
              handleQuery();
              return;
            }
          }}
          onKeyDown={(event) => {
            // Allow shift for newlines.
            if (event.key === "Enter" && !event.shiftKey) {
              event.preventDefault();
              handleQuery();
              return;
            }
          }}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
        />
      </div>
    </Box>
  );
};
