import { useContext, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  OutlinedInput,
} from "@mui/material";
import { useListAdvisorFamilies } from "../../services/advisor";
import ListItem from "@mui/material/ListItem";
import { SxProps } from "@mui/system";
import { isUserAdmin } from "../../common/userUtils";
import { CurrentUserContext } from "../context/RequireAuth";
import { useListAllFamilies } from "../../services/admin";

interface Props {
  selectedFamily: string | null;
  onChange: (familyRef: string | null) => void;
  hideLabels?: boolean;
  error?: string | null;
  disabled?: boolean;
  sx?: SxProps;
  adminMode?: boolean;
  width?: string;
  size?: "small" | "medium";
  disableUnderline?: boolean;
  variant?: "standard" | "outlined" | "filled";
}

export const useListFamilies = (adminMode: boolean = false) => {
  const currentUser = useContext(CurrentUserContext);
  return adminMode && isUserAdmin(currentUser)
    ? useListAllFamilies()
    : useListAdvisorFamilies();
};

export default ({
  selectedFamily,
  onChange,
  error,
  disabled,
  hideLabels,
  sx,
  adminMode = false,
  width = "100%",
  size,
  disableUnderline,
  variant,
}: Props) => {
  const [open, setOpen] = useState(false);
  const { data, request } = useListFamilies(adminMode);
  useEffect(() => {
    request();
  }, []);
  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value;
    onChange(selectedValue);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const families = data?.families || [];
  return (
    <FormControl
      sx={{ width: width, cursor: "pointer", ...sx }}
      onClick={() => setOpen(!open)}
      variant={variant}
      size={size}
    >
      {!hideLabels && <InputLabel id="select-label">Family</InputLabel>}
      <Select
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        disabled={disabled}
        labelId="select-label"
        id="select-label"
        label={!hideLabels ? "Family" : undefined}
        error={!!error}
        value={(!!families.length && selectedFamily) || ""}
        onChange={handleChange}
        input={!hideLabels ? <OutlinedInput label="Family" /> : undefined}
        sx={{ cursor: "pointer" }}
        inputProps={
          variant === "standard" ? { disableUnderline: disableUnderline } : {}
        }
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {families
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((family) => (
            <MenuItem key={family.ref} value={family.ref}>
              <ListItem
                component="div"
                disablePadding
                sx={{ overflow: "hidden" }}
              >
                {family.name}
              </ListItem>
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
