import useProtoMethod from "./useProtoMethod";
import {
  AdvisorFetchBillingRequest,
  AdvisorFetchBillingResponse,
  AdvisorGenerateBillingAccountRequest,
  AdvisorGenerateBillingAccountResponse,
  AdvisorUpdateBillingRequest,
  AdvisorUpdateBillingResponse,
  CreateFamilyBillingRequest,
  CreateFamilyBillingResponse,
  FetchFamilyBillingRequest,
  FetchFamilyBillingResponse,
  UpdateFamilyBillingRequest,
  UpdateFamilyBillingResponse,
  CreateMemberBillingRequest,
  CreateMemberBillingResponse,
  ListBillingProductsResponse,
  ListBillingProductsRequest,
  ListBillingCouponsResponse,
  ListBillingCouponsRequest,
} from "../protogen/billing_service_pb";

const useAdvisorFetchBilling = (
  callback?: (r: AdvisorFetchBillingResponse) => void,
) => {
  return useProtoMethod<
    AdvisorFetchBillingRequest,
    AdvisorFetchBillingResponse
  >("AdvisorFetchBilling", AdvisorFetchBillingResponse, { callback });
};

const useAdvisorGenerateBillingAccount = (
  callback?: (r: AdvisorGenerateBillingAccountResponse) => void,
) => {
  return useProtoMethod<
    AdvisorGenerateBillingAccountRequest,
    AdvisorGenerateBillingAccountResponse
  >("AdvisorGenerateBillingAccount", AdvisorGenerateBillingAccountResponse, {
    callback,
  });
};

const useAdvisorUpdateBilling = (
  callback?: (r: AdvisorUpdateBillingResponse) => void,
) => {
  return useProtoMethod<
    AdvisorUpdateBillingRequest,
    AdvisorUpdateBillingResponse
  >("AdvisorUpdateBilling", AdvisorUpdateBillingResponse, { callback });
};

const useFetchFamilyBilling = (
  callback?: (r: FetchFamilyBillingResponse) => void,
) => {
  return useProtoMethod<FetchFamilyBillingRequest, FetchFamilyBillingResponse>(
    "FetchFamilyBilling",
    FetchFamilyBillingResponse,
    { callback },
  );
};

const useUpdateFamilyBilling = (
  callback?: (r: UpdateFamilyBillingResponse) => void,
) => {
  return useProtoMethod<
    UpdateFamilyBillingRequest,
    UpdateFamilyBillingResponse
  >("UpdateFamilyBilling", UpdateFamilyBillingResponse, { callback });
};

const useCreateFamilyBilling = (
  callback?: (r: CreateFamilyBillingResponse) => void,
) => {
  return useProtoMethod<
    CreateFamilyBillingRequest,
    CreateFamilyBillingResponse
  >("CreateFamilyBilling", CreateFamilyBillingResponse, { callback });
};

const useCreateMemberBilling = (
  callback?: (r: CreateMemberBillingResponse) => void,
) => {
  return useProtoMethod<
    CreateMemberBillingRequest,
    CreateMemberBillingResponse
  >("CreateMemberBilling", CreateMemberBillingResponse, { callback });
};

const useListBillingProducts = (
  callback?: (r: ListBillingProductsResponse) => void,
) => {
  return useProtoMethod<
    ListBillingProductsRequest,
    ListBillingProductsResponse
  >("ListBillingProducts", ListBillingProductsResponse, { callback });
};

const useListBillingCoupons = (
  callback?: (r: ListBillingCouponsResponse) => void,
) => {
  return useProtoMethod<ListBillingCouponsRequest, ListBillingCouponsResponse>(
    "ListBillingCoupons",
    ListBillingCouponsResponse,
    { callback },
  );
};

export {
  useAdvisorFetchBilling,
  useAdvisorUpdateBilling,
  useAdvisorGenerateBillingAccount,
  useCreateFamilyBilling,
  useFetchFamilyBilling,
  useUpdateFamilyBilling,
  useCreateMemberBilling,
  useListBillingProducts,
  useListBillingCoupons,
};
