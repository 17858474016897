import { Family, Medium } from "../../protogen/advisors_service_pb";
import { Box, Button } from "@mui/material";
import { FeedFocusState } from "../../types/feed";
import { Mail, Phone } from "lucide-react";

type ContactSectionProps = {
  family: Family;
  feedFocusState: FeedFocusState;
  setFeedFocusState: (s: FeedFocusState) => void;
};
export default ({ feedFocusState, setFeedFocusState }: ContactSectionProps) => {
  return (
    <Box
      sx={{
        paddingTop: "12px",
        display: "flex",
        margin: "0px 24px",
        gap: "20px",
      }}
    >
      <Button
        sx={{
          border: "1px solid #D4D4D4",
          borderRadius: "5px",
          padding: "8px 24px !important",
          flexGrow: "1",
          color: "#6B6E7B",
          backgroundColor: "#fff",
          fontSize: "14px",
        }}
        startIcon={<Phone height="20px" width="20px" />}
        variant="text"
        size="small"
        onClick={() => {
          setFeedFocusState({
            ...feedFocusState,
            selectedMedium: Medium.PHONE,
          });
        }}
      >
        Call
      </Button>
      <Button
        sx={{
          border: "1px solid #D4D4D4",
          borderRadius: "5px",
          padding: "8px 24px !important",
          flexGrow: "1",
          color: "#6B6E7B",
          backgroundColor: "#fff",
          fontSize: "14px",
        }}
        startIcon={<Mail height="20px" width="20px" />}
        variant="text"
        size="small"
        onClick={() => {
          setFeedFocusState({
            ...feedFocusState,
            selectedMedium: Medium.EMAIL,
          });
        }}
      >
        Email
      </Button>
    </Box>
  );
};
