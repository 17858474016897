import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Box,
  Button,
  Divider,
  ListItemAvatar,
  Paper,
  Typography,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router";
import Loading from "../components/common/Loading";
import GridPage from "../components/layout/GridPage";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import useIsVisible from "../components/hooks/useIsVisible";
import {
  useDeletePost,
  useEditPost,
  useFetchPost,
  usePinForumPost,
} from "../services/forum";
import AddComment from "../components/forum/AddComment";
import {
  ForumPost,
  ForumPostType,
  ForumComment,
} from "../protogen/forum_service_pb";
import PostComment from "../components/forum/PostComment";
import DateDisplay from "../components/common/DateDisplay";
import DocumentViewer from "../components/editor/DocumentViewer";
import { UserAvatar } from "../components/common/CurrentUserAvatar";
import { CurrentUserContext } from "../components/context/RequireAuth";
import { isUserAdmin } from "../common/userUtils";
import DocumentEditor from "../components/editor/DocumentEditor";
import { RichContent } from "../components/editor/utils";
import { SearchContext } from "../components/context/SearchContextProvider";
import useIsMobile from "../components/hooks/useIsMobile";
import ConfirmationDialog, {
  useConfirmationDialog,
} from "../components/common/ConfirmationDialog";
import PostTopics from "../components/forum/PostTopics";
import TopicAutocomplete from "../components/forum/TopicAutocomplete";
import PostLikesAndComments from "../components/forum/PostLikesAndComments";
import { Ellipsis } from "lucide-react";
import { protoInt64 } from "@bufbuild/protobuf";
import BusinessInfo from "../components/forum/BusinessInfo";
import PopperMenu, { createMenuOption } from "../components/common/PopperMenu";
import NotFound from "../components/NotFound";

const arraysContainSame = (a: any[] | null, b: any[] | null) => {
  const a_ = Array.isArray(a) ? a : [];
  const b_ = Array.isArray(b) ? b : [];
  return a_.length === b_.length && a_.every((el) => b_.includes(el));
};

type EditAreaProps = {
  post: ForumPost;
  cancelEdit: () => void;
  onUpdate: () => void;
};
const EditArea = ({ post, cancelEdit, onUpdate }: EditAreaProps) => {
  const _initialContent = post.body.payload
    ? JSON.parse(post.body.payload)
    : null;
  const [content, setContent] = useState<RichContent | null>(null);
  const [topics, setTopics] = useState<string[] | null>(null);
  const { request, loading } = useEditPost(() => {
    onUpdate();
  });
  const updatedTopics =
    arraysContainSame(topics, post.topics) || !topics ? [] : topics;
  const changes = content || updatedTopics.length;
  const handleSave = () => {
    if (changes) {
      request({
        ref: post.ref,
        title: post.title,
        topics: updatedTopics, // Empty means no changes.
        ...(content && {
          body: {
            contentType: "json",
            payload: content.json,
            textContent: content.text,
            attachments: content.attachments,
          },
        }),
      });
    }
  };
  return (
    <>
      <Box sx={{ padding: "16px 16px" }}>
        <DocumentEditor
          initialContent={_initialContent}
          disabled={loading}
          setContent={setContent}
          attachmentsEnabled={true}
          initialAttachments={post.body.attachments}
          secondaryAction={
            <Button onClick={() => cancelEdit()} variant="outlined">
              Cancel
            </Button>
          }
          primaryAction={
            <Button
              onClick={handleSave}
              disabled={loading || !(content || updatedTopics.length)}
            >
              Save
            </Button>
          }
        />
      </Box>
      <Box display={"flex"} flexDirection={"row"}>
        <TopicAutocomplete
          setSelected={(s) => setTopics(s)}
          initialSelected={post.topics || []}
          sx={{
            width: "100%",
            maxHeight: "48px",
            ".MuiInputBase-root": {
              minHeight: "48px",
            },
            ".MuiAutocomplete-tag": {
              marginTop: "0px",
            },
            input: {
              marginTop: "0px",
            },
          }}
        />
      </Box>
    </>
  );
};

export default () => {
  const navigate = useNavigate();
  const { setForumOnly } = useContext(SearchContext);
  let params = useParams();
  const isMobile = useIsMobile();
  const { isVisible } = useIsVisible({});
  const postRef = params.postRef!;
  const queryParams = new URLSearchParams(location.search);
  const commentRef = queryParams.get("commentRef") || null;
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [menuOptions, setMenuOptions] = useState<JSX.Element[]>([]);
  const [isPinned, setIsPinned] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const currentUser = useContext(CurrentUserContext);
  const { request: pinRequest } = usePinForumPost();
  const { request, data, errorCode } = useFetchPost(() => {
    setForumOnly(true);
    //   // Don't thrash the state when it's just image URLs changing.
    //   setEmails(preValue => {
    //     const prevMap = new Map(preValue.map(i => [i.ref, i]))
    //     return r.emails.map(e => {
    //       const prev = prevMap.get(e.ref);
    //       return prev && prev.timestampSec === e.timestampSec ? prev : e;
    //     });
    //   });
  });
  const [editMode, setEditMode] = useState(false);

  const { request: deleteRequest, loading: deleteLoading } = useDeletePost();
  const confirmState = useConfirmationDialog();
  const deletePost = () => {
    if (!deleteLoading) {
      confirmState.openDialog(async () => {
        await deleteRequest({ ref: post.ref });
        navigate("/community");
      });
    }
  };

  useEffect(() => {
    request({ postRef, markAsRead: true });
    const intervalId = setInterval(async () => {
      if (isVisible) {
        request({ postRef, markAsRead: true });
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [params.postRef]);

  const createMenuOptions = (post: ForumPost) => {
    const options = [];
    if (canEdit) {
      options.push(
        createMenuOption(
          "Edit",
          () => {
            setEditMode((e) => !e);
            handleClose();
          },
          "pencil",
        ),
        createMenuOption(
          "Delete",
          () => {
            if (!deleteLoading) {
              deletePost();
            }
            handleClose();
          },
          "trash",
        ),
      );
    }
    if (isUserAdmin(currentUser)) {
      options.push(
        createMenuOption(
          isPinned ? "Unpin" : "Pin",
          () => {
            pinRequest({ postRef: post.ref, unpin: isPinned });
            setIsPinned((p) => !p);
            handleClose();
          },
          isPinned ? "pinOff" : "pin",
        ),
      );
    }
    return options;
  };

  useEffect(() => {
    if (data?.post) {
      setMenuOptions(createMenuOptions(data.post));
    }
  }, [data?.post, isPinned]);

  useEffect(() => {
    if (data?.post) {
      setIsPinned(post.pinnedSec > protoInt64.zero);
    }
  }, [data?.post]);
  if (errorCode === 404) return <NotFound title={"Post not found"} />;
  if (!data) {
    return <Loading />;
  }

  const onCommentCreation = () => {
    request({ postRef, markAsRead: true });
  };
  const onUpdated = () => {
    request({ postRef, markAsRead: true });
    setEditMode(false);
  };

  const sortCommentsByDepth = (
    comments: ForumComment[],
    commentMap: { [key: string]: ForumComment[] },
  ): ForumComment[] => {
    let sorted: ForumComment[] = [];

    const addCommentAndChildren = (comment: ForumComment) => {
      sorted.push(comment);
      const children = commentMap[comment.ref];
      children.forEach((child) => addCommentAndChildren(child));
    };

    comments.forEach((comment) => {
      if (comment.parentRef === "") {
        addCommentAndChildren(comment);
      }
    });

    return sorted;
  };

  const createCommentMap = (comments: ForumComment[]) => {
    const commentMap: { [key: string]: ForumComment[] } = {};
    comments.forEach((comment) => {
      commentMap[comment.ref] = [];
      if (comment.parentRef !== "") {
        commentMap[comment.parentRef].push(comment);
      }
    });
    return commentMap;
  };

  const determineThreadGuidelines = (depthFirstArray: ForumComment[]) => {
    // The UI structure has comments as their own component.  Each comment does not know about
    // it's siblings, parent or children.  However, comments are responsible for rendering
    // their thread guidelines, so we must pass the information in.
    const threadGuidlines: { [key: string]: boolean[] } = {};
    let lines: boolean[] = [];

    const hasSiblingAtDepth = (currentIndex: number, depth: number) => {
      for (let j = currentIndex + 1; j < depthFirstArray.length; j++) {
        if (depthFirstArray[j].depth === depth) {
          return true;
        }
        if (depthFirstArray[j].depth < depth) {
          break;
        }
      }
      return false;
    };

    depthFirstArray.forEach((node, index) => {
      if (node.depth === 0) {
        lines = [];
      } else {
        lines[node.depth - 1] = hasSiblingAtDepth(index, node.depth);
      }

      threadGuidlines[node.ref] = [...lines];
    });

    return threadGuidlines;
  };

  const canEdit =
    currentUser.ref === data.post?.author?.ref || isUserAdmin(currentUser);
  const post = data.post!;
  const comments = data.comments;
  // Comments are returned sorted by timestamp. This is two loops.
  // First to create a map of comment refs to children.
  // createCommentMap - extracted from the sort method because we also use it to determine children/parents.
  // Second to recursively start at top level parents and insert children(replies) in order.  "Depth first insert"
  // This is a "depth first array" a flat array of a tree structure.
  const commentMap = createCommentMap(comments);
  const sortedComments = sortCommentsByDepth(comments, commentMap);
  const threadGuidelines = determineThreadGuidelines(sortedComments);
  const bodyContent = post.body?.payload
    ? JSON.parse(post.body?.payload)
    : post.body?.textContent;

  return (
    <GridPage
      sx={{
        padding: isMobile ? "32px 24px" : "64px 100px",
        maxWidth: "1000px",
      }}
    >
      <Box
        onClick={() => {
          navigate(-1);
        }}
        style={{ cursor: "pointer" }}
      >
        <Typography
          variant="body"
          sx={{
            color: "#198282",
            fontWeight: "600",
            display: "flex",
            alignItems: "center",
          }}
        >
          <ChevronLeftIcon
            width="18px"
            strokeWidth="2.5px"
            style={{ marginRight: "4px" }}
          />
          {"Back to posts"}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          marginBottom: isMobile ? "8px" : "16px",
        }}
      >
        <Typography
          sx={{
            flex: "1 1 100%",

            marginTop: "16px",
          }}
          variant="h1"
          id="tableTitle"
        >
          {data?.post?.title}
        </Typography>
        <PostTopics topics={post.topics} />
      </Box>

      <Paper sx={{ marginBottom: 2, boxShadow: "none" }}>
        <Box sx={{ marginBottom: "18px" }}>
          {editMode ? (
            <EditArea
              post={post}
              cancelEdit={() => setEditMode(false)}
              onUpdate={onUpdated}
            />
          ) : (
            <>
              <Box sx={{ padding: "0px" }}>
                <DocumentViewer
                  key={`${post.lastEditSec}`}
                  content={bodyContent}
                  attachments={post.body?.attachments}
                  minHeight="75px"
                />
              </Box>
              {post.postType === ForumPostType.POST_RECOMMENDATION && (
                <BusinessInfo
                  postRef={post.ref}
                  businessInfo={data.businessInfo!}
                  onUpdated={onUpdated}
                />
              )}
              <Box
                alignItems="flex-start"
                sx={{
                  padding: "24px 0px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <ListItemAvatar>
                    <UserAvatar user={post.author!} />
                  </ListItemAvatar>
                  <Box>
                    <Typography sx={{ fontWeight: "700" }}>
                      {post.author?.displayName}
                    </Typography>
                    <Typography
                      component="span"
                      variant="bodySmall"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "4px",
                        fontSize: "14px",
                        color: "#6B6E7B",
                      }}
                    >
                      <DateDisplay
                        date={new Date(Number(post.createdSec) * 1000)}
                        sx={{
                          fontSize: "14px",
                          color: "#6B6E7B",
                        }}
                      />
                      {post.createdSec !== post.lastEditSec && (
                        <span>{" (edited)"}</span>
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              {!isMobile && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Box sx={{ display: "flex", gap: "12px" }}>
                    <PostLikesAndComments post={post} />
                    {canEdit && (
                      <Box>
                        <IconButton ref={anchorRef} onClick={handleToggle}>
                          <Ellipsis size={20} />
                        </IconButton>
                        <PopperMenu
                          open={open}
                          anchorRef={anchorRef}
                          handleClose={handleClose}
                        >
                          {menuOptions}
                        </PopperMenu>
                      </Box>
                    )}
                  </Box>
                </Box>
              )}
              {isMobile && (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    gap: "12px",
                  }}
                >
                  <PostTopics topics={post.topics} />
                  <PostLikesAndComments post={post} />
                  {canEdit && (
                    <Box>
                      <IconButton ref={anchorRef} onClick={handleToggle}>
                        <Ellipsis size={20} />
                      </IconButton>
                      <PopperMenu
                        open={open}
                        anchorRef={anchorRef}
                        handleClose={handleClose}
                      >
                        {menuOptions}
                      </PopperMenu>
                    </Box>
                  )}
                </Box>
              )}
            </>
          )}
        </Box>
        <Divider />
        <Box sx={{ marginTop: "24px" }}>
          <AddComment postRef={postRef} onCreate={onCommentCreation} />
          <Box
            sx={{
              margin: "0",
              display: "flex",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            {sortedComments.map((comment) => (
              <PostComment
                key={comment.ref}
                comment={comment}
                post={post}
                highlighted={commentRef === comment.ref}
                onCommentCreation={onCommentCreation}
                hasParent={comment.parentRef !== ""}
                hasChildren={commentMap[comment.ref].length > 0}
                threadGuidelines={threadGuidelines[comment.ref]}
              />
            ))}
          </Box>
        </Box>
      </Paper>
      <ConfirmationDialog
        title="Delete post"
        noIcon={true}
        content="Are you sure you want to delete this post?"
        {...confirmState.dialogProps}
      />
    </GridPage>
  );
};
