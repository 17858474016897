import { Box, Typography, Button } from "@mui/material";
import { Lock } from "lucide-react";
import { SecretStub } from "../../protogen/secrets_pb";
import { getSecretLabel } from "./Constants";
import useIsMobile from "../hooks/useIsMobile";

const SecretListItem = ({
  secret,
  onClick,
}: {
  secret: SecretStub;
  onClick: (secret: SecretStub) => void;
}) => {
  const isMobile = useIsMobile();
  return (
    <Box
      sx={(theme) => ({
        width: "100%",
        display: "flex",
        borderRadius: "8px",
        padding: "16px 24px",
        border: `1px solid ${theme.palette.border}`,
      })}
    >
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <Typography variant="body">{secret.name}</Typography>
        <Typography
          variant="bodySmall"
          sx={(theme) => ({ color: `${theme.palette.text.secondary}` })}
        >
          {getSecretLabel(secret.valueType)}
        </Typography>
      </Box>
      <Box>
        <Button
          onClick={() => onClick(secret)}
          variant="text"
          startIcon={isMobile ? null : <Lock size={20} stroke="#8E9598"></Lock>}
        >
          Unlock
        </Button>
      </Box>
    </Box>
  );
};

export default ({
  secrets,
  onClick,
}: {
  secrets: SecretStub[];
  onClick: (secret: SecretStub) => void;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: "16px",
        alignSelf: "stretch",
        flexDirection: "column",
        width: "100%",
      }}
    >
      {secrets.length === 0 && (
        <Typography variant="body1">No secrets have been added yet.</Typography>
      )}
      {secrets.map((secret) => {
        return (
          <SecretListItem key={secret.ref} secret={secret} onClick={onClick} />
        );
      })}
    </Box>
  );
};
