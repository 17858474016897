import { useEffect, useRef, useState, useMemo, useContext } from "react";
import useIsVisible from "../../components/hooks/useIsVisible";
import { ScrollContainerHandle } from "../../components/common/ScrollContainer";
import { Box, Chip, IconButton } from "@mui/material";
import { useGetConversationFeed } from "../../services/advisor";
import {
  FeedEntry,
  GetConversationFeedResponse,
  Member,
} from "../../protogen/advisors_service_pb";
import { AccountStub, Attachment } from "../../protogen/common_pb";
import {
  dedupeFeedEntries,
  sortEntryTimestamps,
} from "../../components/activity/utils";
import FeedEntryComponent from "../../components/activity/FeedEntryComponent";
import ScrollableActivityPanel from "../../components/details-page/ScrollableActivityPanel";
import AttachmentDialog from "../../components/common/AttachmentDialog";
import TextMessageCompose from "../../components/activity/TextMessageCompose";
import PhoneCallInstantiation from "../../components/activity/PhoneCallInstantiation";
import PhoneIcon from "@mui/icons-material/Phone";
import FaceIcon from "@mui/icons-material/Face";
import { insertTaskSuggestion } from "../activity/Suggestions";
import SuggestionActions, {
  SuggestionActionsHandle,
} from "../activity/SuggestionActions";
import InboxThreadHeader from "../inbox/InboxThreadHeader";
import { ConversationStarter } from "../creation/StartExternalConversation";
import { CurrentUserContext } from "../context/RequireAuth";
import { commaSeparatedEnglishList } from "../../common/utils";

const MessageFeed = ({
  conversationRef,
  onUpdates,
}: {
  conversationRef: string;
  onUpdates: () => void;
}) => {
  const currentUser = useContext(CurrentUserContext);
  const scrollRef = useRef<ScrollContainerHandle | null>(null);
  const actionsRef = useRef<SuggestionActionsHandle | null>(null);
  const hasLoadedOnce = useRef(false);
  const [localEntries, setLocalEntries] = useState<FeedEntry[]>([]);
  const [feedCursor, setFeedCursor] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [entries, setEntries] = useState<FeedEntry[]>([]);
  const [accountMap, setAccountMap] = useState<Record<string, AccountStub>>({});
  const [callModalOpen, setCallModalOpen] = useState<boolean>(false);
  const [openAttachment, setOpenAttachment] = useState<Attachment | null>(null);
  const [contact, setContact] = useState<AccountStub | null>(null);
  const updateEntries = (r: GetConversationFeedResponse) => {
    hasLoadedOnce.current = true;
    setContact(r.contact);
    setEntries((pastEntries) => {
      return dedupeFeedEntries(
        [...r.entries, ...pastEntries].sort(sortEntryTimestamps),
      );
    });
    setAccountMap((pastMap) => {
      return {
        ...pastMap,
        ...r.accounts.reduce(
          (acc: Record<string, AccountStub>, account: AccountStub) => {
            acc[account.ref] = account;
            return acc;
          },
          {},
        ),
      };
    });
    if (!r.hasMore) {
      // End of the line, currently you can't get out of this state
      setHasMore(false);
    }
    // Don't blow away a good cursor on a refresh If the prev request used a cursor update the next one OR if
    // we haven't set one yet.
    if ((r.startCursor || !feedCursor) && feedCursor !== r.nextCursor) {
      setFeedCursor(() => r.nextCursor);
    }
  };
  const { request } = useGetConversationFeed(updateEntries);

  const refresh = async (feedCursor?: string) => {
    await request({
      conversationRef,
      startCursor: feedCursor || "",
      // Only mark conversations as read if the feed is visible.
      markConversationsRead: isVisible,
    });
  };
  const { isVisible } = useIsVisible({
    onRefocus: async (blurSecs) => {
      if (blurSecs > 10) {
        await refresh();
      }
    },
  });
  useEffect(() => {
    if (!hasLoadedOnce.current) {
      refresh();
    }
    const intervalId = setInterval(async () => {
      if (isVisible) {
        await refresh();
      }
    }, 5000);

    return () => clearInterval(intervalId);
  }, [conversationRef]);

  useEffect(() => {
    setLocalEntries([]);
    setFeedCursor("");
    setHasMore(true);
    setEntries([]);
    setAccountMap({});
    refresh();
  }, [conversationRef]);

  // Dedupe.
  const allEntries = dedupeFeedEntries(
    [...entries, ...localEntries].sort(sortEntryTimestamps),
  );
  const otherContacts = Object.entries(accountMap)
    .map(([_, v]) => v)
    .filter((v) => v.ref !== currentUser.ref);
  const createFeedEntryComponent = (e: FeedEntry, i: number): JSX.Element => {
    return (
      <FeedEntryComponent
        key={`${e.medium}-${e.ref}`}
        suggestFact={(f) => actionsRef.current?.suggestFact(f)}
        entry={e}
        feedFocusState={{
          // Arggh.
          selectedContact: new Member({
            ref: contact?.ref || "",
            firstName: contact?.firstName || "",
            lastName: contact?.lastName || "",
            displayName: contact?.displayName || "",
            avatarUrl: contact?.avatarUrl || "",
            primaryPhone: "",
            primaryEmail: "",
            alternateEmails: [],
          }),
          selectedMedium: null,
          selectedEntryKey: null,
          replyToEmail: null,
          editEmailDraft: null,
          isDirty: false,
          groupText: false,
        }}
        setFeedFocusState={() => {}}
        accountMap={accountMap}
        openAttachment={(attachment) => {
          setOpenAttachment(attachment);
        }}
        // Be confused! We sort these in reverse from our CSS rendering which makes life more confusing.
        nextEntry={allEntries[i - 1]}
        prevEntry={allEntries[i + 1]}
      />
    );
  };
  const scrollEntries = useMemo(() => {
    return insertTaskSuggestion(
      allEntries,
      {
        suggestTask: actionsRef.current?.suggestTask || (() => {}),
        suggestFact: actionsRef.current?.suggestFact || (() => {}),
        suggestEvent: actionsRef.current?.suggestEvent || (() => {}),
      },
      createFeedEntryComponent,
      { marginLeft: "54px" },
    );
  }, [entries, localEntries]);

  const addFeedEntry = (entry: FeedEntry) => {
    setLocalEntries([...localEntries, entry]);
    scrollRef.current?.setSticky();
    refresh();
    onUpdates();
  };

  return (
    <>
      <ScrollableActivityPanel
        scrollEntries={scrollEntries}
        fetchScrollEntries={() => refresh(feedCursor)}
        hasMoreScrollEntries={hasMore}
        scrollRef={scrollRef}
        headerPanel={
          <InboxThreadHeader>
            {otherContacts.map((c) => (
              <Chip key={c.ref} icon={<FaceIcon />} label={c?.displayName} />
            ))}
            <IconButton onClick={() => setCallModalOpen(true)}>
              <PhoneIcon />
            </IconButton>
          </InboxThreadHeader>
        }
        footerPanel={
          <Box sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}>
            {contact && (
              <TextMessageCompose
                familyRef={null}
                recipientRefs={otherContacts.map((c) => c.ref)}
                contactEnabled={true}
                placeholder={`Message ${commaSeparatedEnglishList(
                  otherContacts.map((c) => c.firstName),
                )}`}
                onSent={addFeedEntry}
              />
            )}
          </Box>
        }
        sx={{ height: "100%", padding: "0px" }}
      />
      <AttachmentDialog
        attachment={openAttachment}
        onClose={() => setOpenAttachment(null)}
      />
      <PhoneCallInstantiation
        closed={!callModalOpen}
        onClose={() => setCallModalOpen(false)}
        recipientRef={contact?.ref || ""}
        onStarted={addFeedEntry}
      />
      <SuggestionActions ref={actionsRef} familyRef={""} />
    </>
  );
};

interface MessageThreadProps {
  entryRef: string | undefined;
  onUpdates: () => void;
  isNewConversation: boolean;
}

export default ({
  entryRef,
  onUpdates,
  isNewConversation,
}: MessageThreadProps) => {
  if (isNewConversation && !entryRef) {
    return <ConversationStarter />;
  }

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <MessageFeed conversationRef={entryRef || ""} onUpdates={onUpdates} />
    </Box>
  );
};
