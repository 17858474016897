// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/tasks.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { AccountStub, Attachment, FamilyStub } from "./common_pb.ts";

/**
 * @generated from enum protogen.TaskState
 */
export enum TaskState {
  /**
   * @generated from enum value: TASK_STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: TASK_STATE_OPEN = 1;
   */
  OPEN = 1,

  /**
   * @generated from enum value: TASK_STATE_INPROGRESS = 2;
   */
  INPROGRESS = 2,

  /**
   * @generated from enum value: TASK_STATE_COMPLETED = 3;
   */
  COMPLETED = 3,

  /**
   * @generated from enum value: TASK_STATE_DELETED = 4;
   */
  DELETED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(TaskState)
proto3.util.setEnumType(TaskState, "protogen.TaskState", [
  { no: 0, name: "TASK_STATE_UNSPECIFIED" },
  { no: 1, name: "TASK_STATE_OPEN" },
  { no: 2, name: "TASK_STATE_INPROGRESS" },
  { no: 3, name: "TASK_STATE_COMPLETED" },
  { no: 4, name: "TASK_STATE_DELETED" },
]);

/**
 * @generated from enum protogen.TaskBlockStatus
 */
export enum TaskBlockStatus {
  /**
   * @generated from enum value: TASK_BLOCK_STATUS_UNSET = 0;
   */
  UNSET = 0,

  /**
   * @generated from enum value: TASK_BLOCK_STATUS_ACTIVE = 1;
   */
  ACTIVE = 1,

  /**
   * @generated from enum value: TASK_BLOCK_STATUS_DELETED = 2;
   */
  DELETED = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(TaskBlockStatus)
proto3.util.setEnumType(TaskBlockStatus, "protogen.TaskBlockStatus", [
  { no: 0, name: "TASK_BLOCK_STATUS_UNSET" },
  { no: 1, name: "TASK_BLOCK_STATUS_ACTIVE" },
  { no: 2, name: "TASK_BLOCK_STATUS_DELETED" },
]);

/**
 * @generated from message protogen.TaskTimer
 */
export class TaskTimer extends Message<TaskTimer> {
  /**
   * @generated from field: int32 hoursReported = 1;
   */
  hoursReported = 0;

  /**
   * @generated from field: int32 minutesReported = 2;
   */
  minutesReported = 0;

  constructor(data?: PartialMessage<TaskTimer>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.TaskTimer";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hoursReported", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 2, name: "minutesReported", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskTimer {
    return new TaskTimer().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskTimer {
    return new TaskTimer().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskTimer {
    return new TaskTimer().fromJsonString(jsonString, options);
  }

  static equals(a: TaskTimer | PlainMessage<TaskTimer> | undefined, b: TaskTimer | PlainMessage<TaskTimer> | undefined): boolean {
    return proto3.util.equals(TaskTimer, a, b);
  }
}

/**
 * @generated from message protogen.TaskBlock
 */
export class TaskBlock extends Message<TaskBlock> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: int64 createdSec = 2;
   */
  createdSec = protoInt64.zero;

  /**
   * @generated from field: int64 lastUpdatedSec = 3;
   */
  lastUpdatedSec = protoInt64.zero;

  /**
   * @generated from field: string type = 4;
   */
  type = "";

  /**
   * @generated from field: string content = 5;
   */
  content = "";

  /**
   * @generated from field: string textContent = 6;
   */
  textContent = "";

  /**
   * @generated from field: repeated protogen.Attachment attachments = 7;
   */
  attachments: Attachment[] = [];

  /**
   * @generated from field: string metadata = 8;
   */
  metadata = "";

  /**
   * @generated from field: protogen.AccountStub createdBy = 9;
   */
  createdBy?: AccountStub;

  /**
   * @generated from field: string contentType = 10;
   */
  contentType = "";

  constructor(data?: PartialMessage<TaskBlock>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.TaskBlock";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "createdSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "lastUpdatedSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 4, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "textContent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "attachments", kind: "message", T: Attachment, repeated: true },
    { no: 8, name: "metadata", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "createdBy", kind: "message", T: AccountStub },
    { no: 10, name: "contentType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskBlock {
    return new TaskBlock().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskBlock {
    return new TaskBlock().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskBlock {
    return new TaskBlock().fromJsonString(jsonString, options);
  }

  static equals(a: TaskBlock | PlainMessage<TaskBlock> | undefined, b: TaskBlock | PlainMessage<TaskBlock> | undefined): boolean {
    return proto3.util.equals(TaskBlock, a, b);
  }
}

/**
 * @generated from message protogen.TaskRecommendationResult
 */
export class TaskRecommendationResult extends Message<TaskRecommendationResult> {
  /**
   * @generated from field: string title = 1;
   */
  title = "";

  /**
   * @generated from field: string url = 2;
   */
  url = "";

  /**
   * @generated from field: string explanation = 3;
   */
  explanation = "";

  /**
   * @generated from field: string thumbnailUrl = 4;
   */
  thumbnailUrl = "";

  /**
   * @generated from field: string price = 5;
   */
  price = "";

  /**
   * @generated from field: string phone = 6;
   */
  phone = "";

  /**
   * @generated from field: string address = 7;
   */
  address = "";

  constructor(data?: PartialMessage<TaskRecommendationResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.TaskRecommendationResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "explanation", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "thumbnailUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "price", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "address", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskRecommendationResult {
    return new TaskRecommendationResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskRecommendationResult {
    return new TaskRecommendationResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskRecommendationResult {
    return new TaskRecommendationResult().fromJsonString(jsonString, options);
  }

  static equals(a: TaskRecommendationResult | PlainMessage<TaskRecommendationResult> | undefined, b: TaskRecommendationResult | PlainMessage<TaskRecommendationResult> | undefined): boolean {
    return proto3.util.equals(TaskRecommendationResult, a, b);
  }
}

/**
 * @generated from message protogen.TaskRecommendation
 */
export class TaskRecommendation extends Message<TaskRecommendation> {
  /**
   * @generated from field: string suggestionRef = 1;
   */
  suggestionRef = "";

  /**
   * @generated from field: string queryString = 2;
   */
  queryString = "";

  /**
   * @generated from field: string url = 3;
   */
  url = "";

  /**
   * @generated from field: repeated protogen.TaskRecommendationResult results = 4;
   */
  results: TaskRecommendationResult[] = [];

  constructor(data?: PartialMessage<TaskRecommendation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.TaskRecommendation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "suggestionRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "queryString", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "results", kind: "message", T: TaskRecommendationResult, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskRecommendation {
    return new TaskRecommendation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskRecommendation {
    return new TaskRecommendation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskRecommendation {
    return new TaskRecommendation().fromJsonString(jsonString, options);
  }

  static equals(a: TaskRecommendation | PlainMessage<TaskRecommendation> | undefined, b: TaskRecommendation | PlainMessage<TaskRecommendation> | undefined): boolean {
    return proto3.util.equals(TaskRecommendation, a, b);
  }
}

/**
 * @generated from message protogen.ForumPostResult
 */
export class ForumPostResult extends Message<ForumPostResult> {
  /**
   * @generated from field: string postRef = 1;
   */
  postRef = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string textContent = 3;
   */
  textContent = "";

  /**
   * @generated from field: string queryString = 4;
   */
  queryString = "";

  /**
   * @generated from field: protogen.AccountStub createdBy = 5;
   */
  createdBy?: AccountStub;

  /**
   * @generated from field: repeated string topics = 6;
   */
  topics: string[] = [];

  constructor(data?: PartialMessage<ForumPostResult>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ForumPostResult";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "postRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "textContent", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "queryString", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "createdBy", kind: "message", T: AccountStub },
    { no: 6, name: "topics", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ForumPostResult {
    return new ForumPostResult().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ForumPostResult {
    return new ForumPostResult().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ForumPostResult {
    return new ForumPostResult().fromJsonString(jsonString, options);
  }

  static equals(a: ForumPostResult | PlainMessage<ForumPostResult> | undefined, b: ForumPostResult | PlainMessage<ForumPostResult> | undefined): boolean {
    return proto3.util.equals(ForumPostResult, a, b);
  }
}

/**
 * @generated from message protogen.ForumPostRecommendation
 */
export class ForumPostRecommendation extends Message<ForumPostRecommendation> {
  /**
   * @generated from field: string suggestionRef = 1;
   */
  suggestionRef = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string queryString = 3;
   */
  queryString = "";

  /**
   * @generated from field: repeated protogen.ForumPostResult posts = 4;
   */
  posts: ForumPostResult[] = [];

  constructor(data?: PartialMessage<ForumPostRecommendation>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ForumPostRecommendation";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "suggestionRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "queryString", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "posts", kind: "message", T: ForumPostResult, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ForumPostRecommendation {
    return new ForumPostRecommendation().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ForumPostRecommendation {
    return new ForumPostRecommendation().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ForumPostRecommendation {
    return new ForumPostRecommendation().fromJsonString(jsonString, options);
  }

  static equals(a: ForumPostRecommendation | PlainMessage<ForumPostRecommendation> | undefined, b: ForumPostRecommendation | PlainMessage<ForumPostRecommendation> | undefined): boolean {
    return proto3.util.equals(ForumPostRecommendation, a, b);
  }
}

/**
 * @generated from message protogen.Task
 */
export class Task extends Message<Task> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string familyRef = 2;
   */
  familyRef = "";

  /**
   * @generated from field: string title = 3;
   */
  title = "";

  /**
   * @generated from field: string category = 4;
   */
  category = "";

  /**
   * @generated from field: string priority = 5;
   */
  priority = "";

  /**
   * @generated from field: string dueDate = 6;
   */
  dueDate = "";

  /**
   * @generated from field: bool hidden = 7;
   */
  hidden = false;

  /**
   * @generated from field: protogen.TaskState state = 8;
   */
  state = TaskState.UNSPECIFIED;

  /**
   * @generated from field: protogen.AccountStub assignee = 9;
   */
  assignee?: AccountStub;

  /**
   * @generated from field: int64 createdSec = 11;
   */
  createdSec = protoInt64.zero;

  /**
   * @generated from field: int64 lastUpdatedSec = 12;
   */
  lastUpdatedSec = protoInt64.zero;

  /**
   * @generated from field: protogen.AccountStub createdBy = 13;
   */
  createdBy?: AccountStub;

  /**
   * @generated from field: protogen.FamilyStub family = 14;
   */
  family?: FamilyStub;

  /**
   * @generated from field: protogen.TaskTimer timing = 15;
   */
  timing?: TaskTimer;

  /**
   * @generated from field: repeated protogen.TaskBlock blocks = 16;
   */
  blocks: TaskBlock[] = [];

  /**
   * @generated from field: int64 completionDateSec = 17;
   */
  completionDateSec = protoInt64.zero;

  /**
   * Depends on user perspective
   *
   * @generated from field: int32 unreadStatusCount = 18;
   */
  unreadStatusCount = 0;

  /**
   * @generated from field: string sourceEntityType = 19;
   */
  sourceEntityType = "";

  /**
   * @generated from field: string sourceEntityRef = 20;
   */
  sourceEntityRef = "";

  constructor(data?: PartialMessage<Task>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.Task";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "priority", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "dueDate", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "hidden", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 8, name: "state", kind: "enum", T: proto3.getEnumType(TaskState) },
    { no: 9, name: "assignee", kind: "message", T: AccountStub },
    { no: 11, name: "createdSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 12, name: "lastUpdatedSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 13, name: "createdBy", kind: "message", T: AccountStub },
    { no: 14, name: "family", kind: "message", T: FamilyStub },
    { no: 15, name: "timing", kind: "message", T: TaskTimer },
    { no: 16, name: "blocks", kind: "message", T: TaskBlock, repeated: true },
    { no: 17, name: "completionDateSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 18, name: "unreadStatusCount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 19, name: "sourceEntityType", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 20, name: "sourceEntityRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Task {
    return new Task().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Task {
    return new Task().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Task {
    return new Task().fromJsonString(jsonString, options);
  }

  static equals(a: Task | PlainMessage<Task> | undefined, b: Task | PlainMessage<Task> | undefined): boolean {
    return proto3.util.equals(Task, a, b);
  }
}

/**
 * @generated from message protogen.DefaultTaskDetail
 */
export class DefaultTaskDetail extends Message<DefaultTaskDetail> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: string type = 2;
   */
  type = "";

  constructor(data?: PartialMessage<DefaultTaskDetail>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.DefaultTaskDetail";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): DefaultTaskDetail {
    return new DefaultTaskDetail().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): DefaultTaskDetail {
    return new DefaultTaskDetail().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): DefaultTaskDetail {
    return new DefaultTaskDetail().fromJsonString(jsonString, options);
  }

  static equals(a: DefaultTaskDetail | PlainMessage<DefaultTaskDetail> | undefined, b: DefaultTaskDetail | PlainMessage<DefaultTaskDetail> | undefined): boolean {
    return proto3.util.equals(DefaultTaskDetail, a, b);
  }
}

/**
 * @generated from message protogen.TaskInspiration
 */
export class TaskInspiration extends Message<TaskInspiration> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string title = 2;
   */
  title = "";

  /**
   * @generated from field: string type = 3;
   */
  type = "";

  /**
   * @generated from field: string additionalContext = 4;
   */
  additionalContext = "";

  /**
   * @generated from field: string status = 5;
   */
  status = "";

  /**
   * @generated from field: repeated protogen.DefaultTaskDetail defaultDetails = 6;
   */
  defaultDetails: DefaultTaskDetail[] = [];

  constructor(data?: PartialMessage<TaskInspiration>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.TaskInspiration";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "title", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "additionalContext", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "defaultDetails", kind: "message", T: DefaultTaskDetail, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TaskInspiration {
    return new TaskInspiration().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TaskInspiration {
    return new TaskInspiration().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TaskInspiration {
    return new TaskInspiration().fromJsonString(jsonString, options);
  }

  static equals(a: TaskInspiration | PlainMessage<TaskInspiration> | undefined, b: TaskInspiration | PlainMessage<TaskInspiration> | undefined): boolean {
    return proto3.util.equals(TaskInspiration, a, b);
  }
}

