import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from "react";
import set from "lodash/set";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Box, Button, Typography } from "@mui/material";
import { SecretFormHandle, SecretFormProps } from "../types";
import { AddressForm } from "../../common/types";
import {
  ExpiryDateInput,
  CreditCardNumberInput,
} from "../../common/MaskedFields";

type CreditCardForm = {
  title?: string;
  number?: string;
  cvv?: string;
  expiration?: string;
  name?: string;
  altAddress?: AddressForm;
  notes?: string;
  [key: string]: string | AddressForm | undefined;
};
export default forwardRef<SecretFormHandle, SecretFormProps>(
  ({ secret, updateFormData, errors }: SecretFormProps, ref) => {
    const parsed: CreditCardForm =
      secret.value === "" ? {} : JSON.parse(secret.value);
    useImperativeHandle(ref, () => ({
      isValid: () => {
        return secret.name !== "";
      },
    }));
    const [expiration, setExpiration] = React.useState(parsed.expiration || "");
    const [showDifferentBillingAddress, setShowDifferentBillingAddress] =
      useState(false);
    const [showNotes, setShowNotes] = useState(false);

    const createChangeHandler = (path: string[], value: string | null) => {
      const updatedParsed = { ...parsed };

      set(updatedParsed, path, value);

      const form = {
        ...secret,
        value: JSON.stringify(updatedParsed),
      };
      updateFormData(form);
    };

    // open address and notes if there is a value
    useEffect(() => {
      if (parsed.notes && parsed.notes.length > 0) {
        setShowNotes(true);
      }

      if (
        Object.values(parsed?.altAddress || []).some(
          (value) => value !== undefined,
        )
      ) {
        setShowDifferentBillingAddress(true);
      }
    }, [parsed]);

    const handleExpirationChange = (
      event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
      const value = event.target.value;
      const isValidPartial =
        /^((0[1-9]|1[0-2])\/([0-9][0-9])?[0-9]{0,2}|(0[1-9]|1[0-2])|[0-9]{0,2})$/.test(
          value,
        );

      if (isValidPartial || value === "") {
        setExpiration(value);
        createChangeHandler(["expiration"], event.target.value);
      }
    };
    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <TextField
          sx={{ margin: "0px" }}
          margin="dense"
          label="Title"
          type="text"
          fullWidth
          variant="outlined"
          value={secret.name}
          error={!!errors?.value}
          helperText={errors?.value}
          onChange={(e) => updateFormData({ ...secret, name: e.target.value })}
        />
        <TextField
          sx={{ margin: "0px" }}
          margin="dense"
          label="Card number"
          type="text"
          fullWidth
          variant="outlined"
          value={parsed.number || ""}
          error={!!errors?.value}
          helperText={errors?.value}
          onChange={(e) => createChangeHandler(["number"], e.target.value)}
          InputProps={{
            inputComponent: CreditCardNumberInput as any,
          }}
        />
        <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
          <TextField
            sx={{ margin: "0px" }}
            margin="dense"
            label="CVV"
            type="text"
            variant="outlined"
            value={parsed.cvv || ""}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={(e) => createChangeHandler(["cvv"], e.target.value)}
          />
          <TextField
            sx={{ margin: "0px" }}
            margin="dense"
            label="Expiration"
            type="text"
            variant="outlined"
            value={expiration}
            error={!!errors?.value}
            helperText={errors?.value}
            onChange={handleExpirationChange}
            placeholder="MM/YY"
            InputProps={{
              inputComponent: ExpiryDateInput as any,
            }}
          />
        </Box>
        <TextField
          sx={{ margin: "0px" }}
          margin="dense"
          label="Name"
          type="text"
          fullWidth
          variant="outlined"
          value={parsed.name || ""}
          error={!!errors?.value}
          helperText={errors?.value}
          onChange={(e) => createChangeHandler(["name"], e.target.value)}
        />
        {!showDifferentBillingAddress && (
          <Button
            fullWidth
            variant="text"
            onClick={() => {
              setShowDifferentBillingAddress(!showDifferentBillingAddress);
            }}
            startIcon={<AddIcon />}
            sx={{
              justifyContent: "flex-start",
              paddingLeft: "0px",
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            Different billing address
          </Button>
        )}
        {showDifferentBillingAddress && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="bodyHeavy">Billing address</Typography>
              <IconButton
                onClick={() => {
                  setShowDifferentBillingAddress(false);
                  createChangeHandler(["altAddress"], null);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <TextField
              sx={{ margin: "0px" }}
              margin="dense"
              label="Street address"
              type="text"
              fullWidth
              variant="outlined"
              value={parsed.altAddress?.street1 || ""}
              error={!!errors?.value}
              helperText={errors?.value}
              onChange={(e) =>
                createChangeHandler(["altAddress", "street1"], e.target.value)
              }
            />
            <TextField
              sx={{ margin: "0px" }}
              margin="dense"
              label="Apt, Suite, etc."
              type="text"
              fullWidth
              variant="outlined"
              value={parsed.altAddress?.street2 || ""}
              error={!!errors?.value}
              helperText={errors?.value}
              onChange={(e) =>
                createChangeHandler(["altAddress", "street2"], e.target.value)
              }
            />
            <TextField
              sx={{ margin: "0px" }}
              margin="dense"
              label="City"
              type="text"
              fullWidth
              variant="outlined"
              value={parsed.altAddress?.city || ""}
              error={!!errors?.value}
              helperText={errors?.value}
              onChange={(e) =>
                createChangeHandler(["altAddress", "city"], e.target.value)
              }
            />

            <Box sx={{ display: "flex", flexDirection: "row", gap: "10px" }}>
              <TextField
                sx={{ margin: "0px" }}
                margin="dense"
                label="State"
                type="text"
                variant="outlined"
                value={parsed.altAddress?.state || ""}
                error={!!errors?.value}
                helperText={errors?.value}
                onChange={(e) =>
                  createChangeHandler(["altAddress", "state"], e.target.value)
                }
              />
              <TextField
                sx={{ margin: "0px" }}
                margin="dense"
                label="Zip"
                type="text"
                variant="outlined"
                value={parsed.altAddress?.zipCode || ""}
                error={!!errors?.value}
                helperText={errors?.value}
                onChange={(e) =>
                  createChangeHandler(["altAddress", "zipCode"], e.target.value)
                }
              />
            </Box>
          </Box>
        )}
        {!showNotes && (
          <Button
            fullWidth
            variant="text"
            onClick={() => {
              setShowNotes(!showNotes);
            }}
            startIcon={<AddIcon />}
            sx={{
              justifyContent: "flex-start",
              paddingLeft: "0px",
              "&:hover": {
                backgroundColor: "inherit",
              },
            }}
          >
            Notes
          </Button>
        )}
        {showNotes && (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography variant="bodyHeavy">Notes</Typography>
              <IconButton
                onClick={() => {
                  createChangeHandler(["notes"], null);
                  setShowNotes(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <TextField
              margin="dense"
              rows={5}
              label="Notes"
              type="text"
              fullWidth
              multiline
              variant="outlined"
              value={parsed.notes || ""}
              error={!!errors?.value}
              helperText={errors?.value}
              onChange={(e) => createChangeHandler(["notes"], e.target.value)}
            />
          </Box>
        )}
      </Box>
    );
  },
);
