import ReactiveDialog from "../common/ReactiveDialog";
import { useState } from "react";
import PostForm from "./PostForm";
import { EphemeralPost, FormErrors } from "./types";
import useIsMobile from "../hooks/useIsMobile";
import { useNavigate } from "react-router-dom";
import { useCreatePost } from "../../services/forum";
import { PlusIcon } from "@heroicons/react/24/outline";
import { ForumPost, ForumPostType } from "../../protogen/forum_service_pb";
import { stripImageSrc } from "../editor/utils";

type Props = {
  open: boolean;
  onClose: () => void;
  onCreated?: (p: ForumPost) => void;
  postType?: ForumPostType;
  topics?: string[];
};

const defaultFormData = (
  postType?: ForumPostType,
  topics?: string[],
): EphemeralPost => ({
  title: "",
  postType: postType || ForumPostType.POST_DISCUSSION,
  topics: topics,
});

const validatePost = (s: EphemeralPost): FormErrors => {
  const errors: FormErrors = {};
  if (!s.title) {
    errors.name = "Title is required";
  }
  if (!s.postType) {
    errors.name = "A type is required";
  }
  // if (!s.value) {
  //   errors.value = 'Value is required';
  // }
  if (!(s.body && s.body.textContent)) {
    errors.content = "Content is required";
  }
  return errors;
};

export default ({ open, onClose, postType, topics, onCreated }: Props) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState<FormErrors | null>(null);
  const [formData, setFormData] = useState<EphemeralPost>(
    defaultFormData(postType, topics),
  );
  const { request, loading } = useCreatePost((r) => {
    if (onCreated && r?.post) {
      onCreated(r?.post);
    } else {
      // Navigate to the newly created post
      navigate(`/community/${encodeURIComponent(r?.post?.ref || "")}`);
    }
  });
  const updateFormData = (s: EphemeralPost) => {
    setFormData((prevState) => ({
      ...(prevState || {}),
      ...s,
    }));
  };
  const _validate = () => {
    const errors = validatePost(formData);
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const handleCreate = () => {
    if (_validate()) {
      const strippedBody = {
        ...formData.body,
        payload: stripImageSrc(formData.body?.payload || "{}"),
      };
      request({
        title: formData.title!,
        body: strippedBody || {},
        postType: formData.postType!,
        topics: formData.topics || [],
      });
    }
  };
  return (
    <ReactiveDialog
      fullWidthSize="lg"
      open={open}
      onClose={onClose}
      title={"Create Post"}
      primaryAction={handleCreate}
      primaryActionName={
        isMobile ? (
          <PlusIcon height="24px" width="24px" stroke="#FFF"></PlusIcon>
        ) : (
          "Post"
        )
      }
      primaryActionEnabled={!loading}
      noActionsFullScreen={true}
    >
      <PostForm
        loading={loading}
        formData={formData}
        updateFormData={updateFormData}
        errors={formErrors}
        onSubmit={handleCreate}
        hideAction={isMobile}
        onClose={onClose}
      />
    </ReactiveDialog>
  );
};
