import { Button } from "@mui/material";
import React from "react";
import { useUpdateFamilyBilling } from "../../services/billing";

type Props = {
  familyRef: string;
  onComplete: () => void;
};

export default ({ familyRef, onComplete }: Props) => {
  const { request, loading } = useUpdateFamilyBilling();
  const onClick = async () => {
    const data = await request({
      familyRef: familyRef,
      customerId: "customerId",
      unlinkCustomer: true,
    });
    if (data) {
      onComplete();
    }
  };
  return (
    <Button disabled={loading} variant="text" onClick={onClick}>
      Unlink stripe customer
    </Button>
  );
};
