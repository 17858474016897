import { FamilyBilling } from "../../protogen/advisors_service_pb";
import { Box, Typography } from "@mui/material";
import LinkRouter from "../navigation/LinkRouter";
import { ExternalLink } from "lucide-react";

export default ({ billingInfo }: { billingInfo: FamilyBilling }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        borderColor: "rgba(0, 0, 0, 0.23)",
        border: "1px solid #D4D4D4",
        borderRadius: "8px",
        padding: "7px 20px",
        alignItems: "start",
      }}
    >
      <Typography
        variant="bodySmall"
        sx={{
          color: "#6B6E7B",
          fontWeight: 500,
          fontSize: "12px",
          userSelect: "none",
          cursor: "default",
        }}
      >
        Stripe Customer ID
      </Typography>
      {billingInfo?.stripeCustomerId ? (
        <LinkRouter
          targetNew={true}
          inline={true}
          sx={{ padding: 0 }}
          to={`https://dashboard.stripe.com/customers/${billingInfo?.stripeCustomerId}`}
        >
          <Typography
            variant="bodySmall"
            sx={{
              display: "inline",
              marginRight: "6px",
              fontWeight: 600,
            }}
          >
            {billingInfo?.stripeCustomerId}
          </Typography>
          <ExternalLink size={20} />
        </LinkRouter>
      ) : (
        <Typography variant="bodySmall">N/A</Typography>
      )}
    </Box>
  );
};
