import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import auth from "../../../components/nux/auth";
import {
  useConfirmAdvisorLanding,
  useCreateNuxChangeEvent,
} from "../../..//services/nux";
import { getStepPath } from "../../../components/nux/utils";
import NuxContentLayout from "../../../components/nux/NuxContentLayout";
import { ReactComponent as FayeIcon } from "../../../icons/FayeBrand.svg";
import useIsMobile from "../../..//components/hooks/useIsMobile";
import { USER_NUX_TYPE } from "../../../components/nux/constants";

export default () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { request, loading } = useConfirmAdvisorLanding((r) => {
    if (r?.nuxDetails?.stepName) {
      const path = getStepPath(r.nuxDetails.stepName, USER_NUX_TYPE.ADVISOR);
      navigate(path);
    }
  });

  const { request: createNuxChangeEvent } = useCreateNuxChangeEvent();

  const onContinue = () => {
    request({
      token: auth.getAuthData(),
    });
  };

  useEffect(() => {
    const token = queryParams.get("token");
    if (!token) {
      navigate("/login");
      return;
    }
    auth.setAuthData(token);
    createNuxChangeEvent({
      name: "page_load",
      value: "landing",
      token: auth.getAuthData(),
    });
  }, []);

  return (
    <NuxContentLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "36px",
          alignItems: "center",
        }}
      >
        <FayeIcon
          style={{
            alignSelf: isMobile ? "start" : "center",
            height: "48px",
            width: "82px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              alignSelf: isMobile ? "start" : "center",
            }}
            variant="title"
          >
            Welcome! We’re excited you’re here!
          </Typography>
          <Typography variant="body" sx={{ alignSelf: "start" }}>
            First on the to-do list? Getting you set up in Advisor!
          </Typography>
          <Typography variant="body" sx={{ alignSelf: "start" }}>
            Let’s activate your Advisor account, which includes your new Faye
            phone number and email. Then, we’ll review our terms of service and
            set up your public-facing profile.
          </Typography>
          <Typography variant="body" sx={{ alignSelf: "start" }}>
            Let’s go!
          </Typography>
          <Box
            sx={{ alignSelf: "flex-end", width: isMobile ? "100%" : "auto" }}
          >
            <Button
              disabled={loading}
              sx={{ width: "100%" }}
              onClick={onContinue}
            >
              Let's go
            </Button>
          </Box>
        </Box>
      </Box>
    </NuxContentLayout>
  );
};
