import { Attachment as AttachmentProto } from "../../protogen/common_pb";
import { Card, CardActionArea, CardHeader } from "@mui/material";
import FileDownload from "@mui/icons-material/FileDownload";
import ContactCardEntry from "../common/ContactCardEntry";
import { withImageProps, withVideoProps } from "../common/SignedImageCache";
import { humanFileSize } from "../../common/utils";

export default ({
  attachment,
  openAttachment,
  loading,
  error,
}: {
  attachment: AttachmentProto;
  openAttachment: (a: AttachmentProto) => void;
  loading?: boolean;
  error?: string | undefined;
}) => {
  const errorBorder = { border: "2px solid red", boxShadow: "0 0 3px red" };
  if (attachment.contentType.startsWith("image")) {
    return (
      <div>
        <img
          onClick={() => openAttachment(attachment)}
          {...withImageProps({ url: attachment.url })}
          alt={attachment.filename}
          style={{
            minHeight: "20px",
            minWidth: "20px",
            maxWidth: "100%",
            maxHeight: "30vh",
            borderRadius: "12px",
            cursor: "pointer",
            marginTop: "6px",
            // Handle long alt text.
            textOverflow: "ellipsis",
            overflowX: "hidden",
            ...{ textWrap: "nowrap" },
            ...(loading ? { filter: "blur(4px)" } : {}),
            ...(error ? errorBorder : {}),
          }}
        />
      </div>
    );
  } else if (
    attachment.contentType.startsWith("video") &&
    attachment.contentType.toLowerCase() !== "video/3gpp"
  ) {
    const videoProps = withVideoProps({ url: attachment.url });
    return (
      <div>
        <video
          width="320"
          height="240"
          onError={videoProps.onError}
          controls
          style={{
            minHeight: "20px",
            minWidth: "20px",
            maxWidth: "100%",
            maxHeight: "30vh",
            borderRadius: "12px",
            cursor: "pointer",
            marginTop: "6px",
            // Handle long alt text.
            textOverflow: "ellipsis",
            overflowX: "hidden",
            ...{ textWrap: "nowrap" },
            ...(loading ? { filter: "blur(4px)" } : {}),
            ...(error ? errorBorder : {}),
          }}
        >
          <source
            src={videoProps.src}
            type={"video/mp4"}
            // type={attachment.contentType}
          />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  } else if (
    ["text/x-vcard", "text/vcard"].indexOf(
      attachment.contentType.toLowerCase(),
    ) >= 0
  ) {
    return (
      <div
        style={{
          marginTop: "10px",
          ...(loading ? { filter: "blur(4px)" } : {}),
          ...(error ? errorBorder : {}),
        }}
      >
        <ContactCardEntry
          vcfUrl={attachment.url}
          // For now, downloading a contact card is less desirable than copying the text.
          // onClick={() => { window.open(attachment.url, "Download"); }}
        />
      </div>
    );
  } else {
    return (
      <Card
        onClick={() => {
          window.open(attachment.url, "Download");
        }}
        sx={{
          maxWidth: 345,
          ...(loading ? { filter: "blur(3px)" } : {}),
          ...(error ? errorBorder : {}),
        }}
      >
        <CardActionArea>
          <CardHeader
            avatar={<FileDownload />}
            title={attachment.filename}
            subheader={humanFileSize(attachment.fileSize)}
          />
        </CardActionArea>
      </Card>
    );
  }
};
