import { FactStub } from "../../protogen/facts_pb";
import { Family } from "../../protogen/advisors_service_pb";
import { formatUSPhoneNumber } from "../../common/utils";

export const createMemberFactStubs = (family: Family): FactStub[] => {
  const memberFacts = family.familyMembers.map((member) => {
    const values = [
      `Phone: ${formatUSPhoneNumber(member.primaryPhone)}`,
      `Email: ${member.primaryEmail}`,
      `Alternate Emails: ${member.alternateEmails.join(", ")}`,
      `Timezone: ${member.timezone}`,
    ];
    return new FactStub({
      ref: member.ref,
      name: `${member.firstName} ${member.lastName}`,
      valueType: "member",
      value: values.join("\n"),
      attachments: [],
    });
  });

  return memberFacts;
};
