import { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import auth from "../../../components/nux/auth";
import { useFinishMemberNux } from "../../..//services/nux";
import NuxContentLayout from "../../../components/nux/NuxContentLayout";
import useIsMobile from "../../..//components/hooks/useIsMobile";
import { useGetMemberDetails } from "../../../services/member";
import AdvisorNote from "../../../components/nux/member/AdvisorNote";
import { NuxDetailsContext } from "../../../components/context/AdvisorNuxContext";

export default () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const nuxDetails = useContext(NuxDetailsContext);
  const [welcomeMessage, setWelcomeMessage] = useState<string>("");
  const { request } = useFinishMemberNux(() => {
    navigate("/");
  });

  const { request: getMemberRequest, data } = useGetMemberDetails();
  useEffect(() => {
    getMemberRequest();
  }, []);

  useEffect(() => {
    try {
      const nuxState = JSON.parse(nuxDetails?.state);
      setWelcomeMessage(nuxState.welcomeMessage);
    } catch (e) {
      setWelcomeMessage("");
    }
  }, [nuxDetails]);

  const onContinue = () => {
    request({
      token: auth.getAuthData(),
    });
  };

  return (
    <NuxContentLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "36px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <Typography variant="title">
            Hooray, we’re all set to go! 🎉
          </Typography>
        </Box>
        <AdvisorNote advisor={data?.primaryAdvisor} message={welcomeMessage} />
        <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
          <Typography variant="h4">What’s next?</Typography>
          <Typography variant="body" color="#3D3D3D">
            You now have access to the platform. Check it out to add more
            profile information and see your shared task list with{" "}
            {data?.primaryAdvisor?.firstName || "your advisor"}.
          </Typography>
        </Box>
        <Box sx={{ alignSelf: "flex-end", width: isMobile ? "100%" : "auto" }}>
          <Button sx={{ width: "100%" }} onClick={onContinue}>
            Let's go
          </Button>
        </Box>
      </Box>
    </NuxContentLayout>
  );
};
