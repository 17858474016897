import { Button, ListItemAvatar } from "@mui/material";
import { useCreateComment } from "../../services/forum";
import { useContext, useState } from "react";
import { ForumComment } from "../../protogen/forum_service_pb";
import ListItem from "@mui/material/ListItem";
import { UserAvatar } from "../common/CurrentUserAvatar";
import { CurrentUserContext } from "../context/RequireAuth";
import { RichContent } from "../editor/utils";
import SimpleEditor from "../editor/SimpleEditor";
import useIsMobile from "../hooks/useIsMobile";

type Props = {
  postRef: string;
  parentRef?: string | null;
  onCreate: (c: ForumComment) => void;
};

export default ({ postRef, parentRef = null, onCreate }: Props) => {
  const currentUser = useContext(CurrentUserContext);
  const isMobile = useIsMobile();
  const [content, setContent] = useState<RichContent | null>(null);
  const [commentKey, setCommentKey] = useState("");
  const [uploadsInProgress, setUploadsInProgress] = useState(false);
  const { request, loading } = useCreateComment((r) => {
    setContent(null);
    onCreate(r.comment!);
    setCommentKey(r.comment!.ref);
  });

  const handleComment = () => {
    if (!content) return;
    request({
      postRef,
      parentRef: parentRef || "",
      body: {
        contentType: "json",
        payload: content.json,
        textContent: content.text,
        attachments: content.attachments,
      },
    });
  };
  return (
    <ListItem
      sx={{
        marginTop: "16px",
        marginBottom: "16px",
        padding: "8px 0",
      }}
    >
      <ListItemAvatar>
        <UserAvatar user={currentUser} size={40} />
      </ListItemAvatar>
      <SimpleEditor
        key={commentKey}
        disabled={loading}
        setContent={setContent}
        setUploadsInProgress={setUploadsInProgress}
        attachmentsEnabled={true}
        defaultInlineAttachments={true}
        onKeyDown={(_, e: KeyboardEvent) => {
          if (e.key === "Enter" && e.metaKey) {
            handleComment();
          }
        }}
        primaryAction={
          <Button
            size="small"
            onClick={handleComment}
            disabled={loading || !content || uploadsInProgress}
          >
            Reply
          </Button>
        }
        stackActions={isMobile}
      />
    </ListItem>
  );
};
