import { mergeAttributes, Node } from "@tiptap/core";
import { NodeViewWrapper, ReactNodeViewRenderer } from "@tiptap/react";
import { Box } from "@mui/system";
import { IconButton, Typography } from "@mui/material";
import { X } from "lucide-react";
import { _NodeViewProps } from "./utils";
import { formatUSPhoneNumber, getDomainFromURL } from "../../../common/utils";

const Component = ({ node, ...otherProps }: _NodeViewProps) => {
  return (
    <NodeViewWrapper className="react-component-product">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        sx={{
          borderRadius: "4px",
          border: "1px solid #E2E3E4",
          marginTop: "8px",
          gap: "18px",
          padding: "10px 12px",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => window.open(node.attrs.url, "_blank")}
        // Enable dragging for this node.
        // https://github.com/ueberdosis/tiptap/issues/2597
        draggable="true"
        data-drag-handle=""
      >
        {node.attrs.thumbnailUrl && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src={node.attrs.thumbnailUrl}
              alt={node.attrs.title}
              style={{
                width: "60px",
                height: "60px",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
            <Typography
              color="#6B6E7B"
              variant="bodySmall"
              sx={{
                textAlign: "right",
                textDecoration: "underline",
              }}
            >
              {getDomainFromURL(node.attrs.url)}
            </Typography>
          </Box>
        )}
        <Box display="flex" flexDirection="column" gap="4px" flexGrow={1}>
          <Typography variant="bodySmallHeavy">{node.attrs.title}</Typography>
          <Typography variant="bodySmall">{node.attrs.description}</Typography>
          {(node.attrs.phone || node.attrs.address) && (
            <Box display={"flex"} flexDirection={"column"} gap={"4px"}>
              {node.attrs.phone && (
                <Typography color="#6B6E7B" variant="bodySmall">
                  {formatUSPhoneNumber(node.attrs.phone)}
                </Typography>
              )}
              {node.attrs.address && (
                <Typography color="#6B6E7B" variant="bodySmall">
                  {node.attrs.address}
                </Typography>
              )}
            </Box>
          )}
        </Box>
        <Box alignSelf="start">
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              otherProps.deleteNode();
            }}
          >
            <X size={24} />
          </IconButton>
        </Box>
      </Box>
    </NodeViewWrapper>
  );
};

export interface ExtOptions {
  // inline: boolean;
}

export default Node.create<ExtOptions>({
  name: "productLinkExtension",
  group: "block",
  atom: true,
  draggable: true,
  selectable: true,

  addAttributes() {
    return {
      identifier: {
        default: null,
      },
      title: {
        default: null,
      },
      description: {
        default: null,
      },
      url: {
        default: null,
      },
      thumbnailUrl: {
        default: null,
      },
      phone: {
        default: null,
      },
      address: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "react-component-product",
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return ["react-component-product", mergeAttributes(HTMLAttributes)];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Component);
  },
});
