import {
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
  Typography,
  MenuItem,
} from "@mui/material";
import { ReactElement, RefObject } from "react";
import { Pencil, Pin, PinOff, Trash2 } from "lucide-react";

export default ({
  open,
  anchorRef,
  handleClose,
  children,
}: {
  open: boolean;
  anchorRef: RefObject<HTMLButtonElement>;
  handleClose: (event: Event) => void;
  children: ReactElement | ReactElement[];
}) => {
  return (
    <Popper
      sx={{ zIndex: 1 }}
      open={open}
      anchorEl={anchorRef.current}
      placement="bottom-start"
      modifiers={[
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ]}
      role={undefined}
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom" ? "center top" : "center bottom",
          }}
        >
          <Paper
            sx={{
              display: "flex",
              padding: "8px",
              alignItems: "center",
              gap: "8px",
              borderRadius: "12px",
              border: "2px solid #EAEBEC",
              background: "#FFF",
              boxShadow: "0px 2px 8px 0px rgba(151, 93, 51, 0.07)",
              minWidth: "160px",
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList>{children}</MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  );
};

const getOptionIcon = (iconName: string) => {
  switch (iconName) {
    case "pencil":
      return <Pencil size={20} color="#8E9598" />;
    case "trash":
      return <Trash2 size={20} color="#8E9598" />;
    case "pin":
      return <Pin size={20} color="#8E9598" />;
    case "pinOff":
      return <PinOff size={20} color="#8E9598" />;
    default:
      return null;
  }
};

export const createMenuOption = (
  title: string,
  onClick: () => void,
  iconName: string,
) => {
  return (
    <MenuItem
      sx={{ display: "flex", gap: "12px" }}
      onClick={onClick}
      key={title}
    >
      {getOptionIcon(iconName)}
      <Typography color="#3D3D3D" variant="body">
        {title}
      </Typography>
    </MenuItem>
  );
};
