import { useContext, useState, useEffect } from "react";
import { Alert, Box, Grid, Paper, Typography } from "@mui/material";
import AuthService from "../services/auth";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SWRegistrationContext } from "../components/context/AddServiceWorker";

export default () => {
  const { token } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const tokenVal = queryParams.get("token") || token || "";
  const navigate = useNavigate();
  const { subscription } = useContext(SWRegistrationContext);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // If this device is already subscribed to notifications, we should send that along.
    const subscriptionString = subscription
      ? JSON.stringify(subscription.toJSON())
      : null;
    AuthService.memberLogin(tokenVal, subscriptionString).then(
      (data) => {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(data?.redirectUrl || "/", { replace: true });
        setError(null);
        // navigate(0); // Note - this still is stored ephemerally rather than persisted in the URL.
        // window.location.reload();
      },
      (error) => {
        const resMessage =
          error?.response?.data || error.message || error.toString();
        setError(resMessage);
      },
    );
  }, []);

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid container justifyContent="center">
        <Grid item xs={12} sm={8} md={6}>
          <Paper elevation={3} sx={{ padding: "2rem", maxWidth: "500px" }}>
            <Typography variant="display">Logging In</Typography>
            <form>
              {error && (
                <Alert severity="error" style={{ marginTop: 16 }}>
                  {error}
                </Alert>
              )}
            </form>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
