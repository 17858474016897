import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import Carousel from "react-material-ui-carousel";
import { useLocation } from "react-router-dom";
import auth from "../../../components/nux/auth";
import {
  useConfirmMemberLanding,
  useCreateMemberNuxChangeEvent,
} from "../../..//services/nux";
import { getStepPath } from "../../../components/nux/utils";
import NuxContentLayout from "../../../components/nux/NuxContentLayout";
import { ReactComponent as FayeIcon } from "../../../icons/FayeBrand.svg";
import useIsMobile from "../../..//components/hooks/useIsMobile";
import { USER_NUX_TYPE } from "../../../components/nux/constants";
import AuthService from "../../../services/auth";
import { ChevronRight, ChevronLeft } from "lucide-react";

type CarouselItem = {
  imagePath: string;
  description: string;
  alt: string;
};

const CAROUSEL_GROUP_SIZE = 3;

const CAROUSEL_ITEMS: CarouselItem[] = [
  {
    imagePath: "../../../assets/images/nux/client-nux-present.png",
    description: "Buy and wrap birthday present",
    alt: "present",
  },
  {
    imagePath: "../../../assets/images/nux/client-nux-furniture.png",
    description: "Research furniture upholstery companies",
    alt: "furniture",
  },
  {
    imagePath: "../../../assets/images/nux/client-nux-bouquet.png",
    description: "Cupcake floral bouquet for a bridal shower",
    alt: "bouquet",
  },
  {
    imagePath: "../../../assets/images/nux/client-nux-cake.png",
    description: "Custom cake for a mermaid birthday party",
    alt: "cake",
  },
  {
    imagePath: "../../../assets/images/nux/client-nux-flowers.png",
    description: "Buy last minute floral arrangement",
    alt: "flowers",
  },
  {
    imagePath: "../../../assets/images/nux/client-nux-basket.png",
    description: 'Put together a "feel better" package',
    alt: "basket",
  },
  {
    imagePath: "../../../assets/images/nux/client-nux-card.png",
    description: "Teacher's appreciation gift",
    alt: "card",
  },
];

const groupCarouselItems = (items: any[], groupSize: number) => {
  let groups = [];
  for (let i = 0; i < items.length - groupSize + 1; i++) {
    groups.push(items.slice(i, i + groupSize));
  }
  return groups;
};

const CarouselCard = ({ item }: { item: CarouselItem }) => {
  return (
    <Box
      sx={{
        marginBottom: "6px",
        border: "1px solid #D4D4D4",
        borderRadius: "12px",
      }}
    >
      <img src={item.imagePath} alt={item.alt} style={{ height: "219px" }} />
      <Box
        sx={{
          dsiplay: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "16px",
          textAlign: "center",
        }}
      >
        <Typography>{item.description}</Typography>
      </Box>
    </Box>
  );
};

const CarouselGroup = ({ items }: { items: CarouselItem[] }) => {
  return (
    <Box sx={{ display: "flex", gap: "16px" }}>
      {items.map((item, i) => {
        return <CarouselCard key={i} item={item} />;
      })}
    </Box>
  );
};

const TaskCarousel = () => {
  const isMobile = useIsMobile();

  const groupedItems = groupCarouselItems(CAROUSEL_ITEMS, CAROUSEL_GROUP_SIZE);

  return (
    <Box>
      {isMobile ? (
        <Box
          sx={{
            paddingLeft: "24px",
            paddingRight: "24px",
            display: "flex",
            overflowX: "auto",
            marginLeft: "-24px",
            width: "calc(100% + 48px)",
            gap: "16px",
          }}
        >
          {CAROUSEL_ITEMS.map((item, i) => {
            return <CarouselCard key={i} item={item} />;
          })}
        </Box>
      ) : (
        <Carousel
          fullHeightHover={false}
          height={308}
          navButtonsAlwaysVisible
          autoPlay={false}
          cycleNavigation={false}
          animation="slide"
          indicators={false}
          NextIcon={<ChevronRight size={24} color="#8E9598"></ChevronRight>}
          PrevIcon={<ChevronLeft size={24} color="#8E9598"></ChevronLeft>}
          navButtonsProps={{
            style: {
              backgroundColor: "#FFF",
              border: "1px solid #D4D4D4",
              borderRadius: "100px",
            },
          }}
        >
          {groupedItems.map((items, i) => {
            return <CarouselGroup key={i} items={items} />;
          })}
        </Carousel>
      )}
    </Box>
  );
};

export default () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { request, loading } = useConfirmMemberLanding((r) => {
    if (r?.nuxDetails?.stepName) {
      const path = getStepPath(r.nuxDetails.stepName, USER_NUX_TYPE.MEMBER);
      navigate(path);
    }
  });

  const { request: createMemberNuxChangeEvent } =
    useCreateMemberNuxChangeEvent();

  const onContinue = () => {
    request({
      token: auth.getAuthData(),
    });
  };

  useEffect(() => {
    const token = queryParams.get("token");
    const authData = auth.getAuthData();
    if (token) {
      auth.setAuthData(token);
      AuthService.memberNuxLogin(token)
        .catch(() => {
          navigate("/welcome/error");
        })
        .then(() => {
          createMemberNuxChangeEvent({
            name: "page_load",
            value: "landing",
            token: auth.getAuthData(),
          });
        });
      return;
    } else if (authData !== "") {
      createMemberNuxChangeEvent({
        name: "page_load",
        value: "landing",
        token: auth.getAuthData(),
      });
      return;
    } else {
      navigate("/login");
    }
  }, []);

  return (
    <NuxContentLayout>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          gap: "48px",
        }}
      >
        <FayeIcon
          style={{
            height: "48px",
            width: "82px",
          }}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="title" sx={{ marginBottom: "24px" }}>
            Welcome! We’re excited you’re here!
          </Typography>
          <Typography variant="body" sx={{ marginBottom: "8px" }}>
            Life is about to get easier because your Family Advisor is ready to
            hit the ground running.
          </Typography>
          <Typography variant="body">
            To get started, we’re going to ask you for some account and payment
            information — and get you thinking about the first to-dos you want
            to delegate!
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            width: "100%",
          }}
        >
          <Typography variant="h4">
            The Family Advisor’s super power? Making magic happen for clients.
          </Typography>
          <TaskCarousel />
        </Box>
        <Box sx={{ alignSelf: "flex-end", width: isMobile ? "100%" : "auto" }}>
          <Button
            sx={{ width: "100%" }}
            onClick={onContinue}
            disabled={loading}
          >
            Let's go
          </Button>
        </Box>
      </Box>
    </NuxContentLayout>
  );
};
