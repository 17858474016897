import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useGetTask } from "../services/tasks";
import Loading from "../components/common/Loading";
import { Box } from "@mui/system";
import useIsMobile from "../components/hooks/useIsMobile";
import TaskDetailsPanel from "../components/tasks/TaskDetailsPanel";
import Breadcrumbs from "../components/common/Breadcrumbs";
import { Task } from "../protogen/tasks_pb";
import NotFound from "../components/NotFound";
import TaskDropDown from "../components/tasks/TaskDropDown";

export default () => {
  let params = useParams();
  const [task, setTask] = useState<Task | null>(null);
  const { data, request, error } = useGetTask((r) => {
    setTask(r.task);
  });
  const isMobile = useIsMobile();
  useEffect(() => {
    request({ taskRef: params.taskRef!, markTaskNotificationsRead: true });
  }, [params.taskRef]);

  if (error) return <NotFound title={"Task not found"} />;
  if (!task || !data) return <Loading />;

  return (
    <Box
      sx={{
        margin: isMobile ? "" : "64px min(7%, 100px)",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "0",
      }}
    >
      <Box display="flex" flexDirection="row" maxWidth="850px">
        <Box display="flex" flexDirection="column" width="100%">
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Breadcrumbs
              breadcrumbs={[
                {
                  name: task?.family?.name,
                  link: `/families/${encodeURIComponent(
                    task?.family?.ref || "",
                  )}`,
                },
                { name: "Tasks", back: true },
              ]}
            />
            <TaskDropDown
              task={task}
              refresh={() => {
                request({
                  taskRef: task?.ref!,
                  markTaskNotificationsRead: false,
                });
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <TaskDetailsPanel
          task={task}
          family={data?.family}
          updateTask={(t) => {
            setTask(t);
          }}
        />
      </Box>
    </Box>
  );
};
