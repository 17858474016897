import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../components/common/Loading";
import FlexPage from "../components/layout/FlexPage";
import { useGetMemberDetails } from "../services/member";
import { createTaskHelper } from "../components/tasks/utils";
import { useListAllMemberTaskInspirations } from "../services/tasks";

export default () => {
  const navigate = useNavigate();
  const { taskRef } = useParams();
  const { data, request: listAllTaskInspirationRequest } =
    useListAllMemberTaskInspirations(() => {});
  const { request: memberDetailsRequest, data: memberDetails } =
    useGetMemberDetails(() => {});
  const assigneeRef = memberDetails?.primaryAdvisor?.ref || "";
  const familyRef = memberDetails?.family?.ref || "";
  const { createTask } = createTaskHelper({
    referral: "task-inspiration",
    creatorType: "member",
    assigneeRef,
  });

  useEffect(() => {
    listAllTaskInspirationRequest();
    memberDetailsRequest();
  }, []);

  useEffect(() => {
    if (!data || !memberDetails) return;
    const task = data.taskInspirations.find((t) => t.ref === taskRef);
    if (!task) {
      navigate("/");
      return;
    }
    createTask({
      familyRef,
      title: task.title,
      sourceEntityType: "task_inspiration",
      sourceEntityRef: task.ref,
    });
  }, [data, memberDetails]);

  return (
    <FlexPage leftAligned sx={{ width: "100%" }}>
      <Loading />
    </FlexPage>
  );
};
