import { Box, Button } from "@mui/material";
import { Editor, BubbleMenu } from "@tiptap/react";
import { EditorView } from "@tiptap/pm/view";
import { useCreateShortUrl } from "../../services/urlShortener";
import { SHORT_URL_REGEX } from "./utils";

export default ({ editor }: { editor: Editor }) => {
  const { request } = useCreateShortUrl();
  const shouldShow = ({ view }: { view: EditorView }) => {
    const { from, to } = view.state.selection;
    const selectedText = view.state.doc.textBetween(from, to);

    const isUrl = SHORT_URL_REGEX.test(selectedText);
    if (selectedText.includes("fayeurl.io")) return false;
    if (selectedText.includes("localhost:3001")) return false;
    return isUrl;
  };
  const shortenUrl = async () => {
    const { from, to } = editor.state.selection;
    const selectedText = editor.state.doc.textBetween(from, to);
    const response = await request({ url: selectedText });
    if (response?.shortUrl) {
      editor
        .chain()
        .focus()
        .deleteRange({ from, to })
        .insertContent(response.shortUrl)
        .run();
    }
  };
  return (
    <>
      <BubbleMenu
        shouldShow={shouldShow}
        editor={editor}
        tippyOptions={{ duration: 100 }}
      >
        <Box
          sx={{
            backgroundColor: "#FFF",
            padding: "0px 28px",
            border: "1px solid #D4D4D4",
            borderRadius: "8px",
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: "-20px",
              left: "50%",
              transform: "translateX(-50%)",
              borderWidth: "10px",
              borderStyle: "solid",
              borderColor: "#D4D4D4 transparent transparent transparent",
            },
          }}
        >
          <Button variant="text" size="small" onClick={shortenUrl}>
            Shorten URL
          </Button>
        </Box>
      </BubbleMenu>
    </>
  );
};
