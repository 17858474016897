import AutorenewIcon from "@mui/icons-material/Autorenew";
import { Typography } from "@mui/material";
import { isYesterday, isThisPastWeek } from "../../common/utils";
import { differenceInMinutes, format, isThisYear } from "date-fns";

const _relativeUpdatedTime = (dte: Date): string => {
  const now = new Date();
  const minutesDifference = differenceInMinutes(now, dte);
  if (minutesDifference < 5) {
    return "just now";
  } else if (minutesDifference < 55) {
    return `${minutesDifference} minutes ago now`;
  } else if (minutesDifference < 90) {
    return `an hour ago`;
  } else if (minutesDifference < 12 * 60) {
    return `${Math.ceil(minutesDifference / 60)} hours ago`;
  } else if (isYesterday(dte)) {
    return `Yesterday, ${format(dte, `h:mmaaa`)}`;
  } else if (isThisPastWeek(dte)) {
    return format(dte, `eee, h:mmaaa`);
  } else if (isThisYear(dte)) {
    return format(dte, `MMM d, h:mmaaa`);
  } else {
    return format(dte, `MMM d, yyyy`);
  }
};

type Props = {
  lastUpdatedSec: number | null;
  saving?: boolean;
  hasError?: boolean;
};
export default ({
  lastUpdatedSec,
  saving = false,
  hasError = false,
}: Props) => {
  const updateTime = lastUpdatedSec
    ? _relativeUpdatedTime(new Date(lastUpdatedSec * 1000))
    : null;

  return (
    <div
      style={{
        fontSize: "12px",
        fontStyle: "italic",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
      }}
    >
      {!saving && !hasError && updateTime && `Updated ${updateTime}`}
      {saving && (
        <>
          <AutorenewIcon fontSize="small" /> Saving...
        </>
      )}
      {!saving && hasError && (
        <Typography variant="bodySmall" color="error">
          Error saving
        </Typography>
      )}
    </div>
  );
};
