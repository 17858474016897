import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Avatar, Badge, Box } from "@mui/material";
import { CurrentUserContext } from "../context/RequireAuth";
import { CallContext } from "../context/AddCallContext";
import { PhoneState } from "../../types/phone";
import { CurrentUser } from "../../protogen/auth_pb";
import { Advisor, PublicAdvisor } from "../../protogen/common_pb";
import { AccountStub } from "../../protogen/common_pb";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { withImageProps } from "./SignedImageCache";

export enum AvatarBadgeType {
  NONE = 0,
  PHONE_STATE = 1,
  FILE_UPLOAD = 2,
}

const PhoneStateBadge = styled(Badge)(({ theme, color }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: color,
    color: color,
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const FileUploadBadge = ({
  user,
  size,
  autoscale,
  onUpload,
}: {
  user: CurrentUser | AccountStub | Advisor | PublicAdvisor;
  size?: number;
  autoscale?: boolean;
  onUpload?(e: FileList | null): void;
}) => {
  return (
    <Badge
      badgeContent={
        <Box>
          <input
            type="file"
            style={{ display: "none", height: "100%", width: "100%" }}
            onChange={(e) => {
              if (!onUpload) return;
              onUpload(e.target.files);
            }}
            id="input-file-upload"
          />
          <label htmlFor="input-file-upload">
            <Box
              sx={{
                cursor: "pointer",
                border: "2px solid #D0D5DD",
                height: "40px",
                width: "40px",
                backgroundColor: "#FFFFFF",
                borderRadius: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <EditOutlinedIcon sx={{ color: "#6B6E7B" }} fontSize="medium" />
            </Box>
          </label>
        </Box>
      }
      overlap="circular"
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      sx={{ width: "100%", aspectRatio: "1/1" }}
    >
      <UserAvatar user={user} size={size} autoscale={autoscale} />
    </Badge>
  );
};

export const UserAvatar = ({
  user,
  size = 40,
  autoscale = false,
  publicLink = false,
  clickable = true,
  placeholder = undefined,
}: {
  user: CurrentUser | AccountStub | Advisor | PublicAdvisor;
  size?: number;
  autoscale?: boolean;
  clickable?: boolean;
  publicLink?: boolean;
  placeholder?: React.ReactNode;
}) => {
  const navigate = useNavigate();
  const imgProps = withImageProps({
    url: "avatarUrl" in user ? user.avatarUrl : "",
  });
  return (
    <Box
      style={{
        width: autoscale ? "100%" : size,
        position: "relative",
        aspectRatio: "1/1",
      }}
    >
      <Avatar
        onClick={() => {
          if (!clickable) return;
          navigate(
            publicLink && "profilePath" in user
              ? user.profilePath
              : `/advisor/${user.ref}`,
          );
        }}
        src={imgProps.src}
        imgProps={imgProps}
        // This is so we can have a proportional 1/1 avatar with variable width on mobile
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "#F2F4F7",
          color: "#475467",
          textAlign: "center",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "28px",
          cursor: clickable ? "pointer" : "default",
        }}
      >
        {placeholder ? (
          placeholder
        ) : (
          <Box>
            {user.firstName[0]}
            {user.lastName[0]}
          </Box>
        )}
      </Avatar>
    </Box>
  );
};

export const DefaultAvatar = ({
  size = 40,
  autoscale = false,
}: {
  size?: number;
  autoscale?: boolean;
}) => {
  return (
    <Box
      style={{
        width: autoscale ? "100%" : size,
        position: "relative",
        aspectRatio: "1/1",
      }}
    >
      <Avatar
        sx={{
          height: autoscale ? 48 : size,
          width: autoscale ? 48 : size,
          background: "#F2F4F7",
          color: "#475467",
          textAlign: "center",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: "28px",
        }}
        alt={"Profile not present"}
      />
    </Box>
  );
};

export default ({
  size = 40,
  user,
  loading,
  autoscale,
  badgeType = AvatarBadgeType.NONE,
  onUpload,
  clickable = true,
  placeholder = undefined,
  publicLink = false,
}: {
  size?: number;
  user?: CurrentUser | AccountStub | Advisor | PublicAdvisor;
  loading?: boolean;
  autoscale?: boolean;
  badgeType?: AvatarBadgeType;
  onUpload?(e: FileList | null): void;
  clickable?: boolean;
  placeholder?: React.ReactNode;
  publicLink?: boolean;
}) => {
  const currentUser = user || useContext(CurrentUserContext);
  const {
    device: { phoneState },
  } = useContext(CallContext);
  let color: "error" | "warning" | "success" = "success";
  if (phoneState === PhoneState.FAILED) {
    color = "error";
  } else if (phoneState === PhoneState.NOT_CONFIGURED) {
    color = "warning";
  }

  return (
    <Box>
      {badgeType === AvatarBadgeType.NONE &&
        (loading || (user === undefined && currentUser === undefined) ? (
          <DefaultAvatar size={size} autoscale={autoscale} />
        ) : (
          <UserAvatar
            user={currentUser}
            size={size}
            autoscale={autoscale}
            clickable={clickable}
            placeholder={placeholder}
            publicLink={publicLink}
          />
        ))}
      {badgeType === AvatarBadgeType.PHONE_STATE && (
        <PhoneStateBadge
          overlap="circular"
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          variant="dot"
          color={color}
        >
          <UserAvatar user={currentUser} size={size} autoscale={autoscale} />
        </PhoneStateBadge>
      )}
      {badgeType === AvatarBadgeType.FILE_UPLOAD && (
        <FileUploadBadge
          user={currentUser}
          size={size}
          autoscale={autoscale}
          onUpload={onUpload}
        />
      )}
    </Box>
  );
};
