// API with JWT middleware as suggested by this blog:
// https://www.bezkoder.com/react-refresh-token/

import axios, { AxiosResponse, AxiosError, isAxiosError } from "axios";
import TokenStore from "./TokenStore";
import { getApiDomain } from "../constants";
import { CurrentUser } from "../protogen/auth_pb";

const instance = axios.create({
  baseURL: getApiDomain(),
  headers: {
    "Content-Type": "application/json",
  },
  // Triggers the browser to include our cookie.
  withCredentials: true,
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenStore.getLocalAccessToken()?.value;
    if (token) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

const isLoginOrRefresh = (config: any) => {
  // Can probably get rid of this.
  if (config.url === "/auth/login" || config.url === "/auth/refresh")
    return true;
  const data = JSON.parse(config.data || "") || {};
  if (data.method === "Login" || data.method === "RefreshToken") return true;
  return false;
};

const refreshToken = async () => {
  const rs = await instance.post("/proto?method=RefreshToken", {
    method: "RefreshToken",
    payload: {
      refreshToken: TokenStore.getLocalRefreshToken()?.value,
    },
  });

  const { accessToken, currentUser } = rs.data;
  TokenStore.updateAuthData(accessToken, new CurrentUser(currentUser));
};

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error: AxiosError) => {
    const originalConfig = error.config;
    if (
      originalConfig &&
      !isLoginOrRefresh(originalConfig) &&
      // Note: originalConfig._retry is not getting set for some reason.
      // @ts-ignore
      !originalConfig._retry &&
      error.response &&
      error.response.status === 401 &&
      TokenStore.getLocalRefreshToken()
    ) {
      // Access Token was expired
      // @ts-ignore
      originalConfig._retry = true;

      try {
        await refreshToken();
        return instance(originalConfig);
      } catch (nestedError) {
        if (
          isAxiosError(nestedError) &&
          nestedError?.response?.status === 401
        ) {
          // Lets reset the state.
          TokenStore.clearAuthData();
          window.location.reload();
        }
        return Promise.reject(nestedError);
      }
    }

    return Promise.reject(error);
  },
);
export default instance;
export { refreshToken };
