import * as React from "react";

import { createContext, useEffect, useMemo, useState } from "react";
import {
  Family,
  FetchAdvisorDashboardResponse_FamilyStatus,
} from "../../protogen/advisors_service_pb";
import { useFetchAdvisorDashboard } from "../../services/advisor";
import { useLocation } from "react-router-dom";
import { usePushContext } from "./PushContextProvider";
import useIsVisible from "../hooks/useIsVisible";
import { debounce } from "lodash";

type contentType = {
  families: Family[];
  familyStatus: Map<string, FetchAdvisorDashboardResponse_FamilyStatus>;
  badgeCount: number | null;
  emailUnreadCount: number;
  phoneUnreadCount: number;
  messageUnreadCount: number;
  taskUnreadCount: number;
  datebookUnreadCount: number;
  // unreadForum: number;
  loaded: boolean;
  loading: boolean;
  // Last updated
};

const defaultContext = () => ({
  families: [],
  familyStatus: new Map(),
  badgeCount: null,
  emailUnreadCount: 0,
  phoneUnreadCount: 0,
  messageUnreadCount: 0,
  taskUnreadCount: 0,
  datebookUnreadCount: 0,
  // unreadForum: number;
  loaded: false,
  loading: false,
});

export const StatusContext = createContext<contentType>(defaultContext());

export default ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const { registerHandler, unregisterHandler } = usePushContext();
  const [context, setContext] = useState<contentType>(defaultContext());
  const { request } = useFetchAdvisorDashboard((r) => {
    const familyStatus = new Map(
      (r.familyStatus || []).map((s) => [s.familyRef, s]),
    );
    // Let's not do this everytime, I think apple can get upset if you do this in iOS.
    if (r.badgeCount !== context.badgeCount && "setAppBadge" in navigator) {
      // @ts-ignore
      navigator.setAppBadge(r.badgeCount || 0);
    }
    setContext({
      families: r.families,
      familyStatus: familyStatus,
      badgeCount: r.badgeCount,
      emailUnreadCount: r.emailUnreadCount,
      phoneUnreadCount: r.phoneUnreadCount,
      messageUnreadCount: r.messageUnreadCount,
      taskUnreadCount: r.taskUnreadCount,
      datebookUnreadCount: r.datebookUnreadCount,
      // It has been loaded at least once.
      loaded: true,
      loading: false,
    });
  });

  const refresh = () => {
    request();
    setContext((prev) => ({
      ...prev,
      loading: true,
    }));
  };

  // Reload the state whenever the page is changed or we have re-opened the page after some period.
  // Add a debounce + delay. Delay is so that we can let users fire off quick events like markAsRead
  // and then we can consume the most up to date data (like the newest badge count).
  const debouncedRefresh = useMemo(
    () => debounce(refresh, 500, { trailing: true }), // Adjust the delay as needed (e.g., 1000ms = 1 second)
    [],
  );
  useIsVisible({
    onRefocus: async (blurSecs) => {
      if (blurSecs > 10) {
        debouncedRefresh();
      }
    },
  });
  // This might need to get tweaked, but we don't want
  // to refetch on single location change since things like search params might change frequently.
  useEffect(debouncedRefresh, [location.pathname]);

  useEffect(() => {
    const pushHandler = () => refresh();
    registerHandler(pushHandler);

    return () => {
      unregisterHandler(pushHandler);
    };
  }, [registerHandler, unregisterHandler]);

  return (
    <StatusContext.Provider value={context}>{children}</StatusContext.Provider>
  );
};
