import { forwardRef, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { ExternalLink } from "lucide-react";
import BlockGutterMap from "./BlockGutterMap";
import { BlockHandle, BlockProps } from "./utils";
import BlockContainer from "./BlockContainer";
import { Box, Button, Typography } from "@mui/material";
import DateDisplay from "../../common/DateDisplay";
import { Attachment as AttachmentProto } from "../../../protogen/common_pb";
import { useGetTextMessageEntry } from "../../../services/advisor";
import AttachmentComp from "../../common/AttachmentComp";
import AttachmentDialog from "../../common/AttachmentDialog";

const formatTitleForBookmark = (blockContent: any): string => {
  const sender = blockContent.sender.firstName;
  switch (blockContent.conversationMedium) {
    case "sms":
      return `Saved text from ${sender}`;
    case "email":
      return `Saved email from ${sender}`;
    case "phone":
      return `Saved phone call from ${sender}`;
    default:
      return "Message";
  }
};

const formatTitleForCreation = (blockContent: any): string => {
  const sender = blockContent.sender.firstName;
  return `Task created from conversation with ${sender}`;
};

const formatTitle = (blockContent: any): string => {
  if (blockContent.bookmarkType === "task-creation") {
    return formatTitleForCreation(blockContent);
  } else {
    return formatTitleForBookmark(blockContent);
  }
};

const linkToConversation = (blockContent: any, familyRef: string): string => {
  switch (blockContent.conversationMedium) {
    case "sms":
      return `/families/${familyRef}?activityRef=${blockContent.messageRef}`;
    case "email":
      return `/inbox/email/${encodeURIComponent(blockContent.messageRef)}`;
    case "phone":
      return `/calls/${encodeURIComponent(blockContent.messageRef)}`;
    default:
      return "";
  }
};

export default forwardRef<BlockHandle, BlockProps>(
  ({ block, task }: BlockProps, _ref) => {
    const [openAttachment, setOpenAttachment] =
      useState<AttachmentProto | null>(null);
    const [attachments, setAttachments] = useState<AttachmentProto[]>([]);
    const navigate = useNavigate();
    const blockData = JSON.parse(block.content);

    const { request: fetchMessageEntry } = useGetTextMessageEntry(async (r) => {
      setAttachments(r.entry?.textMessage?.attachments || []);
    });

    useEffect(() => {
      const blockData = JSON.parse(block.content);
      if (blockData.conversationMedium === "sms") {
        fetchMessageEntry({ messageRef: blockData.messageRef });
      }
    }, [block]);

    return (
      <BlockContainer
        gutterIcon={BlockGutterMap.message.icon({ user: task.createdBy })}
        isLast={false}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
            alignSelf: "stretch",
            flexDirection: "column",
            alignItems: "flex-start",
            padding: "24px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "4px",
              alignSelf: "stretch",
              flexDirection: "column",
            }}
          >
            <Typography variant="bodyHeavy">
              {formatTitle(blockData)}
            </Typography>
            <DateDisplay date={new Date(blockData.timestamp * 1000)} />
          </Box>
          {blockData.message && (
            <Typography variant="body" sx={{ fontStyle: "italic" }}>
              "{blockData.message}"
            </Typography>
          )}
          {attachments.length > 0 && (
            <Box
              style={{
                gap: "8px",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                marginTop: "8px",
                flexWrap: "wrap",
              }}
            >
              {attachments.map((attachment, i) => (
                <Box
                  key={i}
                  sx={{
                    flex: "1 0 calc(33.333% - 8px)",
                    maxWidth: "calc(33.333% - 8px)",
                  }}
                >
                  <AttachmentComp
                    attachment={attachment}
                    openAttachment={() => setOpenAttachment(attachment)}
                  />
                </Box>
              ))}
            </Box>
          )}
          <Button
            variant={"text"}
            sx={{ height: "unset" }}
            onClick={() =>
              navigate(linkToConversation(blockData, task.familyRef))
            }
            endIcon={<ExternalLink />}
          >
            See conversation
          </Button>
        </Box>
        <AttachmentDialog
          attachment={openAttachment}
          onClose={() => setOpenAttachment(null)}
        />
      </BlockContainer>
    );
  },
);
