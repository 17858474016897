import { useEffect, useState } from "react";
import ReactiveDialog from "../common/ReactiveDialog";
import { Advisor } from "../../protogen/common_pb";
import { Alert, Box } from "@mui/material";
import ChipsField from "../family/ChipsField";
import { isEqual } from "lodash";
import { useUpdateRoles } from "../../services/admin";

const checkRole = (role: string) => {
  return /^[A-Z0-9._-]+$/i.test(role);
};

interface Props {
  advisor: Advisor;
  open: boolean;
  onClose: (c?: boolean) => void;
}

export default ({ advisor, open, onClose }: Props) => {
  const [hasUpdated, setHasUpdated] = useState(false);
  const [roles, setRoles] = useState<string[]>(advisor.roles);
  const { request, loading, error } = useUpdateRoles(() => {
    setHasUpdated(true);
  });
  useEffect(() => {
    setRoles(advisor?.roles || []);
    setHasUpdated(false);
  }, [open, advisor]);

  const handleSave = () => {
    request({ advisorRef: advisor.ref, roles: roles });
  };

  const hasChanges = () => {
    return !isEqual(new Set(roles), new Set(advisor.roles));
  };
  return (
    <ReactiveDialog
      fullWidthSize="sm"
      primaryAction={handleSave}
      primaryActionName={"Update"}
      primaryActionEnabled={hasChanges() && !loading}
      open={open}
      onClose={() => onClose(hasUpdated)}
      title="Update Roles"
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          marginTop: "16px",
          height: "100%",
        }}
      >
        <ChipsField
          title="User Roles"
          values={roles}
          setValues={(v) => setRoles(v)}
          validator={checkRole}
        />
        {error && <Alert severity="error">{error}</Alert>}
      </Box>
    </ReactiveDialog>
  );
};
