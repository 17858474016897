import { Task, TaskBlock } from "../../../protogen/tasks_pb";
import {
  useGetTaskBlock,
  useGetMemberTaskBlock,
} from "../../../services/tasks";
import { useEffect, useState } from "react";

export type BlockHandle = {
  isPolling?: () => boolean;
};
export type BlockProps = {
  task: Task;
  block: TaskBlock;
  onUpdate?: (n: bigint) => void;
  accountType?: "advisor" | "member";
  updateTask?: (t: Task) => void;
  onDeleteTaskBlock?: (ref: string) => void;
};

const toMaybeNumber = (n: BigInt | number | null) => {
  if (!n) return null;
  if (typeof n === "number") return n;
  return Number(n);
};

type PollReturn = {
  block: TaskBlock;
  polling: boolean;
  completed: boolean;
  restart: () => void;
};

const usePollForBlockUpdates = ({
  initialBlock,
  isComplete,
  onCompleted,
  pollingSleep = 1000,
  maxIterations = 30,
  accountType = "advisor",
}: {
  initialBlock: TaskBlock;
  isComplete: (t: TaskBlock) => boolean;
  onCompleted: (t: TaskBlock) => void;
  pollingSleep?: number;
  maxIterations?: number;
  accountType?: "advisor" | "member";
}): PollReturn => {
  const { request } =
    accountType === "advisor" ? useGetTaskBlock() : useGetMemberTaskBlock();
  const [polling, setPolling] = useState<boolean>(!isComplete(initialBlock));
  const [completed, setCompleted] = useState<boolean>(isComplete(initialBlock));
  const [block, setBlock] = useState<TaskBlock>(initialBlock);

  useEffect(() => {
    let counter = 0;
    const pollEndpoint = async () => {
      if (completed || !polling || counter >= maxIterations) return;
      counter += 1;

      const resp = await request({ taskBlockRef: initialBlock.ref });
      const complete = resp && isComplete(resp.block);
      if (resp) setBlock(resp.block);
      if (complete) {
        setPolling(false);
        setCompleted(true);
        onCompleted(resp.block);
      } else {
        setTimeout(pollEndpoint, pollingSleep);
      }
      setPolling(!(complete || counter >= maxIterations));
    };

    pollEndpoint();
  }, [initialBlock.ref, completed, polling]);

  const handleRestart = () => {
    setPolling(true);
    setCompleted(false);
  };

  return {
    block: block,
    polling: polling,
    completed: completed,
    restart: handleRestart,
  };
};

export { toMaybeNumber, usePollForBlockUpdates };
