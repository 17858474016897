import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import Secret from "../components/secrets/Secret";
import Breadcrumbs from "../components/common/Breadcrumbs";

export default () => {
  const navigate = useNavigate();
  const { secretRef } = useParams();
  const onSaveHandler = () => {
    navigate(`/family-info`);
  };
  const onCancelHandler = () => {
    navigate(`/family-info`);
  };

  return (
    <Box>
      <Box sx={{ marginTop: "32px", marginLeft: "24px" }}>
        <Breadcrumbs
          breadcrumbs={[
            { name: "Family Info", link: "/family-info" },
            { name: "Secrets" },
          ]}
        />
      </Box>
      <Secret
        secretRef={secretRef || ""}
        onSaveHandler={onSaveHandler}
        onCancelHandler={onCancelHandler}
      />
    </Box>
  );
};
