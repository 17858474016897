import useProtoMethod from "./useProtoMethod";
import {
  FetchMessageInboxRequest,
  FetchMessageInboxResponse,
  FetchMessageThreadRequest,
  FetchMessageThreadResponse,
  GetOrCreateMessageConversationRequest,
  GetOrCreateMessageConversationResponse,
  SendMessageRequest,
  SendMessageResponse,
  UpdateMessageReactionRequest,
  UpdateMessageReactionResponse,
} from "../protogen/messaging_service_pb";

const useFetchMessageInbox = (
  callback?: (r: FetchMessageInboxResponse) => void,
) => {
  return useProtoMethod<FetchMessageInboxRequest, FetchMessageInboxResponse>(
    "FetchMessageInbox",
    FetchMessageInboxResponse,
    {
      callback,
    },
  );
};

const useGetOrCreateMessageConversation = (
  callback?: (r: GetOrCreateMessageConversationResponse) => void,
) => {
  return useProtoMethod<
    GetOrCreateMessageConversationRequest,
    GetOrCreateMessageConversationResponse
  >("GetOrCreateMessageConversation", GetOrCreateMessageConversationResponse, {
    callback,
  });
};

const useSendMessage = (callback?: (r: SendMessageResponse) => void) => {
  return useProtoMethod<SendMessageRequest, SendMessageResponse>(
    "SendMessage",
    SendMessageResponse,
    {
      callback,
    },
  );
};

const useFetchMessageThread = (
  callback?: (r: FetchMessageThreadResponse) => void,
) => {
  return useProtoMethod<FetchMessageThreadRequest, FetchMessageThreadResponse>(
    "FetchMessageThread",
    FetchMessageThreadResponse,
    {
      callback,
    },
  );
};

// UpdateMessageReactionRequest, UpdateMessageReactionResponse
const useUpdateMessageReaction = (
  callback?: (r: UpdateMessageReactionResponse) => void,
) => {
  return useProtoMethod<
    UpdateMessageReactionRequest,
    UpdateMessageReactionResponse
  >("UpdateMessageReaction", UpdateMessageReactionResponse, {
    callback,
  });
};

export {
  useFetchMessageInbox,
  useGetOrCreateMessageConversation,
  useSendMessage,
  useFetchMessageThread,
  useUpdateMessageReaction,
};
