import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useFinishAdvisorNux } from "../../..//services/nux";
import auth from "../../../components/nux/auth";
import NuxContentLayout from "../../../components/nux/NuxContentLayout";
import useIsMobile from "../../..//components/hooks/useIsMobile";

export default () => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { request } = useFinishAdvisorNux(() => {
    navigate("/");
  });
  const onContinue = () => {
    request({
      token: auth.getAuthData(),
    });
  };
  return (
    <NuxContentLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "36px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{ alignSelf: isMobile ? "start" : "center" }}
            variant="title"
          >
            You’re all set up! 🎉
          </Typography>
          <Typography
            sx={{ alignSelf: isMobile ? "start" : "center" }}
            variant="body"
          >
            Whew, you did it! Now it’s time to dive in. Click below to check out
            our Advisor Community forum.
          </Typography>
          <Box
            sx={{
              alignSelf: "center",
              width: isMobile ? "100%" : "auto",
              marginTop: isMobile ? "24px" : "42px",
            }}
          >
            <Button sx={{ width: "100%" }} onClick={onContinue}>
              Get started with Faye
            </Button>
          </Box>
        </Box>
      </Box>
    </NuxContentLayout>
  );
};
