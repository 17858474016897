import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Button } from "@mui/material";
import { SecretStub } from "../../protogen/secrets_pb";
import SecretDialog from "../secrets/SecretDialog";
import { useRevealSecret } from "../../services/secrets";
import SnackPack, { SnackbarMessage } from "../common/SnackPack";
import ConfirmationDialog, {
  useConfirmationDialog,
} from "../common/ConfirmationDialog";
import { EphemeralSecret } from "../secrets/types";
import { attachmentsToUploads } from "../creation/FileUploader";
import SecretList from "../secrets/SecretList";

interface Props {
  title: string;
  secrets: SecretStub[];
  familyRef: string;
}
export default ({ secrets, familyRef }: Props) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [snackPack, setSnackPack] = useState<readonly SnackbarMessage[]>([]);
  const { request } = useRevealSecret((r) => {
    const secret: EphemeralSecret = {
      ref: r?.secret?.ref,
      name: r?.secret?.name || "",
      valueType: r?.secret?.valueType || "",
      value: r.secretValue,
      attachments: r?.attachments || [],
      uploads: attachmentsToUploads(r?.attachments || []),
    };
    setSelectedSecret(secret);
    setDialogOpen(true);
  });
  const confirmState = useConfirmationDialog();
  const [localSecrets, setLocalSecrets] = useState<SecretStub[]>(secrets);
  const [selectedSecret, setSelectedSecret] = useState<EphemeralSecret | null>(
    null,
  );

  const onAccessed = (secret: SecretStub) => {
    request({ secretRef: secret.ref });
    setSnackPack((prev) => [
      ...prev,
      {
        message: `Access Recorded`,
        key: new Date().getTime(),
      },
    ]);
  };
  const handleClose = () => {
    setSelectedSecret(null);
    setDialogOpen(false);
  };

  useEffect(() => {
    const secretRef = searchParams.get("secretRef");
    if (!secretRef) return;

    request({ secretRef: secretRef });

    // Not required but nice to remove the query parameters to avoid popping up the secret if
    // user manually refreshes the page.
    searchParams.delete("secretRef");
    navigate("." + searchParams.toString(), { replace: true });
  }, []);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: "16px",
        alignSelf: "stretch",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button variant="outlined" onClick={() => setDialogOpen(true)}>
          Add Secret
        </Button>
      </Box>
      <SecretList
        secrets={localSecrets}
        onClick={(secret: SecretStub) => {
          onAccessed(secret);
        }}
      />

      <SnackPack
        snackPack={snackPack}
        alertSeverity="warning"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
      <SecretDialog
        open={dialogOpen}
        familyRef={familyRef}
        onClose={handleClose}
        onCreate={(s) => {
          if (s) {
            setLocalSecrets((prevState) => [s, ...prevState]);
          }
          handleClose();
        }}
        onDelete={(ref) => {
          if (ref) {
            setLocalSecrets((prevState) => [
              ...prevState.filter((secret) => secret.ref !== ref),
            ]);
          }
          handleClose();
        }}
        onEdit={(s) => {
          if (s) {
            setLocalSecrets((prevState) => [
              ...prevState.map((secret) => (secret.ref === s.ref ? s : secret)),
            ]);
          }
          handleClose();
        }}
        isEdit={selectedSecret !== null}
        secret={selectedSecret}
      />
      <ConfirmationDialog {...confirmState.dialogProps} />
    </Box>
  );
};
