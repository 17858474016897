import useProtoMethod from "./useProtoMethod";
import {
  CreateMemberInquiryRequest,
  CreateMemberInquiryResponse,
  FetchAdvisorPublicProfileRequest,
  FetchAdvisorPublicProfileResponse,
} from "../protogen/public_service_pb";

const useFetchPublicAdvisorProfile = (
  callback?: (r: FetchAdvisorPublicProfileResponse) => void,
) => {
  return useProtoMethod<
    FetchAdvisorPublicProfileRequest,
    FetchAdvisorPublicProfileResponse
  >("FetchPublicAdvisorProfile", FetchAdvisorPublicProfileResponse, {
    callback,
  });
};

const useCreateMemberInquiry = (
  callback?: (r: CreateMemberInquiryResponse) => void,
) => {
  return useProtoMethod<
    CreateMemberInquiryRequest,
    CreateMemberInquiryResponse
  >("CreateMemberInquiry", CreateMemberInquiryResponse, {
    callback,
  });
};

export { useFetchPublicAdvisorProfile, useCreateMemberInquiry };
