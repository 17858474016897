import { ReactElement, useState, useEffect, useMemo } from "react";
import { Box, Typography, Button } from "@mui/material";
import { ReactComponent as CheckmarkIcon } from "../icons/Member/Checkmark.svg";
import { ReactComponent as LoadingIcon } from "../icons/Member/Loading.svg";
import Loading from "../components/common/Loading";
import FlexPage from "../components/layout/FlexPage";
import useIsMobile from "../components/hooks/useIsMobile";
import Breadcrumbs from "../components/common/Breadcrumbs";
import AddTaskBar from "../components/tasks/AddTaskBar";
import { Task, TaskState } from "../protogen/tasks_pb";
import { useListTasksForMember } from "../services/tasks";
import { useGetMemberDetails } from "../services/member";
import TaskInspiration from "../components/tasks/TaskInspiration";
import MemberTaskSection from "../components/tasks/MemberTaskSection";

const TaskSectionHeader = ({
  title,
  icon,
  count,
}: {
  title: string;
  icon: ReactElement;
  count: number;
}) => {
  return (
    <Box sx={{ display: "flex", gap: "8px", marginBottom: "24px" }}>
      {icon}
      <Typography variant="h2">{title}</Typography>
      <Typography
        variant="bodyHeavy"
        sx={{ color: "#6B6E7B", marginTop: "2px" }}
      >
        {count}
      </Typography>
    </Box>
  );
};

export default () => {
  const isMobile = useIsMobile();
  const [tasks, setTasks] = useState<Task[]>([]);
  const [showDoneTasks, setShowDoneTasks] = useState(false);
  const { request: memberDetailsRequest, data: memberDetails } =
    useGetMemberDetails(() => {});
  const openTasks = useMemo(
    () =>
      tasks.filter(
        (task) =>
          task.state === TaskState.INPROGRESS || task.state === TaskState.OPEN,
      ),
    [tasks],
  );
  const doneTasks = useMemo(
    () =>
      tasks
        .filter((task) => task.state === TaskState.COMPLETED)
        .sort((a, b) => Number(a.createdSec) - Number(b.createdSec)),

    [tasks],
  );
  const { request, data } = useListTasksForMember((r) => {
    setTasks(r.tasks);
  });

  useEffect(() => {
    request();
    memberDetailsRequest();
  }, []);

  return (
    <FlexPage leftAligned sx={{ width: "100%" }}>
      <Breadcrumbs
        breadcrumbs={[{ name: "Home", link: "/" }, { name: "Tasks" }]}
      />
      <Typography
        variant="display"
        sx={{ marginBottom: isMobile ? "24px" : "32px" }}
      >
        Family tasks
      </Typography>
      {!data ? (
        <Loading />
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "48px",
            width: "100%",
          }}
        >
          <AddTaskBar
            familyRef={memberDetails?.family?.ref}
            assigneeRef={memberDetails?.primaryAdvisor?.ref}
            creatorType="member"
            buttonPlacement="visible"
          />
          <Box sx={{ width: "100%" }}>
            <TaskSectionHeader
              title="Open tasks"
              icon={<LoadingIcon height="24px" width="24px" />}
              count={openTasks.length}
            />
            <MemberTaskSection tasks={openTasks} />
          </Box>
          <Box sx={{ width: "100%" }}>
            <TaskSectionHeader
              title="Done"
              icon={<CheckmarkIcon />}
              count={doneTasks.length}
            />
            {showDoneTasks ? (
              <MemberTaskSection tasks={doneTasks} />
            ) : (
              <Button
                variant="outlinedSecondary"
                onClick={() => {
                  setShowDoneTasks(!showDoneTasks);
                }}
              >
                Show {doneTasks.length} finished tasks
              </Button>
            )}
          </Box>
          <TaskInspiration
            familyRef={memberDetails?.family?.ref || ""}
            assigneeRef={memberDetails?.primaryAdvisor?.ref || ""}
            activeTasks={memberDetails?.activeTasks || []}
          />
        </Box>
      )}
    </FlexPage>
  );
};
