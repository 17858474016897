import { useCreateFamilyBilling } from "../../services/billing";
import React, { useState } from "react";
import ReactiveDialog from "../common/ReactiveDialog";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe, PaymentMethod } from "@stripe/stripe-js";

interface StripeFormProps {
  metadata: Record<string, string>;
  onSuccess: (paymentMethod: PaymentMethod) => void;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const StripeForm: React.FC<StripeFormProps> = ({ metadata, onSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) {
      return;
    }
    const billingDetails = {
      name: "John Doe", // Example: You can retrieve this from your form inputs
      email: "john@example.com", // Example: You can retrieve this from your form inputs
      address: {
        // Example: You can retrieve this from your form inputs
        city: "New York",
        country: "US",
        line1: "123 Main Street",
        line2: "",
        postal_code: "10001",
        state: "NY",
      },
    };

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement)!,
      billing_details: billingDetails,
      metadata: metadata,
    });

    setLoading(false);

    if (error) {
      console.error(error);
      setError(error.message || "An unknown error occurred");
    } else {
      console.log("Payment Method:", paymentMethod);
      onSuccess(paymentMethod!);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      {error && <div>{error}</div>}
      <button type="submit" disabled={!stripe || loading}>
        {loading ? "Processing..." : "Pay Now"}
      </button>
    </form>
  );
};

type Props = {
  open: boolean;
  onClose: () => void;
  familyRef: string;
};

export default ({ open, onClose, familyRef }: Props) => {
  const { request, loading } = useCreateFamilyBilling();
  const handleStripeSuccess = async (pm: PaymentMethod) => {
    if (!pm.id) {
      return;
    }
    const data = await request({
      familyRef: familyRef,
      paymentMethodId: pm.id,
    });
    if (data) {
      onClose();
    }
  };
  return (
    <ReactiveDialog
      open={open}
      fullWidthSize="sm"
      onClose={onClose}
      title={"Link Stripe customer account to family"}
      primaryActionEnabled={!loading}
    >
      <p>This will link an existing Stripe customer account to a family.</p>
      <Elements stripe={stripePromise}>
        <StripeForm
          metadata={{ key: "value" }}
          onSuccess={handleStripeSuccess}
        />
      </Elements>
    </ReactiveDialog>
  );
};
