import { useState } from "react";
import ReactiveDialog from "../common/ReactiveDialog";
import { Family, FormErrors, Member, UserPermission } from "./types";
import { cleanPhone, validateFamily, validateFamilyMembers } from "./utils";
import {
  Family as FamilyProto,
  FamilyStatus,
  Member as MemberProto,
  PlatformFeeSchedule,
  UpdateFamilyRequest,
  UpdateFamilyRequest_UpdatedPermissions,
} from "../../protogen/advisors_service_pb";
import Form from "./Form";
import { useUpdateFamily } from "../../services/advisor";
import { PlainMessage } from "@bufbuild/protobuf";
import {
  Advisor,
  UserPermission as UserPermissionProto,
} from "../../protogen/common_pb";
import { isEqual } from "lodash";

const permissionAreEqual = (
  prevPermission: UserPermissionProto,
  nextPermission: UserPermission,
): boolean => {
  return (
    prevPermission.ref === nextPermission.ref &&
    prevPermission.account?.ref === nextPermission.advisorRef &&
    prevPermission.expiration === nextPermission.expiration &&
    prevPermission.active === nextPermission.active &&
    isEqual(prevPermission.actions, nextPermission.actions)
  );
};

const updatedPermissions = (
  prevPermissions: UserPermissionProto[],
  nextPermissions: UserPermission[],
): PlainMessage<UpdateFamilyRequest_UpdatedPermissions>[] => {
  let hasChanges = false;
  const updatedPerms: PlainMessage<UpdateFamilyRequest_UpdatedPermissions>[] =
    [];
  const prevMap = new Map(prevPermissions.map((p) => [p.ref, p]));
  for (const next of nextPermissions) {
    if (next.ref && prevMap.has(next.ref)) {
      if (
        !permissionAreEqual(prevMap.get(next.ref)!, next) ||
        next.reactivate ||
        next.deactivate
      ) {
        hasChanges = true;
      }
      updatedPerms.push({
        ref: next.ref,
        accountRef: next.advisorRef,
        expiration: next.expiration || BigInt(0),
        actions: next.actions,
        deactivate: !!next.deactivate,
        isCreation: false,
        reactivate: !!next.reactivate,
      });
    } else {
      hasChanges = true;
      updatedPerms.push({
        isCreation: true,
        ref: "",
        accountRef: next.advisorRef,
        expiration: next.expiration || BigInt(0),
        actions: next.actions,
        deactivate: false,
        reactivate: false,
      });
    }
  }
  return hasChanges ? updatedPerms : [];
};

interface Props {
  open: boolean;
  onClose: (updated: boolean) => void;
  family: FamilyProto;
  familyOnly?: boolean;
  contactOnly?: string;
  // Should be required.
  primaryAdvisor?: Advisor;
  advisorPermissions?: UserPermissionProto[];
}

const updates = (
  family: FamilyProto,
  familyData: Family,
  memberData: Member[],
  advisorPermissions: UserPermissionProto[],
): PlainMessage<UpdateFamilyRequest> => {
  const familyMemberMap = new Map<string, MemberProto>();
  family.familyMembers.forEach((member: MemberProto) => {
    familyMemberMap.set(member.ref, member);
  });
  return {
    familyRef: family.ref,
    updatedName: family.name === familyData.name ? "" : familyData.name!,
    updatedAdvisorRef:
      family.advisorRef === familyData.advisorRef ? "" : familyData.advisorRef!,
    updatedStatus:
      family.status === familyData.status
        ? FamilyStatus.NOT_SET
        : familyData.status,
    updatedStartDate: familyData.startDate || "",
    clearStartDate: !!familyData.clearStartDate,
    updatedEndDate: familyData.endDate || "",
    clearEndDate: !!familyData.clearEndDate,
    platformFeeSchedule:
      family.platformFeeSchedule === familyData.platformFeeSchedule
        ? PlatformFeeSchedule.SCHEDULE_NOT_SET
        : familyData.platformFeeSchedule ||
          PlatformFeeSchedule.SCHEDULE_NOT_SET,
    updatedPermissions: updatedPermissions(
      advisorPermissions,
      familyData.permissions,
    ),
    updatedAddress:
      family.address === familyData.address ? "" : familyData.address!,
    updatedProduct:
      familyData?.product?.id &&
      familyData?.product?.id != family.billingInfo?.product?.id
        ? familyData?.product
        : undefined,
    updatedIntroCoupon:
      familyData?.introCoupon?.id &&
      familyData?.introCoupon?.id != family.billingInfo?.introCoupon?.id
        ? familyData?.introCoupon
        : undefined,
    nullifyCoupon:
      family.billingInfo?.introCoupon && !familyData?.introCoupon?.id,
    updatedMembers: memberData
      .filter((m) => !!m.ref)
      .map((member) => ({
        ref: member.ref!,
        updatedFirstName:
          member.firstName === familyMemberMap.get(member.ref!)?.firstName
            ? ""
            : member.firstName!,
        updatedLastName:
          member.lastName === familyMemberMap.get(member.ref!)?.lastName
            ? ""
            : member.lastName!,
        updatedPhone:
          member.phone === familyMemberMap.get(member.ref!)?.primaryPhone
            ? ""
            : cleanPhone(member.phone!),
        updatedEmail:
          member.email === familyMemberMap.get(member.ref!)?.primaryEmail
            ? ""
            : member.email!,
        shouldUpdateAltEmails:
          member.altEmails !==
          familyMemberMap.get(member.ref!)?.alternateEmails,
        updatedAltEmails:
          member.altEmails === familyMemberMap.get(member.ref!)?.alternateEmails
            ? []
            : member.altEmails!,
        updatedTimezone:
          member.timezone === familyMemberMap.get(member.ref!)?.timezone
            ? ""
            : member.timezone!,
      })),
    newMembers: memberData
      .filter((m) => !m.ref)
      .map((member) => ({
        ref: "",
        updatedFirstName: member.firstName,
        updatedLastName: member.lastName,
        updatedPhone: cleanPhone(member.phone),
        updatedEmail: member.email,
        shouldUpdateAltEmails: true,
        updatedAltEmails: member.altEmails,
        updatedTimezone: member.timezone,
      })),
  };
};

export default ({
  open,
  onClose,
  family,
  familyOnly,
  contactOnly,
  primaryAdvisor,
  advisorPermissions,
}: Props) => {
  const [familyErrors, setFamilyErrors] = useState<FormErrors | null>(null);
  const [memberErrors, setMemberErrors] = useState<FormErrors[] | null>(null);

  const [familyData, setFamilyData] = useState<Family>({
    ref: family.ref,
    name: family.name,
    advisorRef: family.advisorRef,
    status: family.status,
    startDate: family.startDate || null,
    product: family.billingInfo?.product?.id
      ? family.billingInfo?.product
      : undefined,
    introCoupon: family.billingInfo?.introCoupon?.id
      ? family.billingInfo?.introCoupon
      : undefined,
    address: family.address,
    endDate: family.endDate || null,
    platformFeeSchedule: family.platformFeeSchedule,
    permissions: (advisorPermissions || []).map((permission) => ({
      ref: permission.ref,
      account: permission.account,
      advisorRef: permission?.account?.ref || "",
      active: permission.active,
      expiration: permission.expiration,
      actions: permission.actions,
    })),
    billingInfo: family.billingInfo,
  });
  const [members, setMembers] = useState<Member[]>(
    family.familyMembers.map((member) => ({
      ref: member.ref,
      firstName: member.firstName,
      lastName: member.lastName,
      phone: member.primaryPhone,
      email: member.primaryEmail,
      altEmails: member.alternateEmails,
      timezone: member.timezone,
    })),
  );

  const { request, loading } = useUpdateFamily(() => {
    onClose(true);
  });

  const _validate = () => {
    const famErrors = validateFamily(familyData);
    setFamilyErrors(famErrors);
    let hasErrors = Object.keys(famErrors).length !== 0;
    const [hasMemberErrors, memberErrors] = validateFamilyMembers(members);
    setMemberErrors(memberErrors);
    return !(hasErrors || hasMemberErrors);
  };
  const handleEditFamily = () => {
    if (_validate()) {
      const r = updates(family, familyData, members, advisorPermissions || []);
      request(new UpdateFamilyRequest(r));
    }
  };

  return (
    <ReactiveDialog
      primaryAction={handleEditFamily}
      primaryActionName="Save"
      primaryActionEnabled={!loading}
      fullWidthSize="sm"
      open={open}
      onClose={() => onClose(false)}
      title={`Edit ${family.name}`}
    >
      <Form
        familyData={familyData}
        members={members}
        setFamilyData={setFamilyData}
        setMembers={setMembers}
        familyErrors={familyErrors}
        memberErrors={memberErrors}
        handleSubmit={handleEditFamily}
        familyOnly={familyOnly}
        contactOnly={contactOnly}
        primaryAdvisor={primaryAdvisor}
      />
    </ReactiveDialog>
  );
};
