import React, {
  useRef,
  ReactNode,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Box, Typography, Button, Collapse, Skeleton } from "@mui/material";
import {
  capitalize,
  commaSeparatedEnglishList,
  formatUSPhoneNumber,
  getDomainFromURL,
} from "../../../common/utils";
import LinkRouter from "../../navigation/LinkRouter";
import { BlockHandle, BlockProps, usePollForBlockUpdates } from "./utils";
import BlockContainer from "./BlockContainer";
import BlockGutterMap from "./BlockGutterMap";
import { useUpdateBlockMetadata } from "../../../services/tasks";
import {
  ChevronDown,
  ChevronUp,
  ExternalLink,
  Globe,
  Map,
  MapPinned,
  Phone,
  RefreshCw,
} from "lucide-react";
import { ReactComponent as SparklesIcon } from "../../../icons/Advisor/Sparkles.svg";

import {
  TaskBlock,
  TaskRecommendationResult,
  ForumPostResult,
} from "../../../protogen/tasks_pb";
import { AccountStub } from "../../../protogen/common_pb";
import useIsMobile from "../../hooks/useIsMobile";
import WithDividers from "../../helpers/WithDividers";
import Spinner from "../../common/Spinner";
import { useRunWorkflow } from "../../../services/extraction";
import { SuggestionEntityType } from "../../../protogen/suggestions_pb";
import MapList, { MapLocation } from "../../common/maps/MapList";
import { Treatment } from "../../common/maps/FMarker";

const RecommenderByline = ({ recommender }: { recommender: AccountStub }) => {
  return (
    <Typography
      color="#6B6E7B"
      variant="bodySmall"
      sx={{
        textAlign: "right",
      }}
    >
      Shared by{" "}
      <LinkRouter to={`/advisor/${recommender.ref}`} inline>
        <span
          style={{
            cursor: "pointer",
          }}
        >
          {recommender.displayName}
        </span>
      </LinkRouter>
    </Typography>
  );
};

const SuggestionsContainer = ({
  title,
  children,
  actionTitle,
  actionLink,
  targetNew,
  isCollapsed,
  toggleCollapsed,
  secondaryAction,
}: {
  title: ReactNode;
  children: ReactNode | ReactNode[];
  actionTitle?: string;
  actionLink?: string;
  targetNew?: boolean;
  isCollapsed?: boolean;
  toggleCollapsed?: () => void;
  secondaryAction: ReactNode | ReactNode[];
}) => {
  const isMobile = useIsMobile();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "16px",
        padding: isMobile ? "20px" : "16px 24px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {title}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {!isCollapsed && secondaryAction && secondaryAction}
          <Box
            sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
            onClick={toggleCollapsed}
          >
            {isCollapsed ? (
              <ChevronDown size={24} stroke="#8E9598" />
            ) : (
              <ChevronUp size={24} stroke="#8E9598" />
            )}
          </Box>
        </Box>
      </Box>

      <Collapse in={!isCollapsed}>
        <Box
          sx={{
            marginTop: "12px",
            display: "flex",
            flexDirection: "column",
            gap: "16px",
          }}
        >
          {children}
          {actionLink && actionTitle && (
            <LinkRouter to={actionLink} targetNew={targetNew}>
              <Button
                variant="outlined"
                sx={{
                  width: "fit-content",
                  height: "inherit",
                }}
              >
                {actionTitle}
              </Button>
            </LinkRouter>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

const ForumSuggestionEntry = ({
  destinationUrl,
  title,
  description,
  subHeader,
}: {
  destinationUrl: string;
  title: string;
  description: string;
  thumbnailUrl?: string | null;
  subHeader?: string | ReactNode | null;
}) => {
  const isMobile = useIsMobile();
  return (
    <Box display="flex" gap={"16px"}>
      <Box>
        <LinkRouter to={destinationUrl} targetNew>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "4px",
            }}
          >
            {!isMobile && (
              <img
                src="../../../assets/images/community-placeholder.svg"
                alt="Faye community"
                style={{
                  height: "100%",
                  aspectRatio: "1 / 1",
                  objectFit: "cover",
                  maxHeight: "110px",
                  cursor: "pointer",
                }}
              />
            )}
            {getDomainFromURL(destinationUrl) && (
              <Typography
                color="#6B6E7B"
                variant="bodySmall"
                sx={{
                  cursor: "pointer",
                }}
              >
                {getDomainFromURL(destinationUrl)}
              </Typography>
            )}
          </Box>
        </LinkRouter>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          alignItems: "flex-start",
        }}
      >
        <Typography
          color="#3D3D3D"
          variant="body"
          sx={{
            fontWeight: isMobile ? 500 : 700,
            overflow: "hidden",
            cursor: "pointer",
          }}
          onClick={() => window.open(destinationUrl, "_blank")}
        >
          {title}
        </Typography>
        <Typography
          color="#616161"
          variant="bodySmall"
          sx={{
            display: "-webkit-box",
            WebkitLineClamp: "3",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            wordBreak: "break-word",
          }}
        >
          {description}
        </Typography>
        {subHeader}
      </Box>
    </Box>
  );
};
const SuggestionEntry = ({
  destinationUrl,
  title,
  description,
  showImage,
  thumbnailUrl,
  phone,
  address,
}: {
  destinationUrl: string;
  title: string;
  description: string;
  showImage: boolean;
  thumbnailUrl?: string | null;
  phone?: string;
  address?: string;
}) => {
  const [hasThumbnailError, setHasThumbnailError] = useState(false);
  const isMobile = useIsMobile();
  const titleSection = (
    <Box
      display="flex"
      flexDirection="row"
      gap="4px"
      alignItems="center"
      sx={{ overflow: "hidden" }}
    >
      <Typography
        color="#3D3D3D"
        variant="body"
        sx={{
          fontWeight: isMobile ? 500 : 700,
          overflow: "hidden",
          cursor: "pointer",
          flexGrow: 1,
        }}
        onClick={() => window.open(destinationUrl, "_blank")}
      >
        {title}
      </Typography>
    </Box>
  );
  let imageSection = null;
  if (showImage) {
    imageSection = (
      <LinkRouter
        to={destinationUrl}
        targetNew
        sx={{
          display: "inline-block",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "4px",
          }}
        >
          {(!thumbnailUrl || hasThumbnailError) && (
            <div
              style={{
                height: "92px",
                width: "92px",
                cursor: "pointer",
                borderRadius: "8px",
                backgroundColor: "#D4D4D4",
              }}
            >
              <Globe
                stroke="#F2F2F2"
                strokeWidth="1.5px"
                style={{
                  padding: "19px",
                  height: "100%",
                  width: "100%",
                }}
              />
            </div>
          )}
          {thumbnailUrl && !hasThumbnailError && (
            <img
              src={thumbnailUrl}
              alt={title}
              style={{
                height: "100%",
                aspectRatio: "1 / 1",
                objectFit: "cover",
                maxWidth: "92px",
                maxHeight: "92px",
                cursor: "pointer",
                borderRadius: "8px",
                border: "1px solid rgba(0, 0, 0, 0.12)",
              }}
              onError={() => {
                setHasThumbnailError(true);
              }}
            />
          )}
        </Box>
      </LinkRouter>
    );
  }
  const bodySection = (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        gap: "12px",
      }}
    >
      <Typography
        color="#616161"
        variant="bodySmall"
        sx={{
          display: "-webkit-box",
          WebkitLineClamp: "3",
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
        }}
      >
        {description}
      </Typography>
    </Box>
  );
  const attributeSection = (
    <>
      {address && (
        <Box
          display={"flex"}
          flexDirection={"row"}
          gap={"4px"}
          alignItems={isMobile ? "start" : "center"}
        >
          <MapPinned size="16px" color="#198282" />
          <Typography color="#616161" variant="bodySmall">
            {address}
          </Typography>
        </Box>
      )}
      <Box
        display={"flex"}
        flexDirection={isMobile ? "column" : "row"}
        gap={isMobile ? "6px" : "16px"}
      >
        {phone && (
          <Box
            display={"flex"}
            flexDirection={"row"}
            gap={"4px"}
            alignItems={isMobile ? "start" : "center"}
          >
            <Phone size="16px" color="#198282" />
            <Typography color="#616161" variant="bodySmall">
              {formatUSPhoneNumber(phone)}
            </Typography>
          </Box>
        )}
        {getDomainFromURL(destinationUrl) && (
          <LinkRouter to={destinationUrl} targetNew={true}>
            <Box
              display={"flex"}
              flexDirection={"row"}
              gap={"4px"}
              alignItems={isMobile ? "start" : "center"}
            >
              <Globe size="16px" color="#198282" />
              <Typography color="#616161" variant="bodySmall">
                {getDomainFromURL(destinationUrl)}
              </Typography>
            </Box>
          </LinkRouter>
        )}
      </Box>
    </>
  );
  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        gap: "16px",
      }}
    >
      {!isMobile && imageSection}
      <Box display="flex" flexDirection="column" gap={"8px"}>
        {titleSection}
        {bodySection}
        {attributeSection}
      </Box>
    </Box>
  );
};

type Query = {
  query_url?: string;
  query_string?: string;
  dedicated_websites?: string[];
};
const queriesTitle = (webQueries: string) => {
  if (!webQueries) return null;
  const queries = JSON.parse(webQueries) as Query[];
  if (!queries || !queries.length) return null;
  const sites = queries.flatMap((q) => {
    if (q.dedicated_websites) {
      return q.dedicated_websites.map((s) => capitalize(s.replace(".com", "")));
    } else if (q.query_url?.includes("yelp.com")) {
      return ["Yelp"];
    } else {
      return ["Google"];
    }
  });
  const siteList = commaSeparatedEnglishList(Array.from(new Set(sites)).sort());
  return `Searched from ${siteList}.`;
};
const Queries = ({ webQueries }: { webQueries: string }) => {
  if (!webQueries) return null;
  const queries = JSON.parse(webQueries) as Query[];
  if (!queries || !queries.length) return null;
  return (
    <Box display={"flex"} flexDirection={"column"}>
      {queries.map((q, i) => (
        <LinkRouter to={q.query_url} targetNew={true} key={i}>
          <Box sx={{ marginRight: "6px" }}>
            <ExternalLink size={20} color="#198282" />
          </Box>
          <Typography
            variant={"bodyHeavy"}
            color="#198282"
            sx={{ fontWeight: 600 }}
          >
            {(q.query_string || "").replace(/\s*site:\S+/gi, "")}
          </Typography>
        </LinkRouter>
      ))}
    </Box>
  );
};

type MetadataType = {
  processing_state?: string;
  query_terms?: string;
};
const BlockProcessing = ({ metadata }: { metadata: MetadataType }) => {
  const state = metadata?.processing_state;
  const understanding = state === "understanding";
  const searching = state === "searching";
  const choosing = state === "choosing";
  const queries: string[] = metadata?.query_terms
    ? JSON.parse(metadata?.query_terms)
    : [];
  return (
    <Box display="flex" flexDirection="column" gap="24px">
      <Box display="flex" flexDirection="column" gap="8px">
        <Typography
          variant="body"
          color={understanding ? undefined : "text.tertiary"}
        >
          Parsing task information...
        </Typography>
        {(searching || choosing) && (
          <>
            {queries.map((kw) => (
              <Typography
                key={kw}
                variant="body"
                color={searching ? undefined : "text.tertiary"}
              >
                {kw}...
              </Typography>
            ))}
            {choosing && (
              <Typography variant="body">
                Choosing the best options...
              </Typography>
            )}
          </>
        )}
      </Box>
      <Box display="flex" flexDirection="row" gap="18px">
        <Skeleton
          variant="rounded"
          width={92}
          height={92}
          sx={{ borderRadius: "8px" }}
        />
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          gap="8px"
          flex="1"
        >
          <Skeleton variant="rounded" width={"50%"} height={20} />
          <Skeleton variant="rounded" width={"74%"} height={8} />
          <Skeleton variant="rounded" width={"60%"} height={8} />
          <Skeleton variant="rounded" width={"70%"} height={8} />
        </Box>
      </Box>
    </Box>
  );
};

const ResultsList = ({
  webContent,
  communityContent,
}: {
  webContent: TaskRecommendationResult[];
  communityContent: ForumPostResult[];
}) => {
  const MAX_RESULTS = 5 - communityContent.length;
  const [expanded, setExpanded] = useState(webContent.length <= MAX_RESULTS);
  const show = expanded ? webContent : webContent.slice(0, MAX_RESULTS);
  const showImages =
    webContent.filter((r) => !!r.thumbnailUrl).length /
      (webContent.length || 1) >
    0.2;

  return (
    <>
      <WithDividers>
        {communityContent.map((result: ForumPostResult, idx: number) => (
          <Box key={idx}>
            <ForumSuggestionEntry
              destinationUrl={`/community/${encodeURIComponent(
                result.postRef,
              )}`}
              title={result.title}
              description={result.textContent}
              subHeader={<RecommenderByline recommender={result.createdBy!} />}
            />
          </Box>
        ))}
        {show.map((result: TaskRecommendationResult) => (
          <Box key={result.url}>
            <SuggestionEntry
              destinationUrl={result.url}
              title={result.title}
              showImage={showImages}
              description={result.explanation}
              thumbnailUrl={result.thumbnailUrl}
              phone={result.phone}
              address={result.address}
            />
          </Box>
        ))}
      </WithDividers>
      {!expanded && (
        <Button
          variant={"text"}
          onClick={() => setExpanded(true)}
          sx={{ height: "unset" }}
        >
          Show more
        </Button>
      )}
    </>
  );
};
interface Props extends BlockProps {
  mappedAddresses?: [string, string][];
}
export default forwardRef<BlockHandle, Props>(
  ({ task, block: initialBlock, mappedAddresses }: Props, ref) => {
    const [parsedWebContent, setParsedWebContent] = useState<
      TaskRecommendationResult[] | null
    >(null);
    const [parsedCommunityContent, setParsedCommunityContent] = useState<
      ForumPostResult[] | null
    >(null);
    const [parsedMetadata, setParsedMetadata] = useState<any>();
    const [isRefreshing, setRefreshing] = useState(false);
    const [mapOpen, setMapOpen] = useState(false);
    const hasContent = useRef<boolean>(!!initialBlock.content);
    const { request } = useUpdateBlockMetadata((r) => {
      if (!r.block?.metadata) return;
      setParsedMetadata(JSON.parse(r.block?.metadata));
    });
    const { block, polling, restart } = usePollForBlockUpdates({
      initialBlock,
      isComplete: (b: TaskBlock) =>
        JSON.parse(b.metadata).processing_state === "complete" ||
        JSON.parse(b.metadata).processing_state === "processed",
      onCompleted: (b: TaskBlock) => {
        hasContent.current = !!b.content;
      },
      pollingSleep: 1500,
      maxIterations: 45,
    });
    const { request: workflowRequest } = useRunWorkflow();
    const preprocessing = parsedMetadata?.processing_state === "processing";
    const processing = parsedMetadata?.processing_state !== "complete";
    useImperativeHandle(ref, () => ({
      isPolling: () => preprocessing && polling,
    }));
    useEffect(() => {
      const { content: jsonString } = block;
      if (jsonString) {
        let parsed = JSON.parse(jsonString);
        const details = parsed.content?.[0]?.attrs?.details;
        parsed.content?.forEach((content: any) => {
          // TODO @kegami fix these names!
          if (content.type === "onlineProductResults") {
            setParsedWebContent(content.attrs.details);
          } else if (content.type === "recommendations") {
            setParsedCommunityContent(content.attrs.details);
          }
        });
        setParsedWebContent(details);
      }
      if (block.metadata) {
        setParsedMetadata(JSON.parse(block.metadata));
      }
    }, [block]);

    const onRefresh = async () => {
      setRefreshing(true);
      const resp = await workflowRequest({
        workflowName: "process-refresh-task-suggestions",
        arguments: [],
        entityType: SuggestionEntityType.TASK,
        entityRef: task.ref,
        runAsync: true,
      });
      if (!resp) return;
      restart();
      setTimeout(() => {
        restart();
        setRefreshing(false);
      }, 5000);
    };

    const handleToggleCollapsed = () => {
      request({
        taskBlockRef: block.ref,
        key: "isCollapsed",
        value: (!parsedMetadata.isCollapsed).toString(),
      });
    };
    if (preprocessing) return null;
    const noResults = parsedMetadata?.processing_state === "processed";
    if (noResults) return null;
    let mapLocations: MapLocation[] = [];
    if (
      parsedWebContent &&
      parsedWebContent.filter((result) => result.address).length >= 3
    ) {
      mapLocations = [
        ...parsedWebContent
          .filter((result) => result.address)
          .map((result) => ({
            address: result.address,
            title: result.title,
            link: result.url,
            content: result.explanation,
          })),
        ...(mappedAddresses || []).map(([title, address]) => ({
          title,
          address,
          bounding: false,
          treatment: Treatment.PersistentDot,
        })),
      ];
    }
    return (
      <BlockContainer
        gutterIcon={BlockGutterMap.recommendation.icon}
        advisorViewOnly={true}
      >
        <Box display="flex" flexDirection="column">
          <Box>
            <SuggestionsContainer
              title={
                isRefreshing || (!noResults && processing) ? (
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap="10px"
                    alignItems="center"
                  >
                    <Spinner />
                    <Typography color="#3D3D3D" variant="bodyHeavy">
                      Generating suggestions
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    display="flex"
                    flexDirection="row"
                    gap="10px"
                    sx={{ alignItems: "center" }}
                  >
                    <SparklesIcon />
                    <Typography color="#3D3D3D" variant="bodyHeavy">
                      Smart suggestions
                    </Typography>
                  </Box>
                )
              }
              isCollapsed={parsedMetadata?.isCollapsed}
              toggleCollapsed={handleToggleCollapsed}
              secondaryAction={
                <>
                  {mapLocations.length > 0 && (
                    <Map
                      size={20}
                      stroke="#8E9598"
                      style={{ cursor: "pointer" }}
                      onClick={() => setMapOpen(true)}
                    />
                  )}
                  {!processing && !isRefreshing ? (
                    <RefreshCw
                      size={20}
                      stroke="#8E9598"
                      style={{ cursor: "pointer" }}
                      onClick={onRefresh}
                    />
                  ) : undefined}
                </>
              }
            >
              {processing && <BlockProcessing metadata={parsedMetadata} />}
              {parsedWebContent && (
                <>
                  <Typography variant={"body"}>
                    {queriesTitle(parsedMetadata?.web_queries)}
                  </Typography>
                  {(parsedWebContent || parsedCommunityContent) && (
                    <ResultsList
                      webContent={parsedWebContent || []}
                      communityContent={parsedCommunityContent || []}
                    />
                  )}
                  <Queries webQueries={parsedMetadata?.web_queries} />
                </>
              )}
            </SuggestionsContainer>
          </Box>
        </Box>
        <MapList
          open={mapOpen}
          onClose={() => setMapOpen(false)}
          locations={mapLocations}
        />
      </BlockContainer>
    );
  },
);
