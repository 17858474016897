import { useEffect } from "react";
import { Box, Typography } from "@mui/material";
import GridPage from "src/components/layout/GridPage";
import TimeUtilization from "../../components/time-utilization/TimeUtilization";
import { useAllActiveFamilyPlanUtilization } from "../../services/admin";
import { FamilyPlanUtilization } from "src/protogen/admin_service_pb";
import useIsMobile from "../../components/hooks/useIsMobile";

export default () => {
  const isMobile = useIsMobile();
  const { data, request } = useAllActiveFamilyPlanUtilization(() => {});
  useEffect(() => {
    request();
  }, []);
  if (!data) return <></>;

  return (
    <GridPage
      sx={{
        padding: isMobile ? "32px 24px" : "",
        margin: isMobile ? "" : "64px min(7%, 100px)",
      }}
    >
      <Typography variant="title">Family time utilization</Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "16px" }}>
        {data.familyPlanUtilization
          .sort((a: FamilyPlanUtilization, b: FamilyPlanUtilization) => {
            return a.familyName.localeCompare(b.familyName);
          })
          .map((data: FamilyPlanUtilization, index: number) => {
            return (
              <Box key={index} sx={{ width: "280px" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box>
                    <Typography variant="h6">{data.familyName}</Typography>
                  </Box>
                  <Box
                    sx={{ display: "flex", alignItems: "center", gap: "8px" }}
                  >
                    <TimeUtilization planUtilization={data.planUtilization} />
                  </Box>
                </Box>
              </Box>
            );
          })}
      </Box>
    </GridPage>
  );
};
