// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/member_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { Family, Member } from "./advisors_service_pb.ts";
import { Task } from "./tasks_pb.ts";
import { SecretStub } from "./secrets_pb.ts";
import { PlanUtilization, PublicAdvisor } from "./common_pb.ts";

/**
 * @generated from message protogen.GetMemberDetailsRequest
 */
export class GetMemberDetailsRequest extends Message<GetMemberDetailsRequest> {
  constructor(data?: PartialMessage<GetMemberDetailsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetMemberDetailsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMemberDetailsRequest {
    return new GetMemberDetailsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMemberDetailsRequest {
    return new GetMemberDetailsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMemberDetailsRequest {
    return new GetMemberDetailsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetMemberDetailsRequest | PlainMessage<GetMemberDetailsRequest> | undefined, b: GetMemberDetailsRequest | PlainMessage<GetMemberDetailsRequest> | undefined): boolean {
    return proto3.util.equals(GetMemberDetailsRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetMemberDetailsResponse
 */
export class GetMemberDetailsResponse extends Message<GetMemberDetailsResponse> {
  /**
   * @generated from field: protogen.Family family = 1;
   */
  family?: Family;

  /**
   * @generated from field: repeated protogen.Task activeTasks = 4;
   */
  activeTasks: Task[] = [];

  /**
   * @generated from field: repeated protogen.SecretStub secrets = 5;
   */
  secrets: SecretStub[] = [];

  /**
   * @generated from field: protogen.PublicAdvisor primaryAdvisor = 6;
   */
  primaryAdvisor?: PublicAdvisor;

  /**
   * @generated from field: protogen.Member member = 7;
   */
  member?: Member;

  /**
   * @generated from field: protogen.PlanUtilization planUtilization = 8;
   */
  planUtilization?: PlanUtilization;

  constructor(data?: PartialMessage<GetMemberDetailsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetMemberDetailsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "family", kind: "message", T: Family },
    { no: 4, name: "activeTasks", kind: "message", T: Task, repeated: true },
    { no: 5, name: "secrets", kind: "message", T: SecretStub, repeated: true },
    { no: 6, name: "primaryAdvisor", kind: "message", T: PublicAdvisor },
    { no: 7, name: "member", kind: "message", T: Member },
    { no: 8, name: "planUtilization", kind: "message", T: PlanUtilization },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetMemberDetailsResponse {
    return new GetMemberDetailsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetMemberDetailsResponse {
    return new GetMemberDetailsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetMemberDetailsResponse {
    return new GetMemberDetailsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetMemberDetailsResponse | PlainMessage<GetMemberDetailsResponse> | undefined, b: GetMemberDetailsResponse | PlainMessage<GetMemberDetailsResponse> | undefined): boolean {
    return proto3.util.equals(GetMemberDetailsResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateMembersFamilyRequest
 */
export class UpdateMembersFamilyRequest extends Message<UpdateMembersFamilyRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string updatedAddress = 2;
   */
  updatedAddress = "";

  /**
   * @generated from field: repeated protogen.UpdateMembersFamilyRequest.Member newMembers = 3;
   */
  newMembers: UpdateMembersFamilyRequest_Member[] = [];

  constructor(data?: PartialMessage<UpdateMembersFamilyRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateMembersFamilyRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "updatedAddress", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "newMembers", kind: "message", T: UpdateMembersFamilyRequest_Member, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateMembersFamilyRequest {
    return new UpdateMembersFamilyRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateMembersFamilyRequest {
    return new UpdateMembersFamilyRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateMembersFamilyRequest {
    return new UpdateMembersFamilyRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateMembersFamilyRequest | PlainMessage<UpdateMembersFamilyRequest> | undefined, b: UpdateMembersFamilyRequest | PlainMessage<UpdateMembersFamilyRequest> | undefined): boolean {
    return proto3.util.equals(UpdateMembersFamilyRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateMembersFamilyRequest.Member
 */
export class UpdateMembersFamilyRequest_Member extends Message<UpdateMembersFamilyRequest_Member> {
  /**
   * @generated from field: string firstName = 2;
   */
  firstName = "";

  /**
   * @generated from field: string lastName = 3;
   */
  lastName = "";

  /**
   * @generated from field: string primaryEmail = 4;
   */
  primaryEmail = "";

  /**
   * @generated from field: string primaryPhone = 5;
   */
  primaryPhone = "";

  constructor(data?: PartialMessage<UpdateMembersFamilyRequest_Member>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateMembersFamilyRequest.Member";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "firstName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "lastName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "primaryEmail", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "primaryPhone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateMembersFamilyRequest_Member {
    return new UpdateMembersFamilyRequest_Member().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateMembersFamilyRequest_Member {
    return new UpdateMembersFamilyRequest_Member().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateMembersFamilyRequest_Member {
    return new UpdateMembersFamilyRequest_Member().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateMembersFamilyRequest_Member | PlainMessage<UpdateMembersFamilyRequest_Member> | undefined, b: UpdateMembersFamilyRequest_Member | PlainMessage<UpdateMembersFamilyRequest_Member> | undefined): boolean {
    return proto3.util.equals(UpdateMembersFamilyRequest_Member, a, b);
  }
}

/**
 * @generated from message protogen.UpdateMembersFamilyResponse
 */
export class UpdateMembersFamilyResponse extends Message<UpdateMembersFamilyResponse> {
  /**
   * @generated from field: protogen.Family family = 1;
   */
  family?: Family;

  constructor(data?: PartialMessage<UpdateMembersFamilyResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateMembersFamilyResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "family", kind: "message", T: Family },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateMembersFamilyResponse {
    return new UpdateMembersFamilyResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateMembersFamilyResponse {
    return new UpdateMembersFamilyResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateMembersFamilyResponse {
    return new UpdateMembersFamilyResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateMembersFamilyResponse | PlainMessage<UpdateMembersFamilyResponse> | undefined, b: UpdateMembersFamilyResponse | PlainMessage<UpdateMembersFamilyResponse> | undefined): boolean {
    return proto3.util.equals(UpdateMembersFamilyResponse, a, b);
  }
}

/**
 * @generated from message protogen.GetBillingPortalUrlRequest
 */
export class GetBillingPortalUrlRequest extends Message<GetBillingPortalUrlRequest> {
  constructor(data?: PartialMessage<GetBillingPortalUrlRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetBillingPortalUrlRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBillingPortalUrlRequest {
    return new GetBillingPortalUrlRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBillingPortalUrlRequest {
    return new GetBillingPortalUrlRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBillingPortalUrlRequest {
    return new GetBillingPortalUrlRequest().fromJsonString(jsonString, options);
  }

  static equals(a: GetBillingPortalUrlRequest | PlainMessage<GetBillingPortalUrlRequest> | undefined, b: GetBillingPortalUrlRequest | PlainMessage<GetBillingPortalUrlRequest> | undefined): boolean {
    return proto3.util.equals(GetBillingPortalUrlRequest, a, b);
  }
}

/**
 * @generated from message protogen.GetBillingPortalUrlResponse
 */
export class GetBillingPortalUrlResponse extends Message<GetBillingPortalUrlResponse> {
  /**
   * @generated from field: string url = 1;
   */
  url = "";

  constructor(data?: PartialMessage<GetBillingPortalUrlResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.GetBillingPortalUrlResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): GetBillingPortalUrlResponse {
    return new GetBillingPortalUrlResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): GetBillingPortalUrlResponse {
    return new GetBillingPortalUrlResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): GetBillingPortalUrlResponse {
    return new GetBillingPortalUrlResponse().fromJsonString(jsonString, options);
  }

  static equals(a: GetBillingPortalUrlResponse | PlainMessage<GetBillingPortalUrlResponse> | undefined, b: GetBillingPortalUrlResponse | PlainMessage<GetBillingPortalUrlResponse> | undefined): boolean {
    return proto3.util.equals(GetBillingPortalUrlResponse, a, b);
  }
}

