import React, { CSSProperties } from "react";
import { Typography } from "@mui/material";
import { LinearProgress } from "@mui/material";

const unformattedCopy = (e: React.ClipboardEvent<HTMLDivElement>) => {
  // Get the selected content as a Range object
  const selection = window.getSelection();
  if (!selection || selection.rangeCount === 0) return;

  // Get the selected HTML content
  const range = selection.getRangeAt(0);
  const fragment = range.cloneContents();

  // Create a temporary element to hold the copied content
  const tempElement = document.createElement("div");
  tempElement.appendChild(fragment);

  // Remove background color and other unwanted styles from the copied content
  tempElement.querySelectorAll("*").forEach((el) => {
    const element = el as HTMLElement;
    element.style.fontWeight = "unset";
    element.style.color = "unset";
    element.style.backgroundColor = "unset";
  });

  if (!e.clipboardData) return;

  // Set the modified content as the data to be copied
  e.clipboardData.setData("text/plain", tempElement.innerText);
  e.clipboardData.setData("text/html", tempElement.innerHTML);

  // Prevent the default copy action
  e.preventDefault();
};

interface Props {
  alignLeft: boolean;
  children: string | React.ReactNode;
  onClick?: () => void;
  loading?: boolean;
  sx?: CSSProperties;
}
export default ({
  alignLeft,
  children,
  onClick,
  loading = false,
  sx,
}: Props) => {
  return (
    <div
      style={{
        position: "relative",
        ...(onClick ? { cursor: "pointer" } : {}),
      }}
      onClick={onClick}
    >
      <div
        onCopy={unformattedCopy}
        style={{
          padding: "10px 14px",
          width: "100%",
          fontWeight: "500",
          ...(alignLeft
            ? {
                backgroundColor: "#F2F4F7",
                borderRadius: "0 8px 8px",
                border: "1px solid #EAEBEC",
              }
            : {
                borderRadius: "8px 0px 8px 8px",
                background: "#1C9191",
              }),
          ...sx,
        }}
      >
        <Typography
          variant="body"
          sx={{
            wordBreak: "break-word",
            whiteSpace: "pre-line", // Lets messages use new lines.
            fontWeight: "500",
            margin: 0,
            ...(alignLeft
              ? { color: "#3D3D3D" }
              : { color: "#FFF", "& a": { color: "primary.light" } }),
          }}
        >
          {children}
        </Typography>
      </div>
      {loading && (
        <LinearProgress
          sx={{ width: "100%", position: "absolute", bottom: "0" }}
          color="inherit"
        />
      )}
    </div>
  );
};
