import { Alert, Box, IconButton, Typography } from "@mui/material";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { useEffect, useRef, useState } from "react";
import GridPage from "../components/layout/GridPage";
import useIsMobile from "../components/hooks/useIsMobile";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router";
import Loading from "../components/common/Loading";
import SearchResult from "../components/search/SearchResult";
import { useSearch } from "../services/search";
import { Filter } from "../components/search/types";
import SearchBar from "../components/forum/SearchBar";
import SimpleFilters from "../components/search/SimpleFilters";
import { parseSearchQueryUrl } from "../components/search/utils";

const Constraints = ({
  filters,
  setFilters,
  loading,
}: {
  filters: Filter[];
  setFilters: (f: Filter[]) => void;
  loading: boolean;
}) => {
  return (
    <Box display={"flex"} flexDirection={"row"} gap={"12px"}>
      {filters
        .filter(
          (f) =>
            (f.presentationPrefix &&
              f.presentationValue &&
              f.field !== "family") ||
            f.field === "entity_type",
        )
        .map((filter, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "6px",
              padding: "8px 18px",
              borderRadius: "100px",
              background: "#E9F3F3",
            }}
          >
            <IconButton
              sx={{
                height: "30px",
                padding: "0px",
              }}
              disabled={loading}
              onClick={() => {
                setFilters(filters.filter((f) => f !== filter));
              }}
            >
              <XMarkIcon
                height={20}
                width={20}
                stroke="#198282"
                strokeWidth="2"
              />
            </IconButton>
            <Typography variant="body" color="#198282" fontWeight="600">
              {filter.presentationValue}
            </Typography>
          </div>
        ))}
    </Box>
  );
};

type Props = {};

export default ({}: Props) => {
  const isMobile = useIsMobile();
  const textFieldRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
  const navigate = useNavigate();
  const {
    searchQuery,
    referrer,
    filters: defaultFilters,
  } = parseSearchQueryUrl(location.search);
  const [query, setQuery] = useState<string>(searchQuery);
  const { request, data, loading } = useSearch();
  const [adminSearch, setAdminSearch] = useState(false);
  const [appliedFilters, setAppliedFilters] =
    useState<Filter[]>(defaultFilters);
  const handleQuery = () => {
    if (!query) return;
    request({
      queryText: query,
      adminSearchMode: adminSearch,
      filters: appliedFilters.map((filter) => ({
        field: filter.field,
        operator: filter.operator,
        value: filter.value,
      })),
    });
  };
  useEffect(() => {
    handleQuery();
  }, [query, adminSearch, appliedFilters]);
  useEffect(() => {
    if (searchQuery) {
      handleQuery();
    } else {
      textFieldRef.current?.focus();
    }
  }, []);

  return (
    <GridPage
      sx={{
        padding: isMobile ? "32px 24px" : "",
        margin: isMobile ? "" : "64px min(7%, 100px)",
        maxWidth: "1000px",
      }}
    >
      <Box
        sx={{
          flex: "1 1 100%",
          marginBottom: "32px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <Box display="flex" flexDirection="column">
          {referrer && (
            <Box
              onClick={() => {
                navigate(-1);
              }}
              style={{ cursor: "pointer" }}
            >
              <Typography
                variant="body"
                sx={{
                  color: "#8E9598",
                  fontWeight: "600",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <ChevronLeftIcon
                  width="18px"
                  strokeWidth="2.5px"
                  style={{ marginRight: "4px" }}
                />
                {"Back"}
              </Typography>
            </Box>
          )}
          <Box>
            <Typography variant="display">Search</Typography>
          </Box>
        </Box>
        {!isMobile && (
          <SearchBar
            initialQuery={query}
            topics={[]}
            captureHotkeys={true}
            onQuery={(q) => {
              setQuery(q);
            }}
          />
        )}
      </Box>
      {isMobile && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <SearchBar
            fullWidth
            initialQuery={query}
            topics={[]}
            onQuery={(q) => {
              setQuery(q);
            }}
          />
        </Box>
      )}
      <Box
        display={query ? "flex" : "none"}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          marginTop: isMobile ? "12px" : "24px",
        }}
      >
        <Box>
          <Typography variant="body">Search results for "{query}"</Typography>
        </Box>
      </Box>
      <Box
        display="flex"
        sx={{
          marginTop: isMobile ? "12px" : "24px",
          marginBottom: isMobile ? "12px" : "24px",
          flexWrap: "wrap",
          ...(isMobile
            ? {
                gap: "12px",
                flexDirection: "column",
              }
            : {
                gap: "12px",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }),
        }}
      >
        <SimpleFilters
          updateAdminSearch={(b) => {
            setAppliedFilters(
              appliedFilters.filter((f) => !b || f.field !== "family"),
            );
            setAdminSearch(b);
          }}
          filters={appliedFilters}
          setFilters={setAppliedFilters}
          loading={loading}
        />
        <Constraints
          filters={appliedFilters}
          setFilters={setAppliedFilters}
          loading={loading}
        />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        gap={isMobile ? "18px" : "24px"}
        sx={{ width: "100%" }}
      >
        {loading && <Loading />}
        {!loading &&
          (data?.results || []).map((result, index) => (
            <SearchResult key={index} result={result} onClose={() => {}} />
          ))}
        {!loading && data?.results.length === 0 && (
          <Alert variant="outlined" severity="info">
            No results for your search - consider tweaking your search filters.
          </Alert>
        )}
      </Box>
    </GridPage>
  );
};
