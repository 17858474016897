import { Task, TaskState } from "../protogen/tasks_pb";
import { PlainMessage } from "@bufbuild/protobuf";
import { EditTaskRequest } from "../protogen/tasks_service_pb";
import { NoteContent } from "../protogen/notes_pb";
import { UploadAttachment } from "../protogen/common_pb";

type EphemeralTask = {
  ref?: string;
  title?: string;
  priority?: string;
  assigneeRef?: string;
  dueDate?: string;
  hidden?: boolean;
  state?: TaskState;
  details?: PlainMessage<NoteContent>;
  attachments?: UploadAttachment[];
  // Only matters if family selector is present.
  familyRef?: string;
  // Used to pass in initial text state (not json).
  initialDetailsText?: string;
  // Used for associating a task with where it was made.
  sourceEntityType?: string;
  sourceEntityRef?: string;
  sourceEntityIndex?: number;
  hoursReported?: number;
  minutesReported?: number;
  completionDateSec?: bigint;
};
type TaskFormErrors = { [key: string]: string };

const validateTask = (
  task: EphemeralTask,
  verifyFamily?: boolean,
): TaskFormErrors => {
  const errors: TaskFormErrors = {};
  if (verifyFamily && !task.familyRef) {
    errors["familyRef"] = "Required";
  }
  if (!task.title) {
    errors["title"] = "Required";
  }
  if (!task.state) {
    errors["state"] = "Required";
  }
  if (!task.priority) {
    errors["priority"] = "Required";
  }
  if (!task.assigneeRef) {
    errors["assigneeRef"] = "Required";
  }
  // if (!task.dueDate) {
  //   errors['dueDate'] = 'Required';
  // }
  return errors;
};

const updates = (
  task: Task,
  formData: EphemeralTask,
): PlainMessage<EditTaskRequest> => {
  return {
    taskRef: task.ref,
    updatedTitle: task.title === formData.title ? "" : formData.title!,
    updatedPriority:
      task.priority === formData.priority ? "" : formData.priority!,
    updatedDueDate: task.dueDate === formData.dueDate ? "" : formData.dueDate!,
    updatedAssigneeRef:
      task.assignee.ref === formData.assigneeRef ? "" : formData.assigneeRef!,
    updatedState:
      task.state === formData.state ? TaskState.UNSPECIFIED : formData.state!,
    shouldUpdateHidden: formData.hidden !== undefined,
    updatedHidden: (formData.hidden !== undefined && formData.hidden) || false,
  };
};

const compareTaskDates = (dateA: string, dateB: string): number => {
  if (dateA === "" && dateB === "") return 0;
  if (dateA === "") return 1;
  if (dateB === "") return -1;

  const dateAObj = new Date(dateA);
  const dateBObj = new Date(dateB);

  return dateAObj.getTime() - dateBObj.getTime();
};

const compareTaskStatus = (statusA: TaskState, statusB: TaskState): number => {
  if (statusA > statusB) return 1;
  if (statusA < statusB) return -1;
  return 0;
};

type EphemeralTaskInspiration = {
  ref?: string;
  type?: string;
  title?: string;
  status?: string;
  additionalContext?: string;
  defaultDetails?: { name: string; type: string }[];
};

export type { EphemeralTask, TaskFormErrors, EphemeralTaskInspiration };

export { compareTaskDates, validateTask, updates, compareTaskStatus };
