import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  ListItem,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import { EphemeralTaskInspiration, TaskFormErrors } from "../../types/tasks";
import { Plus, X } from "lucide-react";

interface TaskInspirationFormProps {
  formData: EphemeralTaskInspiration;
  updateFormData: (f: EphemeralTaskInspiration) => void | Promise<void>;
  isEdit: boolean;
  errors: TaskFormErrors | null;
  onSave?: () => void;
}

interface InspirationType {
  value: string;
  label: string;
}

const INSPIRATION_TYPES: InspirationType[] = [
  {
    value: "common",
    label: "common",
  },
  {
    value: "trending",
    label: "trending",
  },
];

interface InspirationStatus {
  value: string;
  label: string;
}

const INSPIRATION_STATUS: InspirationStatus[] = [
  {
    value: "active",
    label: "active",
  },
  {
    value: "inactive",
    label: "inactive",
  },
];

const DETAIL_TYPES = [
  { value: "text", label: "Text" },
  { value: "number", label: "Number" },
  { value: "date", label: "Date" },
];

export default ({
  formData,
  updateFormData,
  isEdit,
  errors,
  onSave,
}: TaskInspirationFormProps) => {
  const selectedType =
    INSPIRATION_TYPES.find((type) => type.value === formData.type) ||
    INSPIRATION_TYPES[0];

  const handleTypeChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    const selectedType = INSPIRATION_TYPES.find(
      (type) => type.value === selectedValue,
    );
    if (selectedType) {
      updateFormData({ ...formData, type: selectedType.value });
    }
  };

  const selectedStatus =
    INSPIRATION_STATUS.find((status) => status.value === formData.status) ||
    INSPIRATION_STATUS[0];

  const handleStatusChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value;
    const selectedStatus = INSPIRATION_STATUS.find(
      (status) => status.value === selectedValue,
    );
    if (selectedStatus) {
      updateFormData({ ...formData, status: selectedStatus.value });
    }
  };

  const handleAddDetail = () => {
    const newDetails = [
      ...(formData.defaultDetails || []),
      { name: "", type: "text" },
    ];
    updateFormData({ ...formData, defaultDetails: newDetails });
  };

  const handleDetailChange = (
    index: number,
    field: "name" | "type",
    value: string,
  ) => {
    const newDetails = [...(formData.defaultDetails || [])];
    newDetails[index] = { ...newDetails[index], [field]: value };
    updateFormData({ ...formData, defaultDetails: newDetails });
  };

  const handleRemoveDetail = (index: number) => {
    const newDetails =
      formData.defaultDetails?.filter((_, i) => i !== index) || [];
    updateFormData({ ...formData, defaultDetails: newDetails });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "12px",
        maxWidth: "850px",
      }}
    >
      <TextField
        error={!!errors?.title}
        helperText={errors?.title}
        label="Title"
        fullWidth
        value={formData.title || ""}
        onChange={(e) => updateFormData({ ...formData, title: e.target.value })}
        margin="none"
      />
      {!isEdit && (
        <FormControl fullWidth>
          <InputLabel>Inspiration type</InputLabel>
          <Select value={selectedType.value} onChange={handleTypeChange}>
            {INSPIRATION_TYPES.map((type) => (
              <MenuItem key={type.value} value={type.value}>
                <ListItem component="div" disablePadding>
                  {type.label}
                </ListItem>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {isEdit && (
        <FormControl fullWidth>
          <InputLabel>Status</InputLabel>
          <Select value={selectedStatus.value} onChange={handleStatusChange}>
            {INSPIRATION_STATUS.map((status) => (
              <MenuItem key={status.value} value={status.value}>
                <ListItem component="div" disablePadding>
                  {status.label}
                </ListItem>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}

      {/* Details Section */}
      <Divider />
      <Typography variant="h4">Details</Typography>
      <Box
        sx={{
          borderLeft: "1px solid #D4D4D4;",
          paddingLeft: "12px",
          marginLeft: "12px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        {(formData.defaultDetails || []).map((detail, index) => (
          <Box
            key={index}
            sx={{ display: "flex", gap: "12px", alignItems: "center" }}
          >
            <TextField
              label="Task Detail"
              value={detail.name}
              placeholder={"A detail relevant to most executions"}
              onChange={(e) =>
                handleDetailChange(index, "name", e.target.value)
              }
              sx={{ flex: "1" }}
              autoFocus
              // On "new line" press, add a new detail
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleAddDetail();
                }
              }}
            />
            <FormControl>
              <InputLabel>Type</InputLabel>
              <Select
                value={detail.type}
                sx={{
                  minWidth: "130px",
                }}
                onChange={(e) =>
                  handleDetailChange(index, "type", e.target.value)
                }
              >
                {DETAIL_TYPES.map((typeOption) => (
                  <MenuItem key={typeOption.value} value={typeOption.value}>
                    {typeOption.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <IconButton onClick={() => handleRemoveDetail(index)}>
              <X />
            </IconButton>
          </Box>
        ))}
        {errors?.details && (
          <Typography color="error" variant="body2">
            {errors.details}
          </Typography>
        )}
      </Box>
      <Button
        startIcon={<Plus />}
        variant={"text"}
        sx={{
          height: "unset",
          justifyContent: "flex-start",
        }}
        onClick={handleAddDetail}
      >
        Add detail
      </Button>
      <Box display="flex" flexDirection="row" justifyContent="end">
        <Button onClick={onSave}>Save</Button>
      </Box>
    </Box>
  );
};
