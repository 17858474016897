import React from "react";
import { Box, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";

export const SectionHeader = ({
  title,
  actionIcon,
}: {
  title: string | React.ReactNode;
  actionIcon?: React.ReactNode;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "flex-start",
        gap: "16px",
        alignSelf: "stretch",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "4px",
          flex: "1 0 0",
          alignSelf: "stretch",
        }}
      >
        <Typography
          component={"h2"}
          sx={{
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "28px",
          }}
        >
          {title}
        </Typography>
      </Box>
      {actionIcon && <Box sx={{}}>{actionIcon}</Box>}
    </Box>
  );
};

export interface SectionProps {
  title: string | React.ReactNode;
  children?: React.ReactNode;
  actionIcon?: React.ReactNode;
  style?: React.CSSProperties;
}
export default ({ title, children, style, actionIcon }: SectionProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "12px",
        alignSelf: "stretch",
        ...style,
      }}
    >
      <SectionHeader title={title} actionIcon={actionIcon} />
      <Divider sx={{ width: "100%" }} />
      {children}
    </Box>
  );
};
