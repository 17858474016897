import { CSSProperties, forwardRef, ReactNode } from "react";
import { Box, Typography } from "@mui/material";
import BlockGutter from "./BlockGutter";
import useIsMobile from "src/components/hooks/useIsMobile";
export default forwardRef(
  (
    {
      gutterIcon,
      children,
      sx,
      isLast,
      advisorViewOnly,
      visibilityCTA,
    }: {
      gutterIcon: ReactNode;
      children: ReactNode;
      sx?: CSSProperties;
      isLast?: boolean;
      advisorViewOnly?: boolean;
      visibilityCTA?: ReactNode;
    },
    ref,
  ) => {
    const isMobile = useIsMobile();
    return (
      <Box
        ref={ref}
        sx={{ display: "flex", gap: isMobile ? "12px" : "16px", ...sx }}
      >
        <BlockGutter icon={gutterIcon} isLast={isLast} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0px",
            width: "100%",
            border: "1px solid #D4D4D4",
            borderRadius: "16px",
            marginBottom: "16px",
            overflow: "hidden",
          }}
        >
          <Box flex={1}>{children}</Box>
          {advisorViewOnly && (
            <Box
              sx={(theme) => ({
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "#FAF9FA",
                padding: "12px 24px",
                borderTop: `1px solid ${theme.palette.border}`,
                borderRadius: "0 0 16px 16px",
              })}
            >
              <Typography variant="bodySmall" sx={{ color: "text.tertiary" }}>
                Only you can see this.
              </Typography>
              {visibilityCTA}
            </Box>
          )}
        </Box>
      </Box>
    );
  },
);
