import { useState } from "react";
import { Box, Button, IconButton, Typography } from "@mui/material";
import PostList from "../components/forum/PostList";
import AddPostDialog from "../components/forum/AddPostDialog";
import useIsMobile from "../components/hooks/useIsMobile";
import { useParams } from "react-router-dom";
import NotFound from "../components/NotFound";
import { capitalize } from "lodash";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router";
import SearchBar from "../components/forum/SearchBar";
import { PAGE_DEFS, TopicFeed } from "../components/forum/constants";
import { ForumPostType } from "../protogen/forum_service_pb";
import LinkRouter from "../components/navigation/LinkRouter";
import { generateSearchQueryUrl } from "../components/search/utils";
import HomePanel from "../components/forum/HomePanel";
import RecommendationsPanel from "../components/forum/RecommendationsPanel";
import ResourcesPanel from "../components/forum/ResourcesPanel";

const panelForTreatment = (treatment: TopicFeed) => {
  switch (treatment) {
    case TopicFeed.Home:
      return <HomePanel />;
    case TopicFeed.Recommendations:
      return <RecommendationsPanel />;
    case TopicFeed.Resources:
      return <ResourcesPanel />;
    default:
      return null;
  }
};

type Props = {
  treatment?: TopicFeed;
};

export default ({ treatment }: Props) => {
  // Now that the forum is the home
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [modalOpen, setModalOpen] = useState(false);
  let params = useParams();
  // Default to explicit topics.
  let topics: string[] | undefined;
  let title: string | null = null;
  let subtitle = null;
  let postType: ForumPostType = ForumPostType.POST_RECOMMENDATION;
  if (treatment) {
    const pageDef = PAGE_DEFS[treatment];
    title = pageDef.title;
    subtitle ||= pageDef.subtitle;
    topics = [];
    postType = pageDef.type;
  } else if (params.topicQuery) {
    topics = decodeURIComponent(params.topicQuery).split(",");
    title = (topics || []).map(capitalize).join(", ");
    postType = ForumPostType.POST_TYPE_UNSPECIFIED;
    if (topics.length === 0) {
      return <NotFound />;
    }
  }
  return (
    <Box
      sx={{
        padding: isMobile ? "32px 20px" : "",
        margin: isMobile ? "" : "64px min(7%, 100px)",
        maxWidth: "1000px",
      }}
    >
      <Box
        sx={{
          flex: "1 1 100%",
          marginBottom: "32px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box display="flex" flexDirection="column">
          {treatment !== TopicFeed.Home && (
            <LinkRouter to={"/community"}>
              <Typography
                sx={{ color: "#198282", fontWeight: "700" }}
                variant="body"
              >
                Community
              </Typography>
            </LinkRouter>
          )}
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <Typography variant="display" sx={{ lineHeight: 1 }}>
              {treatment === TopicFeed.Home ? "Community" : title}
            </Typography>
            {subtitle && <Typography variant="body">{subtitle}</Typography>}
          </Box>
        </Box>
        {isMobile ? (
          <IconButton
            type="button"
            sx={{ p: "10px", marginRight: "10px" }}
            aria-label="search"
            onClick={() => {
              navigate(generateSearchQueryUrl(null, topics, "community"));
            }}
          >
            <SearchIcon />
          </IconButton>
        ) : (
          <SearchBar topics={topics} />
        )}
      </Box>
      {treatment && panelForTreatment(treatment)}
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "end",
        }}
      >
        <Typography variant="h3">Recent posts</Typography>
        <Button
          variant="outlined"
          size="small"
          onClick={() => setModalOpen(true)}
        >
          New post
        </Button>
      </Box>
      <PostList
        key={`feed-${treatment}-${(topics || []).join("-")}`}
        topics={topics}
        postType={treatment === TopicFeed.Home ? null : postType}
        respectPinned={treatment !== TopicFeed.Home}
      />
      {/* Pre-select category */}
      <AddPostDialog
        key={`post-${treatment}-${(topics || []).join("-")}`}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        topics={topics}
        postType={postType}
      />
    </Box>
  );
};
