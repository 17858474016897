import useProtoMethod from "./useProtoMethod";
import {
  GenerateSuggestionsRequest,
  GenerateSuggestionsResponse,
  GPTExtractionFromSegmentsPromptRequest,
  GPTExtractionFromSegmentsPromptResponse,
  GPTExtractionFromSegmentsRequest,
  GPTExtractionFromSegmentsResponse,
  ListExtractionFunctionsRequest,
  ListExtractionFunctionsResponse,
  RunWorkflowRequest,
  RunWorkflowResponse,
} from "../protogen/extraction_service_pb";
import { getInferenceDomain } from "../constants";

const useSegmentExtraction = (
  callback?: (r: GPTExtractionFromSegmentsResponse) => void,
) => {
  return useProtoMethod<
    GPTExtractionFromSegmentsRequest,
    GPTExtractionFromSegmentsResponse
  >("GPTExtractionFromSegments", GPTExtractionFromSegmentsResponse, {
    baseUrl: getInferenceDomain(),
    callback,
  });
};

const useGenerateSuggestions = (
  callback?: (r: GenerateSuggestionsResponse) => void,
) => {
  return useProtoMethod<
    GenerateSuggestionsRequest,
    GenerateSuggestionsResponse
  >("GenerateSuggestions", GenerateSuggestionsResponse, {
    baseUrl: getInferenceDomain(),
    callback,
  });
};

const useListExtractionFunction = (
  callback?: (r: ListExtractionFunctionsResponse) => void,
) => {
  return useProtoMethod<
    ListExtractionFunctionsRequest,
    ListExtractionFunctionsResponse
  >("ListExtractionFunctions", ListExtractionFunctionsResponse, {
    baseUrl: getInferenceDomain(),
    callback,
  });
};

const usePromptExtraction = (
  callback?: (r: GPTExtractionFromSegmentsPromptResponse) => void,
) => {
  return useProtoMethod<
    GPTExtractionFromSegmentsPromptRequest,
    GPTExtractionFromSegmentsPromptResponse
  >(
    "GPTExtractionFromSegmentsPrompt",
    GPTExtractionFromSegmentsPromptResponse,
    {
      baseUrl: getInferenceDomain(),
      callback,
    },
  );
};

const useRunWorkflow = (callback?: (r: RunWorkflowResponse) => void) => {
  return useProtoMethod<RunWorkflowRequest, RunWorkflowResponse>(
    "RunWorkflow",
    RunWorkflowResponse,
    {
      baseUrl: getInferenceDomain(),
      callback,
    },
  );
};

export {
  useSegmentExtraction,
  useListExtractionFunction,
  usePromptExtraction,
  useGenerateSuggestions,
  useRunWorkflow,
};
