// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/billing_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { FamilyStub } from "./common_pb.ts";
import { BillingCoupon, BillingProduct } from "./billing_pb.ts";

/**
 * @generated from message protogen.ConnectedAccount
 */
export class ConnectedAccount extends Message<ConnectedAccount> {
  /**
   * @generated from field: string name = 1;
   */
  name = "";

  /**
   * @generated from field: bool active = 2;
   */
  active = false;

  constructor(data?: PartialMessage<ConnectedAccount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ConnectedAccount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "active", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ConnectedAccount {
    return new ConnectedAccount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ConnectedAccount {
    return new ConnectedAccount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ConnectedAccount {
    return new ConnectedAccount().fromJsonString(jsonString, options);
  }

  static equals(a: ConnectedAccount | PlainMessage<ConnectedAccount> | undefined, b: ConnectedAccount | PlainMessage<ConnectedAccount> | undefined): boolean {
    return proto3.util.equals(ConnectedAccount, a, b);
  }
}

/**
 * @generated from message protogen.AdvisorFetchBillingRequest
 */
export class AdvisorFetchBillingRequest extends Message<AdvisorFetchBillingRequest> {
  /**
   * @generated from field: string advisorRef = 1;
   */
  advisorRef = "";

  constructor(data?: PartialMessage<AdvisorFetchBillingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AdvisorFetchBillingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdvisorFetchBillingRequest {
    return new AdvisorFetchBillingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdvisorFetchBillingRequest {
    return new AdvisorFetchBillingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdvisorFetchBillingRequest {
    return new AdvisorFetchBillingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdvisorFetchBillingRequest | PlainMessage<AdvisorFetchBillingRequest> | undefined, b: AdvisorFetchBillingRequest | PlainMessage<AdvisorFetchBillingRequest> | undefined): boolean {
    return proto3.util.equals(AdvisorFetchBillingRequest, a, b);
  }
}

/**
 * @generated from message protogen.AdvisorFetchBillingResponse
 */
export class AdvisorFetchBillingResponse extends Message<AdvisorFetchBillingResponse> {
  /**
   * @generated from field: bool accountLinked = 1;
   */
  accountLinked = false;

  /**
   * @generated from field: bool paymentsActive = 2;
   */
  paymentsActive = false;

  /**
   * @generated from field: repeated protogen.ConnectedAccount connections = 3;
   */
  connections: ConnectedAccount[] = [];

  /**
   * @generated from field: bool account = 4;
   */
  account = false;

  constructor(data?: PartialMessage<AdvisorFetchBillingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AdvisorFetchBillingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accountLinked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "paymentsActive", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "connections", kind: "message", T: ConnectedAccount, repeated: true },
    { no: 4, name: "account", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdvisorFetchBillingResponse {
    return new AdvisorFetchBillingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdvisorFetchBillingResponse {
    return new AdvisorFetchBillingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdvisorFetchBillingResponse {
    return new AdvisorFetchBillingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdvisorFetchBillingResponse | PlainMessage<AdvisorFetchBillingResponse> | undefined, b: AdvisorFetchBillingResponse | PlainMessage<AdvisorFetchBillingResponse> | undefined): boolean {
    return proto3.util.equals(AdvisorFetchBillingResponse, a, b);
  }
}

/**
 * @generated from message protogen.AdvisorGenerateBillingAccountRequest
 */
export class AdvisorGenerateBillingAccountRequest extends Message<AdvisorGenerateBillingAccountRequest> {
  /**
   * @generated from field: string advisorRef = 1;
   */
  advisorRef = "";

  constructor(data?: PartialMessage<AdvisorGenerateBillingAccountRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AdvisorGenerateBillingAccountRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdvisorGenerateBillingAccountRequest {
    return new AdvisorGenerateBillingAccountRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdvisorGenerateBillingAccountRequest {
    return new AdvisorGenerateBillingAccountRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdvisorGenerateBillingAccountRequest {
    return new AdvisorGenerateBillingAccountRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdvisorGenerateBillingAccountRequest | PlainMessage<AdvisorGenerateBillingAccountRequest> | undefined, b: AdvisorGenerateBillingAccountRequest | PlainMessage<AdvisorGenerateBillingAccountRequest> | undefined): boolean {
    return proto3.util.equals(AdvisorGenerateBillingAccountRequest, a, b);
  }
}

/**
 * @generated from message protogen.AdvisorGenerateBillingAccountResponse
 */
export class AdvisorGenerateBillingAccountResponse extends Message<AdvisorGenerateBillingAccountResponse> {
  /**
   * better...
   *
   * @generated from field: string setupUrl = 1;
   */
  setupUrl = "";

  constructor(data?: PartialMessage<AdvisorGenerateBillingAccountResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AdvisorGenerateBillingAccountResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "setupUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdvisorGenerateBillingAccountResponse {
    return new AdvisorGenerateBillingAccountResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdvisorGenerateBillingAccountResponse {
    return new AdvisorGenerateBillingAccountResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdvisorGenerateBillingAccountResponse {
    return new AdvisorGenerateBillingAccountResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdvisorGenerateBillingAccountResponse | PlainMessage<AdvisorGenerateBillingAccountResponse> | undefined, b: AdvisorGenerateBillingAccountResponse | PlainMessage<AdvisorGenerateBillingAccountResponse> | undefined): boolean {
    return proto3.util.equals(AdvisorGenerateBillingAccountResponse, a, b);
  }
}

/**
 * @generated from message protogen.AdvisorUpdateBillingRequest
 */
export class AdvisorUpdateBillingRequest extends Message<AdvisorUpdateBillingRequest> {
  /**
   * @generated from field: string advisorRef = 1;
   */
  advisorRef = "";

  constructor(data?: PartialMessage<AdvisorUpdateBillingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AdvisorUpdateBillingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "advisorRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdvisorUpdateBillingRequest {
    return new AdvisorUpdateBillingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdvisorUpdateBillingRequest {
    return new AdvisorUpdateBillingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdvisorUpdateBillingRequest {
    return new AdvisorUpdateBillingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdvisorUpdateBillingRequest | PlainMessage<AdvisorUpdateBillingRequest> | undefined, b: AdvisorUpdateBillingRequest | PlainMessage<AdvisorUpdateBillingRequest> | undefined): boolean {
    return proto3.util.equals(AdvisorUpdateBillingRequest, a, b);
  }
}

/**
 * @generated from message protogen.AdvisorUpdateBillingResponse
 */
export class AdvisorUpdateBillingResponse extends Message<AdvisorUpdateBillingResponse> {
  /**
   * bad...
   *
   * @generated from field: bool account = 1;
   */
  account = false;

  constructor(data?: PartialMessage<AdvisorUpdateBillingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AdvisorUpdateBillingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "account", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdvisorUpdateBillingResponse {
    return new AdvisorUpdateBillingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdvisorUpdateBillingResponse {
    return new AdvisorUpdateBillingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdvisorUpdateBillingResponse {
    return new AdvisorUpdateBillingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdvisorUpdateBillingResponse | PlainMessage<AdvisorUpdateBillingResponse> | undefined, b: AdvisorUpdateBillingResponse | PlainMessage<AdvisorUpdateBillingResponse> | undefined): boolean {
    return proto3.util.equals(AdvisorUpdateBillingResponse, a, b);
  }
}

/**
 * @generated from message protogen.CustomerCharge
 */
export class CustomerCharge extends Message<CustomerCharge> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int32 amount = 2;
   */
  amount = 0;

  /**
   * @generated from field: string currency = 3;
   */
  currency = "";

  /**
   * @generated from field: string description = 4;
   */
  description = "";

  /**
   * @generated from field: int64 createdSec = 5;
   */
  createdSec = protoInt64.zero;

  constructor(data?: PartialMessage<CustomerCharge>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CustomerCharge";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "createdSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CustomerCharge {
    return new CustomerCharge().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CustomerCharge {
    return new CustomerCharge().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CustomerCharge {
    return new CustomerCharge().fromJsonString(jsonString, options);
  }

  static equals(a: CustomerCharge | PlainMessage<CustomerCharge> | undefined, b: CustomerCharge | PlainMessage<CustomerCharge> | undefined): boolean {
    return proto3.util.equals(CustomerCharge, a, b);
  }
}

/**
 * @generated from message protogen.CustomerAccount
 */
export class CustomerAccount extends Message<CustomerAccount> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int64 createdSec = 2;
   */
  createdSec = protoInt64.zero;

  /**
   * @generated from field: string email = 3;
   */
  email = "";

  /**
   * @generated from field: string phone = 4;
   */
  phone = "";

  /**
   * @generated from field: string name = 5;
   */
  name = "";

  /**
   * @generated from field: map<string, string> metadata = 6;
   */
  metadata: { [key: string]: string } = {};

  constructor(data?: PartialMessage<CustomerAccount>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CustomerAccount";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "createdSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "metadata", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "scalar", T: 9 /* ScalarType.STRING */} },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CustomerAccount {
    return new CustomerAccount().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CustomerAccount {
    return new CustomerAccount().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CustomerAccount {
    return new CustomerAccount().fromJsonString(jsonString, options);
  }

  static equals(a: CustomerAccount | PlainMessage<CustomerAccount> | undefined, b: CustomerAccount | PlainMessage<CustomerAccount> | undefined): boolean {
    return proto3.util.equals(CustomerAccount, a, b);
  }
}

/**
 * @generated from message protogen.CreateFamilyBillingRequest
 */
export class CreateFamilyBillingRequest extends Message<CreateFamilyBillingRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string paymentMethodId = 2;
   */
  paymentMethodId = "";

  constructor(data?: PartialMessage<CreateFamilyBillingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFamilyBillingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "paymentMethodId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFamilyBillingRequest {
    return new CreateFamilyBillingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFamilyBillingRequest {
    return new CreateFamilyBillingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFamilyBillingRequest {
    return new CreateFamilyBillingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFamilyBillingRequest | PlainMessage<CreateFamilyBillingRequest> | undefined, b: CreateFamilyBillingRequest | PlainMessage<CreateFamilyBillingRequest> | undefined): boolean {
    return proto3.util.equals(CreateFamilyBillingRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateFamilyBillingResponse
 */
export class CreateFamilyBillingResponse extends Message<CreateFamilyBillingResponse> {
  /**
   * @generated from field: protogen.CustomerAccount customerAccount = 1;
   */
  customerAccount?: CustomerAccount;

  constructor(data?: PartialMessage<CreateFamilyBillingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateFamilyBillingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customerAccount", kind: "message", T: CustomerAccount },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateFamilyBillingResponse {
    return new CreateFamilyBillingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateFamilyBillingResponse {
    return new CreateFamilyBillingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateFamilyBillingResponse {
    return new CreateFamilyBillingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateFamilyBillingResponse | PlainMessage<CreateFamilyBillingResponse> | undefined, b: CreateFamilyBillingResponse | PlainMessage<CreateFamilyBillingResponse> | undefined): boolean {
    return proto3.util.equals(CreateFamilyBillingResponse, a, b);
  }
}

/**
 * @generated from message protogen.CreateMemberBillingRequest
 */
export class CreateMemberBillingRequest extends Message<CreateMemberBillingRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string paymentMethodId = 2;
   */
  paymentMethodId = "";

  constructor(data?: PartialMessage<CreateMemberBillingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateMemberBillingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "paymentMethodId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateMemberBillingRequest {
    return new CreateMemberBillingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateMemberBillingRequest {
    return new CreateMemberBillingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateMemberBillingRequest {
    return new CreateMemberBillingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: CreateMemberBillingRequest | PlainMessage<CreateMemberBillingRequest> | undefined, b: CreateMemberBillingRequest | PlainMessage<CreateMemberBillingRequest> | undefined): boolean {
    return proto3.util.equals(CreateMemberBillingRequest, a, b);
  }
}

/**
 * @generated from message protogen.CreateMemberBillingResponse
 */
export class CreateMemberBillingResponse extends Message<CreateMemberBillingResponse> {
  /**
   * @generated from field: protogen.CustomerAccount customerAccount = 1;
   */
  customerAccount?: CustomerAccount;

  constructor(data?: PartialMessage<CreateMemberBillingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.CreateMemberBillingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customerAccount", kind: "message", T: CustomerAccount },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): CreateMemberBillingResponse {
    return new CreateMemberBillingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): CreateMemberBillingResponse {
    return new CreateMemberBillingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): CreateMemberBillingResponse {
    return new CreateMemberBillingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: CreateMemberBillingResponse | PlainMessage<CreateMemberBillingResponse> | undefined, b: CreateMemberBillingResponse | PlainMessage<CreateMemberBillingResponse> | undefined): boolean {
    return proto3.util.equals(CreateMemberBillingResponse, a, b);
  }
}

/**
 * @generated from message protogen.FetchFamilyBillingRequest
 */
export class FetchFamilyBillingRequest extends Message<FetchFamilyBillingRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  constructor(data?: PartialMessage<FetchFamilyBillingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchFamilyBillingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchFamilyBillingRequest {
    return new FetchFamilyBillingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchFamilyBillingRequest {
    return new FetchFamilyBillingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchFamilyBillingRequest {
    return new FetchFamilyBillingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: FetchFamilyBillingRequest | PlainMessage<FetchFamilyBillingRequest> | undefined, b: FetchFamilyBillingRequest | PlainMessage<FetchFamilyBillingRequest> | undefined): boolean {
    return proto3.util.equals(FetchFamilyBillingRequest, a, b);
  }
}

/**
 * @generated from message protogen.FetchFamilyBillingResponse
 */
export class FetchFamilyBillingResponse extends Message<FetchFamilyBillingResponse> {
  /**
   * @generated from field: bool accountLinked = 1;
   */
  accountLinked = false;

  /**
   * @generated from field: protogen.CustomerAccount customerAccount = 2;
   */
  customerAccount?: CustomerAccount;

  /**
   * @generated from field: repeated protogen.CustomerCharge charges = 3;
   */
  charges: CustomerCharge[] = [];

  /**
   * @generated from field: protogen.FamilyStub family = 4;
   */
  family?: FamilyStub;

  constructor(data?: PartialMessage<FetchFamilyBillingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.FetchFamilyBillingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accountLinked", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "customerAccount", kind: "message", T: CustomerAccount },
    { no: 3, name: "charges", kind: "message", T: CustomerCharge, repeated: true },
    { no: 4, name: "family", kind: "message", T: FamilyStub },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): FetchFamilyBillingResponse {
    return new FetchFamilyBillingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): FetchFamilyBillingResponse {
    return new FetchFamilyBillingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): FetchFamilyBillingResponse {
    return new FetchFamilyBillingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: FetchFamilyBillingResponse | PlainMessage<FetchFamilyBillingResponse> | undefined, b: FetchFamilyBillingResponse | PlainMessage<FetchFamilyBillingResponse> | undefined): boolean {
    return proto3.util.equals(FetchFamilyBillingResponse, a, b);
  }
}

/**
 * @generated from message protogen.UpdateFamilyBillingRequest
 */
export class UpdateFamilyBillingRequest extends Message<UpdateFamilyBillingRequest> {
  /**
   * @generated from field: string familyRef = 1;
   */
  familyRef = "";

  /**
   * @generated from field: string customerId = 2;
   */
  customerId = "";

  /**
   * @generated from field: bool unlinkCustomer = 3;
   */
  unlinkCustomer = false;

  constructor(data?: PartialMessage<UpdateFamilyBillingRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateFamilyBillingRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "familyRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "customerId", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "unlinkCustomer", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFamilyBillingRequest {
    return new UpdateFamilyBillingRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFamilyBillingRequest {
    return new UpdateFamilyBillingRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFamilyBillingRequest {
    return new UpdateFamilyBillingRequest().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateFamilyBillingRequest | PlainMessage<UpdateFamilyBillingRequest> | undefined, b: UpdateFamilyBillingRequest | PlainMessage<UpdateFamilyBillingRequest> | undefined): boolean {
    return proto3.util.equals(UpdateFamilyBillingRequest, a, b);
  }
}

/**
 * @generated from message protogen.UpdateFamilyBillingResponse
 */
export class UpdateFamilyBillingResponse extends Message<UpdateFamilyBillingResponse> {
  /**
   * @generated from field: protogen.CustomerAccount customerAccount = 1;
   */
  customerAccount?: CustomerAccount;

  constructor(data?: PartialMessage<UpdateFamilyBillingResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.UpdateFamilyBillingResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "customerAccount", kind: "message", T: CustomerAccount },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): UpdateFamilyBillingResponse {
    return new UpdateFamilyBillingResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): UpdateFamilyBillingResponse {
    return new UpdateFamilyBillingResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): UpdateFamilyBillingResponse {
    return new UpdateFamilyBillingResponse().fromJsonString(jsonString, options);
  }

  static equals(a: UpdateFamilyBillingResponse | PlainMessage<UpdateFamilyBillingResponse> | undefined, b: UpdateFamilyBillingResponse | PlainMessage<UpdateFamilyBillingResponse> | undefined): boolean {
    return proto3.util.equals(UpdateFamilyBillingResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListBillingProductsRequest
 */
export class ListBillingProductsRequest extends Message<ListBillingProductsRequest> {
  constructor(data?: PartialMessage<ListBillingProductsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListBillingProductsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListBillingProductsRequest {
    return new ListBillingProductsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListBillingProductsRequest {
    return new ListBillingProductsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListBillingProductsRequest {
    return new ListBillingProductsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListBillingProductsRequest | PlainMessage<ListBillingProductsRequest> | undefined, b: ListBillingProductsRequest | PlainMessage<ListBillingProductsRequest> | undefined): boolean {
    return proto3.util.equals(ListBillingProductsRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListBillingProductsResponse
 */
export class ListBillingProductsResponse extends Message<ListBillingProductsResponse> {
  /**
   * @generated from field: repeated protogen.BillingProduct products = 1;
   */
  products: BillingProduct[] = [];

  constructor(data?: PartialMessage<ListBillingProductsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListBillingProductsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "products", kind: "message", T: BillingProduct, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListBillingProductsResponse {
    return new ListBillingProductsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListBillingProductsResponse {
    return new ListBillingProductsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListBillingProductsResponse {
    return new ListBillingProductsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListBillingProductsResponse | PlainMessage<ListBillingProductsResponse> | undefined, b: ListBillingProductsResponse | PlainMessage<ListBillingProductsResponse> | undefined): boolean {
    return proto3.util.equals(ListBillingProductsResponse, a, b);
  }
}

/**
 * @generated from message protogen.ListBillingCouponsRequest
 */
export class ListBillingCouponsRequest extends Message<ListBillingCouponsRequest> {
  constructor(data?: PartialMessage<ListBillingCouponsRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListBillingCouponsRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListBillingCouponsRequest {
    return new ListBillingCouponsRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListBillingCouponsRequest {
    return new ListBillingCouponsRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListBillingCouponsRequest {
    return new ListBillingCouponsRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ListBillingCouponsRequest | PlainMessage<ListBillingCouponsRequest> | undefined, b: ListBillingCouponsRequest | PlainMessage<ListBillingCouponsRequest> | undefined): boolean {
    return proto3.util.equals(ListBillingCouponsRequest, a, b);
  }
}

/**
 * @generated from message protogen.ListBillingCouponsResponse
 */
export class ListBillingCouponsResponse extends Message<ListBillingCouponsResponse> {
  /**
   * @generated from field: repeated protogen.BillingCoupon coupons = 1;
   */
  coupons: BillingCoupon[] = [];

  constructor(data?: PartialMessage<ListBillingCouponsResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ListBillingCouponsResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "coupons", kind: "message", T: BillingCoupon, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ListBillingCouponsResponse {
    return new ListBillingCouponsResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ListBillingCouponsResponse {
    return new ListBillingCouponsResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ListBillingCouponsResponse {
    return new ListBillingCouponsResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ListBillingCouponsResponse | PlainMessage<ListBillingCouponsResponse> | undefined, b: ListBillingCouponsResponse | PlainMessage<ListBillingCouponsResponse> | undefined): boolean {
    return proto3.util.equals(ListBillingCouponsResponse, a, b);
  }
}

