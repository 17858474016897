import { Alert, AlertTitle, Box, Button, Typography } from "@mui/material";
import useIsMobile from "../../components/hooks/useIsMobile";
import { useLocation, useParams } from "react-router-dom";
import { CurrentUserContext } from "../../components/context/RequireAuth";
import { useContext, useEffect, useState } from "react";
import { isUserAdmin } from "../../common/userUtils";
import {
  useAdvisorFetchBilling,
  useAdvisorGenerateBillingAccount,
} from "../../services/billing";
import Loading from "../../components/common/Loading";
import { TriangleAlert } from "lucide-react";
import CheckIcon from "@mui/icons-material/Check";

const AccountLink = ({
  advisorRef,
  text,
}: {
  advisorRef: string;
  text: string;
}) => {
  const [loading, setLoading] = useState(false);
  const { request } = useAdvisorGenerateBillingAccount();
  const linkAccount = async () => {
    setLoading(true);
    const resp = await request({ advisorRef });
    if (resp) {
      window.location.href = resp.setupUrl;
    }
  };
  return (
    <>
      <Button onClick={linkAccount} sx={{ width: "250px" }} disabled={loading}>
        {text}
      </Button>
    </>
  );
};

type Props = {
  isRefreshCallback?: boolean;
  isReturnCallback?: boolean;
};
export default ({
  isRefreshCallback = false,
  isReturnCallback = false,
}: Props) => {
  const currentUser = useContext(CurrentUserContext);
  let params = useParams();
  const isMobile = useIsMobile();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const refParam =
    isReturnCallback || isRefreshCallback
      ? queryParams.get("ref")
      : params.advisorRef;
  const advisorRef = (isUserAdmin(currentUser) && refParam) || currentUser?.ref;
  const { request, data, loading } = useAdvisorFetchBilling();

  useEffect(() => {
    request({ advisorRef: params.advisorRef || "" });
  }, []);

  if (loading) {
    return <Loading />;
  }
  let actionText = "Link Account";
  if (data?.accountLinked && data?.paymentsActive) {
    actionText = "Update payment account";
  } else if (data?.accountLinked && !data?.paymentsActive) {
    actionText = "Complete setup";
  }
  return (
    <Box
      sx={{
        margin: isMobile ? "" : "64px min(7%, 100px)",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "0",
      }}
    >
      <Box display="flex" flexDirection="column" gap="24px">
        <Typography variant="display">Advisor Payments</Typography>
        <Box display="flex" flexDirection="row" alignItems="center" gap="12px">
          {!data?.paymentsActive && (
            <>
              <TriangleAlert size={24} color="#EF7B77" />
              <Typography variant="h4" color="#EF7B77">
                {data?.accountLinked
                  ? "Setup is not complete for this account"
                  : "Payments have not been enabled for this account"}
              </Typography>
            </>
          )}
        </Box>
        {data?.paymentsActive && (
          <Box>
            <Typography variant="h4">
              Payments are enabled for this account
            </Typography>
          </Box>
        )}
        {data?.connections.map((c) => (
          <Alert
            key={c.name}
            icon={c.active ? <CheckIcon fontSize="inherit" /> : undefined}
            severity={c.active ? "success" : "warning"}
            sx={{ width: "450px" }}
          >
            <AlertTitle>{c.name}</AlertTitle>
          </Alert>
        ))}
        <AccountLink advisorRef={advisorRef} text={actionText} />
      </Box>
    </Box>
  );
};
