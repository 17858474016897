import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DialpadIcon from "@mui/icons-material/Dialpad";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { PhoneState } from "../../types/phone";
import CallIcon from "@mui/icons-material/Call";
import * as React from "react";
import { useContext, useState } from "react";
import { CallContext } from "../context/AddCallContext";
import { useTransferCallDevice } from "../../services/phone";
import { Device } from "../../protogen/phone_service_pb";
import Dialpad from "../phone/Dialpad";

const ActionButton = ({
  icon,
  label,
  onClick,
  pressed = false,
}: {
  icon: React.ReactNode;
  label: string;
  pressed?: boolean;
  onClick?: () => void;
}) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", textAlign: "center" }}
    >
      <IconButton
        aria-label={label}
        onClick={onClick}
        color={pressed ? "primary" : "default"}
      >
        {icon}
      </IconButton>
      <Typography variant="caption">{label}</Typography>
    </div>
  );
};

export default ({}: {}) => {
  const {
    device: {
      phoneState,
      isMuted,
      acceptCall,
      denyCall,
      endCall,
      muteCall,
      sendDigits,
    },
    activeCallId,
  } = useContext(CallContext);
  const { request } = useTransferCallDevice();
  const [dialpadOpen, setDialpadOpen] = useState(false);
  const callForward = () => {
    request({
      callId: activeCallId!,
      toDevice: Device.PHONE,
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        gap: "24px",
        marginBottom: "15px",
        padding: "16px 24px",
        position: "relative",
      }}
    >
      {phoneState === PhoneState.RINGING && (
        <>
          <ActionButton
            icon={<CallIcon />}
            label="Accept"
            onClick={acceptCall}
          />
          <ActionButton icon={<CloseIcon />} label="Deny" onClick={denyCall} />
        </>
      )}
      {phoneState === PhoneState.ACTIVE && (
        <>
          <ActionButton
            icon={<PhoneForwardedIcon />}
            label="Transfer"
            onClick={callForward}
          />
          <ActionButton
            icon={isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
            label="Mute"
            onClick={muteCall}
          />
          <ActionButton
            icon={<DialpadIcon />}
            label="Keypad"
            onClick={() => setDialpadOpen((o) => !o)}
            pressed={dialpadOpen}
          />
          <ActionButton icon={<CloseIcon />} label="End" onClick={endCall} />
          <Dialpad
            open={dialpadOpen}
            onPress={(d) => sendDigits && sendDigits(d)}
          />
        </>
      )}
    </Box>
  );
};
