import { FeedEntry, Medium } from "../../protogen/advisors_service_pb";
import { CircularProgress, IconButton } from "@mui/material";
import TaskIcon from "@mui/icons-material/Task";
import { DocumentPlusIcon } from "@heroicons/react/24/outline";

import { useNavigate } from "react-router-dom";
import { EphemeralFact } from "../facts/types";
import { DocumentSuggestion } from "../../protogen/suggestions_pb";

const SuggestedAttachmentAction = ({
  entry,
  suggestFact,
}: {
  entry: FeedEntry;
  suggestFact: (t: EphemeralFact) => void;
}) => {
  // Only handle the first suggestion for now
  const suggestion = entry.textMessage?.documentSuggestion;
  const AGE_THRESHOLD = 70; // Only wait 70 seconds.
  const ageSeconds =
    new Date().getTime() / 1000 - entry.textMessage?.timestampSec;
  const loading =
    ageSeconds < AGE_THRESHOLD && !entry.textMessage?.documentSuggestion;
  return (
    <IconButton
      sx={{
        borderRadius: "36px",
        border: "2px solid #F2EFEC",
        background: "#FFF",
        width: "40px",
        padding: "8px",
        position: "relative",
      }}
      onClick={() => {
        if (loading) return;
        suggestFact({
          name: suggestion?.name || "Message attachments",
          value: suggestion?.details || "",
          valueType: "document",
          attachments: entry.textMessage.attachments,
          uploads: [],
          sourceEntityType: "message",
          sourceEntityRef: entry.ref,
        });
      }}
    >
      <DocumentPlusIcon height={20} width={20} stroke="#198282" />
      {loading && (
        <CircularProgress
          size={40}
          thickness={2}
          sx={{
            position: "absolute",
            color: "#198282",
            top: -2,
            left: -2,
            zIndex: 1,
          }}
        />
      )}
    </IconButton>
  );
};

const ExistingAttachmentAction = ({
  suggestion,
}: {
  suggestion: DocumentSuggestion;
}) => {
  const navigate = useNavigate();
  return (
    <IconButton
      onClick={() => {
        navigate(`?factRef=${encodeURIComponent(suggestion.factRef)}`);
      }}
    >
      <TaskIcon />
    </IconButton>
  );
};

const actionForEntry = (
  entry: FeedEntry,
  suggestFact: (t: EphemeralFact) => void,
) => {
  if (entry.medium === Medium.SMS && entry.textMessage?.attachments.length) {
    // Handle existing case.
    if (entry.textMessage?.documentSuggestion?.factRef) {
      return (
        <ExistingAttachmentAction
          suggestion={entry.textMessage.documentSuggestion}
        />
      );
    }
    return (
      <SuggestedAttachmentAction entry={entry} suggestFact={suggestFact} />
    );
  }
  return null;
};

export { actionForEntry };
