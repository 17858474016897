import React, { useState } from "react";
import {
  Avatar,
  Container,
  List,
  ListSubheader,
  Skeleton,
  Switch,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import useIsMobile from "../components/hooks/useIsMobile";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import { getInferenceDomain } from "../constants";
import { useLocation, useNavigate } from "react-router-dom";
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";

const HOST = getInferenceDomain();

type ResultType = {
  title: string;
  content: string;
  context: string;
  url: string;
  text: string;
};

enum FeedbackType {
  POSITIVE = "POSITIVE",
  NEGATIVE = "NEGATIVE",
}

const HighlightedText = ({ text, query }: { text: string; query: string }) => {
  if (!query) {
    return <>{text}</>;
  }

  // Create a regular expression with the query for case-insensitive highlighting
  const regex = new RegExp(`(${query})`, "gi");
  const parts = text.split(regex);

  return (
    <>
      {parts.map((part, index) =>
        regex.test(part) ? (
          <mark
            key={index}
            className="highlighted"
            style={{
              backgroundColor: "#FFFF8F",
              fontWeight: "bold",
            }}
          >
            {part}
          </mark>
        ) : (
          part
        ),
      )}
    </>
  );
};

const useApiCall = <T1, T2>(url: string) => {
  const [loading, setLoading] = useState(false);
  const [setError] = useState<any | null>(null);
  const [data, setData] = useState<null | T2>(null);

  const request = async (payload: T1) => {
    try {
      setLoading(true);
      const response: { data: T2 } = await axios.post(url, payload, {
        baseURL: HOST,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setData(response.data);
    } catch (error) {
      setError(error);
      console.error("Error fetching search results:", error);
    }
    setLoading(false);
  };

  return {
    data,
    request,
    loading,
  };
};

const useSearch = () => {
  return useApiCall<
    { query: string; context: Context },
    { results: ResultType[]; queryId: string }
  >("/statistics/query");
};

const useProvideFeedback = () => {
  return useApiCall<
    { feedback: FeedbackType; result: ResultType; queryId: string },
    {}
  >("/statistics/feedback");
};

const SearchResult = ({
  result,
  queryId,
}: {
  result: ResultType;
  queryId: string;
}) => {
  const isMobile = useIsMobile();
  const { request, loading } = useProvideFeedback();
  const [hover, setHover] = useState(false);
  const [feedbackState, setFeedbackState] = useState<null | FeedbackType>(null);

  const feedback = async (f: FeedbackType) => {
    request({
      result: result,
      queryId: queryId,
      feedback: f,
    });
    setFeedbackState(f);
  };
  return (
    <Box
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        display: "flex",
        flexDirection: "row",
        padding: "8px 16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "12px",
            background: "#f1f3f4",
            width: "52px",
            borderRadius: "47px",
            padding: "20px 0",
            visibility: hover || isMobile ? "visible" : "hidden",
          }}
        >
          <IconButton
            sx={{ "&:hover svg path": { color: "black" } }}
            disableRipple={true}
            disabled={loading}
            onClick={() => feedback(FeedbackType.POSITIVE)}
          >
            {feedbackState !== FeedbackType.POSITIVE ? (
              <ThumbUpOffAltIcon />
            ) : (
              <ThumbUpAltIcon />
            )}
          </IconButton>
          <IconButton
            sx={{ "&:hover svg path": { color: "black" } }}
            disableRipple={true}
            disabled={loading}
            onClick={() => feedback(FeedbackType.NEGATIVE)}
          >
            {feedbackState !== FeedbackType.NEGATIVE ? (
              <ThumbDownOffAltIcon />
            ) : (
              <ThumbDownAltIcon />
            )}
          </IconButton>
        </div>
      </Box>
      <Box
        onClick={() => {
          const start = encodeURIComponent(
            result.context.split(" ").slice(0, 4).join(" "),
          );
          const end = encodeURIComponent(
            result.context.split(" ").slice(-4).join(" "),
          );
          const link = `${result.url}#:~:text=${start},${end}`;
          window.open(link, "_blank");
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          cursor: "pointer",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Avatar
            alt={result.title}
            sx={{ width: "24px", height: "24px", margin: "10px" }}
          />
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography
              variant="h6"
              component="span"
              sx={{
                flexGrow: 1,
                fontSize: "1rem",
                lineHeight: "1.5",
              }}
            >
              {result.title}
            </Typography>
            <Typography
              variant="h6"
              component="span"
              sx={{
                flexGrow: 1,
                fontSize: "0.875rem",
                lineHeight: "1.43",
                color: "rgba(0, 0, 0, 0.6)",
              }}
            >
              {result.url}
            </Typography>
          </div>
        </div>
        <Typography
          variant="h6"
          component="span"
          sx={{
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: "14px",
            lineHeight: "18px",
            marginLeft: "10px",
            padding: "5px 5px",
          }}
        >
          <HighlightedText text={result.context} query={result.content} />
        </Typography>
      </Box>
    </Box>
  );
};

const SearchResults = ({
  results,
  queryId,
}: {
  queryId: string;
  results: ResultType[];
}) => (
  <Container sx={{ maxWidth: "950px", marginTop: "40px" }}>
    <List>
      <div style={{ display: "flex", flexDirection: "column" }}>
        {results.map((result, index) => (
          <SearchResult key={index} queryId={queryId} result={result} />
        ))}
      </div>
    </List>
  </Container>
);

const SearchLoading = ({ count = 1 }: { count?: number }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      marginTop="24px"
      sx={{ width: "100%" }}
    >
      {[...Array(count)].map((_, index) => (
        <Box
          key={index}
          flexGrow={1}
          padding="16px 16px 0px 16px"
          overflow="auto"
          sx={{}}
        >
          <Box sx={{ pt: 0.5 }}>
            <Skeleton width="30%" />
            <Skeleton width="30%" />
            <Skeleton />
            <Skeleton width="80%" />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

type SettingsItemProps = {
  title: string;
  // value: string;
  checked: boolean;
  icon: React.ReactNode;
  toggleOn: () => void;
  toggleOff: () => void;
};
const SettingsItem = ({
  title,
  icon,
  checked,
  toggleOn,
  toggleOff,
}: SettingsItemProps) => {
  return (
    <ListItem>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText id="switch-list-label-wifi" primary={title} />
      <Switch
        edge="end"
        onChange={() => {
          checked ? toggleOff() : toggleOn();
        }}
        checked={checked}
        inputProps={{
          "aria-labelledby": "switch-list-label-wifi",
        }}
      />
    </ListItem>
  );
};

type Context = {
  settings: string[];
};
const Settings = ({
  context,
  setContext,
}: {
  context: Context;
  setContext: (c: Context) => void;
}) => {
  const isChecked = (value: string) => {
    return context.settings.indexOf(value) !== -1;
  };
  return (
    <List
      sx={{ width: "100%", maxWidth: 480, bgcolor: "background.paper" }}
      subheader={<ListSubheader>Context</ListSubheader>}
    >
      <SettingsItem
        checked={isChecked("In the year 2023")}
        toggleOn={() =>
          setContext({ settings: [...context.settings, "In the year 2023"] })
        }
        toggleOff={() =>
          setContext({
            settings: context.settings.filter((s) => s !== "In the year 2023"),
          })
        }
        title="In the year 2023"
        icon={<CalendarTodayIcon />}
      />
      <SettingsItem
        checked={isChecked("In the USA")}
        toggleOn={() =>
          setContext({ settings: [...context.settings, "In the USA"] })
        }
        toggleOff={() =>
          setContext({
            settings: context.settings.filter((s) => s !== "In the USA"),
          })
        }
        title="In the USA"
        icon={<TravelExploreIcon />}
      />
    </List>
  );
};

const SearchPage = () => {
  const isMobile = useIsMobile();
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [menuOpen, setMenuOpen] = useState(false);
  const [context, setContext] = useState<Context>({
    settings: ["In the USA", "In the year 2023"],
  });
  const [query, setQuery] = useState<string>(queryParams.get("q") || "");
  const { request, data, loading } = useSearch();

  const handleSearch = async () => {
    setMenuOpen(false);
    if (query) {
      const newQueryParams = new URLSearchParams();
      newQueryParams.set("q", query);
      navigate({ search: newQueryParams.toString() });
      await request({
        context: context,
        query,
      });
    }
  };
  const results = data ? data.results : [];
  return (
    <Container
      style={{
        // marginTop: '2rem',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        paddingBottom: "10vh",
        marginTop: "50px",
        ...(results.length || loading ? {} : { height: "100vh" }),
      }}
    >
      <Box
        sx={{
          ...(!isMobile ? { minWidth: "70vh" } : {}),
        }}
      >
        <Typography
          variant="title"
          align="center"
          style={{ fontSize: "50px", marginBottom: "5px" }}
        >
          ⚡tat Me Tha＋
        </Typography>
        <Typography
          align="center"
          style={{ fontSize: "18px", marginBottom: "25px", color: "grey" }}
        >
          LLM powered statistics discovery
        </Typography>
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <IconButton
              sx={{ p: "10px" }}
              aria-label="menu"
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
            >
              <MenuIcon />
            </IconButton>
            <InputBase
              fullWidth
              defaultValue={query}
              onChange={(event) => setQuery(event.target.value)}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Find the right statistic for your needs"
              inputProps={{
                "aria-label": "Find the right statistic for your needs",
              }}
              onKeyDown={(event) => {
                // Allow shift for newlines.
                if (event.key === "Enter" && !event.shiftKey) {
                  event.preventDefault();
                  handleSearch();
                }
              }}
            />
            <IconButton
              type="button"
              sx={{ p: "10px" }}
              aria-label="search"
              onClick={handleSearch}
            >
              <SearchIcon />
            </IconButton>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Collapse in={menuOpen} sx={{ width: "100%" }}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Settings context={context} setContext={setContext} />
              </Box>
            </Collapse>
          </div>
        </Paper>
      </Box>
      {!loading && results && results.length > 0 && (
        <SearchResults queryId={data?.queryId!} results={results} />
      )}
      {loading && <SearchLoading count={5} />}
    </Container>
  );
};

export default SearchPage;
