import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import Address from "../components/family/Address";
import Breadcrumbs from "../components/common/Breadcrumbs";

export default () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [address, setAddress] = useState("");
  const [isLoaded, setIsLoaded] = useState(false);
  const [familyRef, setFamilyRef] = useState("");

  useEffect(() => {
    if (location.state) {
      setAddress(location.state.familyAddress);
      setFamilyRef(location.state.familyRef);
      setIsLoaded(true);
    }
  }, [location]);

  const onSaveHandler = () => {
    navigate(`/family-info`);
  };
  const onCancelHandler = () => {
    navigate(`/family-info`);
  };

  return (
    <Box>
      <Box sx={{ marginTop: "32px", marginLeft: "24px" }}>
        <Breadcrumbs
          breadcrumbs={[
            { name: "Home", link: "/" },
            { name: "Family info", link: "/family-info" },
            { name: "Address" },
          ]}
        />
      </Box>
      {isLoaded && (
        <Address
          familyRef={familyRef}
          address={address}
          onSaveHandler={onSaveHandler}
          onCancelHandler={onCancelHandler}
        />
      )}
    </Box>
  );
};
