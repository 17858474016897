import React, { useContext, useEffect } from "react";
import { FeedEntry, Medium } from "../../protogen/advisors_service_pb";
import { AccountStub, Attachment } from "../../protogen/common_pb";
import { FeedFocusState } from "../../types/feed";
import { CurrentUserContext } from "../context/RequireAuth";
import { isToday } from "../../common/utils";
import DateDisplay from "../common/DateDisplay";
import CallPreview from "./CallPreview";
import EmailPreview from "./EmailPreview";
import { actionForEntry } from "./FeedEntryActionIcons";
import ActivityFeedEntry from "./ActivityFeedEntry";
import { Avatar, Box, Typography } from "@mui/material";
import ActivityDivider from "./ActivityDivider";
import TextMessage from "./TextMessage";
import { TextMessageStatus } from "../../protogen/conversation_pb";
import { EphemeralFact } from "../facts/types";
import LockOpenIcon from "@heroicons/react/24/outline/LockOpenIcon";
import HighlightProvider from "../context/HighlightProvider";

const areArraysEqual = (a: string[], b: string[]) => {
  const s1 = new Set(a);
  const s2 = new Set(b);
  if (s1 === s2) return true;
  if (s1.size !== s2.size) return false;
  for (const item of Array.from(s1.values())) {
    if (!s2.has(item)) return false;
  }
  return true;
};

const LoginLinkEntry = ({
  entry,
  user,
}: {
  entry: FeedEntry;
  user: AccountStub;
}) => {
  return (
    <Box
      key={entry.ref}
      sx={{
        width: "100%",
        backgroundColor: "#FFF",
        padding: "0px 16px",
        border: "1px solid #F2F2F2",
        color: "#3D3D3D",
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        borderRadius: "4px",
        boxShadow: "0px 1px 4px 0px rgba(0, 0, 0, 0.11)",
        overflow: "hidden",
        maxWidth: "345px",
        height: "48px",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "8px",
      }}
    >
      <LockOpenIcon height={17} width={17} />
      <Typography color="text.secondary" variant="bodySmall">
        {user.firstName} logged into Faye
      </Typography>
    </Box>
  );
};

type FeedEntryProps = {
  entry: FeedEntry;
  // Used to format things in a sequence.
  nextEntry?: FeedEntry;
  prevEntry?: FeedEntry;
  isCenteredEntry?: boolean;
  accountMap: Record<string, AccountStub>;
  feedFocusState: FeedFocusState;
  setFeedFocusState: (s: FeedFocusState) => void;
  openAttachment: (a: Attachment) => void;
  suggestFact: (e: EphemeralFact) => void;
  onBookmark?: () => void;
  noAvatars?: boolean;
  familyRef?: string;
};
export default ({
  entry,
  accountMap,
  feedFocusState,
  setFeedFocusState,
  nextEntry,
  prevEntry,
  isCenteredEntry,
  openAttachment,
  suggestFact,
  onBookmark,
  noAvatars,
  familyRef,
}: FeedEntryProps) => {
  const currentUser = useContext(CurrentUserContext);
  const entryDt = new Date(Number(entry.timestampSec) * 1000);
  const time = DateDisplay({ date: entryDt });
  const sender = accountMap[entry.senderRef];
  const loginLinkText =
    entry?.textMessage &&
    /\s*Welcome! Your login url is: .+\/member-login-setup\/[a-zA-Z0-9]+\s*/.test(
      entry.textMessage.content,
    );
  // Helpful if you get confused.
  // console.log(entry.textMessage?.content, ' between ', prevEntry?.textMessage?.content, ' and ', nextEntry?.textMessage?.content);
  const SAME_TS_THRESHOLD_SEC = 90 * 60;
  const includeSender =
    !loginLinkText &&
    (!prevEntry ||
      prevEntry.senderRef !== entry.senderRef ||
      !areArraysEqual(prevEntry.recipientRefs, entry.recipientRefs));
  const includeTime =
    !nextEntry ||
    Number(nextEntry.timestampSec) - Number(entry.timestampSec) >
      SAME_TS_THRESHOLD_SEC;
  const includeHeader = includeSender || includeTime;
  const includeAvatar =
    !noAvatars && includeSender && sender && !sender.isAdvisor;
  const includeTodayDivider =
    isToday(entryDt) &&
    (!prevEntry || !isToday(new Date(Number(prevEntry.timestampSec) * 1000)));

  const getSubcomponent = (medium: Medium) => {
    if (loginLinkText) {
      return (
        <LoginLinkEntry
          entry={entry}
          user={accountMap[entry.recipientRefs[0]]}
        />
      );
    }
    switch (medium) {
      case Medium.SMS:
        return (
          <HighlightProvider
            familyRef={familyRef}
            entityRef={entry.textMessage!.ref}
            entityType={"message"}
          >
            <TextMessage
              loading={
                entry.textMessage!.status === TextMessageStatus.STATUS_PENDING
              }
              error={
                entry.textMessage!.status ===
                TextMessageStatus.STATUS_UNDELIVERED
                  ? "Message failed to send."
                  : undefined
              }
              alignLeft={!(sender && sender.isAdvisor)}
              content={entry.textMessage!.content}
              attachments={entry.textMessage!.attachments}
              openAttachment={openAttachment}
              sx={{
                backgroundColor: !(sender && sender.isAdvisor)
                  ? "#FFF"
                  : "#1C9191",
              }}
            />
          </HighlightProvider>
        );
      case Medium.PHONE:
        return <CallPreview call={entry.phoneCall!} />;
      case Medium.EMAIL:
        return (
          <EmailPreview
            isAdvisor={sender && sender.isAdvisor}
            email={entry.emailMessage!}
            feedFocusState={feedFocusState}
            setFeedFocusState={setFeedFocusState}
          />
        );
      default:
        return null;
    }
  };
  let action = actionForEntry(entry, suggestFact);
  const buildHeader = (): string => {
    if (loginLinkText) return "";
    if (!sender && entry.medium === Medium.EMAIL) {
      return entry.emailMessage?.fromAddress || "";
    }
    if (sender && !sender.isAdvisor) {
      if (entry.recipientRefs.length > 1) {
        const otherRecipients = entry.recipientRefs
          .filter((r) => !(accountMap[r]?.isAdvisor || r === sender.ref))
          .map((r) => accountMap[r]?.firstName || "Unknown")
          .join(", ");
        return `${sender.firstName} → ${otherRecipients}`;
      }
      return `${sender.firstName}`;
    }
    const senderName =
      currentUser.ref === entry.senderRef ? "You" : `${sender.firstName}`;
    if (!entry.recipientRefs.length || !accountMap[entry.recipientRefs[0]]) {
      return senderName;
    }
    return `${senderName} → ${entry.recipientRefs
      .map((r) => accountMap[r].firstName)
      .join(", ")}`;
  };

  useEffect(() => {
    const highlightedElement = document.querySelector(
      "[data-is-highlighted='true']",
    );
    if (highlightedElement) {
      highlightedElement.scrollIntoView({ behavior: "smooth" });
    }
  }, [isCenteredEntry]);
  const entryToBookmarks = (e: FeedEntry) => {
    if (e.medium === Medium.EMAIL) {
      return e.emailMessage!.taskBookmarks;
    } else if (e.medium === Medium.SMS) {
      return e.textMessage!.taskBookmarks;
    }
    return [];
  };
  return (
    <>
      <ActivityFeedEntry
        highlighted={isCenteredEntry ? "#C8DBD7" : undefined}
        alignLeft={loginLinkText || !(sender && sender.isAdvisor)}
        avatarIcon={
          includeAvatar && (
            <Avatar sx={{ height: 40, width: 40 }}>
              {sender && sender.firstName[0]}
              {sender && sender.lastName ? sender.lastName[0] : ""}
            </Avatar>
          )
        }
        action={action}
        avatarGutter={!(loginLinkText || noAvatars)}
        headerLeftText={includeHeader ? buildHeader() : null}
        headerRightText={includeHeader ? time : null}
        onBookmark={onBookmark}
        taskBookmarks={entryToBookmarks(entry)}
      >
        {getSubcomponent(entry.medium)}
      </ActivityFeedEntry>
      {includeTodayDivider && <ActivityDivider text={"Today"} />}
    </>
  );
};
