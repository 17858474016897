import { useState, useContext, useEffect } from "react";
import { Box, MobileStepper } from "@mui/material";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { NuxDetailsContext } from "../../components/context/AdvisorNuxContext";
import { getStepperIndex } from "./utils";
import { ReactComponent as FayeIcon } from "../../icons/FayeBrand.svg";
import useIsMobile from "../hooks/useIsMobile";
import {
  USER_NUX_TYPE,
  ADVISOR_NUX_STEPS,
  MEMBER_NUX_STEPS,
} from "../../components/nux/constants";

type DeskstopStepperProps = {
  steps: { label: string }[];
  activeStep: number;
};

const DeskstopStepper: React.FC<DeskstopStepperProps> = ({
  steps,
  activeStep,
}) => {
  return (
    <Box
      sx={{
        padding: "32px",
        minWidth: "260px",
        display: "flex",
        flexDirection: "column",
        gap: "47px",
      }}
    >
      <FayeIcon
        style={{
          height: "31px",
          width: "53px",
        }}
      />
      <Stepper
        sx={{
          ".MuiStepConnector-line": {
            borderColor: "#D4D4D4",
            height: "52px",
            width: "2px",
          },
          ".Mui-completed .MuiStepConnector-line, .Mui-active .MuiStepConnector-line":
            {
              borderColor: "primary.main",
            },
          ".MuiStepIcon-root.MuiStepIcon-text": {
            fill: "#D4D4D4",
          },
          ".MuiStepLabel-label.Mui-disabled": {
            color: "#8E9598",
          },
        }}
        activeStep={activeStep}
        orientation="vertical"
      >
        {steps.map((step) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

const _MobileStepper: React.FC<DeskstopStepperProps> = ({
  steps,
  activeStep,
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#FDFAF7",
        width: "100%",
      }}
    >
      <Box sx={{ padding: "20px 27px", height: "67px" }}>
        {activeStep > 0 && <FayeIcon />}
      </Box>
      <MobileStepper
        variant="progress"
        steps={steps.length}
        position="static"
        activeStep={activeStep}
        nextButton={<span style={{ width: "0px" }}></span>}
        backButton={<span></span>}
        sx={{
          padding: "0px",
          height: "8px",
          // Stepper requires buttons, but we can set them to null
          // Then we make the progres bar 100%
          // plain width: 100% will not work
          ".MuiLinearProgress-root": {
            width: "100%",
          },
        }}
      />
    </Box>
  );
};

export default ({ userType }: { userType: USER_NUX_TYPE }) => {
  const nuxDetails = useContext(NuxDetailsContext);
  const isMobile = useIsMobile();
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const activeStep = getStepperIndex(nuxDetails.stepName, userType);
    setActiveStep(activeStep);
  }, [nuxDetails]);

  const steps =
    userType === USER_NUX_TYPE.ADVISOR ? ADVISOR_NUX_STEPS : MEMBER_NUX_STEPS;

  return (
    <Box>
      {isMobile ? (
        <_MobileStepper steps={steps} activeStep={activeStep} />
      ) : (
        <DeskstopStepper steps={steps} activeStep={activeStep} />
      )}
    </Box>
  );
};
