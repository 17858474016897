import { ForumPost } from "../../protogen/forum_service_pb";
import useIsMobile from "../hooks/useIsMobile";
import { Box, Typography } from "@mui/material";
import LinkRouter from "../navigation/LinkRouter";
import PostTopics from "./PostTopics";
import { Pin } from "lucide-react";
import { POST_LABELS_BY_TYPE } from "./constants";
import { UserAvatar } from "../common/CurrentUserAvatar";
import PostLikesAndComments from "./PostLikesAndComments";

export default ({
  post,
  respectPinned,
}: {
  post: ForumPost;
  respectPinned: boolean;
}) => {
  const isMobile = useIsMobile();

  return (
    <LinkRouter to={`/community/${encodeURIComponent(post.ref)}`}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          padding: isMobile ? "16px 24px" : "24px 32px",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "12px",
          borderRadius: "12px",
          border: "1px solid #D4D4D4",
          background: "#FFF",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: "4px",
              alignSelf: "stretch",
            }}
          >
            <Typography
              variant="bodySmallHeavy"
              sx={(theme) => ({
                color: theme.palette.primary.main,
              })}
            >
              {POST_LABELS_BY_TYPE[post.postType]}
            </Typography>
            <Typography
              variant="h4"
              color="text.primary"
              sx={{
                width: "100%",
              }}
            >
              {post.title}
            </Typography>
          </Box>
          {post.pinnedSec && respectPinned ? (
            <Pin size={24} color={"#8E9598"} />
          ) : null}
        </Box>
        <Box
          sx={{
            maxHeight: isMobile ? "60px" : "68px",
            overflow: "hidden",
            width: "100%",
          }}
        >
          <Typography variant={isMobile ? "bodySmall" : "body"} color="#616161">
            {post.body?.textContent}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "12px",
          }}
        >
          <UserAvatar user={post.author!} size={32} />
          <Typography variant="bodySmall" color="text.secondary">
            {post.author?.displayName}
          </Typography>
        </Box>
        {!isMobile && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <PostLikesAndComments post={post} />
            <PostTopics topics={post.topics} />
          </Box>
        )}
        {isMobile && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              gap: "12px",
            }}
          >
            <PostTopics topics={post.topics} />
            <PostLikesAndComments post={post} />
          </Box>
        )}
      </Box>
    </LinkRouter>
  );
};
